import { Card, CardContent, Typography } from "@mui/material";

const Description = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="inherit" color="text.primary">
          野球の配球の記録を中心としたスコアブックWebアプリです。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          フォーマットは配球チャート、配球表、配球シートの形式です。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          なお、配球データを自動集計し、球種やコースを円グラフやコース別分布で表示ができ、投手別に対左、右打者別に得意球やそのコースを把握したり、配球の型を検討できます。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          また、カウントごとにも自動集計するため、投手や捕手だけでなく、打者目線で相手の配球のパターンも研究できます。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          さらに、個別で考えた配球を公開設定とすることもでき、他のユーザーの配球を見ることもできたり、限定的な公開で考えた配球をチームのメンバーにリンク共有できたりもします。
          {/* </Typography>
        <br />
        <Typography variant="inherit" color="text.primary"> */}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Description;
