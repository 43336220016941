import { useEffect } from "react";
import { Typography, Box } from "@mui/material";

const RegisterFinish = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {props.error ? (
        <Box>
          <Typography sx={{ mt: 2, mb: 1, margin: 5 }}>
            配球の登録に失敗しました。
          </Typography>
          <Typography sx={{ mt: 2, mb: 1, margin: 5 }}>
            インターネット接続を確認するか、しばらく時間がたってから再度操作してください。
          </Typography>
        </Box>
      ) : (
        <Typography sx={{ mt: 2, mb: 1, margin: 5 }}>
          配球が登録されました
        </Typography>
      )}
    </>
  );
};

export default RegisterFinish;
