import { Container, Box, Grid } from "@mui/material";

const NotFound = () => {
  return (
    <Box>
      <Container sx={{ fontSize: 16, margin: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            申し訳ございません。
          </Grid>
          <Grid item xs={12} sm={12}>
            指定されたページは存在しません。
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotFound;
