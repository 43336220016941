import TextInput from "./Forms/TextInput";
import { InputNoteContext } from "./NoteBody";
import { useState, useCallback, useContext } from "react";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";

const situationInput = () => {
  const { inputs, setInputs } = useContext(InputNoteContext);
  const [inning, setInning] = useState(null);
  const [topOrBottom, setTopOrBottom] = useState("");
  const [outCounts, setOutCounts] = useState(null);
  const [runner, setRunner] = useState(null);

  const inputMatchDate = useCallback(
    (event) => {
      setInputs({ ...inputs, matchDate: event.target.value });
    },
    [inputs]
  );

  const selectInning = useCallback(
    (event) => {
      setInning(event.target.value);

      setInning((prevState) => {
        setInputs({ ...inputs, inning: prevState });
      });
    },
    [inputs, inning]
  );

  const selectTopOrBottom = useCallback(
    (event) => {
      setTopOrBottom(event.target.value);

      setTopOrBottom((prevState) => {
        setInputs({ ...inputs, topOrBottom: prevState });
      });
    },
    [inputs, topOrBottom]
  );

  const selectOutCounts = useCallback(
    (event) => {
      setOutCounts(event.target.value);

      setOutCounts((prevState) => {
        setInputs({ ...inputs, outCounts: prevState });
      });
    },
    [inputs, outCounts]
  );

  const selectRunner = useCallback(
    (event) => {
      setRunner(event.target.value);

      setRunner((prevState) => {
        setInputs({ ...inputs, runner: prevState });
      });
    },
    [inputs, runner]
  );

  const inputBatterTeamScore = useCallback(
    (event) => {
      // 得点は0点以上
      if (event.target.value < 0) {
        event.target.value = 0;
      }
      setInputs({ ...inputs, batterTeamScore: event.target.value });
    },
    [inputs]
  );

  const inputPitcherTeamScore = useCallback(
    (event) => {
      // 得点は0点以上
      if (event.target.value < 0) {
        event.target.value = 0;
      }
      setInputs({ ...inputs, pitcherTeamScore: event.target.value });
    },
    [inputs]
  );

  return (
    <div className="situationInputs">
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          <InputLabel id="select-matchdate-label">試合日</InputLabel>
          <TextInput
            fullWidth={true}
            labelId="select-matchdate-label"
            multiline={false}
            rows={1}
            type={"date"}
            value={inputs.matchDate}
            onChange={inputMatchDate}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          {/* <Box sx={{ minWidth: 120 }}> */}
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-inning-label">イニング</InputLabel>
              <Select
                labelId="select-inning-label"
                value={inputs.inning}
                fullWidth
                label="イニング"
                onChange={selectInning}
              >
                <MenuItem value={1}>1回</MenuItem>
                <MenuItem value={2}>2回</MenuItem>
                <MenuItem value={3}>3回</MenuItem>
                <MenuItem value={4}>4回</MenuItem>
                <MenuItem value={5}>5回</MenuItem>
                <MenuItem value={6}>6回</MenuItem>
                <MenuItem value={7}>7回</MenuItem>
                <MenuItem value={8}>8回</MenuItem>
                <MenuItem value={9}>9回</MenuItem>
                <MenuItem value={10}>10回</MenuItem>
                <MenuItem value={11}>11回</MenuItem>
                <MenuItem value={12}>12回</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          {/* <Box sx={{ minWidth: 120 }}> */}
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-toporbottom-label">表裏</InputLabel>
              <Select
                labelId="select-toporbottom-label"
                value={inputs.topOrBottom}
                fullWidth
                label="表裏"
                onChange={selectTopOrBottom}
              >
                <MenuItem value={"top"}>表</MenuItem>
                <MenuItem value={"bottom"}>裏</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          {/* <Box sx={{ minWidth: 120 }}> */}
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-outCounts-label">
                アウトカウント
              </InputLabel>
              <Select
                labelId="select-outCounts-label"
                value={inputs.outCounts}
                fullWidth
                label="アウトカウント"
                onChange={selectOutCounts}
              >
                <MenuItem value={0}>ノーアウト</MenuItem>
                <MenuItem value={1}>ワンアウト</MenuItem>
                <MenuItem value={2}>ツーアウト</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          {/* <Box sx={{ minWidth: 120 }}> */}
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-runner-label">ランナー</InputLabel>
              <Select
                labelId="select-runner-label"
                value={inputs.runner}
                fullWidth
                label="ランナー"
                onChange={selectRunner}
              >
                <MenuItem value={0}>なし</MenuItem>
                <MenuItem value={1}>一塁</MenuItem>
                <MenuItem value={2}>二塁</MenuItem>
                <MenuItem value={3}>三塁</MenuItem>
                <MenuItem value={4}>一、二塁</MenuItem>
                <MenuItem value={5}>一、三塁</MenuItem>
                <MenuItem value={6}>二、三塁</MenuItem>
                <MenuItem value={7}>満塁</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          <TextInput
            fullWidth={true}
            label={"打者チーム得点"}
            multiline={false}
            rows={1}
            type={"number"}
            value={inputs.batterTeamScore}
            onChange={inputBatterTeamScore}
            // error={inputs.batterScore<0}
            // helperText={inputs.batterScore<0 ? "得点を0点以上にしてください": " "}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          <TextInput
            fullWidth={true}
            label={"投手チーム得点"}
            multiline={false}
            rows={1}
            type={"number"}
            value={inputs.pitcherTeamScore}
            onChange={inputPitcherTeamScore}
          />
        </div>
      </Grid>
    </div>
  );
};

export default situationInput;
