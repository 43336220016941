import { FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";
import TextInput from "../Forms/TextInput";
import { Box } from "@mui/system";

export function StartingMemberInput(props) {
  const inputStartingMember = (e) => {
    let tempStartingMember = props.startingMember;
    tempStartingMember[props.label][0].name = e.target.value;
    props.setStartingMember(tempStartingMember);
  };

  const inputStartingMemberPosition = (e) => {
    let tempStartingMember = props.startingMember;
    tempStartingMember[props.label][0].position = e.target.value;
    props.setStartingMember(tempStartingMember);
    props.checkDH();
  };

  return (
    <Grid container>
      <Grid item xs={9} sm={9}>
        <Box>
          <TextInput
            fullWidth={true}
            label={props.label}
            multiline={false}
            rows={1}
            type={"text"}
            onChange={inputStartingMember}
          />
        </Box>
      </Grid>
      {props.label !== "投手" && (
        <Grid item xs={3} sm={3}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-defensive-position-label">守備</InputLabel>
              <Select
                labelId="select-defensive-position-label"
                // id="select-bat"
                fullWidth
                label="守備"
                onChange={inputStartingMemberPosition}
              >
                <MenuItem value={0}>　</MenuItem>
                <MenuItem value={1}>投</MenuItem>
                <MenuItem value={2}>捕</MenuItem>
                <MenuItem value={3}>一</MenuItem>
                <MenuItem value={4}>二</MenuItem>
                <MenuItem value={5}>三</MenuItem>
                <MenuItem value={6}>遊</MenuItem>
                <MenuItem value={7}>左</MenuItem>
                <MenuItem value={8}>中</MenuItem>
                <MenuItem value={9}>右</MenuItem>
                <MenuItem value={10}>DH</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
