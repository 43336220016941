import { Amplify } from "aws-amplify";
import { InputLabel, Box } from "@mui/material";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
import { I18n } from "aws-amplify";
import { CustomPageLabel } from "./index";
import { vocabularies } from "../assets/i18n/amplify/vocabularies";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Introduction from "./Introduction";
import YanoSanImgPath from "../assets/img/yano_san.png";

I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

Amplify.configure(awsExports);

const Auth = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  return (
    <div id="authDisplay">
      <Authenticator initialState="signUp">
        {({ signOut, user }) => (
          <main>
            <Introduction></Introduction>
          </main>
        )}
      </Authenticator>
      {route !== "authenticated" && (
        <InputLabel
          variant="standard"
          sx={{
            color: "text.primary",
            fontSize: 14,
            fontWeight: "medium",
            textAlign: "left",
            marginTop: 2,
          }}
        >
          新規登録済みの方は「ログイン」タブを
        </InputLabel>
      )}
      {route !== "authenticated" && (
        <InputLabel
          variant="standard"
          sx={{
            color: "text.primary",
            fontSize: 14,
            fontWeight: "medium",
            textAlign: "left",
            marginBottom: 2,
          }}
        >
          クリックし、ログインしてください。
        </InputLabel>
      )}
      {route !== "authenticated" && (
        <>
          <InputLabel
            variant="standard"
            sx={{
              color: "text.primary",
              fontSize: 14,
              fontWeight: "medium",
              textAlign: "left",
              marginBottom: 2,
            }}
          ></InputLabel>
          <img src={YanoSanImgPath} alt="矢野さん" width={130} height={90} />
        </>
      )}
    </div>
  );
};

export default Auth;
