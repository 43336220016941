import { useDrag } from "react-dnd";

const style = {
  position: "absolute",
  width: 25,
  height: 25,
};

const ballStyleChange = [
  "changeup",
  "slowball",
  "palm",
  "knuckle",
  "curve",
  "sinker",
  "screw",
  "slider",
  "cutball",
  "twoseams",
  "fork",
  "verticalslider",
  "sff",
];

const styleChange = {
  position: "absolute",
  width: 31,
  height: 31,
};

const CreateImagePath = (type, count) => {
  if (ballStyleChange.includes(type)) {
    return (
      <img
        src={require(`../assets/img/balls/${type}_${count}.png`)}
        width={styleChange.width}
        height={styleChange.height}
        alt={type}
      />
    );
  }
  return (
    <img
      src={require(`../assets/img/balls/${type}_${count}.png`)}
      width={style.width}
      height={style.height}
      alt={type}
    />
  );
};

const SelectBallImage = (type, count) => CreateImagePath(type, count);
const Ball = ({ id, left, top, type }) => {
  // console.log(`left:${left}, top:${top}, type:${type}`);
  const [, drag] = useDrag(
    () => ({
      type: "ball",
      // left top をDragするときに追跡するJavascript要素とする。
      // ここで指定した値がdropのほうで引数として使用することができる。item.top、item.leftとして。
      item: { id, left, top, type },
    }),
    [id, left, top, type]
  );
  return (
    // dragをrefに指定することでdragする要素を指定する
    // もともとあるstyleの要素にあわせてleft,topの要素を展開する
    // styleはleftって書くとたぶんleft:leftにしてくれるんだと思う
    <div ref={drag} id={id} style={{ ...style, left, top }}>
      {SelectBallImage(type, id)}
    </div>
  );
};

export default Ball;
