import { Ball } from "../index";
import pichsequenceImagePath from "../../assets/img/pichsequence4_1.png";
import { CustomDragLayer } from "../CustumDragLayer";
import { Container } from "@mui/material";
import { useDrop } from "react-dnd";
import { useCallback } from "react";
import { IsMobileDevice } from "../../App";
import update from "react-addons-update";

export function GameSeq(props) {
  const moveBall = useCallback(
    (id, left, top) => {
      props.setBalls(
        update(props.balls, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [props.balls, props.setBalls]
  );

  const [, drop] = useDrop(
    () => ({
      // https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer
      // useDragで定義したDragする要素をここで指定することで
      // 指定のDrop要素内でDrag操作できるようにする
      accept: "ball",
      // DragDrop情報をモニタリングし、都度その情報をDropに返す
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBall(item.id, left, top);
      },
      // moveBoxが更新されるたびにuseDropが発火
    }),
    [moveBall]
  );

  return (
    <Container sx={{ bgcolor: "Transparent" }}>
      {/* dropをrefに指定することでDrop要素を指定 */}
      <div id="pitchSequenceParentFrame">
        <div ref={drop} id="pitchSequenceChildFrame">
          <img
            src={pichsequenceImagePath}
            id="pitchSequenceImage"
            alt="配球画面"
          />
          {/* モバイルのときはデフォルトではドラッグときの軌跡が描画されないので
                          描画されるようにカスタム */}
          {IsMobileDevice() && <CustomDragLayer />}

          {Object.keys(props.balls).map((key) => {
            const { left, top, type } = props.balls[key];
            // 球数に応じて表示する球を変える
            if (props.count >= key) {
              return (
                <Ball key={key} id={key} left={left} top={top} type={type} />
              );
            }
          })}
        </div>
      </div>
    </Container>
  );
}
