import { ViewSequenceSummary } from "./index";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function ViewSequencesSummaryPrivateList(props) {
  return (
    <div>
      {props.games.map((game) =>
        game.privateMatches.items.map((match) =>
          match.cases.items.map((c) => {
            return (
              <Card
                variant="outlined"
                sx={{ width: "315px", marginBottom: "10px" }}
              >
                <CardContent>
                  <ViewSequenceSummary
                    title={match.title}
                    case={c}
                  ></ViewSequenceSummary>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    component={Link}
                    to={`/search/${props.owner}/${c.id}?gameId=${game.id}`}
                  >
                    詳細を見る
                  </Button>
                </CardActions>
              </Card>
            );
          })
        )
      )}
    </div>
  );
}
