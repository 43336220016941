import { useCallback, useState, useEffect } from "react";
import CustomPageLabel from "../CustomPageLabel";
import {
  Typography,
  InputLabel,
  Button,
  Alert,
  Radio,
  RadioGroup,
  Box,
  FormControlLabel,
  CircularProgress,
  Container,
} from "@mui/material";
import TextInput from "../Forms/TextInput";
import { StartingMemberInput } from "./StartingMemberInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "../Situation";
import { Auth } from "../index";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { API, Storage } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { Auth as AWSAuth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const defaultMember = {
  "1番打者": [{ name: "", position: "", bat: "right" }],
  "2番打者": [{ name: "", position: "", bat: "right" }],
  "3番打者": [{ name: "", position: "", bat: "right" }],
  "4番打者": [{ name: "", position: "", bat: "right" }],
  "5番打者": [{ name: "", position: "", bat: "right" }],
  "6番打者": [{ name: "", position: "", bat: "right" }],
  "7番打者": [{ name: "", position: "", bat: "right" }],
  "8番打者": [{ name: "", position: "", bat: "right" }],
  "9番打者": [{ name: "", position: "", bat: "right" }],
  投手: [{ name: "", position: 1, throw: "right" }],
};

export function GameStartingMember() {
  // ログイン状態の取得
  const { route } = useAuthenticator((context) => [context.route]);

  const [owner, setOwner] = useState("");

  useEffect(async () => {
    if (route === "authenticated") {
      const user = await AWSAuth.currentAuthenticatedUser();
      if (user) {
        setOwner(user.attributes.sub);
      }
    }
  }, [route]);

  const [expandedGameInfoEtc, setExpandedGameInfoEtc] = useState(true);
  const handleChangeGameInfoEtc = () => (event) => {
    setExpandedGameInfoEtc(expandedGameInfoEtc ? false : true);
  };

  const [expandedFirstMember, setExpandedFirstMember] = useState(true);
  const handleChangeFirstMember = () => (event) => {
    setExpandedFirstMember(expandedFirstMember ? false : true);
  };
  const [firstStartingMember, setFirstStartingMember] = useState(
    JSON.parse(JSON.stringify(defaultMember))
  );

  const [expandedSecondMember, setExpandedSecondMember] = useState(true);
  const handleChangeSecondMember = () => (event) => {
    setExpandedSecondMember(expandedSecondMember ? false : true);
  };

  const [secondStartingMember, setSecondStartingMember] = useState(
    JSON.parse(JSON.stringify(defaultMember))
  );

  const [DH, setDH] = useState(false);

  const isDHCheck = () => {
    setDH(false);
    for (let i = 0; i < 9; i++) {
      if (firstStartingMember[`${i + 1}番打者`][0].position === 10) {
        setDH(true);
        break;
      }
    }
    for (let i = 0; i < 9; i++) {
      if (secondStartingMember[`${i + 1}番打者`][0].position === 10) {
        setDH(true);
        break;
      }
    }
  };

  const [gameTitle, setGameTitle] = useState("");
  const inputGameTitle = (e) => {
    setGameTitle(e.target.value);
  };

  const [gameDate, setGameDate] = useState("");
  const inputGameDate = (e) => {
    setGameDate(e.target.value);
  };

  const [secondAttackTeam, setSecondAttackTeam] = useState("");
  const inputSecondAttackTeam = (e) => {
    setSecondAttackTeam(e.target.value);
  };

  const [firstAttackTeam, setFirstAttackTeam] = useState("");
  const inputFirstAttackTeam = (e) => {
    setFirstAttackTeam(e.target.value);
  };

  const [disclosure, setDisclosure] = useState(1);
  const checkDisclodure = useCallback((event) => {
    setDisclosure(event.target.value);
  });

  const [expandedDisclosure, setExpandedDisclosure] = useState(true);
  const handleDisclosure = () => (event) => {
    setExpandedDisclosure(expandedDisclosure ? false : true);
  };

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const registerGame = async () => {
    // 投手欄が出力されているのに、入力されていない場合、投手を探して入力しておく
    const checkPitch = () => {
      if (!firstStartingMember["投手"].name) {
        for (let i = 0; i < 9; i++) {
          if (firstStartingMember[`${i + 1}番打者`][0].position === 1) {
            firstStartingMember["投手"][0].name =
              firstStartingMember[`${i + 1}番打者`][0].name;
          }
        }
      }

      if (!secondStartingMember["投手"].name) {
        for (let i = 0; i < 9; i++) {
          if (secondStartingMember[`${i + 1}番打者`][0].position === 1) {
            secondStartingMember["投手"][0].name =
              secondStartingMember[`${i + 1}番打者`][0].name;
          }
        }
      }
    };

    let newPrivateGame;
    let newPublicGame;

    checkPitch();
    const inputsGame = {
      owner: owner,
      firstAttackTeam: firstAttackTeam,
      secondAttackTeam: secondAttackTeam,
      type: "t",
      date: !gameDate ? "2199-12-31" : gameDate,
      firstAttackMember: JSON.stringify(firstStartingMember),
      secondAttackMember: JSON.stringify(secondStartingMember),
      gameTitle: gameTitle,
      registerFinish: false,
    };
    try {
      if (disclosure === 1) {
        newPrivateGame = await API.graphql({
          query: mutations.createPrivateGameForGameFormat,
          variables: { input: inputsGame },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        // 集計データを作成し配置するS3バケットがわかるようにowner名のファイルをS3に配置する
        // 集計データを作成するLambdaがこのファイル名をさがして、バケットを特定する
        const blob = new Blob([""], { type: "text/plain" });
        await Storage.put(`${owner}.txt`, blob, {
          level: "private",
          contentType: "text/plain",
        });
      } else {
        newPublicGame = await API.graphql({
          query: mutations.createPublicGameForGameFormat,
          variables: { input: inputsGame },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(false);

    if (disclosure === 1) {
      navigate(
        `/game/note/${owner}/${newPrivateGame.data.createPrivateGameForGameFormat.id}?disclosure=private`
      );
    }
    // else {
    //   console.log(newPublicGame);

    //   navigate(
    //     `/game/note/${owner}/${newPublicGame.data.createPublicGameForGameFormat.id}?disclosure=limited`
    //   );
    // }
  };

  const setLoadingAndRegisterGame = () => {
    setLoading(true);
    registerGame();
  };

  return (
    <>
      <CustomPageLabel label="試合を記録する" />
      {route === "authenticated" && (
        <>
          <Typography variant="h7" noWrap component="div" sx={{ margin: 2 }}>
            スターティングメンバーを登録します
          </Typography>
          <Accordion
            expanded={expandedGameInfoEtc}
            onChange={handleChangeGameInfoEtc()}
          >
            <AccordionSummary
              aria-controls="panel-firstmember-content"
              id="panel-firstmember-header"
              sx={{ backgroundColor: "#AFEEEE" }}
            >
              <Typography>
                <span className="required">必須</span> タイトル・日程
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InputLabel id="select-game-title-label">タイトル</InputLabel>
              <TextInput
                fullWidth={true}
                labelId="select-game-title-label"
                multiline={false}
                rows={1}
                type={"text"}
                value={gameTitle}
                onChange={inputGameTitle}
                placeholder={"例）○○高校 vs △△高校 ○回戦"}
                error={!gameTitle}
                helperText={!gameTitle && "タイトルを入力してください"}
              />
              <InputLabel id="select-game-date-label">日程</InputLabel>
              <TextInput
                fullWidth={true}
                labelId="select-game-date-label"
                multiline={false}
                rows={1}
                type={"date"}
                value={gameDate}
                onChange={inputGameDate}
                error={!gameDate}
                helperText={!gameDate && "日程を入力してください"}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedFirstMember}
            onChange={handleChangeFirstMember()}
          >
            <AccordionSummary
              aria-controls="panel-firstmember-content"
              id="panel-firstmember-header"
              sx={{ backgroundColor: "#AFEEEE" }}
            >
              <Typography>
                <span className="optional">任意</span> 先攻
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextInput
                fullWidth={true}
                label={"チーム名"}
                multiline={false}
                rows={1}
                type={"text"}
                value={firstAttackTeam}
                onChange={inputFirstAttackTeam}
              />

              {Array(9)
                .fill()
                .map((_, i) => (
                  <StartingMemberInput
                    key={i}
                    label={`${i + 1}番打者`}
                    startingMember={firstStartingMember}
                    setStartingMember={setFirstStartingMember}
                    checkDH={isDHCheck}
                  />
                ))}
              {/* DHが指定された場合は先発投手名を記入する */}
              {DH && (
                <StartingMemberInput
                  label="投手"
                  startingMember={firstStartingMember}
                  setStartingMember={setFirstStartingMember}
                />
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expandIcon={<ExpandMoreIcon />}
            expanded={expandedSecondMember}
            onChange={handleChangeSecondMember()}
          >
            <AccordionSummary
              aria-controls="panel-firstmember-content"
              id="panel-firstmember-header"
              sx={{ backgroundColor: "#AFEEEE" }}
            >
              <Typography>
                <span className="optional">任意</span> 後攻
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextInput
                fullWidth={true}
                label={"チーム名"}
                multiline={false}
                rows={1}
                type={"text"}
                value={secondAttackTeam}
                onChange={inputSecondAttackTeam}
              />
              {Array(9)
                .fill()
                .map((_, i) => (
                  <StartingMemberInput
                    key={i}
                    label={`${i + 1}番打者`}
                    startingMember={secondStartingMember}
                    setStartingMember={setSecondStartingMember}
                    checkDH={isDHCheck}
                  />
                ))}
              {/* DHが指定された場合は先発投手名を記入する */}
              {DH && (
                <StartingMemberInput
                  label="投手"
                  startingMember={secondStartingMember}
                  setStartingMember={setSecondStartingMember}
                />
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedDisclosure}
            onChange={handleDisclosure()}
          >
            <AccordionSummary
              aria-controls="panel-disclosure-content"
              id="panel-disclosure-header"
              sx={{ backgroundColor: "#AFEEEE" }}
            >
              <Typography>
                <span className="required">必須</span> 公開範囲（非公開のみ）
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                aria-labelledby="disclosure-setting-radio-buttons-group-label"
                name="disclosure-setting-radio-buttons-group"
                onChange={checkDisclodure}
                value={disclosure}
                sx={{ marginBottom: 2 }}
              >
                <Box component="div" sx={{ display: "inline" }}>
                  {/* <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="URLを知ってる人は閲覧可"
                  /> */}
                </Box>

                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="非公開"
                />
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
          {error && (
            <Alert
              severity="error"
              onClose={() => {
                setError(false);
              }}
            >
              {" "}
              登録が失敗しました、ネットワーク接続を確認してください。{" "}
            </Alert>
          )}
          {loading && <CircularProgress />}
          <Container>
            <Button
              variant="outlined"
              startIcon={<ModeCommentIcon />}
              onClick={setLoadingAndRegisterGame}
              sx={{ marginBottom: 2 }}
              disabled={!gameTitle || !gameDate}
              size="large"
            >
              {" "}
              登録
            </Button>
          </Container>
        </>
      )}
      {route !== "authenticated" && (
        <div id="noteAuthDisplay">
          {" "}
          <InputLabel
            variant="standard"
            sx={{
              color: "text.primary",
              fontSize: 14,
              fontWeight: "medium",
              textAlign: "left",
              marginTop: 2,
            }}
          >
            「試合を記録する」ご利用の場合
          </InputLabel>
          <div id="noteAuthDisplay">
            {" "}
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 14,
                fontWeight: "medium",
                textAlign: "left",
                // margin: 2,
              }}
            >
              新規登録頂き、ログイン願います。
            </InputLabel>
          </div>
          <Auth />
        </div>
      )}
    </>
  );
}
