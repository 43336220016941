import { CustomPageLabel } from "./index";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, Link, Grid, Box } from "@mui/material";

function Learn() {
  return (
    <>
      <CustomPageLabel label="配球を勉強する" />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ maxWidth: 300 }}>
              {/* <CardMedia
            sx={{ height: 140 }}
            image="/static/images/cards/contemplative-reptile.jpg"
            title="green iguana"
          /> */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  配球の基本②（谷繁ノート 書評）
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <br />
                  NPBの通算出場記録を持つ谷繁元信さんが書いた谷繁ノートを読み、そこから配球の基本をここに書いてみたい...
                </Typography>
              </CardContent>
              <CardActions>
                <Link href="/learn/0509">
                  <Button size="small">続きを見る</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ maxWidth: 300 }}>
              {/* <CardMedia
            sx={{ height: 140 }}
            image="/static/images/cards/contemplative-reptile.jpg"
            title="green iguana"
          /> */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  配球の基本①、初球の入り方（フルタの方程式 書評）
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <br />
                  配球は基本としてその考え方を抽出することは難しいことだと思っていたが、この本にはその配球の基本というものが抽出され表現されており、素晴らしい本だと思った...
                </Typography>
              </CardContent>
              <CardActions>
                <Link href="/learn/0501">
                  <Button size="small">続きを見る</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ maxWidth: 300 }}>
              {/* <CardMedia
            sx={{ height: 140 }}
            image="/static/images/cards/contemplative-reptile.jpg"
            title="green iguana"
          /> */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  打者の裏をかく配球、リードとは
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  配球の一番の醍醐味のひとつといえる裏をかく配球、リードを考えてみたい...
                </Typography>
              </CardContent>
              <CardActions>
                <Link href="/learn/0208">
                  <Button size="small">続きを見る</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ maxWidth: 300 }}>
              {/* <CardMedia
            sx={{ height: 140 }}
            image="/static/images/cards/contemplative-reptile.jpg"
            title="green iguana"
          /> */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  配球に頼らなくて良いとき
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  野球の醍醐味である配球はあらゆる要素を考慮し、正解があるとも限らない中で、なんとかひとつの選択をする難しい行為だと思う。
                  <br />
                  ただ、そもそも配球に頼りすぎる必要がないラッキーなケースもあるのではないかと考えた...
                </Typography>
              </CardContent>
              <CardActions>
                <Link href="/learn/0107">
                  <Button size="small">続きを見る</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ maxWidth: 300 }}>
              {/* <CardMedia
            sx={{ height: 140 }}
            image="/static/images/cards/contemplative-reptile.jpg"
            title="green iguana"
          /> */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  バントや進塁打を打たせたくないときの配球
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  バントや進塁打を打たせたくないときの配球を考えてみたい...
                </Typography>
              </CardContent>
              <CardActions>
                <Link href="/learn/0129">
                  <Button size="small">続きを見る</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Learn;
