import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// reference https://www.youtube.com/watch?v=HCsFwwolXZw&t=275s

const Footer = () => (
  <footer>
    <Box
      bgcolor="#009999"
      px={{ xs: 3, sm: 5 }}
      py={{ xs: 3, sm: 5 }}
      fontSize={12}
      sx={{ marginTop: 5, bottom: 0 }}
    // sx={{ marginTop: 5, bottom: 0 }}
    >
      <Container maxWidth="xs">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={3}>
            <Box borderBottom={1}>Contents</Box>
            <Box>
              <Link href="/game/note/menu" color="inherit">
                試合を記録
              </Link>
            </Box>
            <Box>
              <Link href="/note" color="inherit">
                作ってみる
              </Link>
            </Box>
            <Box>
              <Link href="/search" color="inherit">
                見る
              </Link>
            </Box>
            <Box>
              <Link href="/learn" color="inherit">
                勉強する
              </Link>
            </Box>
            <Box>
              <Link href="/how" color="inherit">
                操作方法など
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box borderBottom={1}>Account</Box>
            <Box>
              <Link href="/auth" color="inherit">
                ログイン
              </Link>
            </Box>
            <Box>
              <Link href="/user" color="inherit">
                アカウント情報
              </Link>
            </Box>
            {/* <Box>
              <Link href="/" color="inherit">
                Logout
              </Link>
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box borderBottom={1}>Help</Box>
            <Box>
              <Link href="https://twitter.com/PitStrategyNote" color="inherit">
                お問い合わせ
              </Link>
            </Box>
            <Box>
              <Link href="/privacy" color="inherit">
                プライバシーポリシー
              </Link>
            </Box>
            {/* <Box>
              <Link href="/rule" color="inherit">
                利用規約
              </Link>
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={12}>
            ©Copyright Pitching Strategy Note.
          </Grid>
        </Grid>
      </Container>
    </Box>
  </footer>
);

export default Footer;
