import React from "react";
import { createContext, useState } from "react";
import { Box, Grid, Container, Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Situation, Sequence, CustomPageLabel } from "./index";
import TestContext from "./TestContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { IsMobileDevice } from "../App";
import { atBatResults } from "./Sequence";
import { makeStyles } from "@mui/styles";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API, Storage } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import RegisterFinish from "./RegisterFinish";
import HomeBaseImgPath from "../assets/img/homebase_boss.png";

const steps = ["状況", "配球", "確認"];

// Noteの各Stepで入力された内容を保存するオブジェクト
export const InputNoteContext = createContext();

export default function NoteBody() {
  const [activeStep, setActiveStep] = useState(0);

  let confirmDispFlag = false;

  let defaultInputs = {
    batter: "",
    batterTeam: "",
    bat: "",
    atBats: null,
    pitcherTeam: "",
    pitcher: "",
    throw: "",
    matchDate: "",
    inning: null,
    topOrBottom: "",
    outCounts: null,
    runner: null,
    pitcherTeamScore: null,
    batterTeamScore: null,
    memo: "",
    title: "",
    disclosure: 2,
    dataAggrFlag: true,
  };

  const [inputs, setInputs] = useState(defaultInputs);

  let defaultBalls = {
    1: {
      top: 0,
      left: 0,
      type: "straight",
      result: "",
      speed: null,
      strikes: 0,
      balls: 0,
    },
    2: {
      top: 10,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    3: {
      top: 20,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    4: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    5: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    6: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    7: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    8: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    9: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    10: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    11: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    12: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    13: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    14: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
    15: {
      top: 30,
      left: 20,
      type: "straight",
      result: "",
      speed: "",
      strikes: 0,
      balls: 0,
    },
  };

  // ボールの初期位置
  const [balls, setBalls] = useState(defaultBalls);

  const [count, setCount] = useState(1);
  const [strikeCount, setStrikeCount] = useState(0);
  const [ballCount, setBallCount] = useState(0);
  const [registerError, setRegisterError] = useState(false);

  // 入力データを保存するuseStateをコンポーネント間で共有する
  const value = {
    inputs,
    setInputs,
    balls,
    setBalls,
    count,
    setCount,
    strikeCount,
    setStrikeCount,
    ballCount,
    setBallCount,
    registerError,
    setRegisterError,
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // window.scrollTo(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // window.scrollTo(0, 0);
  };

  //  繰り返し登録のため、contextの値を軒並み値を初期化する
  const handleReset = () => {
    setActiveStep(0);
    setInputs(defaultInputs);
    setBalls(defaultBalls);
    setCount(1);
    setStrikeCount(0);
    setBallCount(0);
    setRegisterError(false);
    // window.scrollTo(0, 0);
  };

  const useStyles = makeStyles(() => ({
    root: {
      "& .Mui-active": { color: "orangered" },
      "& .Mui-completed": { color: "green" },
      // "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
    },
  }));

  const c = useStyles();

  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const disclosure = { public: 0, limitedDisclosure: 1, private: 2 };

  const registerSequence = async () => {
    try {
      // 集計データを作成し配置するS3バケットがわかるようにowner名のファイルをS3に配置する
      // 集計データを作成するLambdaがこのファイル名をさがして、バケットを特定する
      const blob = new Blob([""], { type: "text/plain" });
      await Storage.put(`${user.attributes.sub}.txt`, blob, {
        level: "private",
        contentType: "text/plain",
      });

      const inputsGame = {
        owner: user.attributes.sub,
        pitcherTeam: inputs.pitcherTeam,
        batterTeam: inputs.batterTeam,
        type: "t",
        // 空文字は許容しないため、ダミーデータを入れる。2199-12-31
        date: !inputs.matchDate ? "2199-12-31" : inputs.matchDate,
      };

      if (
        inputs.disclosure == disclosure.public ||
        inputs.disclosure == disclosure.limitedDisclosure
      ) {
        const newPublicGame = await API.graphql({
          query: mutations.createPublicGame,
          variables: { input: inputsGame },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const inputsMatch = {
          gameId: newPublicGame.data.createPublicGame.id,
          // sortキーに指定したカラムは空文字が許容されないため、ダミーデータを入れる
          batter: !inputs.batter ? "NODATA" : inputs.batter,
          bat: inputs.bat,
          atBats: inputs.atBats,
          pitcher: !inputs.pitcher ? "NODATA" : inputs.pitcher,
          throw: inputs.throw,
          title: inputs.title,
          limitedDisclosure: inputs.disclosure,
        };

        const newPublicMatch = await API.graphql({
          query: mutations.createPublicMatch,
          variables: { input: inputsMatch },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const inputsCase = {
          matchId: newPublicMatch.data.createPublicMatch.id,
          inning: inputs.inning,
          topOrBottom: inputs.topOrBottom,
          outCounts: inputs.outCounts,
          runner: inputs.runner,
          pitcherTeamScore: parseInt(inputs.pitcherTeamScore),
          batterTeamScore: parseInt(inputs.batterTeamScore),
          dataAggrFlag: inputs.dataAggrFlag,
        };

        const newPublicCase = await API.graphql({
          query: mutations.createPublicCase,
          variables: { input: inputsCase },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        for (let c = 1; c <= count; c++) {
          // 最後の球の結果はatBatResultのほうにも入れる
          // またメモも入れる
          let inputsSequence;
          if (c == count) {
            inputsSequence = {
              caseId: newPublicCase.data.createPublicCase.id,
              sequence: c,
              strikes: balls[c].strikes,
              balls: balls[c].balls,
              speed: parseInt(balls[c].speed),
              pitchResult: balls[c].result,
              atBatResult: balls[c].result,
              pitchXPosition: balls[c].left,
              pitchYPosition: balls[c].top,
              breakingBall: balls[c].type,
              memo: inputs.memo,
            };
          } else {
            inputsSequence = {
              caseId: newPublicCase.data.createPublicCase.id,
              sequence: c,
              strikes: balls[c].strikes,
              balls: balls[c].balls,
              speed: parseInt(balls[c].speed),
              pitchResult: balls[c].result,
              pitchXPosition: balls[c].left,
              pitchYPosition: balls[c].top,
              breakingBall: balls[c].type,
            };
          }
          await API.graphql({
            query: mutations.createPublicSequence,
            variables: { input: inputsSequence },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }
      } else {
        const newPrivateGame = await API.graphql({
          query: mutations.createPrivateGame,
          variables: { input: inputsGame },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const inputsMatch = {
          gameId: newPrivateGame.data.createPrivateGame.id,
          owner: user.attributes.sub,
          batter: !inputs.batter ? "NODATA" : inputs.batter,
          bat: inputs.bat,
          atBats: inputs.atBats,
          pitcher: !inputs.pitcher ? "NODATA" : inputs.pitcher,
          throw: inputs.throw,
          title: inputs.title,
        };

        const newPrivateMatch = await API.graphql({
          query: mutations.createPrivateMatch,
          variables: { input: inputsMatch },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const inputsCase = {
          matchId: newPrivateMatch.data.createPrivateMatch.id,
          owner: user.attributes.sub,
          inning: inputs.inning,
          topOrBottom: inputs.topOrBottom,
          outCounts: inputs.outCounts,
          runner: inputs.runner,
          pitcherTeamScore: parseInt(inputs.pitcherTeamScore),
          batterTeamScore: parseInt(inputs.batterTeamScore),
          dataAggrFlag: inputs.dataAggrFlag,
        };

        const newPrivateCase = await API.graphql({
          query: mutations.createPrivateCase,
          variables: { input: inputsCase },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        for (let c = 1; c <= count; c++) {
          // 最後の球の結果はatBatResultのほうにも入れる
          // またメモも入れる
          let inputsSequence;
          if (c == count) {
            inputsSequence = {
              caseId: newPrivateCase.data.createPrivateCase.id,
              owner: user.attributes.sub,
              sequence: c,
              strikes: balls[c].strikes,
              balls: balls[c].balls,
              speed: parseInt(balls[c].speed),
              pitchResult: balls[c].result,
              atBatResult: balls[c].result,
              pitchXPosition: balls[c].left,
              pitchYPosition: balls[c].top,
              breakingBall: balls[c].type,
              memo: inputs.memo,
            };
          } else {
            inputsSequence = {
              caseId: newPrivateCase.data.createPrivateCase.id,
              owner: user.attributes.sub,
              sequence: c,
              strikes: balls[c].strikes,
              balls: balls[c].balls,
              speed: parseInt(balls[c].speed),
              pitchResult: balls[c].result,
              pitchXPosition: balls[c].left,
              pitchYPosition: balls[c].top,
              breakingBall: balls[c].type,
            };
          }
          await API.graphql({
            query: mutations.createPrivateSequence,
            variables: { input: inputsSequence },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }
      }
    } catch (error) {
      console.error(error);
      setRegisterError(true);
    }
  };

  const registerSequenceAndHandleNextPage = () => {
    registerSequence();
    handleNext();
  };

  return (
    <>
      <CustomPageLabel label="配球を考える" />
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        {/* {route !== "authenticated" ? <Auth /> : ( */}
        {/* {route !== "authenticated" && <Auth />} */}

        <div className="situationHeaderFotter">
          <Grid item xs={12} sm={6}>
            <Stepper className={c.root} activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </div>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Container>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <RegisterFinish error={registerError} />

                {registerError ? (
                  <Button variant="outlined" size="large" onClick={handleBack}>
                    確認画面に戻る
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={handleReset}>
                    繰り返し、配球を登録する
                  </Button>
                )}
                <Box sx={{ marginTop: 5 }}>
                  <img
                    src={HomeBaseImgPath}
                    alt="ホムべっちょ先輩"
                    width={130}
                    height={90}
                  />
                </Box>
              </Stack>
            </Container>
            {/* </Box> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* 入力項目を各Step間で共有 */}
            <InputNoteContext.Provider value={value}>
              {activeStep === 0 && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <Situation />
                </Typography>
              )}
              {activeStep === 1 && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <DndProvider
                    backend={IsMobileDevice() ? TouchBackend : HTML5Backend}
                  >
                    <Sequence />
                  </DndProvider>
                </Typography>
              )}
              {activeStep === 2 && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <TestContext />
                </Typography>
              )}
            </InputNoteContext.Provider>
            <div className="situationHeaderFotter">
              <Grid item xs={8} sm={6}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    // color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    size="large"
                    sx={{ mr: 1 }}
                    variant="outlined"
                  >
                    戻る
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  {/* 入力画面の1つめに対する下部のボタン表示の制御 */}
                  {activeStep === steps.length - 3 && (
                    <Button
                      onClick={handleNext}
                      disabled={!inputs.title}
                      size="large"
                      variant="outlined"
                      // sx={{ color: "red", fontWeight: "600" }}
                      sx={{ fontWeight: "600" }}
                    >
                      配球入力
                    </Button>
                  )}

                  {/* 入力画面の2つめに対する下部のボタン表示の制御 */}
                  {/* 配球結果に最終的な投球結果が入っていた場合に確認へボタンを活性化させる */}
                  {Object.keys(balls).map((key) => {
                    if (atBatResults.includes(balls[key].result)) {
                      confirmDispFlag = true;
                    }
                  })}
                  {activeStep === steps.length - 2 && (
                    <Button
                      onClick={handleNext}
                      disabled={!confirmDispFlag}
                      size="large"
                      variant="outlined"
                      // sx={{ color: "red", fontWeight: "600" }}
                      sx={{ fontWeight: "600" }}
                    >
                      確認画面
                    </Button>
                  )}

                  {/* 入力画面の3つめに対する下部のボタン表示の制御 */}
                  {activeStep === steps.length - 1 && (
                    <Button
                      onClick={registerSequenceAndHandleNextPage}
                      size="large"
                      variant="outlined"
                      sx={{ fontWeight: "600" }}
                    >
                      {/* {activeStep === steps.length - 1 ? "登録" : "次へ"} */}
                      登録
                    </Button>
                  )}
                </Box>
              </Grid>
            </div>
          </React.Fragment>
        )}
      </Box>
    </>
  );
}
