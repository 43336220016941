import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import FaceIcon from "@mui/icons-material/Face";
import { Chip } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import TitleImgPath from "../assets/img/Title.png";
import { Auth } from "aws-amplify";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SchoolIcon from "@mui/icons-material/School";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

// reference https://mui.com/components/app-bar/#app-bar-with-responsive-menu

const Header = () => {
  const [owner, setOwner] = useState();
  useEffect(async () => {
    const fn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setOwner(user.attributes.sub);
        }
      } catch (err) {
        console.log(err);
        setOwner(null);
      }
    };
    fn();
  }, []);

  let pages = [
    { name: "TOP", url: "/" },
    { name: "試合形式で記録する", url: "/game/note/menu" },
    { name: "記録を見る", url: "/search" },
    { name: "配球を作ってみる", url: "/note" },
    // { name: "集計データを見る", url: `/data/${owner}` },
    { name: "勉強する", url: "/learn" },
    { name: "操作方法など", url: "/how" },
  ];
  const settings = [
    { name: "アカウント情報", url: "/user" },
    { name: "ログアウト", url: "/" },
    // { name: "Dashboard", url: "/" },
    // { name: "Logout", url: "/" },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // ログイン状態のときのサインアウトAPIの取得
  const { route } = useAuthenticator((context) => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const signOutAndCloseMenu = () => {
    signOut();
    handleCloseUserMenu();
  };

  // ああああ
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages.map((page, index) => (
          <NavLink
            style={({ isActive }) =>
              isActive
                ? { color: "black", textDecoration: "none" }
                : { color: "black", textDecoration: "none" }
            }
            to={page.url}
          >
            <ListItem key={page.name} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  {page.name === "TOP" && <ListIcon />}
                  {page.name === "試合形式で記録する" && <NoteAddIcon />}
                  {page.name === "記録を見る" && <SearchIcon />}
                  {page.name === "配球を作ってみる" && <NoteAddIcon />}
                  {page.name === "勉強する" && <SchoolIcon />}
                  {/* {page.name === "集計データを見る" && <AutoGraphIcon />} */}
                  {page.name === "操作方法など" && <InboxIcon />}
                </ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* モバイルのときの表示 */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {/* {["left", "right", "top", "bottom"].map((anchor) => ( */}
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  onClick={toggleDrawer(anchor, true)}
                  size="large"
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
          <Container
            component="span"
            sx={{
              flexGrow: 10,
              display: { xs: "flex", md: "none" },
              width: "30%",
            }}
          >
            <img src={TitleImgPath} alt="配球ノート" width={120} height={32} />
          </Container>

          {/* PCのときの表示 */}
          <Box component="span" sx={{ display: { xs: "none", md: "flex" } }}>
            <img src={TitleImgPath} alt="配球ノート" width={130} height={30} />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                <NavLink
                  style={({ isActive }) =>
                    isActive
                      ? { color: "black", textDecoration: "none" }
                      : { color: "black", textDecoration: "none" }
                  }
                  to={page.url}
                >
                  {page.name}
                </NavLink>
              </Button>
            ))}
          </Box>

          {/* ログイン側の表示 */}
          <Box sx={{ flexGrow: 0 }}>
            {route !== "authenticated" && (
              <NavLink
                style={({ isActive }) =>
                  isActive
                    ? { color: "white", textDecoration: "none" }
                    : { color: "white", textDecoration: "none" }
                }
                to="/auth"
              >
                <span className="newRegister">ログイン</span>
              </NavLink>
            )}
            {route === "authenticated" && (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Chip
                    icon={<FaceIcon />}
                    label="ログイン中"
                    variant="outlined"
                    size="small"
                  />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={settings[0].name} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">
                  <NavLink
                    to={settings[0].url}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {settings[0].name}
                  </NavLink>
                </Typography>
              </MenuItem>
              <MenuItem key={settings[1].name} onClick={signOutAndCloseMenu}>
                <Typography textAlign="center">
                  <NavLink
                    to={settings[1].url}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {settings[1].name}
                  </NavLink>
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
