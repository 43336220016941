import { SequenceDetail, CustomPageLabel, Comments } from "./index";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Modal,
  Box,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import {
  useLocation,
  useParams,
  useSearchParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import {
  TwitterIcon,
  TwitterShareButton,
  LineShareButton,
  LineIcon,
} from "react-share";
import YanoSanImgPath from "../assets/img/yano_san.png";
import { Auth } from "aws-amplify";

export default function SearchPublicByCase(props) {
  const { caseId } = useParams();
  const [queryParams] = useSearchParams();
  const gameId = queryParams.get("gameId");

  const [user, setUser] = useState();

  const [redirect, setRedirect] = useState(false);

  const [responsePublic, setResponsePublic] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 315,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchPublicSequenceByCaseId = async () => {
    const filter = {
      id: {
        eq: gameId,
      },
      type: {
        eq: "t",
      },
    };
    const customListPublicGamesByCaseId = `query ListPublicGames(
            $filter: ModelPublicGameFilterInput
          ) { 
            listPublicGames(
              filter: $filter
            ) {
                items {
                  batterTeam
                  createdAt
                  date
                  id
                  owner
                  pitcherTeam
                  updatedAt
                  type
                  publicMatches {
                    items {
                      atBats
                      bat
                      batter
                      createdAt
                      gameId
                      id
                      owner
                      pitcher
                      throw
                      title
                      updatedAt
                      limitedDisclosure
                      cases {
                        items {
                          batterTeamScore
                          createdAt
                          id
                          inning
                          matchId
                          outCounts
                          owner
                          pitcherTeamScore
                          runner
                          topOrBottom
                          dataAggrFlag
                          updatedAt
                          sequences {
                            items {
                              atBatResult
                              balls
                              breakingBall
                              caseId
                              createdAt
                              id
                              memo
                              owner
                              pitchResult
                              pitchXPosition
                              pitchYPosition
                              sequence
                              speed
                              strikes
                              updatedAt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }`;
    // 公開情報については認証情報をcognitoからIAMに切り替えてpublicアクセスできるようにする

    // routeで認証するのではなく、　Auth.currentAuthenticatedUser()を使うと再読み込みしたときでもちゃんと
    // 認証してからクエリを投げてくれる
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        API.graphql({
          query: customListPublicGamesByCaseId,
          variables: { filter: filter },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        }).then((response) => {
          setResponsePublic(response);
        });
      })
      .catch((err) => {
        API.graphql({
          query: customListPublicGamesByCaseId,
          variables: { filter: filter },
          authMode: "AWS_IAM",
        }).then((response) => {
          setResponsePublic(response);
        });
      });
  };

  const deletePublicSequences = async (game) => {
    const inputsGame = {
      id: game.id,
    };
    const deleteGame = await API.graphql({
      query: mutations.deletePublicGame,
      variables: { input: inputsGame },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    // 今の実装は、gameに対して、match,caseは1対1なのでitems[0]を指定
    const inputsMatch = {
      gameId: game.id,
      id: game.publicMatches.items[0].id,
    };
    const deleteMatch = await API.graphql({
      query: mutations.deletePublicMatch,
      variables: { input: inputsMatch },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const inputsCase = {
      id: game.publicMatches.items[0].cases.items[0].id,
    };
    const deleteCase = await API.graphql({
      query: mutations.deletePublicCase,
      variables: { input: inputsCase },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    for (
      let i = 0;
      i < game.publicMatches.items[0].cases.items[0].sequences.items.length;
      i++
    ) {
      const inputsSeqence = {
        id: game.publicMatches.items[0].cases.items[0].sequences.items[i].id,
        sequence: i + 1,
      };

      const deleteSequence = await API.graphql({
        query: mutations.deletePublicSequence,
        variables: { input: inputsSeqence },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    }
    handleClose();

    setRedirect(true);
  };

  useEffect(() => {
    fetchPublicSequenceByCaseId();
  }, []);

  const navigate = useNavigate();

  /* 配球を削除したあとに再アクセスした場合に前のページにリダイレクトされる */
  if (redirect) {
    navigate(-1);
  }

  return (
    <div className="viewSequenceDetail">
      {/* 読込中 */}
      {!responsePublic && (
        <>
          <CircularProgress sx={{ margin: 2 }} />
          <Box sx={{ margin: 2 }}>
            <img src={YanoSanImgPath} alt="矢野さん" width={130} height={90} />
          </Box>
        </>
      )}

      {responsePublic &&
        responsePublic.data.listPublicGames.items.length == 0 && (
          <Navigate
            to="/search/public"
            state={{ from: useLocation() }}
            replace
          />
        )}

      {/* データがあるときの表示 */}
      {responsePublic &&
        responsePublic.data.listPublicGames.items.map((game) =>
          game.publicMatches.items.map((match) =>
            match.cases.items.map((c) => {
              return (
                <>
                  <CustomPageLabel label="配球の詳細を見る" />
                  <Card
                    variant="outlined"
                    sx={{ width: "315px", marginBottom: "10px" }}
                  >
                    <CardContent>
                      <SequenceDetail
                        game={game}
                        match={match}
                        case={c}
                        title={match.title}
                        disclosure="public"
                      ></SequenceDetail>
                    </CardContent>
                  </Card>
                  <Comments user={user} caseId={caseId} />

                  {/* 公開のほうにはツイッター、ライン共有ボタンの配置 */}
                  <Box>
                    <Container>
                      <Grid container spacing={5}>
                        <Grid item xs={6} sm={6}>
                          <TwitterShareButton
                            url={window.location.href}
                            title={"【配球ノート】" + match.title}
                          >
                            <TwitterIcon size={32} round={true} />
                          </TwitterShareButton>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <LineShareButton
                            url={window.location.href}
                            title={"【配球ノート】 " + match.title}
                          >
                            <LineIcon size={32} round={true} />
                          </LineShareButton>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>

                  {/* ログインユーザーがこの配球の登録ユーザーであれば削除ボタンを表示する */}
                  {user && game.owner == user.attributes.sub ? (
                    <div>
                      <Button
                        onClick={handleOpen}
                        size="large"
                        variant="outlined"
                        sx={{ fontWeight: "600", marginTop: 2 }}
                      >
                        削除
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            本当に削除していいですか？
                          </Typography>
                          <Button onClick={() => deletePublicSequences(game)}>
                            はい
                          </Button>
                          <Button onClick={handleClose}>いいえ</Button>
                        </Box>
                      </Modal>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })
          )
        )}
    </div>
  );
}
