import { CustomPageLabel } from "./index";
import { useParams } from "react-router-dom";
import * as React from "react";
import { Article20230107 } from "./Articles/Article20230107";
import { Article20230129 } from "./Articles/Article20230129";
import { Article20230208 } from "./Articles/Article20230208";
import { Article20230501 } from "./Articles/Article20230501";
import { Article20230509 } from "./Articles/Article20230509";

function ArticleList() {
  const { articleId } = useParams();

  return (
    <>
      <CustomPageLabel label="配球を勉強する" />
      {articleId === "0107" && <Article20230107 />}
      {articleId === "0129" && <Article20230129 />}
      {articleId === "0208" && <Article20230208 />}
      {articleId === "0501" && <Article20230501 />}
      {articleId === "0509" && <Article20230509 />}
    </>
  );
}

export default ArticleList;
