import { Container, Box, Typography, Grid } from "@mui/material";
import Description from "./Description";
import MenuDescription from "./MenuDescription";
import StrikeBoyImgPath from "../assets/img/strike_boy.png";
import {Helmet} from "react-helmet-async"

const How = () => {
  return (
    <>
    <Helmet>
    <title>操作方法など</title>
    <meta
      name="description"
      content="配球ノートの操作方法などの説明ページです"
    />
    <meta name="twitter:title" content="操作方法など" />
    <meta name="twitter:description" content="配球ノートの操作方法などの説明ページです" />
    <meta name="twitter:image" content="https://pitching-strategy-note.com/ball_globe_image.jpg" />

    </Helmet>
    <Container sx={{ maxWidth: 500 }}>
      <Typography
        variant="h7"
        noWrap
        component="div"
        sx={{ textDecoration: "underline", margin: 3 }}
      >
        配球ノートとは
      </Typography>
      <Description />
      <Typography
        variant="h7"
        noWrap
        component="div"
        sx={{ textDecoration: "underline", margin: 3 }}
      >
        メニューについて
      </Typography>
      <MenuDescription />

      <Typography
        variant="h7"
        noWrap
        component="div"
        sx={{ textDecoration: "underline", margin: 3 }}
      >
        試合形式で記録する、記録を見る
      </Typography>
      <div class="youtube">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/9kKOBnwgie4?si=bjve52LQTQysKVM7"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <Typography
        variant="h7"
        noWrap
        component="div"
        sx={{ textDecoration: "underline", margin: 3 }}
      >
        考えた配球を登録する
      </Typography>
      <div class="youtube">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/L4ReAaTxPR8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      {/* <Typography
        variant="h7"
        noWrap
        component="div"
        sx={{ textDecoration: "underline", margin: 3 }}
      >
        その他
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="inherit" color="text.primary">
            メニューの【配球を学ぶ】から配球の一例を文章化しています、もしよろしければ、ご一読ください。配球を検討する上でご参考になれば幸いです。
          </Typography>
          <br />
          <Typography variant="inherit" color="text.primary">
            コンテンツは随時、増やしていく考えです。
          </Typography>
        </CardContent>
      </Card> */}
      <Grid container direction="row" justifyContent="center">
        <Box sx={{ marginTop: 4 }}>
          <img
            src={StrikeBoyImgPath}
            alt="ストライク坊や"
            width={130}
            height={90}
          />
        </Box>
      </Grid>
    </Container>
    </>
  );
};

export default How;
