import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import { ListItem, Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import Button from "@mui/material/Button";
import TextInput from "./Forms/TextInput";
import { useEffect } from "react";
import * as mutations from "../graphql/mutations";
import CustomPageLabel from "./CustomPageLabel";
import YanoSanImgPath from "../assets/img/yano_san.png";
import { Box } from "@mui/material";
import { Auth } from "aws-amplify";

export default function UserInfo() {
  const [owner, setOwner] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [preRegisterUser, setPreRegisterUser] = useState("");
  const [editUserNameFlag, setEditUserNameFlag] = useState(false);
  const [userPk, setUserPk] = useState("");

  const fetchUser = async (email) => {
    const res = await API.graphql({
      query: queries.getUserByEmail,
      variables: { email: email },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (res.data.getUserByEmail.items[0].userName) {
      setUserName(res.data.getUserByEmail.items[0].userName);
      setPreRegisterUser(res.data.getUserByEmail.items[0].userName);
    }
    if (res.data.getUserByEmail.items[0].pk) {
      setUserPk(res.data.getUserByEmail.items[0].pk);
    }
  };

  const navigate = useNavigate();
  useEffect(async () => {
    let user;

    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.error(error);
      navigate("/");
    }
    if (user) {
      setOwner(user.attributes.sub);
      setEmail(user.attributes.email);
    }
    if (user) {
      await fetchUser(user.attributes.email);
    }
  }, []);

  const editUserFlag = () => {
    if (!editUserNameFlag) {
      setEditUserNameFlag(true);
    } else {
      setEditUserNameFlag(false);
    }
  };

  const registerUser = async () => {
    // 再登録の場合を加味
    // await fetchUser(email)

    const inputsUser = {
      userName: userName,
      pk: userPk,
    };
    const updateUserResult = await API.graphql({
      query: mutations.updateUser,
      variables: { input: inputsUser },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    if (!preRegisterUser) {
    } else {
      const inputsDeleteUser = {
        pk: `username#${preRegisterUser}`,
      };
      await API.graphql({
        query: mutations.deleteUser,
        variables: { input: inputsDeleteUser },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    }
    const inputsNewUser = {
      pk: `username#${userName}`,
      owner: owner,
    };
    const createNewUserResult = await API.graphql({
      query: mutations.createUser,
      variables: { input: inputsNewUser },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    //連続で変更を加えた場合のケースを加味してpreRegisterUserをここでいれてpreRegisterを変更しておく
    // これしないとたくさん作られる
    setPreRegisterUser(userName);

    editUserFlag();
  };

  const inputUserName = useCallback(
    (event) => {
      setUserName(event.target.value);
    },
    [userName]
  );

  return (
    <div id="userInfoDisplay">
      <CustomPageLabel label="アカウント情報" />
      <List sx={{ bgcolor: "background.paper" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary="Email" sx={{ marginRight: 2 }} />
          <ListItemText secondary={email ? email : "読込中です"} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <WorkIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary="ユーザー名" sx={{ marginRight: 2 }} />
          {editUserNameFlag ? (
            <TextInput
              multiline={false}
              rows={1}
              type={"text"}
              value={userName}
              size="small"
              variant="standard"
              onChange={inputUserName}
              error={!userName}
              helperText={!userName && "入力してください"}
            ></TextInput>
          ) : (
            <ListItemText secondary={!userName ? "登録なし" : userName} />
          )}
        </ListItem>
      </List>
      {editUserNameFlag ? (
        <Button variant="outlined" onClick={registerUser}>
          ユーザー名の登録
        </Button>
      ) : (
        <Button variant="outlined" onClick={editUserFlag}>
          ユーザー名の編集
        </Button>
      )}
      <Typography
        variant="subtitle2"
        noWrap
        component="div"
        sx={{ marginTop: 2, color: "gray", fontSize: 10 }}
      >
        ユーザー名はコメント機能の名前の表示使用されます。
      </Typography>

      <Box sx={{ marginTop: 3 }}>
        <img src={YanoSanImgPath} alt="矢野さん" width={130} height={90} />
      </Box>
    </div>
  );
}
