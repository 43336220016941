import { useEffect, useState } from "react";
import { Container, Box, InputLabel } from "@mui/material";
import pichsequenceImagePath from "../assets/img/pichsequence4_1.png";
import { SequenceTable } from "./index";

export default function ViewSequenceSummary(props) {
  let [balls, setBalls] = useState([]);

  let newBalls = [];
  let count = 0;

  const style = {
    position: "absolute",
    width: 25,
    height: 25,
  };
  const ballStyleChange = [
    "changeup",
    "slowball",
    "palm",
    "knuckle",
    "curve",
    "sinker",
    "screw",
    "slider",
    "cutball",
    "twoseams",
    "fork",
    "verticalslider",
    "sff",
  ];

  const styleChange = {
    position: "absolute",
    width: 31,
    height: 31,
  };

  const CreateImagePath = (type, count) => {
    if (ballStyleChange.includes(type)) {
      return (
        <img
          src={require(`../assets/img/balls/${type}_${count}.png`)}
          width={styleChange.width}
          height={styleChange.height}
          alt={type}
        />
      );
    }
    return (
      <img
        src={require(`../assets/img/balls/${type}_${count}.png`)}
        width={style.width}
        height={style.height}
        alt={type}
      />
    );
  };

  const SelectBallImage = (type, count) => CreateImagePath(type, count);

  useEffect(() => {
    // リストで表示する際に、リストしただけballsの情報が増えてしまうため、リスト表示用にレンダリングするごとに初期化する。
    balls = [];
    props.case.sequences.items.map((sequence) => {
      balls.push(sequence);
    });
    // 配列は単純に中身をpushしただけでは再レンダリングしてくれない。なので、別の要素を渡してあげるためのもの
    newBalls = balls.slice(0, balls.length);
    setBalls(newBalls);
  }, [props.case]);

  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          color: "text.primary",
          fontSize: 16,
          fontWeight: "medium",
          textAlign: "left",
          margin: 2,
        }}
      >
        {props.title}
      </InputLabel>
      <Container sx={{ bgcolor: "Transparent" }}>
        <div id="pitchSequenceParentFrame">
          <div id="pitchSequenceChildFrame">
            <img
              src={pichsequenceImagePath}
              id="pitchSequenceImage"
              alt="配球画面"
            />

            {Object.keys(balls).map((key) => {
              let left = 0;
              let top = 0;

              left = balls[key].pitchXPosition;
              top = balls[key].pitchYPosition;
              // breakingBall = balls[key].breakingBall;
              count = Number(key) + 1;
              return (
                <div id={count} style={{ ...style, left, top }}>
                  {SelectBallImage(balls[key].breakingBall, count)}
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      {/* 球種と結果一覧 */}
      <Box sx={{ margin: 2 }}>
        <SequenceTable count={count} balls={balls}></SequenceTable>
      </Box>
    </>
  );
}
