export const vocabularies = {
  ja: {
    // "Sign In": "ログイン",
    // "Sign Up": "ユーザー作成",
    // "Sign Out": "ログアウト",
    // "Sign in to your account": "アカウントにログイン",
    // "Username *": "ユーザー名 *",
    // "Password *": "パスワード *",
    // "Enter your username": "ユーザー名を入力",
    // "Enter your password": "パスワードを入力",
    // "Enter your email": "Emailを入力",
    // "No account?": "が未登録ですか？",
    // "Forgot your password?": "パスワードをお忘れですか？",
    // "Reset password": "パスワードをリセット",
    // "User does not exist": "ユーザーが存在しません",
    // "User already exists": "ユーザーは既に存在します",
    // "Incorrect username or password.": "ユーザー名またはパスワードが違います",
    // "Invalid password format": "パスワードのフォーマットが不正です",
    // "Create account": "新規登録",
    // "Forgot Password": "パスワードを忘れた",
    // "Change Password": "パスワードを変更",
    // "New Password": "新しいパスワード",
    // "Confirm Password": "パスワードの確認",
    // Email: "Email",
    // "Phone Number": "電話番号",
    // "Confirm a Code": "コードを確認",
    // "Confirm Sign In": "ログインを確認",
    // "Confirm Sign up": "アカウント作成を確認",
    // "Back to Sign In": "ログインに戻る",
    // "Send code": "コードを送信",
    // Confirm: "確認",
    // "Resend Code": "コードを再送",
    // Submit: "送信",
    // Skip: "スキップ",
    // Verify: "検証",
    // "Verify Contact": "連絡先を検証",
    // Code: "確認コード",
    // "Confirmation Code": "確認コード",
    // "Lost your code?": "コードがありませんか？",
    // "Account recovery requires verified contact information":
    //   "Account recovery requires verified contact information",
    // "Invalid phone number format":
    //   "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
    // "Create Account": "新規登録",
    // "Have an account?": "アカウントをお持ちですか？",
    // "Sign in": "ログイン",
    // "Create a new account": "新しいアカウントを作成",
    // "Reset your password": "パスワードをリセット",
    // "An account with the given email already exists.":
    //   "そのメールアドレスは既に存在します",
    // "Username cannot be empty": "ユーザー名は必須です",
    // "Password attempts exceeded": "パスワード試行回数が超過しました",
    "Account recovery requires verified contact information":
      "アカウントの復旧には確認済みの連絡先が必要です",
    "Back to Sign In": "ログインに戻る",
    "Change Password": "パスワードを変える ",
    Changing: "変更中",
    Code: "コード",
    "Confirm Password": "パスワードの確認",
    "Confirm Sign Up": "登録する",
    "Confirm SMS Code": "SMS コードを確認",
    "Confirm TOTP Code": "TOTP コードを確認",
    Confirm: "確定",
    "Confirmation Code": "確認コード",
    Confirming: "確認中",
    "Create a new account": "新しいアカウントを作る",
    "Create Account": "新規登録",
    "Creating Account": "アカウントの作成中",
    "Dismiss alert": "アラートを閉じる",
    Email: "メールアドレス",
    "Enter your code": "コードを入力",
    "Enter your email": "メールアドレスを入力",
    "Enter your phone number": "電話番号を入力",
    "Enter your username": "ユーザー名を入力 ",
    "Forgot your password?": "パスワードを忘れましたか？ ",
    "Hide password": "パスワードを非表示",
    Loading: "ロード中",
    "New password": "新しいパスワード",
    Password: "パスワード ",
    "Phone Number": "電話番号",
    "Resend Code": "コードを再送信",
    "Reset your Password": "パスワードをリセット",
    "Reset your password": "パスワードをリセットする",
    "Send code": "コードを送信",
    "Send Code": "コードを送信",
    Sending: "送信中",
    "Setup TOTP": "TOTP をセットアップ",
    "Show password": "パスワードを表示",
    "Sign in to your account": "アカウントにログイン ",
    "Sign In with Amazon": "Amazonでログイン",
    "Sign In with Apple": "Apple でログイン",
    "Sign In with Facebook": "Facebookでログイン",
    "Sign In with Google": "Googleでログイン",
    "Sign In": "ログイン ",
    "Sign in": "ログイン",
    "Signing in": "ログイン中",
    Skip: "スキップ",
    Submit: "送信",
    Submitting: "送信中",
    Username: "ユーザー名 ",
    "Verify Contact": "連絡先を確認",
    Verify: "確認",

    // Additional translations provided by customers
    "An account with the given email already exists.":
      "入力されたメールアドレスのアカウントが既に存在します",
    "Confirm a Code": "コードを確認",
    "Confirm Sign In": "ログインする",
    "Create account": "アカウントを作る ",
    "Enter your password": "パスワードを入力 ",
    "Forgot Password": "パスワードを忘れた ",
    "Have an account? ": "アカウントを持っていますか？",
    "Incorrect username or password": "ユーザー名かパスワードが異なります ",
    "Invalid password format": "パスワードの形式が無効です ",
    "Invalid phone number format":
      "不正な電話番号の形式です。\n+12345678900 の形式で入力してください",
    "It may take a minute to arrive.":
      "コードを受信するまで数分かかる場合があります。",
    "Lost your code? ": "コードを失くしましたか？",
    "New Password": "新しいパスワード",
    "No account? ": "アカウントが無いとき ",
    or: "又は",
    "Password attempts exceeded": "ログインの試行回数が上限に達しました",
    "Reset password": "パスワードをリセット ",
    "Sign Out": "サインアウト ",
    "Sign Up": "登録 ",
    "User already exists": "既にユーザーが存在しています ",
    "User does not exist": "ユーザーが存在しません ",
    "Username cannot be empty": "ユーザー名は入力必須です",
    "We Emailed You": "コードを送信しました",
    "Your code is on the way. To log in, enter the code we emailed to":
      "ログインするには、メールに記載されたコードを入力してください。送信先:",
    "Your code is on the way. To log in, enter the code we texted to":
      "ログインするには、テキストメッセージに記載されたコードを入力してください。送信先:",
  },
};
