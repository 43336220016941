import { useCallback, useContext, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import TextInput from "./Forms/TextInput";
import { InputNoteContext } from "./NoteBody";
import { Divider, InputLabel } from "@mui/material";
import { Box } from "@mui/material";
import { BatterInput, PitcherInput, SituationInput } from "./index";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Situation() {
  const { inputs, setInputs } = useContext(InputNoteContext);
  const [expanded, setExpanded] = useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const inputTitle = useCallback(
    (event) => {
      setInputs({ ...inputs, title: event.target.value });
    },
    [inputs]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Divider>STEP 1</Divider>
      <div
        style={{ padding: 10, marginBottom: 10, border: 1, borderRadius: 5 }}
      >
        <InputLabel
          variant="standard"
          sx={{
            color: "text.primary",
            fontSize: 16,
            fontWeight: "medium",
            textAlign: "left",
            margin: 2,
          }}
        >
          配球のタイトルや状況の入力。
        </InputLabel>

        <Accordion
          expanded={expanded === "panel0"}
          onChange={handleChange("panel0")}
        >
          <AccordionSummary
            aria-controls="panel0d-content"
            id="panel0d-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="required">必須</span> 配球のタイトル
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Box sx={{ margin: 2 }}>
                <TextInput
                  fullWidth={true}
                  multiline={false}
                  rows={5}
                  type={"text"}
                  value={inputs.title}
                  onChange={inputTitle}
                  placeholder={"左の強打者への配球"}
                  error={inputs.title === ""}
                  helperText={
                    inputs.title === "" ? "タイトルを入力してください" : " "
                  }
                />
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="optional">任意</span> 打者関連
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <BatterInput />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="optional">任意</span> 投手関連
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <PitcherInput />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="optional">任意</span> シチュエーション
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <SituationInput />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
