import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import CustomPageLabel from "./CustomPageLabel";
import StrikeBoyImgPath from "../assets/img/strike_boy.png";
import { Auth } from "aws-amplify";

export default function SearchTop() {
  const [searchOwnerButtonFlag, setSearchOwnerButtonFlag] = useState(true);
  const [owner, setOwner] = useState("");

  // ログインしてない場合は、自分の配球のボタンは非活性状態にする
  useEffect(
    () => [
      Auth.currentAuthenticatedUser()
        .then((user) => {
          setOwner(user.attributes.sub);
        })
        .catch((err) => {
          setSearchOwnerButtonFlag(false);
        }),
    ],
    []
  );

  return (
    <div id="searchTopDisplay">
      <CustomPageLabel label="配球を見る" />
      <Button
        size="large"
        variant="outlined"
        disabled={!searchOwnerButtonFlag}
        sx={{
          fontWeight: "600",
          width: "300px",
          marginTop: "15px",
          marginLeft: "10px",
          marginBottom: "10px",
          marginRight: "10px",
        }}
        component={Link}
        to={`/game/view/menu`}
      >
        試合の記録を見る
      </Button>
      <Button
        size="large"
        variant="outlined"
        sx={{
          fontWeight: "600",
          width: "300px",
          marginTop: "15px",
          marginLeft: "10px",
          marginBottom: "10px",
          marginRight: "10px",
        }}
        component={Link}
        to={`/search/public`}
      >
        みんなの配球
      </Button>
      <Button
        size="large"
        variant="outlined"
        disabled={!searchOwnerButtonFlag}
        sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
        component={Link}
        to={`/search/${owner}`}
      >
        じぶんの配球
      </Button>
      <Box sx={{ marginTop: 5 }}>
        <img
          src={StrikeBoyImgPath}
          alt="ストライク坊や"
          width={130}
          height={90}
        />
      </Box>
    </div>
  );
}
