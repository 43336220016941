import { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Link,
  CircularProgress,
  Box,
  Grid,
  Stack,
  Card,
} from "@mui/material";
import { VictoryPie, VictoryTooltip } from "victory";
import introductionDataAggrImgPath from "../assets/img/introduction/introductionDataAggr.png";
import GetTwoImgPath from "../assets/img/get_two_kun.png";


export default function CourseData(props) {

  return (
    <>
      {props.pieViewData && props.pieViewData.length > 0 ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
        // spacing={1}
        >
          <Typography
            variant="h7"
            noWrap
            component="div"
            sx={{ textDecoration: "underline", marginLeft: 3, marginRight: 3 }}
          >
            球種と割合
          </Typography>
          <Box sx={{ maxWidth: 500 }}>
            <VictoryPie
              colorScale={[
                "#F6AA00",
                "#005AFF",
                "#03AF7A",
                "#4DC4FF",
                "#FF4B00",
                "#000000",
                "#990099",
                "#84919E",
              ]}
              data={props.pieViewData}
              sortKey={"y"}
              sortOrder={"descending"}
              innerRadius={50}
              labelRadius={({ innerRadius }) => innerRadius + 5}
              style={{
                labels: { fill: "white", fontSize: 15, fontWeight: "bold" },
              }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
            />
          </Box>
          <Typography
            variant="h7"
            noWrap
            component="div"
            sx={{
              textDecoration: "underline",
              marginBottom: 1,
              marginLeft: 3,
              marginRight: 3,
            }}
          >
            コースと球数
          </Typography>
          <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
            コースの数値をクリックするとコース別表示切り替わります
          </Typography>

          <Box className="courseMap" sx={{ maxWidth: 315 }}>
            <Grid container spacing={0}>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course1")}
                  sx={{ borderTop: "1px groove", borderLeft: "1px groove" }}
                  color={props.courseNum === "course1" ? "warning" : "inherit"}
                  disabled={props.courses.course1.count === 0}
                >
                  {props.courses.course1.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course2")}
                  sx={{ borderTop: "1px groove" }}
                  color={props.courseNum === "course2" ? "warning" : "inherit"}
                  disabled={props.courses.course2.count === 0}
                >
                  {" "}
                  {props.courses.course2.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course3")}
                  sx={{ borderTop: "1px groove" }}
                  color={props.courseNum === "course3" ? "warning" : "inherit"}
                  disabled={props.courses.course3.count === 0}
                >
                  {" "}
                  {props.courses.course3.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course4")}
                  sx={{ borderTop: "1px groove" }}
                  color={props.courseNum === "course4" ? "warning" : "inherit"}
                  disabled={props.courses.course4.count === 0}
                >
                  {props.courses.course4.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course5")}
                  sx={{ borderRight: "1px groove", borderTop: "1px groove" }}
                  color={props.courseNum === "course5" ? "warning" : "inherit"}
                  disabled={props.courses.course5.count === 0}
                >
                  {props.courses.course5.count}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course6")}
                  sx={{ borderLeft: "1px groove" }}
                  color={props.courseNum === "course6" ? "warning" : "inherit"}
                  disabled={props.courses.course6.count === 0}
                >
                  {props.courses.course6.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course7")}
                  sx={{
                    borderLeft: "1px solid blue",
                    borderTop: "1px solid blue",
                  }}
                  color={props.courseNum === "course7" ? "warning" : "inherit"}
                  disabled={props.courses.course7.count === 0}
                >
                  {props.courses.course7.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course8")}
                  sx={{ borderTop: "1px solid blue" }}
                  color={props.courseNum === "course8" ? "warning" : "inherit"}
                  disabled={props.courses.course8.count === 0}
                >
                  {props.courses.course8.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course9")}
                  sx={{
                    borderRight: "1px solid blue",
                    borderTop: "1px solid blue",
                  }}
                  color={props.courseNum === "course9" ? "warning" : "inherit"}
                  disabled={props.courses.course9.count === 0}
                >
                  {props.courses.course9.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course10")}
                  sx={{ borderRight: "1px groove" }}
                  color={props.courseNum === "course10" ? "warning" : "inherit"}
                  disabled={props.courses.course10.count === 0}
                >
                  {props.courses.course10.count}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course11")}
                  sx={{ borderLeft: "1px groove" }}
                  color={props.courseNum === "course11" ? "warning" : "inherit"}
                  disabled={props.courses.course11.count === 0}
                >
                  {props.courses.course11.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course12")}
                  sx={{ borderLeft: "1px solid blue" }}
                  color={props.courseNum === "course12" ? "warning" : "inherit"}
                  disabled={props.courses.course12.count === 0}
                >
                  {props.courses.course12.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course13")}
                  color={props.courseNum === "course13" ? "warning" : "inherit"}
                  disabled={props.courses.course13.count === 0}
                >
                  {props.courses.course13.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course14")}
                  sx={{ borderRight: "1px solid blue" }}
                  color={props.courseNum === "course14" ? "warning" : "inherit"}
                  disabled={props.courses.course14.count === 0}
                >
                  {props.courses.course14.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course15")}
                  sx={{ borderRight: "1px groove" }}
                  color={props.courseNum === "course15" ? "warning" : "inherit"}
                  disabled={props.courses.course15.count === 0}
                >
                  {props.courses.course15.count}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course16")}
                  sx={{ borderLeft: "1px groove" }}
                  color={props.courseNum === "course16" ? "warning" : "inherit"}
                  disabled={props.courses.course16.count === 0}
                >
                  {props.courses.course16.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course17")}
                  sx={{
                    borderBottom: "1px solid blue",
                    borderLeft: "1px solid blue",
                  }}
                  color={props.courseNum === "course17" ? "warning" : "inherit"}
                  disabled={props.courses.course17.count === 0}
                >
                  {props.courses.course17.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course18")}
                  sx={{ borderBottom: "1px solid blue" }}
                  color={props.courseNum === "course18" ? "warning" : "inherit"}
                  disabled={props.courses.course18.count === 0}
                >
                  {props.courses.course18.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course19")}
                  sx={{
                    borderRight: "1px solid blue",
                    borderBottom: "1px solid blue",
                  }}
                  color={props.courseNum === "course19" ? "warning" : "inherit"}
                  disabled={props.courses.course19.count === 0}
                >
                  {props.courses.course19.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course20")}
                  sx={{ borderRight: "1px groove" }}
                  color={props.courseNum === "course20" ? "warning" : "inherit"}
                  disabled={props.courses.course20.count === 0}
                >
                  {props.courses.course20.count}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course21")}
                  sx={{ borderLeft: "1px groove", borderBottom: "1px groove" }}
                  color={props.courseNum === "course21" ? "warning" : "inherit"}
                  disabled={props.courses.course21.count === 0}
                >
                  {props.courses.course21.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course22")}
                  sx={{ borderBottom: "1px groove" }}
                  color={props.courseNum === "course22" ? "warning" : "inherit"}
                  disabled={props.courses.course22.count === 0}
                >
                  {props.courses.course22.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course23")}
                  sx={{ borderBottom: "1px groove" }}
                  color={props.courseNum === "course23" ? "warning" : "inherit"}
                  disabled={props.courses.course23.count === 0}
                >
                  {props.courses.course23.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course24")}
                  sx={{ borderBottom: "1px groove" }}
                  color={props.courseNum === "course24" ? "warning" : "inherit"}
                  disabled={props.courses.course24.count === 0}
                >
                  {props.courses.course24.count}
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={2}>
                <Button
                  onClick={() => props.setCourseNum("course25")}
                  sx={{ borderBottom: "1px groove", borderRight: "1px groove" }}
                  color={props.courseNum === "course25" ? "warning" : "inherit"}
                  disabled={props.courses.course25.count === 0}
                >
                  {props.courses.course25.count}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Button onClick={() => props.setCourseNum("all")}>
            全コース表示に戻す
          </Button>
        </Stack>
      ) : props.pieViewData && props.pieViewData.length < 1 ? (
        "該当のデータはありません。"
      ) : props.fetchError && !props.pieViewData ? (
        "読み込みに失敗しました。インターネット接続を確認してください。"
      ) : props.notFoundAggrDataError && !props.pieViewData ? (
        <>
          集計データがありません。
          <br />
          <br />
          新たに配球を登録すると（登録されている場合）過去の配球も含めて、以下のようなイメージでデータ集計されます。
          <br />
          <br />
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{ textDecoration: "underline", marginTop: 2 }}
            >
              データ集計画面イメージ
            </Typography>
            <Card sx={{ margin: 1, maxWidth: 350 }}>
              <img
                src={introductionDataAggrImgPath}
                id="pitchSequenceImg"
                alt="データ集計画面"
              />
            </Card>
            <br />
            <br />
            「右左打者」、「投手」、「投球結果」に応じて球種の割合が確認できる円グラフ、コース分布を確認でき、投手の得意球や得意コースの傾向を確認できます。
            <Box sx={{ marginTop: 2 }}>
              <img
                src={GetTwoImgPath}
                alt="ゲッツーくん"
                width={180}
                height={120}
              />
            </Box>
          </Stack>
        </>
      ) : props.readFileError && !props.pieViewData ? (
        "データの読み込みに失敗しました。投手名等に特殊な記号があると読み込めない場合があります。"
      ) : (
        <CircularProgress />
      )}

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
      // spacing={1}
      >
        {props.links && props.links.length > 0 && (
          <>
            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{ textDecoration: "underline", margin: 3 }}
            >
              配球のリンク
            </Typography>
          </>
        )}

        {props.links
          ? props.links.map((link, index) => (
            <>
              <Box>
                <Link
                  href={`/game/view/${props.owner}/${link.gameId}?situationSeq=${link.situationSeq}`}
                // target="_blank"
                >
                  {link.title}
                </Link>
              </Box>
            </>
          ))
          : ""}
      </Stack>
    </>
  );
}
