import { useRef, useState } from "react";
import { useEffect } from "react";
import {
  useNavigate,
  useParams,
  Link as LinkRouter,
  useSearchParams,
} from "react-router-dom";
import { API } from "aws-amplify";
import {
  Grid,
  Stack,
  Box,
  Container,
  Button,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";
import pichsequenceImagePath from "../../assets/img/pichsequence4_1.png";
import SequenceTable from "../SequenceTable";
import { Auth as AWSAuth } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import TextInput from "../Forms/TextInput";
import YanoSanImgPath from "../../assets/img/yano_san.png";
import FieldImgPath from "../../assets/img/gameNote/baseball_field2.png";
import SignatureCanvas from "react-signature-canvas";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomPageLabel from "../CustomPageLabel";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

export default function GameViewPrivate() {
  const { owner, gameId } = useParams();
  const [user, setUser] = useState("");
  const [game, setGame] = useState();
  const [isError, setIsError] = useState(false);
  const [isNodata, setIsNoData] = useState(false);

  // URLで個別指定して初期レンダリング表示を特定のsituationの配球にしぼる
  const [queryParams] = useSearchParams();
  let situationSeq = queryParams.get("situationSeq");

  const [memo, setMemo] = useState();
  const [editMemoFlag, setEditMemoFlag] = useState(false);

  const changeEditMemoFlag = () => {
    if (!editMemoFlag) {
      setEditMemoFlag(true);
      if (sequences.items[sequences.items.length - 1].memo) {
        setMemo(sequences.items[sequences.items.length - 1].memo);
      }
    } else {
      setEditMemoFlag(false);
    }
  };

  const inputMemo = (event) => {
    setMemo(event.target.value);
  };

  const registerMemo = async () => {
    if (memo) {
      const inputUpdateMemo = {
        id: sequences.items[sequences.items.length - 1].id,
        sequence: sequences.items.length,
        memo: memo,
      };

      try {
        const updateMemo = await API.graphql({
          query: mutations.updatePrivateSequenceForGameFormat,
          variables: { input: inputUpdateMemo },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        sequences.items[sequences.items.length - 1].memo =
          updateMemo.data.updatePrivateSequenceForGameFormat.memo;
        setIsError(false);
      } catch (error) {
        setIsError(true);
      }
    }
    changeEditMemoFlag();
  };

  const [firstattackScores, setFirstattackScores] = useState(Array(18).fill(0));
  const [secondattackScores, setSecondattackScores] = useState(
    Array(18).fill(0)
  );

  const [firstAttackMember, setFirstAttackMember] = useState();
  const [secondAttackMember, setSecondAttackMember] = useState();
  const [firstAttackMemberPitch, setFirstAttackMemberPitch] = useState();
  const [secondAttackMemberPitch, setSecondAttackMemberPitch] = useState();
  const [gameTitle, setGameTitle] = useState("");
  const [registerFinish, setRegisterFinish] = useState(false);

  const [situationMemo, setSituationMemo] = useState("");

  const [date, setDate] = useState("");
  const [firstAttackTeam, setFirstAttackTeam] = useState(0);
  const [secondAttackTeam, setSecondAttackTeam] = useState(0);
  const [firstAttackTeamScore, setFirstAttackTeamScore] = useState(0);
  const [secondAttackTeamScore, setSecondAttackTeamScore] = useState(0);

  const [registeredBatterOrder, setRegisteredBatterOrder] = useState(0);

  const [batter, setBatter] = useState("");
  const [bat, setBat] = useState("");
  const displayBat = (bat) => {
    switch (bat) {
      case "right":
        return "右打";
      case "left":
        return "左打";
      case "both":
        return "両打";
    }
  };
  const [pitcher, setPitcher] = useState("");
  const [pitthrow, setPitthrow] = useState("");
  const [sequences, setSequences] = useState("");
  const displayBallsMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>B </span>
            <font color="green">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>B </span>
            <font color="green">●●</font>
          </>
        );
      case 3:
        return (
          <>
            <span>B </span>
            <font color="green">●●●</font>
          </>
        );
      default:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
    }
  };

  const displayStrikesMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>S </span>
            <font color="yellow">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>S </span>
            <font color="yellow">●●</font>
          </>
        );
      default:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };
  const [outCounts, setOutCounts] = useState(0);
  const displayOutCountMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>O </span>
            <font color="red">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>O </span>
            <font color="red">●●</font>
          </>
        );
      // 牽制死等の際の表示上の整合性のため
      case 3:
        return (
          <>
            <span>O </span>
            <font color="red">●●</font>
          </>
        );
      default:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };
  const [runner, setRunner] = useState(0);

  const styleRunner = {
    position: "absolute",
    width: 50,
    height: 50,
  };

  const displayRunnerPicture = (runner) => {
    switch (runner) {
      case 0:
        return (
          <img
            src={require(`../../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 1:
        return (
          <img
            src={require(`../../assets/img/runner/first.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 2:
        return (
          <img
            src={require(`../../assets/img/runner/second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 3:
        return (
          <img
            src={require(`../../assets/img/runner/third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 4:
        return (
          <img
            src={require(`../../assets/img/runner/first_second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 5:
        return (
          <img
            src={require(`../../assets/img/runner/first_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 6:
        return (
          <img
            src={require(`../../assets/img/runner/second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 7:
        return (
          <img
            src={require(`../../assets/img/runner/first_second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      default:
        return (
          <img
            src={require(`../../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
    }
  };

  const [topOrBottom, setTopOrBottom] = useState("top");
  const [inning, setInning] = useState(1);
  const [scoreBoardMaxInning, setScoreBoardMaxInning] = useState(1);
  const [
    scoreBoardTopOrBottomOfMaxInning,
    setScoreBoardTopOrBottomOfMaxInning,
  ] = useState("top");
  const [scoreBoardFirstAttackScore, setScoreBoardFirstAttackScore] =
    useState(0);
  const [scoreBoardSecondAttackScore, setScoreBoardSecondAttackScore] =
    useState(0);

  const [balls, setBalls] = useState([]);

  const [displaySituation, setDisplaySituation] = useState();
  const [privateSituations, setPrivateSituations] = useState();

  const [nextBatterDisabled, setNextBatterDisabled] = useState(false);
  const [preBatterDisabled, setPreBatterDisabled] = useState(false);

  const [pitchNumber, setPitchNumber] = useState()

  const style = {
    position: "absolute",
    width: 25,
    height: 25,
  };
  const ballStyleChange = [
    "changeup",
    "slowball",
    "palm",
    "knuckle",
    "curve",
    "sinker",
    "screw",
    "slider",
    "cutball",
    "twoseams",
    "fork",
    "verticalslider",
    "sff",
  ];

  const styleChange = {
    position: "absolute",
    width: 31,
    height: 31,
  };

  const containerStyle = {
    backgroundImage: `url(${FieldImgPath})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "350px",
    height: "300px",
    position: "relative",
  };

  const hittingDirectionImage = useRef();
  const renderHittingDirection = (situation) => {
    if (situation.sequences.items.length !== 0) {
      // 前の表示情報は削除する
      hittingDirectionImage.current.clear();

      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      let img = new Image();
      img.src =
        situation.sequences.items[
          situation.sequences.items.length - 1
        ].hittingDirectionImage;

      img.onload = function () {
        // newDataUrlをreact-signature-canvasのfromDataURL()に読み込ませます
        hittingDirectionImage.current.fromDataURL(img.src, {
          width: img.width,
          height: img.height,
        });
      };
      // 手書きできないようにする
      const canvas = hittingDirectionImage.current.getCanvas();
      canvas.style.pointerEvents = "none";
    }
  };
  const setProperties = (situation) => {
    setDisplaySituation(situation);
    setFirstAttackTeamScore(situation.firstAttackTeamScore);
    setSecondAttackTeamScore(situation.secondAttackTeamScore);
    setBatter(situation.batter);
    setBat(situation.bat);
    setPitcher(situation.pitcher);
    setPitthrow(situation.throw);
    setOutCounts(situation.outCounts);
    setRunner(situation.runner);
    setSituationMemo(situation.memo);
    setRegisteredBatterOrder(situation.registeredBatterOrder);
    setInning(situation.inning);
    setTopOrBottom(situation.topOrBottom);

    const firstAttackMembers = [];
    const firstAttackMemberTemp = JSON.parse(situation.firstAttackMember);
    for (let i = 0; i < 9; i++) {
      firstAttackMembers.push({
        name: firstAttackMemberTemp[`${i + 1}番打者`][
          firstAttackMemberTemp[`${i + 1}番打者`].length - 1
        ].name
          ? firstAttackMemberTemp[`${i + 1}番打者`][
              firstAttackMemberTemp[`${i + 1}番打者`].length - 1
            ].name
          : "NODATA",
        position:
          firstAttackMemberTemp[`${i + 1}番打者`][
            firstAttackMemberTemp[`${i + 1}番打者`].length - 1
          ].position,
      });
    }
    setFirstAttackMember(firstAttackMembers);
    setFirstAttackMemberPitch(
      firstAttackMemberTemp["投手"][firstAttackMemberTemp["投手"].length - 1]
        .name
        ? firstAttackMemberTemp["投手"][
            firstAttackMemberTemp["投手"].length - 1
          ].name
        : "NODATA"
    );

    const secondAttackMembers = [];
    const secondAttackMemberTemp = JSON.parse(situation.secondAttackMember);
    for (let i = 0; i < 9; i++) {
      secondAttackMembers.push({
        name: secondAttackMemberTemp[`${i + 1}番打者`][
          secondAttackMemberTemp[`${i + 1}番打者`].length - 1
        ].name
          ? secondAttackMemberTemp[`${i + 1}番打者`][
              secondAttackMemberTemp[`${i + 1}番打者`].length - 1
            ].name
          : "NODATA",
        position:
          secondAttackMemberTemp[`${i + 1}番打者`][
            secondAttackMemberTemp[`${i + 1}番打者`].length - 1
          ].position,
      });
    }
    setSecondAttackMember(secondAttackMembers);
    setSecondAttackMemberPitch(
      secondAttackMemberTemp["投手"][secondAttackMemberTemp["投手"].length - 1]
        .name
        ? secondAttackMemberTemp["投手"][
            secondAttackMemberTemp["投手"].length - 1
          ].name
        : "NODATA"
    );

    // useRefがcanvasのDOMについているかチェックしてついていれば打球データを読み込む
    if (hittingDirectionImage.current) {
      renderHittingDirection(situation);
    }

    if(situation.pitchInfo){
      let pitchInfoTmp;
      if (typeof situation.pitchInfo === "string") {
        pitchInfoTmp = JSON.parse(situation.pitchInfo);
      } else {
        pitchInfoTmp = situation.pitchInfo
      }
      let nowPitcherInfo
      if(situation.topOrBottom === "top") {
        // 最新情報の要素から投手名が合致する投球情報を取得
        nowPitcherInfo = pitchInfoTmp.secondAttackPitchInfo.slice().reverse().find(element => element.name === situation.pitcher)
        setPitchNumber(nowPitcherInfo ? nowPitcherInfo.pitchesNumber: 0);
      } else {
        nowPitcherInfo = pitchInfoTmp.firstAttackPitchInfo.slice().reverse().find(element => element.name === situation.pitcher)
        setPitchNumber(nowPitcherInfo ? nowPitcherInfo.pitchesNumber: 0);
      }
    }

    // sequencesのレンダリングで表示制御しているので、更新処理は一番最後
    setSequences(situation.sequences);
  };
  // スコアのイニングをクリックした場合の表示変更
  const selectInningAndTopOrBottom = (inning, topOrBottom) => {
    if (privateSituations) {
      const tmpPrivatesSituations = privateSituations.filter(
        (situation) =>
          situation.inning == inning && situation.topOrBottom == topOrBottom
      );
      let tmpBalls = [];
      setProperties(tmpPrivatesSituations[0]);
      tmpPrivatesSituations[0].sequences.items.map((sequence) => {
        tmpBalls.push(sequence);
      });
      setBalls(tmpBalls);
    }
  };

  const nowBatterFindIndex = () => {
    for (let i = 0; i < privateSituations.length; i++) {
      if (privateSituations[i].id === displaySituation.id) {
        return i;
      }
    }
  };

  // 今表示されている配球のindex番号を特定して前の打者、次の打者ボタンの活性非活性を決定します
  useEffect(() => {
    if (privateSituations) {
      let nowBatterIndex = 0;
      nowBatterIndex = nowBatterFindIndex();

      if (nowBatterIndex !== 0) {
        setPreBatterDisabled(false);
      } else {
        setPreBatterDisabled(true);
      }

      if (nowBatterIndex + 1 < privateSituations.length) {
        setNextBatterDisabled(false);
      } else {
        setNextBatterDisabled(true);
      }
    }
  }, [balls]);

  // 今表示されているindex番号を探して前の打者の情報を表示します。
  const preBatter = () => {
    let nowBatterIndex = 0;
    nowBatterIndex = nowBatterFindIndex();

    if (nowBatterIndex !== 0) {
      nowBatterIndex -= 1;
    }
    setProperties(privateSituations[nowBatterIndex]);

    let tmpBalls = [];
    privateSituations[nowBatterIndex].sequences.items.map((sequence) => {
      tmpBalls.push(sequence);
    });
    setBalls(tmpBalls);
  };

  // 今表示されているindex番号を探して次の打者の情報を表示します。
  const nextBatter = () => {
    let nowBatterIndex = 0;
    nowBatterIndex = nowBatterFindIndex();
    if (nowBatterIndex + 1 < privateSituations.length) {
      nowBatterIndex += 1;
    }
    setProperties(privateSituations[nowBatterIndex]);

    let tmpBalls = [];
    privateSituations[nowBatterIndex].sequences.items.map((sequence) => {
      tmpBalls.push(sequence);
    });
    setBalls(tmpBalls);
  };

  const CreateImagePath = (type, count) => {
    if (ballStyleChange.includes(type)) {
      return (
        <img
          src={require(`../../assets/img/balls/${type}_${count}.png`)}
          width={styleChange.width}
          height={styleChange.height}
          alt={type}
        />
      );
    }
    return (
      <img
        src={require(`../../assets/img/balls/${type}_${count}.png`)}
        width={style.width}
        height={style.height}
        alt={type}
      />
    );
  };

  const SelectBallImage = (type, count) => CreateImagePath(type, count);

  const GetPrivateGameAndSituationAndSequenceByOwnerForGameFormat = `
    query GetPrivateGameByOwnerForGameFormat(
      $owner: String!
      $sortDirection: ModelSortDirection
      $filter: ModelprivateGameForGameFormatFilterInput
      $limit: Int
      $nextToken: String
    ) {
      getPrivateGameByOwnerForGameFormat(
        owner: $owner
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          owner
          firstAttackTeam
          secondAttackTeam
          date
          privateSituations {
            items {
              atBats
              bat
              batter
              createdAt
              dataAggrFlag
              firstAttackTeamScore
              gameId
              id
              inning
              memo
              outCounts
              topOrBottom
              owner
              pitcher
              registerInningTopOrBottomFinishFlag
              registerBatterFinishFlag
              registeredBatterOrder
              runner
              secondAttackTeamScore
              detailsForNextBatter
              throw
              firstAttackMember
              secondAttackMember
              pitchInfo
              sequences {
                items {
                  id
                  owner
                  situationId
                  sequence
                  strikes
                  balls
                  speed
                  pitchResult
                  atBatResult
                  pitchXPosition
                  pitchYPosition
                  breakingBall
                  memo
                  createdAt
                  updatedAt
                  hittingDirectionImage
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          type
          firstAttackMember
          secondAttackMember
          gameTitle
          registerFinish
        }
        nextToken
      }
    }
  `;

  // 初回のレンダリングの表示
  const fetchGame = async () => {
    let game;
    try {
      game = await API.graphql({
        query: GetPrivateGameAndSituationAndSequenceByOwnerForGameFormat,
        variables: { owner: owner, filter: { id: { eq: gameId } } },
      });
    } catch (error) {
      console.log(error);
      setIsError(true);
      return;
    }
    setIsError(false);

    if (
      game.data.getPrivateGameByOwnerForGameFormat.items[0].privateSituations
        .items.length === 0
    ) {
      setIsNoData(true);
      return;
    }
    setIsNoData(false);

    setDate(game.data.getPrivateGameByOwnerForGameFormat.items[0].date);
    setFirstAttackTeam(
      game.data.getPrivateGameByOwnerForGameFormat.items[0].firstAttackTeam
    );
    setSecondAttackTeam(
      game.data.getPrivateGameByOwnerForGameFormat.items[0].secondAttackTeam
    );
    setRegisterFinish(
      game.data.getPrivateGameByOwnerForGameFormat.items[0].registerFinish
    );

    const secondAttackMemberTemp = JSON.parse(
      game.data.getPrivateGameByOwnerForGameFormat.items[0].secondAttackMember
    );

    setGameTitle(
      game.data.getPrivateGameByOwnerForGameFormat.items[0].gameTitle
    );

    setFirstAttackTeam(
      game.data.getPrivateGameByOwnerForGameFormat.items[0].firstAttackTeam
    );
    setSecondAttackTeam(
      game.data.getPrivateGameByOwnerForGameFormat.items[0].secondAttackTeam
    );

    const privateSituationsItemsTmp =
      game.data.getPrivateGameByOwnerForGameFormat.items[0].privateSituations
        .items;
    const privateSituationsItems = [...privateSituationsItemsTmp].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    const maxRegisterNumber = privateSituationsItems.length;

    let detailsForNextBatterTmp = JSON.parse(
      privateSituationsItems[maxRegisterNumber - 1].detailsForNextBatter
    );

    setFirstattackScores(detailsForNextBatterTmp.firstattackScores);
    setSecondattackScores(detailsForNextBatterTmp.secondattackScores);

    setFirstAttackTeamScore(
      privateSituationsItems[maxRegisterNumber - 1].firstAttackTeamScore
    );
    setSecondAttackTeamScore(
      privateSituationsItems[maxRegisterNumber - 1].secondAttackTeamScore
    );

    setScoreBoardMaxInning(
      privateSituationsItems[maxRegisterNumber - 1].inning
    );
    setScoreBoardTopOrBottomOfMaxInning(
      privateSituationsItems[maxRegisterNumber - 1].topOrBottom
    );
    setScoreBoardFirstAttackScore(
      privateSituationsItems[maxRegisterNumber - 1].firstAttackTeamScore
    );
    setScoreBoardSecondAttackScore(
      privateSituationsItems[maxRegisterNumber - 1].secondAttackTeamScore
    );

    setPrivateSituations(privateSituationsItems);

    // URLで個別指定がなければ初期レンダリング表示を一番はじめの登録にする
    if (!situationSeq) {
      situationSeq = 0;
    }

    setProperties(privateSituationsItems[situationSeq]);

    privateSituationsItems[situationSeq].sequences.items.map((sequence) => {
      balls.push(sequence);
    });
    const tmpBalls = balls.slice(0, balls.length);
    setBalls(tmpBalls);
  };

  const navigate = useNavigate();
  useEffect(async () => {
    try {
      const user = await AWSAuth.currentAuthenticatedUser();
      if (user) {
        setUser(user.attributes.sub);
      }
      // 認証はされてるけど、ownerと違う人
      if (user.attributes.sub !== owner) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      navigate("/");
    }

    fetchGame();
  }, []);

  const convertPositionExpression = (number) => {
    let position;
    switch (number) {
      case 0:
        position = "-";
        break;
      case 1:
        position = "投";
        break;
      case 2:
        position = "捕";
        break;
      case 3:
        position = "一";
        break;
      case 4:
        position = "二";
        break;
      case 5:
        position = "三";
        break;
      case 6:
        position = "遊";
        break;
      case 7:
        position = "左";
        break;
      case 8:
        position = "中";
        break;
      case 9:
        position = "右";
        break;
      case 10:
        position = "DH";
        break;
      default:
        position = "-";
        break;
    }
    return position;
  };

  return (
    <Box>
      <CustomPageLabel label="試合の記録を見る" />
      <Box>
        {!sequences && !isError && !isNodata ? (
          <CircularProgress />
        ) : isError && !isNodata ? (
          <Grid container direction="row" justifyContent="center">
            <Alert
              severity="error"
              onClose={() => {
                setIsError(false);
              }}
            >
              エラーが発生しました。ネットワーク接続を確認してください。
            </Alert>
            <Box sx={{ margin: 2 }}>
              <img
                src={YanoSanImgPath}
                alt="矢野さん"
                width={130}
                height={90}
              />
            </Box>
          </Grid>
        ) : isNodata ? (
          <>
            <Grid container direction="row" justifyContent="center">
              <Alert severity="error">
                選択された試合は配球情報がなく、表示する情報がありません。
              </Alert>
            </Grid>
            <Grid container direction="row" justifyContent="center">
              <Button
                size="large"
                variant="outlined"
                sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
                component={LinkRouter}
                to={`/game/view/menu`}
              >
                戻る
              </Button>
            </Grid>
            <Grid container direction="row" justifyContent="center">
              <Box sx={{ margin: 2 }}>
                <img
                  src={YanoSanImgPath}
                  alt="矢野さん"
                  width={130}
                  height={90}
                />
              </Box>
            </Grid>
          </>
        ) : (
          <Box>
            {/* スコアボード（スマホ向け） */}
            <Box
              sx={{
                backgroundColor: "#009966",
                color: "white",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                display: { xs: "block", sm: "none" },
              }}
            >
              <Grid container direction="row" justifyContent="center">
                {scoreBoardMaxInning < 10 && (
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <div>
                        <table>
                          <thead>
                            <tr>
                              <th>-</th>
                              {firstattackScores.map((_, i) => {
                                //9イニング以内だったら9イニングまでの表示
                                if (scoreBoardMaxInning <= 9 && i < 9) {
                                  return <th key={i}> {i + 1}</th>;
                                }
                                //9イニングより多かったら必要なイニングまでの表示
                                if (
                                  scoreBoardMaxInning > 9 &&
                                  scoreBoardMaxInning > i
                                ) {
                                  return <th key={i}> {i + 1}</th>;
                                }
                              })}
                              <th>計</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {firstAttackTeam ? (
                                  <Box
                                    sx={{ fontSize: 10, fontWeight: "bold" }}
                                  >
                                    {firstAttackTeam}
                                  </Box>
                                ) : (
                                  "先攻"
                                )}
                              </td>
                              {firstattackScores.map((score, i) => {
                                if (i < scoreBoardMaxInning) {
                                  return (
                                    <Box
                                      key={i}
                                      component="td"
                                      onClick={() =>
                                        selectInningAndTopOrBottom(i + 1, "top")
                                      }
                                    >
                                      {score}
                                    </Box>
                                  );
                                }
                                // 計にscoreをあわせるために空白でうめる
                                if (scoreBoardMaxInning < 10 && i < 9) {
                                  return <Box key={i} component="td"></Box>;
                                }
                              })}
                              <td>{scoreBoardFirstAttackScore}</td>
                            </tr>
                            <tr>
                              <td>
                                {secondAttackTeam ? (
                                  <Box
                                    sx={{ fontSize: 10, fontWeight: "bold" }}
                                  >
                                    {secondAttackTeam}
                                  </Box>
                                ) : (
                                  "後攻"
                                )}
                              </td>
                              {secondattackScores.map((score, i) => {
                                if (
                                  scoreBoardTopOrBottomOfMaxInning === "bottom"
                                ) {
                                  if (i < scoreBoardMaxInning) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "bottom"
                                          )
                                        }
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                  // 計にscoreをあわせるために空白でうめる
                                  if (scoreBoardMaxInning < 10 && i < 9) {
                                    return <Box key={i} component="td"></Box>;
                                  }
                                } else {
                                  if (i < scoreBoardMaxInning - 1) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "bottom"
                                          )
                                        }
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                  // 計にscoreをあわせるために空白でうめる
                                  if (scoreBoardMaxInning - 1 < 10 && i < 9) {
                                    return <Box key={i} component="td"></Box>;
                                  }
                                }
                                // }
                              })}

                              <td>{scoreBoardSecondAttackScore}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Stack>
                  </Grid>
                )}
                {scoreBoardMaxInning > 9 && (
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <div>
                        <table>
                          <thead>
                            <tr>
                              <th>-</th>
                              {firstattackScores.map((_, i) => {
                                //9イニング以内だったら9イニングまでの表示
                                if (i < 9) {
                                  return <th key={i}> {i + 1}</th>;
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {firstAttackTeam ? (
                                  <Box
                                    sx={{ fontSize: 10, fontWeight: "bold" }}
                                  >
                                    {firstAttackTeam}
                                  </Box>
                                ) : (
                                  "先攻"
                                )}
                              </td>
                              {firstattackScores.map((score, i) => {
                                if (i < 9) {
                                  return (
                                    <Box
                                      key={i}
                                      component="td"
                                      onClick={() =>
                                        selectInningAndTopOrBottom(i + 1, "top")
                                      }
                                    >
                                      {score}
                                    </Box>
                                  );
                                }
                              })}
                            </tr>
                            <tr>
                              <td>
                                {secondAttackTeam ? (
                                  <Box
                                    sx={{ fontSize: 10, fontWeight: "bold" }}
                                  >
                                    {secondAttackTeam}
                                  </Box>
                                ) : (
                                  "後攻"
                                )}
                              </td>
                              {secondattackScores.map((score, i) => {
                                if (i < 9) {
                                  return (
                                    <Box
                                      key={i}
                                      component="td"
                                      onClick={() =>
                                        selectInningAndTopOrBottom(
                                          i + 1,
                                          "bottom"
                                        )
                                      }
                                    >
                                      {score}
                                    </Box>
                                  );
                                }
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <table>
                          <thead>
                            <tr>
                              {firstattackScores.map((_, i) => {
                                if (i > 8 && i < scoreBoardMaxInning) {
                                  return (
                                    <th key={i} className="score-board">
                                      {" "}
                                      {i + 1}
                                    </th>
                                  );
                                }
                              })}
                              <th>計</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {firstattackScores.map((score, i) => {
                                if (i > 8 && i < scoreBoardMaxInning) {
                                  return (
                                    <Box
                                      key={i}
                                      component="td"
                                      onClick={() =>
                                        selectInningAndTopOrBottom(i + 1, "top")
                                      }
                                      className="score-board"
                                    >
                                      {score}
                                    </Box>
                                  );
                                }
                                // 計にscoreをあわせるために空白でうめる
                                if (scoreBoardMaxInning < 10 && i < 9) {
                                  return <Box key={i} component="td"></Box>;
                                }
                              })}
                              <td>{scoreBoardFirstAttackScore}</td>
                            </tr>
                            <tr>
                              {secondattackScores.map((score, i) => {
                                if (
                                  scoreBoardTopOrBottomOfMaxInning === "bottom"
                                ) {
                                  if (i > 8 && i < scoreBoardMaxInning) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "bottom"
                                          )
                                        }
                                        className="score-board"
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                  // 計にscoreをあわせるために空白でうめる
                                  if (scoreBoardMaxInning < 10 && i < 9) {
                                    return <Box key={i} component="td"></Box>;
                                  }
                                } else {
                                  if (i > 8 && i < scoreBoardMaxInning - 1) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "bottom"
                                          )
                                        }
                                        className="score-board"
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                  // 計にscoreをあわせるために空白でうめる
                                  if (scoreBoardMaxInning - 1 < 10 && i < 9) {
                                    return <Box key={i} component="td"></Box>;
                                  }
                                }

                                // }
                              })}
                              <td>{scoreBoardSecondAttackScore}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Container>
                <Grid container direction="row" justifyContent="space-around">
                  <Grid item xs={3} sm={6}>
                    {firstAttackMember.map((member, index) => (
                      <Box>
                        {index + 1 === registeredBatterOrder &&
                        member.name === batter ? (
                          // <Box sx={{ fontWeight: "bold", fontSize: 8, textAlign: "center" }}>
                          //   {index + 1} {member.name}
                          // </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                              {index + 1}
                            </Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ fontSize: 8 }}>投</Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          textAlign: "center",
                          fontSize: 8,
                        }}
                      >
                        {firstAttackMemberPitch}
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          fontSize: 8,
                        }}
                      >
                        -
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={6}>
                    {secondAttackMember.map((member, index) => (
                      <Box>
                        {index + 1 === registeredBatterOrder &&
                        member.name === batter ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                              {index + 1}
                            </Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ fontSize: 8 }}>投</Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          textAlign: "center",
                          fontSize: 8,
                        }}
                      >
                        {secondAttackMemberPitch}
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          fontSize: 8,
                        }}
                      >
                        -
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            {/* 対戦サマリ（スマホ向け） */}
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Box sx={{ marginTop: 1 }}>
                <Container
                  sx={{
                    backgroundColor: "#222222",
                    color: "white",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      {date == "2199-12-31" ? (
                        <Box component="span" sx={{ fontSize: 12 }}>
                          試合日情報なし
                        </Box>
                      ) : (
                        date
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </Box>
              <Container
                sx={{
                  paddingTop: 1,
                  backgroundColor: "#222222",
                  color: "white",
                  display: { xs: "block", sm: "none" },
                }}
              >
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!firstAttackTeam ? (
                        // ? "batterTeam No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          打者チーム情報なし
                        </Box>
                      ) : (
                        firstAttackTeam
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!firstAttackTeamScore && firstAttackTeamScore !== 0 ? (
                        // ? "batterTeamScore No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          得点情報なし
                        </Box>
                      ) : (
                        firstAttackTeamScore
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!secondAttackTeam ? (
                        // ? "pitcherTeam No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          投手チーム情報なし
                        </Box>
                      ) : (
                        secondAttackTeam
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!secondAttackTeamScore && secondAttackTeamScore !== 0 ? (
                        // ? "pitcherTeamScore No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          得点情報なし
                        </Box>
                      ) : (
                        secondAttackTeamScore
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Container>
              <Box>
                <Container
                  sx={{
                    paddingTop: 1,
                    backgroundColor: "#222222",
                    color: "white",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      <span>
                        {batter == "NODATA" ? (
                          // ? "batter No DATA"
                          ""
                        ) : (
                          <>
                            <span className="batter">打</span>
                            <> </>
                            {batter}
                          </>
                        )}
                      </span>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <span>
                        {!bat
                          ? // ? "bat NoDATA"
                            ""
                          : ` ${displayBat(bat)}`}
                      </span>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <span>
                        {pitcher == "NODATA" ? (
                          // ? "pitcher No DATA"
                          <span className="pitcher">投</span>
                        ) : (
                          <>
                            <span className="pitcher">投</span>
                            <> </> {pitcher}
                          </>
                        )}
                      </span>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <span>
                        {pitthrow == "right" && " 右投"}
                        {pitthrow == "left" && " 左投"}
                        {!pitthrow && ""}
                      </span>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <span>
                        {pitchNumber ? `投球数:${pitchNumber}` : ""}
                      </span>
                    </Grid>
                  </Grid>
                </Container>
                <Container
                  sx={{
                    paddingTop: 1,
                    backgroundColor: "#222222",
                    color: "white",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    marginBottom: 3,
                  }}
                >
                  <Grid container>
                    <Grid item xs={4} sm={4}>
                      <div>
                        {!sequences.items[sequences.items.length - 1].balls
                          ? // ? "balls no data"
                            "B"
                          : displayBallsMark(
                              sequences.items[sequences.items.length - 1].balls
                            )}
                      </div>
                      <div>
                        {!sequences.items[sequences.items.length - 1].strikes
                          ? // ? "strikes no data"
                            "S"
                          : displayStrikesMark(
                              sequences.items[sequences.items.length - 1]
                                .strikes
                            )}
                      </div>
                      <div>
                        {!outCounts
                          ? // ? "outCounts no data"
                            "O"
                          : displayOutCountMark(outCounts)}
                      </div>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <span>
                        {!inning ? (
                          // ? "inning no data"
                          <Box component="span" sx={{ fontSize: 12 }}>
                            イニング情報なし
                          </Box>
                        ) : (
                          `${inning}回`
                        )}
                      </span>
                      <span>
                        {topOrBottom == "top" && "表"}
                        {topOrBottom == "bottom" && "裏"}
                        {!topOrBottom && ""}
                      </span>

                      <div>
                        {!runner
                          ? // ? "runner no data"
                            displayRunnerPicture(0)
                          : displayRunnerPicture(runner)}
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>

            <Container
              sx={{
                maxWidth: 700,
              }}
            >
              {/* スコアボード（タブレット、デスクトップ向け） */}
              <Box
                sx={{
                  backgroundColor: "#009966",
                  color: "white",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Grid container direction="row" justifyContent="center">
                  <Grid item xs={2} sm={2}>
                    {firstAttackMember.map((member, index) => (
                      <Box>
                        {index + 1 === registeredBatterOrder &&
                        member.name === batter ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                              {index + 1}
                            </Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ fontSize: 8 }}>投</Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          textAlign: "center",
                          fontSize: 8,
                        }}
                      >
                        {firstAttackMemberPitch}
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          fontSize: 8,
                        }}
                      >
                        -
                      </Box>
                    </Box>
                  </Grid>

                  {scoreBoardMaxInning < 10 && (
                    <Grid item xs={7} sm={7}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <div>
                          <table>
                            <thead>
                              <tr>
                                <th>-</th>
                                {firstattackScores.map((_, i) => {
                                  //9イニング以内だったら9イニングまでの表示
                                  if (scoreBoardMaxInning <= 9 && i < 9) {
                                    return <th key={i}> {i + 1}</th>;
                                  }
                                  //9イニングより多かったら必要なイニングまでの表示
                                  if (
                                    scoreBoardMaxInning > 9 &&
                                    scoreBoardMaxInning > i
                                  ) {
                                    return <th key={i}> {i + 1}</th>;
                                  }
                                })}
                                <th>計</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {firstAttackTeam ? (
                                    <Box
                                      sx={{ fontSize: 10, fontWeight: "bold" }}
                                    >
                                      {firstAttackTeam}
                                    </Box>
                                  ) : (
                                    "先攻"
                                  )}
                                </td>
                                {firstattackScores.map((score, i) => {
                                  if (i < scoreBoardMaxInning) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "top"
                                          )
                                        }
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                  // 計にscoreをあわせるために空白でうめる
                                  if (scoreBoardMaxInning < 10 && i < 9) {
                                    return <Box key={i} component="td"></Box>;
                                  }
                                })}
                                <td>{scoreBoardFirstAttackScore}</td>
                              </tr>
                              <tr>
                                <td>
                                  {secondAttackTeam ? (
                                    <Box
                                      sx={{ fontSize: 10, fontWeight: "bold" }}
                                    >
                                      {secondAttackTeam}
                                    </Box>
                                  ) : (
                                    "後攻"
                                  )}
                                </td>
                                {secondattackScores.map((score, i) => {
                                  if (
                                    scoreBoardTopOrBottomOfMaxInning ===
                                    "bottom"
                                  ) {
                                    if (i < scoreBoardMaxInning) {
                                      return (
                                        <Box
                                          key={i}
                                          component="td"
                                          onClick={() =>
                                            selectInningAndTopOrBottom(
                                              i + 1,
                                              "bottom"
                                            )
                                          }
                                        >
                                          {score}
                                        </Box>
                                      );
                                    }
                                    // 計にscoreをあわせるために空白でうめる
                                    if (scoreBoardMaxInning < 10 && i < 9) {
                                      return <Box key={i} component="td"></Box>;
                                    }
                                  } else {
                                    if (i < scoreBoardMaxInning - 1) {
                                      return (
                                        <Box
                                          key={i}
                                          component="td"
                                          onClick={() =>
                                            selectInningAndTopOrBottom(
                                              i + 1,
                                              "bottom"
                                            )
                                          }
                                        >
                                          {score}
                                        </Box>
                                      );
                                    }
                                    // 計にscoreをあわせるために空白でうめる
                                    if (scoreBoardMaxInning - 1 < 10 && i < 9) {
                                      return <Box key={i} component="td"></Box>;
                                    }
                                  }
                                  // }
                                })}

                                <td>{scoreBoardSecondAttackScore}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Stack>
                    </Grid>
                  )}
                  {scoreBoardMaxInning > 9 && (
                    <Grid item xs={7} sm={7}>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <div>
                          <table>
                            <thead>
                              <tr>
                                <th>-</th>
                                {firstattackScores.map((_, i) => {
                                  //9イニング以内だったら9イニングまでの表示
                                  if (i < 9) {
                                    return <th key={i}> {i + 1}</th>;
                                  }
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {firstAttackTeam ? (
                                    <Box sx={{ fontSize: 10 }}>
                                      {firstAttackTeam}
                                    </Box>
                                  ) : (
                                    "先攻"
                                  )}
                                </td>
                                {firstattackScores.map((score, i) => {
                                  if (i < 9) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "top"
                                          )
                                        }
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                })}
                              </tr>
                              <tr>
                                <td>
                                  {secondAttackTeam ? (
                                    <Box sx={{ fontSize: 10 }}>
                                      {secondAttackTeam}
                                    </Box>
                                  ) : (
                                    "後攻"
                                  )}
                                </td>
                                {secondattackScores.map((score, i) => {
                                  if (i < 9) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "bottom"
                                          )
                                        }
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <table>
                            <thead>
                              <tr>
                                {firstattackScores.map((_, i) => {
                                  if (i > 8 && i < scoreBoardMaxInning) {
                                    return (
                                      <th key={i} className="score-board">
                                        {" "}
                                        {i + 1}
                                      </th>
                                    );
                                  }
                                })}
                                <th>計</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {firstattackScores.map((score, i) => {
                                  if (i > 8 && i < scoreBoardMaxInning) {
                                    return (
                                      <Box
                                        key={i}
                                        component="td"
                                        onClick={() =>
                                          selectInningAndTopOrBottom(
                                            i + 1,
                                            "top"
                                          )
                                        }
                                        className="score-board"
                                      >
                                        {score}
                                      </Box>
                                    );
                                  }
                                  // 計にscoreをあわせるために空白でうめる
                                  if (scoreBoardMaxInning < 10 && i < 9) {
                                    return <Box key={i} component="td"></Box>;
                                  }
                                })}
                                <td>{scoreBoardFirstAttackScore}</td>
                              </tr>
                              <tr>
                                {secondattackScores.map((score, i) => {
                                  if (
                                    scoreBoardTopOrBottomOfMaxInning ===
                                    "bottom"
                                  ) {
                                    if (i > 8 && i < scoreBoardMaxInning) {
                                      return (
                                        <Box
                                          key={i}
                                          component="td"
                                          onClick={() =>
                                            selectInningAndTopOrBottom(
                                              i + 1,
                                              "bottom"
                                            )
                                          }
                                          className="score-board"
                                        >
                                          {score}
                                        </Box>
                                      );
                                    }
                                    // 計にscoreをあわせるために空白でうめる
                                    if (scoreBoardMaxInning < 10 && i < 9) {
                                      return <Box key={i} component="td"></Box>;
                                    }
                                  } else {
                                    if (i > 8 && i < scoreBoardMaxInning - 1) {
                                      return (
                                        <Box
                                          key={i}
                                          component="td"
                                          onClick={() =>
                                            selectInningAndTopOrBottom(
                                              i + 1,
                                              "bottom"
                                            )
                                          }
                                          className="score-board"
                                        >
                                          {score}
                                        </Box>
                                      );
                                    }
                                    // 計にscoreをあわせるために空白でうめる
                                    if (scoreBoardMaxInning - 1 < 10 && i < 9) {
                                      return <Box key={i} component="td"></Box>;
                                    }
                                  }
                                  // }
                                })}
                                <td>{scoreBoardSecondAttackScore}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={2} sm={2}>
                    {secondAttackMember.map((member, index) => (
                      <Box>
                        {index + 1 === registeredBatterOrder &&
                        member.name === batter ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                              {index + 1}
                            </Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                textAlign: "center",
                                fontSize: 8,
                              }}
                            >
                              {member.name}
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                fontSize: 8,
                              }}
                            >
                              {convertPositionExpression(member.position)}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ fontSize: 8 }}>投</Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          textAlign: "center",
                          fontSize: 8,
                        }}
                      >
                        {secondAttackMemberPitch}
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          fontSize: 8,
                        }}
                      >
                        -
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* 対戦サマリ */}
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Box sx={{ marginTop: 1 }}>
                  <Container
                    sx={{
                      backgroundColor: "#222222",
                      color: "white",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        {date == "2199-12-31" ? (
                          <Box component="span" sx={{ fontSize: 12 }}>
                            試合日情報なし
                          </Box>
                        ) : (
                          date
                        )}
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Container
                  sx={{
                    paddingTop: 1,
                    backgroundColor: "#222222",
                    color: "white",
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      <div>
                        {!firstAttackTeam ? (
                          // ? "batterTeam No DATA"
                          <Box component="span" sx={{ fontSize: 12 }}>
                            打者チーム情報なし
                          </Box>
                        ) : (
                          firstAttackTeam
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <div>
                        {!firstAttackTeamScore && firstAttackTeamScore !== 0 ? (
                          // ? "batterTeamScore No DATA"
                          <Box component="span" sx={{ fontSize: 12 }}>
                            得点情報なし
                          </Box>
                        ) : (
                          firstAttackTeamScore
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      <div>
                        {!secondAttackTeam ? (
                          // ? "pitcherTeam No DATA"
                          <Box component="span" sx={{ fontSize: 12 }}>
                            投手チーム情報なし
                          </Box>
                        ) : (
                          secondAttackTeam
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <div>
                        {!secondAttackTeamScore &&
                        secondAttackTeamScore !== 0 ? (
                          // ? "pitcherTeamScore No DATA"
                          <Box component="span" sx={{ fontSize: 12 }}>
                            得点情報なし
                          </Box>
                        ) : (
                          secondAttackTeamScore
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Container>
                <Box>
                  <Container
                    sx={{
                      paddingTop: 1,
                      backgroundColor: "#222222",
                      color: "white",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6} sm={6}>
                        <span>
                          {batter == "NODATA" ? (
                            // ? "batter No DATA"
                            ""
                          ) : (
                            <>
                              <span className="batter">打</span>
                              <> </>
                              {batter}
                            </>
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <span>
                          {!bat
                            ? // ? "bat NoDATA"
                              ""
                            : ` ${displayBat(bat)}`}
                        </span>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <span>
                          {pitcher == "NODATA" ? (
                            // ? "pitcher No DATA"
                            <span className="pitcher">投</span>
                          ) : (
                            <>
                              <span className="pitcher">投</span>
                              <> </> {pitcher}
                            </>
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <span>
                          {pitthrow == "right" && " 右投"}
                          {pitthrow == "left" && " 左投"}
                          {!pitthrow && ""}
                        </span>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                      <span>
                        {pitchNumber ? `投球数：${pitchNumber}` : ""}
                      </span>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container
                    sx={{
                      paddingTop: 1,
                      backgroundColor: "#222222",
                      color: "white",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                      marginBottom: 3,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4} sm={4}>
                        <div>
                          {!sequences.items[sequences.items.length - 1].balls
                            ? // ? "balls no data"
                              "B"
                            : displayBallsMark(
                                sequences.items[sequences.items.length - 1]
                                  .balls
                              )}
                        </div>
                        <div>
                          {!sequences.items[sequences.items.length - 1].strikes
                            ? // ? "strikes no data"
                              "S"
                            : displayStrikesMark(
                                sequences.items[sequences.items.length - 1]
                                  .strikes
                              )}
                        </div>
                        <div>
                          {!outCounts
                            ? // ? "outCounts no data"
                              "O"
                            : displayOutCountMark(outCounts)}
                        </div>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <span>
                          {!inning ? (
                            // ? "inning no data"
                            <Box component="span" sx={{ fontSize: 12 }}>
                              イニング情報なし
                            </Box>
                          ) : (
                            `${inning}回`
                          )}
                        </span>
                        <span>
                          {topOrBottom == "top" && "表"}
                          {topOrBottom == "bottom" && "裏"}
                          {!topOrBottom && ""}
                        </span>

                        <div>
                          {!runner
                            ? // ? "runner no data"
                              displayRunnerPicture(0)
                            : displayRunnerPicture(runner)}
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={preBatter}
                  disabled={preBatterDisabled}
                  startIcon={<ArrowBackIosNewIcon />}
                >
                  前の打者
                </Button>
                <Button
                  onClick={nextBatter}
                  disabled={nextBatterDisabled}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  次の打者
                </Button>
              </Stack>

              <Container sx={{ bgcolor: "Transparent" }}>
                <div id="pitchSequenceParentFrame">
                  <div id="pitchSequenceChildFrame">
                    <img
                      src={pichsequenceImagePath}
                      id="pitchSequenceImage"
                      alt="配球画面"
                    />

                    {Object.keys(balls).map((key) => {
                      let left = 0;
                      let top = 0;

                      left = balls[key].pitchXPosition;
                      top = balls[key].pitchYPosition;
                      return (
                        <div
                          id={Number(key) + 1}
                          style={{ ...style, left, top }}
                        >
                          {SelectBallImage(
                            balls[key].breakingBall,
                            Number(key) + 1
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Container>
              {/* 球種と結果一覧 */}
              <Box sx={{ margin: 2 }}>
                <SequenceTable
                  count={balls.length}
                  balls={balls}
                ></SequenceTable>
              </Box>
              <Box sx={{ margin: 2 }}>
                <Box>{situationMemo ? `補足：${situationMemo}` : ""}</Box>
              </Box>
              <span className="title">打球方向</span>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <div style={containerStyle}>
                  <SignatureCanvas
                    penColor="green"
                    canvasProps={{
                      width: 350,
                      height: 350,
                      className: "sigCanvas",
                      background: "transparent",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    ref={(ref) => {
                      hittingDirectionImage.current = ref;
                    }}
                  />
                </div>
              </Stack>

              <div>
                {!sequences.items[sequences.items.length - 1].memo &&
                  !editMemoFlag && (
                    <>
                      <span className="title">メモ</span>
                      <Box sx={{ margin: 1 }}>メモの内容がありません</Box>
                    </>
                  )}
                {sequences.items[sequences.items.length - 1].memo &&
                  !editMemoFlag && (
                    <>
                      <span className="title">メモ</span>
                      <Box
                        sx={{ margin: 1 }}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {sequences.items[sequences.items.length - 1].memo}
                      </Box>
                    </>
                  )}
                {editMemoFlag && (
                  <>
                    <span className="title">メモ</span>
                    <Box sx={{ margin: 1 }}>
                      <TextInput
                        fullWidth={true}
                        multiline={true}
                        rows={8}
                        type={"text"}
                        value={memo}
                        onChange={inputMemo}
                        error={!memo}
                        helperText={!memo && "なにも入力されていません"}
                      ></TextInput>
                    </Box>
                  </>
                )}

                {user && owner == user && !editMemoFlag && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button variant="outlined" onClick={changeEditMemoFlag}>
                      メモの編集
                    </Button>
                  </Stack>
                )}
                {user && owner == user && editMemoFlag && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button variant="outlined" onClick={registerMemo}>
                      メモの編集完了
                    </Button>
                  </Stack>
                )}
              </div>
            </Container>
            <Divider sx={{ margin: 2 }}>集計データ</Divider>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Button
                    size="large"
                    variant="outlined"
                    component={LinkRouter}
                    startIcon={<AutoGraphIcon />}
                    to={`/game/view/data/${owner}/${gameId}`}
                    disabled={!registerFinish}
                  >
                    投球結果
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
}
