import { TextField } from "@mui/material";

const TextInput = (props) => {
  return (
    <TextField
      fullWidth={props.fullWidth}
      label={props.label}
      // margin="dense"
      multiline={props.multiline}
      rows={props.rows}
      value={props.value}
      type={props.type}
      onChange={props.onChange}
      sx={{ margin: props.margin }}
      placeholder={props.placeholder}
      error={props.error}
      helperText={props.helperText}
      size={props.size}
      variant={props.variant}
    />
  );
};

export default TextInput;
