import {
  Box,
  MenuItem,
  Typography,
  FormControl,
  Select,
  InputLabel,
  Tabs,
  Tab,
  Grid,
  Stack,
} from "@mui/material";
import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomPageLabel from "../CustomPageLabel";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import CourseData from "../CourseData";
import { Amplify, Storage } from "aws-amplify";
import awsconfig from "../../aws-exports";
import YanoSanImgPath from "../../assets/img/yano_san.png";
Amplify.configure(awsconfig);
import * as queries from "../../graphql/queries";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const GamePitchData = (props) => {
  const { paramOwner, gameId } = useParams();
  const [owner, setOwner] = useState();
  const [response, setResponse] = useState();

  const [pieViewAllData, setPieViewAllData] = useState();
  const [linksViewAllData, setLinksViewAllData] = useState();
  const [courseViewAllData, setCourseViewAllData] = useState();
  const [allCourseNum, setAllCourseNum] = useState("all");

  const [pieViewOutData, setPieViewOutData] = useState();
  const [linksViewOutData, setLinksViewOutData] = useState();
  const [courseViewOutData, setCourseViewOutData] = useState();
  const [outCourseNum, setOutCourseNum] = useState("all");

  const [pieViewSwingData, setPieViewSwingData] = useState();
  const [linksViewSwingData, setLinksViewSwingData] = useState();
  const [courseViewSwingData, setCourseViewSwingData] = useState();
  const [swingCourseNum, setSwingCourseNum] = useState("all");

  const [pieViewInfieldOutData, setPieViewInfieldOutData] = useState();
  const [linksViewInfieldOutData, setLinksViewInfieldOutData] = useState();
  const [courseViewInfieldOutData, setCourseViewInfieldOutData] = useState();
  const [infieldOutCourseNum, setInfieldOutCourseNum] = useState("all");

  const [pieViewFlightOutData, setPieViewFlightOutData] = useState();
  const [linksViewFlightOutData, setLinksViewFlightOutData] = useState();
  const [courseViewFlightOutData, setCourseViewFlightOutData] = useState();
  const [flightOutCourseNum, setFlightOutCourseNum] = useState("all");

  const [pieViewSingleData, setPieViewSingleData] = useState();
  const [linksViewSingleData, setLinksViewSingleData] = useState();
  const [courseViewSingleData, setCourseViewSingleData] = useState();
  const [singleCourseNum, setSingleCourseNum] = useState("all");

  const [pieViewExtraBaseData, setPieViewExtraBaseData] = useState();
  const [linksViewExtraBaseData, setLinksViewExtraBaseData] = useState();
  const [courseViewExtraBaseData, setCourseViewExtraBaseData] = useState();
  const [extraBaseCourseNum, setExtraBaseCourseNum] = useState("all");

  const [pieViewFirstData, setPieViewFirstData] = useState();
  const [linksViewFirstData, setLinksViewFirstData] = useState();
  const [courseViewFirstData, setCourseViewFirstData] = useState();
  const [firstCourseNum, setFirstCourseNum] = useState("all");

  const [pieViewZeroOneData, setPieViewZeroOneData] = useState();
  const [linksViewZeroOneData, setLinksViewZeroOneData] = useState();
  const [courseViewZeroOneData, setCourseViewZeroOneData] = useState();
  const [zeroOneCourseNum, setZeroOneCourseNum] = useState("all");

  const [pieViewZeroTwoData, setPieViewZeroTwoData] = useState();
  const [linksViewZeroTwoData, setLinksViewZeroTwoData] = useState();
  const [courseViewZeroTwoData, setCourseViewZeroTwoData] = useState();
  const [zeroTwoCourseNum, setZeroTwoCourseNum] = useState("all");

  const [pieViewZeroThreeData, setPieViewZeroThreeData] = useState();
  const [linksViewZeroThreeData, setLinksViewZeroThreeData] = useState();
  const [courseViewZeroThreeData, setCourseViewZeroThreeData] = useState();
  const [zeroThreeCourseNum, setZeroThreeCourseNum] = useState("all");

  const [pieViewOneZeroData, setPieViewOneZeroData] = useState();
  const [linksViewOneZeroData, setLinksViewOneZeroData] = useState();
  const [courseViewOneZeroData, setCourseViewOneZeroData] = useState();
  const [oneZeroCourseNum, setOneZeroCourseNum] = useState("all");

  const [pieViewOneOneData, setPieViewOneOneData] = useState();
  const [linksViewOneOneData, setLinksViewOneOneData] = useState();
  const [courseViewOneOneData, setCourseViewOneOneData] = useState();
  const [oneOneCourseNum, setOneOneCourseNum] = useState("all");

  const [pieViewOneTwoData, setPieViewOneTwoData] = useState();
  const [linksViewOneTwoData, setLinksViewOneTwoData] = useState();
  const [courseViewOneTwoData, setCourseViewOneTwoData] = useState();
  const [oneTwoCourseNum, setOneTwoCourseNum] = useState("all");

  const [pieViewOneThreeData, setPieViewOneThreeData] = useState();
  const [linksViewOneThreeData, setLinksViewOneThreeData] = useState();
  const [courseViewOneThreeData, setCourseViewOneThreeData] = useState();
  const [oneThreeCourseNum, setOneThreeCourseNum] = useState("all");

  const [pieViewTwoZeroData, setPieViewTwoZeroData] = useState();
  const [linksViewTwoZeroData, setLinksViewTwoZeroData] = useState();
  const [courseViewTwoZeroData, setCourseViewTwoZeroData] = useState();
  const [twoZeroCourseNum, setTwoZeroCourseNum] = useState("all");

  const [pieViewTwoOneData, setPieViewTwoOneData] = useState();
  const [linksViewTwoOneData, setLinksViewTwoOneData] = useState();
  const [courseViewTwoOneData, setCourseViewTwoOneData] = useState();
  const [twoOneCourseNum, setTwoOneCourseNum] = useState("all");

  const [pieViewTwoTwoData, setPieViewTwoTwoData] = useState();
  const [linksViewTwoTwoData, setLinksViewTwoTwoData] = useState();
  const [courseViewTwoTwoData, setCourseViewTwoTwoData] = useState();
  const [twoTwoCourseNum, setTwoTwoCourseNum] = useState("all");

  const [pieViewTwoThreeData, setPieViewTwoThreeData] = useState();
  const [linksViewTwoThreeData, setLinksViewTwoThreeData] = useState();
  const [courseViewTwoThreeData, setCourseViewTwoThreeData] = useState();
  const [twoThreeCourseNum, setTwoThreeCourseNum] = useState("all");

  const [batterSelect, setBatterSelect] = useState("all");
  const [pitcherSelect, setPitcherSelect] = useState("all");
  const [selectValue, setSelectValue] = useState("all");

  const [fetchError, setFetchError] = useState(false);
  const [readFileError, setReadFileError] = useState(false);
  const [notFoundAggrDataError, setNotFoundAggrDataError] = useState(false);

  const handleChangeBatterSelect = (event) => {
    setBatterSelect(event.target.value);
  };

  const handleChangePitcherSelect = (event) => {
    setPitcherSelect(event.target.value);
  };

  const [pitcherSelectList, setPitcherSelectList] = useState(["all"]);

  const fetchData = async () => {
    if (!owner) {
      return;
    }
    let s3DataAggrPointer;

    const getS3AggrDataPointerForGameFormat = /* GraphQL */ `
      query GetS3AggrDataPointerForGameFormat($gameId: ID!) {
        getS3AggrDataPointerForGameFormat(gameId: $gameId) {
          owner
          gameId
          fileName
        }
      }
    `;

    try {
      s3DataAggrPointer = await API.graphql({
        query: getS3AggrDataPointerForGameFormat,
        variables: { gameId: gameId },
      });
    } catch (error) {
      console.log(error);
      console.error("Failed to fetch data.");
      setFetchError(true);
      return;
    }

    if (!s3DataAggrPointer.data.getS3AggrDataPointerForGameFormat) {
      console.error("Not found aggr data.");
      setNotFoundAggrDataError(true);
      return;
    }
    let result;
    try {
      result = await Storage.get(
        s3DataAggrPointer.data.getS3AggrDataPointerForGameFormat.fileName,
        {
          level: "private",
          download: true,
          contentType: "application/json", // set return content type, eg "text/html"
        }
      );
    } catch (error) {
      console.error(error);
      setNotFoundAggrDataError(true);
      return;
    }

    const fr = new FileReader();

    // ファイルの読み込みが完了
    fr.onload = (event) => {
      setResponse(JSON.parse(event.target.result));
      setNotFoundAggrDataError(false);
      setFetchError(false);
      setReadFileError(false);
    };

    // ファイル読み込みのエラーの場合
    fr.onerror = (event) => {
      console.error("Failed to read file error.");
      setReadFileError(true);
    };

    // ファイルの読み込みを開始
    if (result) {
      fr.readAsText(result.Body);
    } else {
      console.error("not yet create aggr data.");
      setNotFoundAggrDataError(true);
    }
  };

  // 投手名の選択肢を作成
  useEffect(() => {
    if (response) {
      const pitcherSelectList = ["all"];
      Object.keys(response.all).forEach((property) => {
        //正規表現で先頭がleftのpropertyを取得
        if (property.match(/^left/)) {
          if (property !== "left") {
            //先頭のleftを削除
            pitcherSelectList.push(property.replace(/^left/, ""));
          }
        }
      });
      setPitcherSelectList(pitcherSelectList);
    }
  }, [response]);

  const transformData = (
    courseNum,
    ownerData,
    countBreakingBalls,
    links,
    selectValue
  ) => {
    let linksInternal = [];
    if (courseNum === "all") {
      for (let breakingBall in ownerData[selectValue].all.breakingBall) {
        if (ownerData[selectValue].all.breakingBall[breakingBall] !== 0) {
          countBreakingBalls.push({
            x: breakingBall,
            y: ownerData[selectValue].all.breakingBall[breakingBall],
          });
        }
      }
      for (let link in ownerData[selectValue].all.links) {
        links.push(ownerData[selectValue].all.links[link]);
      }
    } else {
      for (let breakingBall in ownerData[selectValue].courses[courseNum]
        .breakingBall) {
        if (
          ownerData[selectValue].courses[courseNum].breakingBall[
          breakingBall
          ] !== 0
        ) {
          countBreakingBalls.push({
            x: breakingBall,
            y: ownerData[selectValue].courses[courseNum].breakingBall[
              breakingBall
            ],
          });
        }
      }
      for (let link in ownerData[selectValue].courses[courseNum].links) {
        links.push(ownerData[selectValue].courses[courseNum].links[link]);
      }
    }
  };

  // データ種別の選択
  useEffect(() => {
    if (batterSelect === "all" && pitcherSelect === "all") {
      setSelectValue("all");
    } else if (batterSelect === "all" && pitcherSelect !== "all") {
      setSelectValue(pitcherSelect);
    } else if (batterSelect !== "all" && pitcherSelect === "all") {
      setSelectValue(batterSelect);
    } else {
      setSelectValue(batterSelect + pitcherSelect);
    }
    // コースを初期化する
    setAllCourseNum("all");
    setOutCourseNum("all");
    setSwingCourseNum("all");
    setInfieldOutCourseNum("all");
    setFlightOutCourseNum("all");
    setSingleCourseNum("all");
    setExtraBaseCourseNum("all");
    setFirstCourseNum("all");
  }, [batterSelect, pitcherSelect]);

  useEffect(() => {
    if (response) {
      let ownerAllData = response.all;
      let ownerOutData = response.out;
      let ownerSwingData = response.swing;
      let ownerInfieldOutData = response.infield;
      let ownerFlightOutData = response.flight;
      let ownerSingleData = response.single;
      let ownerExtraBaseData = response.extrabase;
      let ownerFirstData = response.first;

      let ownerZeroOneData = response.zeroone;
      let ownerZeroTwoData = response.zerotwo;
      let ownerZeroThreeData = response.zerothree;
      let ownerOneZeroData = response.onezero;
      let ownerOneOneData = response.oneone;
      let ownerOneTwoData = response.onetwo;
      let ownerOneThreeData = response.onethree;
      let ownerTwoZeroData = response.twozero;
      let ownerTwoOneData = response.twoone;
      let ownerTwoTwoData = response.twotwo;
      let ownerTwoThreeData = response.twothree;

      // 取得したdataを打者別、投手名で切り替える
      const switchDataByBatterAndPitcher = (selectValue) => {
        setCourseViewAllData(ownerAllData[selectValue].courses);
        setCourseViewOutData(ownerOutData[selectValue].courses);
        setCourseViewSwingData(ownerSwingData[selectValue].courses);
        setCourseViewInfieldOutData(ownerInfieldOutData[selectValue].courses);
        setCourseViewFlightOutData(ownerFlightOutData[selectValue].courses);
        setCourseViewSingleData(ownerSingleData[selectValue].courses);
        setCourseViewExtraBaseData(ownerExtraBaseData[selectValue].courses);
        setCourseViewFirstData(ownerFirstData[selectValue].courses);

        setCourseViewZeroOneData(ownerZeroOneData[selectValue].courses);
        setCourseViewZeroTwoData(ownerZeroTwoData[selectValue].courses);
        setCourseViewZeroThreeData(ownerZeroThreeData[selectValue].courses);

        setCourseViewOneZeroData(ownerOneZeroData[selectValue].courses);
        setCourseViewOneOneData(ownerOneOneData[selectValue].courses);
        setCourseViewOneTwoData(ownerOneTwoData[selectValue].courses);
        setCourseViewOneThreeData(ownerOneThreeData[selectValue].courses);

        setCourseViewTwoZeroData(ownerTwoZeroData[selectValue].courses);
        setCourseViewTwoOneData(ownerTwoOneData[selectValue].courses);
        setCourseViewTwoTwoData(ownerTwoTwoData[selectValue].courses);
        setCourseViewTwoThreeData(ownerTwoThreeData[selectValue].courses);

      };

      switchDataByBatterAndPitcher(selectValue);

      let countBreakingBallsAll = [];
      let linksAll = [];
      let countBreakingBallsOut = [];
      let linksOut = [];
      let countBreakingBallsSwing = [];
      let linksSwing = [];
      let countBreakingBallsInfieldOut = [];
      let linksInfieldOut = [];
      let countBreakingBallsFlightOut = [];
      let linksFlightOut = [];
      let countBreakingBallsSingle = [];
      let linksSingle = [];
      let countBreakingBallsExtraBase = [];
      let linksExtraBase = [];
      let countBreakingBallsFirst = [];
      let linksFirst = [];

      let countBreakingBallsZeroOne = [];
      let linksZeroOne = [];
      let countBreakingBallsZeroTwo = [];
      let linksZeroTwo = [];
      let countBreakingBallsZeroThree = [];
      let linksZeroThree = [];

      let countBreakingBallsOneZero = [];
      let linksOneZero = [];
      let countBreakingBallsOneOne = [];
      let linksOneOne = [];
      let countBreakingBallsOneTwo = [];
      let linksOneTwo = [];
      let countBreakingBallsOneThree = [];
      let linksOneThree = [];

      let countBreakingBallsTwoZero = [];
      let linksTwoZero = [];
      let countBreakingBallsTwoOne = [];
      let linksTwoOne = [];
      let countBreakingBallsTwoTwo = [];
      let linksTwoTwo = [];
      let countBreakingBallsTwoThree = [];
      let linksTwoThree = [];

      // 全投球
      transformData(
        allCourseNum,
        ownerAllData,
        countBreakingBallsAll,
        linksAll,
        selectValue
      );

      // アウト
      transformData(
        outCourseNum,
        ownerOutData,
        countBreakingBallsOut,
        linksOut,
        selectValue
      );

      // 空振り
      transformData(
        swingCourseNum,
        ownerSwingData,
        countBreakingBallsSwing,
        linksSwing,
        selectValue
      );

      // インフィールド
      transformData(
        infieldOutCourseNum,
        ownerInfieldOutData,
        countBreakingBallsInfieldOut,
        linksInfieldOut,
        selectValue
      );

      // フライトアウト
      transformData(
        flightOutCourseNum,
        ownerFlightOutData,
        countBreakingBallsFlightOut,
        linksFlightOut,
        selectValue
      );

      // シングル
      transformData(
        singleCourseNum,
        ownerSingleData,
        countBreakingBallsSingle,
        linksSingle,
        selectValue
      );

      // 長打
      transformData(
        extraBaseCourseNum,
        ownerExtraBaseData,
        countBreakingBallsExtraBase,
        linksExtraBase,
        selectValue
      );

      // 初球
      transformData(
        firstCourseNum,
        ownerFirstData,
        countBreakingBallsFirst,
        linksFirst,
        selectValue
      );

      // 0-1
      transformData(
        zeroOneCourseNum,
        ownerZeroOneData,
        countBreakingBallsZeroOne,
        linksZeroOne,
        selectValue
      );

      // 0-2
      transformData(
        zeroTwoCourseNum,
        ownerZeroTwoData,
        countBreakingBallsZeroTwo,
        linksZeroTwo,
        selectValue
      );

      // 0-3
      transformData(
        zeroThreeCourseNum,
        ownerZeroThreeData,
        countBreakingBallsZeroThree,
        linksZeroThree,
        selectValue
      );

      // 1-0
      transformData(
        oneZeroCourseNum,
        ownerOneZeroData,
        countBreakingBallsOneZero,
        linksOneZero,
        selectValue
      );

      // 1-1
      transformData(
        oneOneCourseNum,
        ownerOneOneData,
        countBreakingBallsOneOne,
        linksOneOne,
        selectValue
      );

      // 1-2
      transformData(
        oneTwoCourseNum,
        ownerOneTwoData,
        countBreakingBallsOneTwo,
        linksOneTwo,
        selectValue
      );

      // 1-3
      transformData(
        oneThreeCourseNum,
        ownerOneThreeData,
        countBreakingBallsOneThree,
        linksOneThree,
        selectValue
      );

      // 2-0
      transformData(
        twoZeroCourseNum,
        ownerTwoZeroData,
        countBreakingBallsTwoZero,
        linksTwoZero,
        selectValue
      );

      // 2-1
      transformData(
        twoOneCourseNum,
        ownerTwoOneData,
        countBreakingBallsTwoOne,
        linksTwoOne,
        selectValue
      );

      // 2-2
      transformData(
        twoTwoCourseNum,
        ownerTwoTwoData,
        countBreakingBallsTwoTwo,
        linksTwoTwo,
        selectValue
      );

      // 2-3
      transformData(
        twoThreeCourseNum,
        ownerTwoThreeData,
        countBreakingBallsTwoThree,
        linksTwoThree,
        selectValue
      );

      setPieViewAllData(countBreakingBallsAll);
      setLinksViewAllData(linksAll);

      setPieViewOutData(countBreakingBallsOut);
      setLinksViewOutData(linksOut);

      setPieViewSwingData(countBreakingBallsSwing);
      setLinksViewSwingData(linksSwing);

      setPieViewInfieldOutData(countBreakingBallsInfieldOut);
      setLinksViewInfieldOutData(linksInfieldOut);

      setPieViewFlightOutData(countBreakingBallsFlightOut);
      setLinksViewFlightOutData(linksFlightOut);

      setPieViewSingleData(countBreakingBallsSingle);
      setLinksViewSingleData(linksSingle);

      setPieViewExtraBaseData(countBreakingBallsExtraBase);
      setLinksViewExtraBaseData(linksExtraBase);

      setPieViewFirstData(countBreakingBallsFirst);
      setLinksViewFirstData(linksFirst);

      setPieViewZeroOneData(countBreakingBallsZeroOne);
      setLinksViewZeroOneData(linksZeroOne);
      setPieViewZeroTwoData(countBreakingBallsZeroTwo);
      setLinksViewZeroTwoData(linksZeroTwo);
      setPieViewZeroThreeData(countBreakingBallsZeroThree);
      setLinksViewZeroThreeData(linksZeroThree);

      setPieViewOneZeroData(countBreakingBallsOneZero);
      setLinksViewOneZeroData(linksOneZero);
      setPieViewOneOneData(countBreakingBallsOneOne);
      setLinksViewOneOneData(linksOneOne);
      setPieViewOneTwoData(countBreakingBallsOneTwo);
      setLinksViewOneTwoData(linksOneTwo);
      setPieViewOneThreeData(countBreakingBallsOneThree);
      setLinksViewOneThreeData(linksOneThree);

      setPieViewTwoZeroData(countBreakingBallsTwoZero);
      setLinksViewTwoZeroData(linksTwoZero);
      setPieViewTwoOneData(countBreakingBallsTwoOne);
      setLinksViewTwoOneData(linksTwoOne);
      setPieViewTwoTwoData(countBreakingBallsTwoTwo);
      setLinksViewTwoTwoData(linksTwoTwo);
      setPieViewTwoThreeData(countBreakingBallsTwoThree);
      setLinksViewTwoThreeData(linksTwoThree);

    } else {
      setPieViewAllData(null);
      setLinksViewAllData(null);
      setPieViewOutData(null);
      setLinksViewOutData(null);
      setPieViewSwingData(null);
      setLinksViewSwingData(null);
      setPieViewInfieldOutData(null);
      setLinksViewInfieldOutData(null);
      setPieViewFlightOutData(null);
      setLinksViewFlightOutData(null);
      setPieViewSingleData(null);
      setLinksViewSingleData(null);
      setPieViewExtraBaseData(null);
      setLinksViewExtraBaseData(null);
      setPieViewFirstData(null);
      setLinksViewFirstData(null);

      setPieViewZeroOneData(null);
      setLinksViewZeroOneData(null);
      setPieViewZeroTwoData(null);
      setLinksViewZeroTwoData(null);
      setPieViewZeroTwoData(null);
      setLinksViewZeroTwoData(null);
      setPieViewZeroThreeData(null);
      setLinksViewZeroThreeData(null);

      setPieViewOneZeroData(null);
      setLinksViewOneZeroData(null);
      setPieViewOneOneData(null);
      setLinksViewOneOneData(null);
      setPieViewOneTwoData(null);
      setLinksViewOneTwoData(null);
      setPieViewOneTwoData(null);
      setLinksViewOneTwoData(null);
      setPieViewOneThreeData(null);
      setLinksViewOneThreeData(null);

      setPieViewTwoZeroData(null);
      setLinksViewTwoZeroData(null);
      setPieViewTwoOneData(null);
      setLinksViewTwoOneData(null);
      setPieViewTwoTwoData(null);
      setLinksViewTwoTwoData(null);
      setPieViewTwoTwoData(null);
      setLinksViewTwoTwoData(null);
      setPieViewTwoThreeData(null);
      setLinksViewTwoThreeData(null);

      return;
    }
  }, [
    response,
    allCourseNum,
    outCourseNum,
    swingCourseNum,
    infieldOutCourseNum,
    flightOutCourseNum,
    singleCourseNum,
    extraBaseCourseNum,
    firstCourseNum,
    zeroOneCourseNum,
    zeroTwoCourseNum,
    zeroThreeCourseNum,
    oneZeroCourseNum,
    oneOneCourseNum,
    oneTwoCourseNum,
    oneThreeCourseNum,
    twoZeroCourseNum,
    twoOneCourseNum,
    twoTwoCourseNum,
    twoThreeCourseNum,
    selectValue,
  ]);

  const navigate = useNavigate();
  useEffect(async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      if (user) {
        setOwner(user.attributes.sub);
      }
      if (user.attributes.sub !== paramOwner) {
        navigate("/");
      }
    } catch {
      setOwner(null);
    }
  }, []);

  useEffect(() => {
    try {
      fetchData("all");
    } catch {
      setFetchError(true);
    }
  }, [owner]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, index) => {
    setTabIndex(index);
  };

  return (
    <>
      <CustomPageLabel label="集計データを見る" />
      {owner ? (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="batter-select-label">右、左打者</InputLabel>
              <Select
                labelId="batter-select-label"
                id="batter-select-label"
                value={batterSelect}
                onChange={handleChangeBatterSelect}
                label="右、左打者"
                size="small"
              >
                <MenuItem value="right">右打者</MenuItem>
                <MenuItem value="left">左打者</MenuItem>
                {/* <MenuItem value="both">両打ち</MenuItem> */}
                <MenuItem value="all">すべて</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="pitcher-select-label">投手名</InputLabel>
              <Select
                labelId="pitcher-select-label"
                id="pitcher-select-label"
                value={pitcherSelect}
                onChange={handleChangePitcherSelect}
                label="投手名"
                size="small"
              >
                {pitcherSelectList.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item === "all" ? "すべて" : item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                scrollButtons
                allowScrollButtonsMobile
                variant="scrollable"
              >
                <Tab label="全投球" {...a11yProps(0)} />
                <Tab label="全アウト決め球" {...a11yProps(1)} />
                <Tab label="三振、空振り取った球" {...a11yProps(2)} />
                <Tab label="ゴロアウト決め球" {...a11yProps(3)} />
                <Tab label="フライアウト決め球" {...a11yProps(4)} />
                <Tab label="単打された球" {...a11yProps(5)} />
                <Tab label="長打された球" {...a11yProps(6)} />
                <Tab label="初球" {...a11yProps(7)} />
                <Tab label="0S-1B" {...a11yProps(8)} />
                <Tab label="0S-2B" {...a11yProps(9)} />
                <Tab label="0S-3B" {...a11yProps(10)} />
                <Tab label="1S-0B" {...a11yProps(11)} />
                <Tab label="1S-1B" {...a11yProps(12)} />
                <Tab label="1S-2B" {...a11yProps(13)} />
                <Tab label="1S-3B" {...a11yProps(14)} />
                <Tab label="2S-0B" {...a11yProps(15)} />
                <Tab label="2S-1B" {...a11yProps(16)} />
                <Tab label="2S-2B" {...a11yProps(17)} />
                <Tab label="2S-3B" {...a11yProps(18)} />
              </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
              <CourseData
                pieViewData={pieViewAllData}
                courses={courseViewAllData}
                setCourseNum={setAllCourseNum}
                courseNum={allCourseNum}
                links={linksViewAllData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <CourseData
                pieViewData={pieViewOutData}
                courses={courseViewOutData}
                setCourseNum={setOutCourseNum}
                courseNum={outCourseNum}
                links={linksViewOutData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <CourseData
                pieViewData={pieViewSwingData}
                courses={courseViewSwingData}
                setCourseNum={setSwingCourseNum}
                courseNum={swingCourseNum}
                links={linksViewSwingData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <CourseData
                pieViewData={pieViewInfieldOutData}
                courses={courseViewInfieldOutData}
                setCourseNum={setInfieldOutCourseNum}
                courseNum={infieldOutCourseNum}
                links={linksViewInfieldOutData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <CourseData
                pieViewData={pieViewFlightOutData}
                courses={courseViewFlightOutData}
                setCourseNum={setFlightOutCourseNum}
                courseNum={flightOutCourseNum}
                links={linksViewFlightOutData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <CourseData
                pieViewData={pieViewSingleData}
                courses={courseViewSingleData}
                setCourseNum={setSingleCourseNum}
                courseNum={singleCourseNum}
                links={linksViewSingleData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={6}>
              <CourseData
                pieViewData={pieViewExtraBaseData}
                courses={courseViewExtraBaseData}
                setCourseNum={setExtraBaseCourseNum}
                courseNum={extraBaseCourseNum}
                links={linksViewExtraBaseData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={7}>
              <CourseData
                pieViewData={pieViewFirstData}
                courses={courseViewFirstData}
                setCourseNum={setFirstCourseNum}
                courseNum={firstCourseNum}
                links={linksViewFirstData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={8}>
              <CourseData
                pieViewData={pieViewZeroOneData}
                courses={courseViewZeroOneData}
                setCourseNum={setZeroOneCourseNum}
                courseNum={zeroOneCourseNum}
                links={linksViewZeroOneData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={9}>
              <CourseData
                pieViewData={pieViewZeroTwoData}
                courses={courseViewZeroTwoData}
                setCourseNum={setZeroTwoCourseNum}
                courseNum={zeroTwoCourseNum}
                links={linksViewZeroTwoData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={10}>
              <CourseData
                pieViewData={pieViewZeroThreeData}
                courses={courseViewZeroThreeData}
                setCourseNum={setZeroThreeCourseNum}
                courseNum={zeroThreeCourseNum}
                links={linksViewZeroThreeData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={11}>
              <CourseData
                pieViewData={pieViewOneZeroData}
                courses={courseViewOneZeroData}
                setCourseNum={setOneZeroCourseNum}
                courseNum={oneZeroCourseNum}
                links={linksViewOneZeroData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={12}>
              <CourseData
                pieViewData={pieViewOneOneData}
                courses={courseViewOneOneData}
                setCourseNum={setOneOneCourseNum}
                courseNum={oneOneCourseNum}
                links={linksViewOneOneData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={13}>
              <CourseData
                pieViewData={pieViewOneTwoData}
                courses={courseViewOneTwoData}
                setCourseNum={setOneTwoCourseNum}
                courseNum={oneTwoCourseNum}
                links={linksViewOneTwoData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={14}>
              <CourseData
                pieViewData={pieViewOneThreeData}
                courses={courseViewOneThreeData}
                setCourseNum={setOneThreeCourseNum}
                courseNum={oneThreeCourseNum}
                links={linksViewOneThreeData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={15}>
              <CourseData
                pieViewData={pieViewTwoZeroData}
                courses={courseViewTwoZeroData}
                setCourseNum={setTwoZeroCourseNum}
                courseNum={twoZeroCourseNum}
                links={linksViewTwoZeroData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={16}>
              <CourseData
                pieViewData={pieViewTwoOneData}
                courses={courseViewTwoOneData}
                setCourseNum={setTwoOneCourseNum}
                courseNum={twoOneCourseNum}
                links={linksViewTwoOneData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={17}>
              <CourseData
                pieViewData={pieViewTwoTwoData}
                courses={courseViewTwoTwoData}
                setCourseNum={setTwoTwoCourseNum}
                courseNum={twoTwoCourseNum}
                links={linksViewTwoTwoData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={18}>
              <CourseData
                pieViewData={pieViewTwoThreeData}
                courses={courseViewTwoThreeData}
                setCourseNum={setTwoThreeCourseNum}
                courseNum={twoThreeCourseNum}
                links={linksViewTwoThreeData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
          </Box>
        </>
      ) : (
        <>
          <div id="noteAuthDisplay">
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 14,
                fontWeight: "medium",
                textAlign: "left",
                marginTop: 2,
              }}
            >
              「集計データを見る」をご利用の場合
            </InputLabel>{" "}
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 14,
                fontWeight: "medium",
                textAlign: "left",
                margin: 2,
              }}
            >
              新規登録いただき、ログインをお願いします。
            </InputLabel>
          </div>
          <Grid container direction="row" justifyContent="center">
            <Box sx={{ marginTop: 2 }}>
              <img
                src={YanoSanImgPath}
                alt="矢野さん"
                width={100}
                height={70}
              />
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default GamePitchData;
