import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { ViewSequencesSummaryPrivateList, CustomPageLabel } from "./index";
import { ViewSequencesSummaryPublicList } from "./index";
import { useState } from "react";
import { CircularProgress, InputLabel } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLocation, Navigate } from "react-router-dom";
import { MenuItem, Typography, FormControl, Select } from "@mui/material";
import { Pagination } from "@aws-amplify/ui-react";

export default function SearchByOwner() {
  const { owner } = useParams();
  const [responsesPrivate, setResponsesPrivate] = useState();
  const [responsePrivate, setResponsePrivate] = useState();
  const [responsePublic, setResponsePublic] = useState();
  const [responsesPublic, setResponsesPublic] = useState();
  const { route } = useAuthenticator((context) => [context.rule]);
  const [disclosure, setDisclosure] = useState("public");

  const pageLength = 10;
  const viewNumberByPage = 5;
  const [currentPublicPageIndex, setCurrentPublicPageIndex] = useState(1);
  const [hasMorePublicPages, setHasMorePublicPages] = useState(true);
  const [currentPrivatePageIndex, setCurrentPrivatePageIndex] = useState(1);
  const [hasMorePrivatePages, setHasMorePrivatePages] = useState(true);

  const handleChange = (event) => {
    setDisclosure(event.target.value);
  };

  if (route !== "authenticated") {
    const location = useLocation();
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const fetchPrivateSequenceListByOwner = async (owner) => {
    const customListPrivateGamesForSort = `query GetPrivateAllGameForUpdatedSort(
      $type: String!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelprivateGameFilterInput
      $limit: Int
      $nextToken: String  
    ) { 
      getPrivateAllGameForUpdatedSort(
        type: $type
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
          items {
            batterTeam
            createdAt
            date
            id
            owner
            pitcherTeam
            updatedAt
            privateMatches {
              items {
                atBats
                bat
                batter
                createdAt
                gameId
                id
                owner
                pitcher
                throw
                title
                updatedAt
                cases {
                  items {
                    batterTeamScore
                    createdAt
                    id
                    inning
                    matchId
                    outCounts
                    owner
                    pitcherTeamScore
                    runner
                    topOrBottom
                    dataAggrFlag
                    updatedAt
                    sequences {
                      items {
                        atBatResult
                        balls
                        breakingBall
                        caseId
                        createdAt
                        id
                        memo
                        owner
                        pitchResult
                        pitchXPosition
                        pitchYPosition
                        sequence
                        speed
                        strikes
                        updatedAt
                      }
                    }
                  }
                }
              }
            }
          }
          nextToken
        }
      }`;

    let resPrivate;
    let tmpPrivatePageTokens;
    let tmpPrivateGames;
    let tmpDisplayPrivateGames;
    let tmpPrivateHasMorePages;
    tmpPrivatePageTokens = [""];
    tmpPrivateGames = [];
    tmpDisplayPrivateGames = [];
    tmpPrivateHasMorePages = true;

    // 公開設定されている配球情報を一通り取得する
    for (let i = 0; tmpPrivateHasMorePages; i++) {
      if (tmpPrivateHasMorePages) {
        if (i == 0) {
          resPrivate = await API.graphql({
            query: customListPrivateGamesForSort,
            variables: {
              type: "t",
              limit: viewNumberByPage,
              filter: { owner: { eq: owner } },
              sortDirection: "DESC",
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        } else {
          resPrivate = await API.graphql({
            query: customListPrivateGamesForSort,
            variables: {
              type: "t",
              limit: viewNumberByPage,
              nextToken: tmpPrivatePageTokens[i],
              filter: { owner: { eq: owner } },
              sortDirection: "DESC",
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }

        // 終了に達した場合は、これ以上フェッチしない
        if (!resPrivate.data.getPrivateAllGameForUpdatedSort.nextToken) {
          tmpPrivateHasMorePages = false;
        }
        // 決めたページネーション数に達した場合はこれ以上フェッチしない
        if (tmpPrivatePageTokens.length == pageLength) {
          tmpPrivateHasMorePages = false;
        }

        let tmpToken;
        tmpToken = resPrivate.data.getPrivateAllGameForUpdatedSort.nextToken;
        if (tmpToken) {
          tmpPrivatePageTokens.push(tmpToken);
        }

        if (resPrivate.data.getPrivateAllGameForUpdatedSort.items.length != 0) {
          tmpPrivateGames.push(resPrivate);
        }
      }
    }

    setResponsesPrivate(tmpPrivateGames);
    if (tmpPrivateGames.length == 0) {
      setResponsePrivate("notResistered");
    } else {
      setResponsePrivate(tmpPrivateGames[0]);
    }
    setHasMorePrivatePages(false);
  };

  useEffect(() => {
    fetchPrivateSequenceListByOwner(owner);
  }, [owner]);

  const fetchPublicSequenceListByOwner = async (owner) => {
    const customListPublicGames = `query GetPublicGameForCreatedSort(
        $type: String!
        $owner: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelpublicGameFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getPublicGameForCreatedSort(
          type: $type
          owner: $owner
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
      ) {
              items {
                batterTeam
                createdAt
                date
                id
                owner
                pitcherTeam
                updatedAt
                publicMatches {
                  items {
                    atBats
                    bat
                    batter
                    createdAt
                    gameId
                    id
                    owner
                    pitcher
                    throw
                    title
                    updatedAt
                    limitedDisclosure
                    cases {
                      items {
                        batterTeamScore
                        createdAt
                        id
                        inning
                        matchId
                        outCounts
                        owner
                        pitcherTeamScore
                        runner
                        topOrBottom
                        dataAggrFlag
                        updatedAt
                        sequences {
                          items {
                            atBatResult
                            balls
                            breakingBall
                            caseId
                            createdAt
                            id
                            memo
                            owner
                            pitchResult
                            pitchXPosition
                            pitchYPosition
                            sequence
                            speed
                            strikes
                            updatedAt
                          }
                        }
                      }
                    }
                  }
                }
              }
              nextToken
            }
          }`;
    let resPublic;
    let tmpPublicPageTokens;
    let tmpPublicGames;
    let tmpDisplayPublicGames;
    let tmpPublicHasMorePages;
    tmpPublicPageTokens = [""];
    tmpPublicGames = [];
    tmpDisplayPublicGames = [];
    tmpPublicHasMorePages = true;

    // 公開設定されている配球情報を一通り取得する
    for (let i = 0; tmpPublicHasMorePages; i++) {
      if (tmpPublicHasMorePages) {
        if (i == 0) {
          resPublic = await API.graphql({
            query: customListPublicGames,
            variables: {
              type: "t",
              limit: viewNumberByPage,
              owner: { eq: owner },
              sortDirection: "DESC",
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        } else {
          resPublic = await API.graphql({
            query: customListPublicGames,
            variables: {
              type: "t",
              limit: viewNumberByPage,
              nextToken: tmpPublicPageTokens[i],
              owner: { eq: owner },
              sortDirection: "DESC",
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }
        // 終了に達した場合は、これ以上フェッチしない
        if (!resPublic.data.getPublicGameForCreatedSort.nextToken) {
          tmpPublicHasMorePages = false;
        }
        // 決めたページネーション数に達した場合はこれ以上フェッチしない
        if (tmpPublicPageTokens.length == pageLength) {
          tmpPublicHasMorePages = false;
        }

        let tmpToken;
        tmpToken = resPublic.data.getPublicGameForCreatedSort.nextToken;
        if (tmpToken) {
          tmpPublicPageTokens.push(tmpToken);
        }

        if (resPublic.data.getPublicGameForCreatedSort.items.length != 0) {
          tmpPublicGames.push(resPublic);
        }
      }
    }
    setResponsesPublic(tmpPublicGames);
    if (tmpPublicGames.length == 0) {
      setResponsePublic("notRegistered");
    } else {
      setResponsePublic(tmpPublicGames[0]);
    }
    setHasMorePublicPages(false);
  };

  useEffect(() => {
    fetchPublicSequenceListByOwner(owner);
  }, [owner]);

  useEffect(() => {
    if (responsePrivate) {
      setResponsePrivate(responsesPrivate[currentPrivatePageIndex - 1]);
    }
  }, [currentPrivatePageIndex]);

  useEffect(() => {
    if (responsePublic) {
      setResponsePublic(responsesPublic[currentPublicPageIndex - 1]);
    }
  }, [currentPublicPageIndex]);

  return (
    <>
      <div id="searchOwner">
        <CustomPageLabel label="じぶんの配球" />
        <div>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="disclosure-select-label">公開範囲</InputLabel>
            <Select
              labelId="disclosure-select-label"
              id="disclosure-select-label"
              value={disclosure}
              onChange={handleChange}
              label="公開範囲"
            >
              <MenuItem value="public">公開</MenuItem>
              <MenuItem value="private">非公開</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* ID: {owner} */}
        {disclosure == "private" && (
          <>
            {!responsePrivate ? (
              <CircularProgress />
            ) : (
              <>
                {responsePrivate === "notResistered" ? (
                  "登録がありません"
                ) : (
                  <>
                    <ViewSequencesSummaryPrivateList
                      games={
                        responsePrivate.data.getPrivateAllGameForUpdatedSort
                          .items
                      }
                      owner={owner}
                    />
                    <Pagination
                      currentPage={currentPrivatePageIndex}
                      totalPages={
                        responsesPrivate.length < pageLength
                          ? responsesPrivate.length
                          : pageLength
                      }
                      hasMorePages={hasMorePrivatePages}
                      onNext={() => {
                        setCurrentPrivatePageIndex(currentPublicPageIndex + 1);
                      }}
                      onPrevious={() => {
                        setCurrentPrivatePageIndex(currentPublicPageIndex - 1);
                      }}
                      onChange={(pageIndex) =>
                        setCurrentPrivatePageIndex(pageIndex)
                      }
                    />
                    {/* <Typography
                      variant="h7"
                      noWrap
                      component="div"
                      sx={{ margin: 3 }}
                    >
                      最新25件までの表示ですm(_ _)m
                    </Typography> */}
                  </>
                )}
              </>
            )}
          </>
        )}
        {disclosure == "public" && (
          <>
            {!responsePublic ? (
              <CircularProgress />
            ) : (
              <>
                {responsePublic === "notRegistered" ? (
                  "登録がありません"
                ) : (
                  <>
                    <ViewSequencesSummaryPublicList
                      games={
                        responsePublic.data.getPublicGameForCreatedSort.items
                      }
                      // disclosure="publicAndLimited"
                      owner={owner}
                    />
                    <Pagination
                      currentPage={currentPublicPageIndex}
                      totalPages={
                        responsesPublic.length < pageLength
                          ? responsesPublic.length
                          : pageLength
                      }
                      hasMorePages={hasMorePublicPages}
                      onNext={() => {
                        setCurrentPublicPageIndex(currentPublicPageIndex + 1);
                      }}
                      onPrevious={() => {
                        setCurrentPublicPageIndex(currentPublicPageIndex - 1);
                      }}
                      onChange={(pageIndex) =>
                        setCurrentPublicPageIndex(pageIndex)
                      }
                    />
                    {/* <Typography
                      variant="h7"
                      noWrap
                      component="div"
                      sx={{ margin: 3 }}
                    >
                      最新25件までの表示ですm(_ _)m
                    </Typography> */}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
