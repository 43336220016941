import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { IsMobileDevice } from "../../App";
import { GameSeq } from "./GameSeq";
import SignatureCanvas from "react-signature-canvas";
import FieldImgPath from "../../assets/img/gameNote/baseball_field2.png";
import { CustomPageLabel, SequenceTable } from "../index";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  FormHelperText,
  Alert,
  Stack,
  Divider,
  Modal,
  CircularProgress,
  Container,
} from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "../Situation";
import TextInput from "../Forms/TextInput";
import { Box } from "@mui/system";
import { Auth } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { API, Storage } from "aws-amplify";
import update from "react-addons-update";
import ChangeMember from "./ChangeMember";

const containerStyle = {
  backgroundImage: `url(${FieldImgPath})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "350px",
  height: "300px",
  position: "relative",
};

let defaultBalls = {
  1: {
    top: 0,
    left: 0,
    type: "straight",
    result: "",
    speed: null,
    strikes: 0,
    balls: 0,
  },
  2: {
    top: 10,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  3: {
    top: 20,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  4: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  5: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  6: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  7: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  8: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  9: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  10: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  11: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  12: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  13: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  14: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
  15: {
    top: 30,
    left: 20,
    type: "straight",
    result: "",
    speed: "",
    strikes: 0,
    balls: 0,
  },
};

const styleRunner = {
  position: "absolute",
  width: 50,
  height: 50,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#ffffff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleModalMember = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#ffffff",
  border: "2px solid #000",
  boxShadow: 10,
  minWidth: 300,
};

const atBatResults = [
  "deadBall",
  "intentionalWalk",
  "infieldOut",
  "flightOut",
  "hit",
  "double",
  "triple",
  "homeRun",
  "walk",
  "swingingStrikeOut",
  "calledStrikeOut",
  "fildersChoice",
  "swingAway",
  "blowObstruction",
  "doublePlay",
  "sacrificeBunt",
  "sacrificeFly",
  "error",
];

const pitchResults = ["strike", "ball", "foul", "swing"];

export function GameNote() {
  const { owner, gameId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const disclosure = query.get("disclosure");

  // latestSituationIdはChangeMember.jsxでpitchInfoの更新のときに使う
  const [latestSituationId, setLatestSituadionId] = useState(0);

  const [gameTitle, setGameTitle] = useState("");
  const [situationTitle, setSituationTitle] = useState("");

  const inputSituationTitle = (event) => {
    setSituationTitle(event.target.value);
  };

  const [batter, setBatter] = useState("");

  const inputBatter = (event) => {
    setBatter(event.target.value);
  };

  const [firstAttackMember, setFirstAttackMember] = useState();
  const [secondAttackMember, setSecondAttackMember] = useState();

  const [memberBoardFirstAttackMember, setMemberBoardFirstAttackMember] =
    useState();
  const [memberBoardSecondAttackMember, setMemberBoardSecondAttackMember] =
    useState();
  const [
    memberBoardFirstAttackMemberPitch,
    setMemberBoardFirstAttackMemberPitch,
  ] = useState();
  const [
    memberBoardSecondAttackMemberPitch,
    setMemberBoardSecondAttackMemberPitch,
  ] = useState();

  const [bat, setBat] = useState("");
  const [pitcher, setPitcher] = useState("");
  const [pitThrow, setPitThrow] = useState("");
  const [position, setPosition] = useState(0);

  const changeBat = (event) => {
    setBat(event.target.value);
  };

  const inputPitcher = (event) => {
    setPitcher(event.target.value);
  };

  const changePosition = (event) => {
    setPosition(event.target.value);
  };

  const changeThrow = (event) => {
    setPitThrow(event.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const [pitchesNumber, setPitchesNumber] = useState(0);
  const [pitchInfo, setPitchInfo] = useState({
    firstAttackPitchInfo: [{ name: "", pitchesNumber: 0 }],
    secondAttackPitchInfo: [{ name: "", pitchesNumber: 0 }],
  });

  // Situationの初期化
  const [atBats, setAtBats] = useState(1);
  const [inning, setInning] = useState(1);
  const [topOrBottom, setTopOrBottom] = useState("top");
  const [outCounts, setOutCounts] = useState(0);
  const [runner, setRunner] = useState(0);
  const [firstAttackTeamScore, setFirstAttackTeamScore] = useState(0);
  const [secondAttackTeamScore, setSecondAttackTeamScore] = useState(0);
  const [registeredBatterOrder, setRegisteredBatterOrder] = useState(1);
  const [firstAttackTeam, setFirstAttackTeam] = useState(0);
  const [secondAttackTeam, setSecondAttackTeam] = useState(0);
  const [situationMemo, setSituationMemo] = useState("");

  const [sequenceMemo, setSequenceMemo] = useState("");

  const [adjustOutCounts, setAdjustOutCounts] = useState(1);
  const [adjustScore, setAdjustScore] = useState(0);

  const inputSituationMemo = (event) => {
    setSituationMemo(event.target.value);
  };

  const inputAdjustScore = (event) => {
    if (
      (event.target.value >= 0 && event.target.value < 100) ||
      !event.target.value
    ) {
      setAdjustScore(event.target.value);
    }
  };

  const inputSequenceMemo = (event) => {
    setSequenceMemo(event.target.value);
  };

  const incrementOutCount = () => {
    setOutCounts((prev) => {
      if (prev >= 3) {
        return prev;
      }
      return prev + 1;
    });
  };

  const decrementOutCount = () => {
    setOutCounts((prev) => {
      if (prev <= 0) {
        return prev;
      }
      return prev - 1;
    });
  };

  const inputAdjustOutCounts = (event) => {
    switch (parseInt(outCounts)) {
      case 0:
        if (event.target.value >= 0 && event.target.value <= 3) {
          setAdjustOutCounts(event.target.value);
        }
        break;
      case 1:
        if (event.target.value >= 0 && event.target.value <= 2) {
          setAdjustOutCounts(event.target.value);
        }
        break;
      case 2:
        if (event.target.value >= 0 && event.target.value <= 1) {
          setAdjustOutCounts(event.target.value);
        }
        break;
    }
  };

  const displayOutCountMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>O </span>
            <font color="red">●○</font>
          </>
        );
      case 2:
        return (
          <>
            <span>O </span>
            <font color="red">●●</font>
          </>
        );
      case 3:
        return (
          <>
            <span>O </span>
            <font color="red">●●●</font>
          </>
        );
      default:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };

  const displayRunnerPicture = (runner) => {
    switch (runner) {
      case 0:
        return (
          <img
            src={require(`../../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 1:
        return (
          <img
            src={require(`../../assets/img/runner/first.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 2:
        return (
          <img
            src={require(`../../assets/img/runner/second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 3:
        return (
          <img
            src={require(`../../assets/img/runner/third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 4:
        return (
          <img
            src={require(`../../assets/img/runner/first_second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 5:
        return (
          <img
            src={require(`../../assets/img/runner/first_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 6:
        return (
          <img
            src={require(`../../assets/img/runner/second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 7:
        return (
          <img
            src={require(`../../assets/img/runner/first_second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      default:
        return (
          <img
            src={require(`../../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
    }
  };

  const selectRunner = (event) => {
    setRunner(event.target.value);
  };

  const [expandedTitleMatch, setExpandedTitleMatch] = useState(true);
  const handleChangeTitleMatch = () => (event) => {
    setExpandedTitleMatch(expandedTitleMatch ? false : true);
  };

  const [expandedSituations, setExpandedSituations] = useState(true);
  const handleChangeSituations = () => (event) => {
    setExpandedSituations(expandedSituations ? false : true);
  };

  const [expandedPitch, setExpandedPitch] = useState(true);
  const handleChangePitch = () => (event) => {
    setExpandedPitch(expandedPitch ? false : true);
  };

  const [expandedHittingDirection, setExpandedHittingDirection] =
    useState(true);
  const handleChangeHittingDirection = () => (event) => {
    setExpandedHittingDirection(expandedHittingDirection ? false : true);
  };

  const [expandedMemo, setExpandedMemo] = useState(true);
  const handleChangeMemo = () => (event) => {
    setExpandedMemo(expandedMemo ? false : true);
  };

  // GameSeq関連
  const [balls, setBalls] = useState(defaultBalls);

  const [count, setCount] = useState(1);
  const [strikeCount, setStrikeCount] = useState(0);
  const [ballCount, setBallCount] = useState(0);

  const displayBallsMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>B </span>
            <font color="green">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>B </span>
            <font color="green">●●</font>
          </>
        );
      case 3:
        return (
          <>
            <span>B </span>
            <font color="green">●●●</font>
          </>
        );
      default:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
    }
  };

  const displayStrikesMark = (count) => {
    switch (count) {
      case 0:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>S </span>
            <font color="yellow">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>S </span>
            <font color="yellow">●●</font>
          </>
        );
      default:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };

  const selectType = useCallback(
    (event) => {
      let tmpLeft;
      let tmpTop;

      // prevStateを用いて、今の位置を取得する
      setBalls((prevState) => {
        tmpLeft = prevState[count].left;
        tmpTop = prevState[count].top;
      });

      // 特定のカウントについてtypeだけ更新する
      setBalls(
        update(balls, {
          [count]: {
            $merge: { left: tmpLeft, top: tmpTop, type: event.target.value },
          },
        })
      );
    },
    [balls, setBalls, count]
  );

  const selectResult = useCallback(
    (event) => {
      let tmpLeft;
      let tmpTop;
      let tmpType;
      let tmpSpeed;

      setBalls((prevState) => {
        tmpLeft = prevState[count].left;
        tmpTop = prevState[count].top;
        tmpType = prevState[count].type;
        tmpSpeed = prevState[count].speed;
      });

      setBalls(
        update(balls, {
          [count]: {
            $merge: {
              left: tmpLeft,
              top: tmpTop,
              type: tmpType,
              speed: tmpSpeed,
              balls: ballCount,
              strikes: strikeCount,
              result: event.target.value,
            },
          },
        })
      );
    },
    [balls, setBalls, count]
  );

  const inputSpeed = useCallback(
    (event) => {
      // 球速は1km以上
      if (event.target.value < 1) {
        event.target.value = 1;
      }
      setBalls(
        update(balls, {
          [count]: {
            $merge: {
              speed: event.target.value,
            },
          },
        })
      );
    },
    [balls, setBalls, count]
  );

  const countUp = () => {
    setCount((prevState) => prevState + 1);
  };

  const countDown = () => {
    setCount((prevState) => prevState - 1);
  };

  const countStrikeBall = () => {
    setStrikeCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    setBallCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    for (let i = 1; i <= count; i++) {
      let { result } = balls[i];
      if (result == "strike") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "foul") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "swing") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "ball") {
        setBallCount((prevState) => {
          if (prevState < 3) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else {
      }
    }
  };

  const countBackStrikeBall = () => {
    setStrikeCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    setBallCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    for (let i = 1; i < count - 1; i++) {
      let { result } = balls[i];
      if (result == "strike") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "foul") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "swing") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "ball") {
        setBallCount((prevState) => {
          if (prevState < 3) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else {
      }
    }
  };

  // 結果は戻るタイミングで空にすることによって、あらためて選択させ、更新することによって、
  // カウントなどの整合性をとるようにする
  const clearSequence = () => {
    let tmpLeft;
    let tmpTop;
    let tmpType;
    let tmpSpeed;

    setBalls((prevState) => {
      tmpLeft = prevState[count].left;
      tmpTop = prevState[count].top;
      tmpType = prevState[count].type;
      tmpSpeed = prevState[count].speed;
    });

    setBalls(
      update(balls, {
        [count]: {
          $merge: {
            left: tmpLeft,
            top: tmpTop,
            type: tmpType,
            speed: tmpSpeed,
            balls: ballCount,
            strikes: strikeCount,
            result: "",
          },
        },
      })
    );
  };

  const incrementPitchesNumber = (incrementFlag) => {
    // 投球数カウントリリース前の登録済みのデータはカウント対象外
    if (pitchInfo == null) {
      return;
    }

    let newPitchInfoObject;
    if (typeof pitchInfo === "string") {
      newPitchInfoObject = JSON.parse(JSON.stringify(JSON.parse(pitchInfo)));
    } else {
      newPitchInfoObject = JSON.parse(JSON.stringify(pitchInfo));
    }

    // 更新が不要な場合は今のオブジェクトを返すだけ
    if (!incrementFlag) {
      setPitchInfo(newPitchInfoObject);
      return newPitchInfoObject;
    }

    if (topOrBottom === "bottom") {
      const currentPitchInfo =
        newPitchInfoObject.firstAttackPitchInfo[
        newPitchInfoObject.firstAttackPitchInfo.length - 1
        ];
      if (currentPitchInfo.name === pitcher) {
        currentPitchInfo.pitchesNumber++;
        setPitchesNumber(currentPitchInfo.pitchesNumber);
      } else {
        // 高校野球のように投手をやってから野手についてまた投手に戻ったタイミングはないかチェックする
        // [...newPitchInfoObject.firstAttackPitchInfo]
        //   .reverse()
        //   .forEach((pitch) => {
        //     if (pitch.name === pitcher) {
        //       newPitchInfoObject.firstAttackPitchInfo.push(pitch);
        //       return;
        //     }
        //   });

        // 名前がなく登板してなければ情報を追加
        newPitchInfoObject.firstAttackPitchInfo.push({
          name: pitcher,
          pitchesNumber: 1,
        });
        setPitchesNumber(1);
      }
    }

    if (topOrBottom === "top") {
      const currentPitchInfo =
        newPitchInfoObject.secondAttackPitchInfo[
        newPitchInfoObject.secondAttackPitchInfo.length - 1
        ];
      if (currentPitchInfo.name === pitcher) {
        currentPitchInfo.pitchesNumber++;
        setPitchesNumber(currentPitchInfo.pitchesNumber);
      } else {
        // 高校野球のように投手をやってから野手についてまた投手に戻ったタイミングはないかチェックする
        // [...newPitchInfoObject.secondAttackPitchInfo]
        //   .reverse()
        //   .forEach((pitch) => {
        //     if (pitch.name === pitcher) {
        //       newPitchInfoObject.secondAttackPitchInfo.push(pitch);
        //       return;
        //     }
        //   });

        // 名前がなく登板してなければ情報を追加
        newPitchInfoObject.secondAttackPitchInfo.push({
          name: pitcher,
          pitchesNumber: 1,
        });
        setPitchesNumber(1);
      }
    }

    setPitchInfo(newPitchInfoObject);
    return newPitchInfoObject;
  };

  const decrementPitchesNumber = () => {
    // 投球数カウントリリース前の登録済みのデータはカウント対象外
    if (pitchInfo == null) {
      return;
    }

    let newPitchInfoObject;
    if (typeof pitchInfo === "string") {
      newPitchInfoObject = JSON.parse(JSON.stringify(JSON.parse(pitchInfo)));
    } else {
      newPitchInfoObject = JSON.parse(JSON.stringify(pitchInfo));
    }

    if (topOrBottom === "bottom") {
      const currentPitchInfo =
        newPitchInfoObject.firstAttackPitchInfo[
        newPitchInfoObject.firstAttackPitchInfo.length - 1
        ];
      currentPitchInfo.pitchesNumber--;
      setPitchesNumber(currentPitchInfo.pitchesNumber);
    }

    if (topOrBottom === "top") {
      const currentPitchInfo =
        newPitchInfoObject.secondAttackPitchInfo[
        newPitchInfoObject.secondAttackPitchInfo.length - 1
        ];
      currentPitchInfo.pitchesNumber--;
      setPitchesNumber(currentPitchInfo.pitchesNumber);
    }

    setPitchInfo(newPitchInfoObject);
  };

  const nextBall = () => {
    countUp();
    countStrikeBall();
    incrementPitchesNumber(true);
  };

  const preBall = () => {
    clearSequence();
    countDown();
    countBackStrikeBall();
    decrementPitchesNumber();
  };

  // アクセスしてきたユーザーがownerが一致しているか確認します
  // ownerが一致していれば、game情報を取得して各項目を初期化します。
  const [isError, setIsError] = useState(false);
  const [isNotAuth, setIsNotAuth] = useState(false);
  const [isRegisterError, setIsRegisterError] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const navigate = useNavigate();

  const [firstattackScores, setFirstattackScores] = useState(Array(18).fill(0));
  const [secondattackScores, setSecondattackScores] = useState(
    Array(18).fill(0)
  );

  const dispScoreFirstattack = (inning) => {
    return firstattackScores[inning];
  };

  const dispScoreSecondattack = (inning) => {
    return secondattackScores[inning];
  };

  const addScoreToFirstattack = (inning) => {
    setFirstattackScores((prevScores) =>
      prevScores.map((score, i) => (i === inning ? score + 1 : score))
    );
  };

  const addScoreToSecondattack = (inning) => {
    setSecondattackScores((prevScores) =>
      prevScores.map((score, i) => (i === inning ? score + 1 : score))
    );
  };

  const subtractScoreToFirstattack = (inning) => {
    setFirstattackScores((prevScores) =>
      prevScores.map((score, i) =>
        i === inning ? (score > 0 ? score - 1 : score) : score
      )
    );
  };

  const subtractScoreToSecondattack = (inning) => {
    setSecondattackScores((prevScores) =>
      prevScores.map((score, i) =>
        i === inning ? (score > 0 ? score - 1 : score) : score
      )
    );
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const sigCanvas = useRef({});
  const [hittingDirectionImage, setHittingDirectionImage] = useState();
  // const saveSigCanvas = () => {
  //   const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
  //   console.log(dataUrl)
  // }
  const clearSigCanvas = () => {
    setHittingDirectionImage(sigCanvas.current.clear());
  };

  useEffect(async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      if (!user) {
        setIsError(true);
        return;
      }
      if (user.attributes.sub !== owner) {
        setIsError(false);
        navigate("/");
      }
    } catch (error) {
      if (error === "The user is not authenticated") {
        setIsNotAuth(true);
        navigate("/");
        return;
      }
      setIsError(true);
      return;
    }
    await fetchGame();
  }, []);

  // 牽制死などで3アウトになった場合、次の打者か次のイニングを表示するモーダルを起動します
  useEffect(async () => {
    if (outCounts !== 3) {
      return;
    }
    handleOpenModal();
  }, [outCounts]);

  // 先攻のスコアの合計を集計します
  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < firstattackScores.length; i++) {
      sum += firstattackScores[i];
    }
    setFirstAttackTeamScore(sum);
  }, [firstattackScores]);

  // 後攻のスコアの合計を集計します
  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < secondattackScores.length; i++) {
      sum += secondattackScores[i];
    }
    setSecondAttackTeamScore(sum);
  }, [secondattackScores]);

  const register = async (registerBatterFinishFlag) => {
    if (disclosure === "public") {
    } else {
      try {
        // ランナーの暴走などが原因でアウトが増える事象や申告敬遠の場合は投球数は増やさない
        let incrementPitchesFlag = true;
        if (registerBatterFinishFlag) {
          balls[count].result === "intentionalWalk" ? incrementPitchesFlag = false : incrementPitchesFlag = true
        } else {
          incrementPitchesFlag = false
        }
        const newPitchInfo = incrementPitchesNumber(incrementPitchesFlag);
        // 打者情報、投手情報について入力されている内容を配列の要素として追加
        let firstAttackMemberTemp = JSON.parse(
          JSON.stringify(firstAttackMember)
        );
        let secondAttackMemberTemp = JSON.parse(
          JSON.stringify(secondAttackMember)
        );
        if (topOrBottom === "top") {
          // 重複、変更がなければ追加しない
          const latestFirstMemInfo =
            firstAttackMemberTemp[`${registeredBatterOrder}番打者`][
            firstAttackMemberTemp[`${registeredBatterOrder}番打者`].length - 1
            ];
          const orderedFirstMemInfo = Object.keys(latestFirstMemInfo)
            .sort()
            .reduce(
              (acc, key) => {
                acc[key] = latestFirstMemInfo[key];
                return acc;
              },
              { bat: "", name: "", position: "" }
            );

          const firstMemInfo = JSON.stringify(orderedFirstMemInfo);
          const existsBatter =
            firstMemInfo ===
            JSON.stringify({
              bat: bat,
              name: batter,
              position: position,
            });
          if (!existsBatter) {
            firstAttackMemberTemp[`${registeredBatterOrder}番打者`].push({
              bat: bat,
              name: batter,
              position: position,
            });
          }

          const latestSecondPitInfo =
            secondAttackMemberTemp["投手"][
            secondAttackMemberTemp["投手"].length - 1
            ];
          const orderedSecondPitInfo = Object.keys(latestSecondPitInfo)
            .sort()
            .reduce(
              (acc, key) => {
                acc[key] = latestSecondPitInfo[key];
                return acc;
              },
              { name: "", position: 1, throw: "" }
            );

          const secondPitInfo = JSON.stringify(orderedSecondPitInfo);
          const existsPitcher =
            secondPitInfo ===
            JSON.stringify({
              name: pitcher,
              position: 1,
              throw: pitThrow,
            });

          if (!existsPitcher) {
            secondAttackMemberTemp["投手"].push({
              name: pitcher,
              position: 1,
              throw: pitThrow,
            });
          }

          if (!existsBatter || !existsPitcher) {
            //DynamoDb更新
            const inputUpdateGame = {
              id: gameId,
              firstAttackMember: JSON.stringify(firstAttackMemberTemp),
              secondAttackMember: JSON.stringify(secondAttackMemberTemp),
            };

            await API.graphql({
              query: mutations.updatePrivateGameForGameFormat,
              variables: { input: inputUpdateGame },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
          }
        } else {
          const latestSecondMemInfo =
            secondAttackMemberTemp[`${registeredBatterOrder}番打者`][
            secondAttackMemberTemp[`${registeredBatterOrder}番打者`].length -
            1
            ];
          const orderedSecondMemInfo = Object.keys(latestSecondMemInfo)
            .sort()
            .reduce(
              (acc, key) => {
                acc[key] = latestSecondMemInfo[key];
                return acc;
              },
              { bat: "", name: "", position: "" }
            );
          const secondMemInfo = JSON.stringify(orderedSecondMemInfo);
          const existsBatter =
            secondMemInfo ===
            JSON.stringify({
              bat: bat,
              name: batter,
              position: position,
            });
          if (!existsBatter) {
            secondAttackMemberTemp[`${registeredBatterOrder}番打者`].push({
              bat: bat,
              name: batter,
              position: position,
            });
          }

          const latestFirstPitInfo =
            firstAttackMemberTemp["投手"][
            firstAttackMemberTemp["投手"].length - 1
            ];

          const orderedFirstPitInfo = Object.keys(latestFirstPitInfo)
            .sort()
            .reduce(
              (acc, key) => {
                acc[key] = latestFirstPitInfo[key];
                return acc;
              },
              { name: "", position: 1, throw: "" }
            );

          const firstPitInfo = JSON.stringify(orderedFirstPitInfo);
          const existsPitcher =
            firstPitInfo ===
            JSON.stringify({
              name: pitcher,
              position: 1,
              throw: pitThrow,
            });

          if (!existsPitcher) {
            firstAttackMemberTemp["投手"].push({
              name: pitcher,
              position: 1,
              throw: pitThrow,
            });
          }

          if (!existsBatter || !existsPitcher) {
            //DynamoDb更新
            const inputUpdateGame = {
              id: gameId,
              firstAttackMember: JSON.stringify(firstAttackMemberTemp),
              secondAttackMember: JSON.stringify(secondAttackMemberTemp),
            };

            await API.graphql({
              query: mutations.updatePrivateGameForGameFormat,
              variables: { input: inputUpdateGame },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
          }
        }

        const inputsSituation = {
          owner: owner,
          gameId: gameId,
          batter: !batter ? "NODATA" : batter,
          bat: bat,
          atBats: atBats,
          pitcher: !pitcher ? "NODATA" : pitcher,
          throw: pitThrow,
          inning: inning,
          topOrBottom: topOrBottom,
          outCounts: outCounts,
          runner: runner,
          firstAttackTeamScore: firstAttackTeamScore,
          secondAttackTeamScore: secondAttackTeamScore,
          dataAggrFlag: true,
          registeredBatterOrder: registeredBatterOrder,
          registerInningTopOrBottomFinishFlag:
            parseInt(outCounts) + parseInt(adjustOutCounts) >= 3 ? true : false,
          registerBatterFinishFlag: registerBatterFinishFlag,
          title: situationTitle,
          memo: situationMemo,
          firstAttackMember: JSON.stringify(firstAttackMemberTemp),
          secondAttackMember: JSON.stringify(secondAttackMemberTemp),
          detailsForNextBatter: JSON.stringify({
            firstattackScores: firstattackScores,
            secondattackScores: secondattackScores,
            finalyOutCounts: parseInt(outCounts) + parseInt(adjustOutCounts),
            adjustScore: adjustScore,
            preBatterResult: balls[count].result,
          }),
          pitchInfo: JSON.stringify(newPitchInfo),
        };

        const newPrivateSituation = await API.graphql({
          query: mutations.createPrivateSituationForGameFormat,
          variables: { input: inputsSituation },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        setLatestSituadionId(
          newPrivateSituation.data.createPrivateSituationForGameFormat.id
        );

        for (let c = 1; c <= count; c++) {
          // 最後の球の結果はatBatResultのほうにも入れる
          // またメモも入れる
          let inputsSequence;
          if (c == count) {
            if (atBatResults.includes(balls[count].result)) {
              inputsSequence = {
                owner: owner,
                situationId:
                  newPrivateSituation.data.createPrivateSituationForGameFormat
                    .id,
                sequence: c,
                strikes: balls[c].strikes,
                balls: balls[c].balls,
                speed: parseInt(balls[c].speed),
                pitchResult: balls[c].result,
                atBatResult: balls[c].result,
                pitchXPosition: balls[c].left,
                pitchYPosition: balls[c].top,
                breakingBall: balls[c].type,
                memo: sequenceMemo,
                hittingDirectionImage: hittingDirectionImage,
              };
            } else {
              inputsSequence = {
                owner: owner,
                situationId:
                  newPrivateSituation.data.createPrivateSituationForGameFormat
                    .id,
                sequence: c,
                strikes: balls[c].strikes,
                balls: balls[c].balls,
                speed: parseInt(balls[c].speed),
                pitchResult: balls[c].result,
                pitchXPosition: balls[c].left,
                pitchYPosition: balls[c].top,
                breakingBall: balls[c].type,
              };
            }
          } else {
            inputsSequence = {
              owner: owner,
              situationId:
                newPrivateSituation.data.createPrivateSituationForGameFormat.id,
              sequence: c,
              strikes: balls[c].strikes,
              balls: balls[c].balls,
              speed: parseInt(balls[c].speed),
              pitchResult: balls[c].result,
              pitchXPosition: balls[c].left,
              pitchYPosition: balls[c].top,
              breakingBall: balls[c].type,
            };
          }
          await API.graphql({
            query: mutations.createPrivateSequenceForGameFormat,
            variables: { input: inputsSequence },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }
        setIsRegisterError(false);
        setIsRegisterLoading(false);

        // モーダルで入力したアウトカウントを再セットします
      } catch (error) {
        console.error(error);
        setIsRegisterError(true);
        setIsRegisterLoading(false);
      }
    }
  };

  const registerAndCloseModal = async () => {
    setIsRegisterLoading(true);
    await register(true);
    handleCloseModal();
    // if (isRegisterError) {
    await nextBatter();
    // }
    scrollToTop();
  };

  const registerAndSameBatterInNextInning = async () => {
    setIsRegisterLoading(true);
    await register(false);
    handleCloseModal();
    // if (isRegisterError) {
    await nextBatter();
    // }
    scrollToTop();
  };

  const updateRunner = (preBatterRunner, preBatterResult) => {
    if (preBatterResult) {
      switch (preBatterResult) {
        case "walk":
          if (preBatterRunner === 0) {
            setRunner(1);
          } else if (preBatterRunner === 1 || preBatterRunner === 2) {
            setRunner(4);
          } else if (preBatterRunner === 3) {
            setRunner(5);
          } else {
            setRunner(7);
          }
          break;
        case "deadBall":
          if (preBatterRunner === 0) {
            setRunner(1);
          } else if (preBatterRunner === 1 || preBatterRunner === 2) {
            setRunner(4);
          } else if (preBatterRunner === 3) {
            setRunner(5);
          } else {
            setRunner(7);
          }
          break;
        case "intentionalWalk":
          if (preBatterRunner === 0) {
            setRunner(1);
          } else if (preBatterRunner === 1 || preBatterRunner === 2) {
            setRunner(4);
          } else if (preBatterRunner === 3) {
            setRunner(5);
          } else {
            setRunner(7);
          }
          break;
        case "infieldOut":
          break;
        case "flightOut":
          break;
        case "swingingStrikeOut":
          break;
        case "calledStrikeOut":
          break;
        case "hit":
          if (preBatterRunner === 0 || preBatterRunner === 3) {
            setRunner(1);
          } else if (
            preBatterRunner === 1 ||
            preBatterRunner === 2 ||
            preBatterRunner === 4
          ) {
            setRunner(preBatterRunner + 3);
          } else if (preBatterRunner === 5 || preBatterRunner === 6) {
            setRunner(preBatterRunner - 1);
          } else {
            setRunner(7);
          }
          break;
        case "error":
          if (preBatterRunner === 0 || preBatterRunner === 3) {
            setRunner(1);
          } else if (
            preBatterRunner === 1 ||
            preBatterRunner === 2 ||
            preBatterRunner === 4
          ) {
            setRunner(preBatterRunner + 3);
          } else if (preBatterRunner === 5 || preBatterRunner === 6) {
            setRunner(preBatterRunner - 1);
          } else {
            setRunner(7);
          }
          break;
        case "double":
          if (
            preBatterRunner === 0 ||
            preBatterRunner === 2 ||
            preBatterRunner === 3 ||
            preBatterRunner === 6
          ) {
            setRunner(2);
          } else {
            setRunner(6);
          }
          break;
        case "triple":
          setRunner(3);
          break;
        case "homeRun":
          setRunner(0);
          break;
        case "fildersChoice":
          if (preBatterRunner === 0 || preBatterRunner === 3) {
            setRunner(1);
          } else if (
            preBatterRunner === 1 ||
            preBatterRunner === 2 ||
            preBatterRunner === 4
          ) {
            setRunner(preBatterRunner + 3);
          } else if (preBatterRunner === 5 || preBatterRunner === 6) {
            setRunner(preBatterRunner - 1);
          } else {
            setRunner(7);
          }
          break;
        case "swingAway":
          if (preBatterRunner === 0 || preBatterRunner === 3) {
            setRunner(1);
          } else if (
            preBatterRunner === 1 ||
            preBatterRunner === 2 ||
            preBatterRunner === 4
          ) {
            setRunner(preBatterRunner + 3);
          } else if (preBatterRunner === 5 || preBatterRunner === 6) {
            setRunner(preBatterRunner - 1);
          } else {
            setRunner(7);
          }
          break;
        case "blowObstruction":
          if (preBatterRunner === 0) {
            setRunner(1);
          } else if (preBatterRunner === 1 || preBatterRunner === 2) {
            setRunner(4);
          } else if (preBatterRunner === 3) {
            setRunner(5);
          } else {
            setRunner(7);
          }
          break;
        case "doublePlay":
          if (
            preBatterRunner === 0 ||
            preBatterRunner === 1 ||
            preBatterRunner === 2 ||
            preBatterRunner === 3 ||
            preBatterRunner === 5
          ) {
            setRunner(0);
          } else if (preBatterRunner === 4 || preBatterRunner === 6) {
            setRunner(3);
          } else if (preBatterRunner === 7) {
            setRunner(6);
          } else {
            setRunner(0);
          }
          break;
        case "sacrificeBunt":
          if (preBatterRunner === 0 || preBatterRunner === 3) {
            setRunner(0);
          } else if (preBatterRunner === 1) {
            setRunner(2);
          } else if (preBatterRunner === 2) {
            setRunner(3);
          } else if (preBatterRunner === 4) {
            setRunner(6);
          } else if (preBatterRunner === 5) {
            setRunner(2);
          } else if (preBatterRunner === 6) {
            setRunner(3);
          } else if (preBatterRunner === 7) {
            setRunner(6);
          } else {
            setRunner(0);
          }
        case "sacrificeFly":
          if (preBatterRunner === 0 || preBatterRunner === 3) {
            setRunner(0);
          } else if (preBatterRunner === 1) {
            setRunner(2);
          } else if (preBatterRunner === 2) {
            setRunner(3);
          } else if (preBatterRunner === 4) {
            setRunner(6);
          } else if (preBatterRunner === 5) {
            setRunner(2);
          } else if (preBatterRunner === 6) {
            setRunner(3);
          } else if (preBatterRunner === 7) {
            setRunner(6);
          } else {
            setRunner(0);
          }
        default:
          break;
      }
    } else {
      setRunner(0);
    }
  };

  const GetPrivateGameAndSituationByOwnerForGameFormat = `
  query GetPrivateGameByOwnerForGameFormat(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelprivateGameForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateGameByOwnerForGameFormat(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstAttackTeam
        secondAttackTeam
        date
        privateSituations {
          items {
            atBats
            bat
            batter
            createdAt
            dataAggrFlag
            firstAttackTeamScore
            gameId
            id
            inning
            memo
            outCounts
            topOrBottom
            owner
            pitcher
            registerInningTopOrBottomFinishFlag
            registerBatterFinishFlag
            registeredBatterOrder
            runner
            secondAttackTeamScore
            detailsForNextBatter
            pitchInfo
          }
          nextToken
        }
        createdAt
        updatedAt
        type
        firstAttackMember
        secondAttackMember
        gameTitle
        registerFinish
      }
      nextToken
    }
  }
`;

  const settingBatterPitcherAndTitleInPrivate = (
    firstAttackMemberTemp,
    secondAttackMemberTemp,
    privateSituationsItems,
    maxRegisterNumber,
    registeredBatterOrderTmp,
    gameTitleAndDate,
    inning
  ) => {
    if (
      privateSituationsItems[maxRegisterNumber - 1]
        .registerInningTopOrBottomFinishFlag
    ) {
      if (privateSituationsItems[maxRegisterNumber - 1].topOrBottom === "top") {
        // このケースは裏として登録する
        // 裏の場合
        setBottomCase(
          firstAttackMemberTemp,
          secondAttackMemberTemp,
          privateSituationsItems,
          maxRegisterNumber,
          registeredBatterOrderTmp,
          gameTitleAndDate,
          inning
        );
      } else {
        setTopCase(
          firstAttackMemberTemp,
          secondAttackMemberTemp,
          privateSituationsItems,
          maxRegisterNumber,
          registeredBatterOrderTmp,
          gameTitleAndDate,
          inning
        );
      }
    } else {
      if (privateSituationsItems[maxRegisterNumber - 1].topOrBottom === "top") {
        setTopCase(
          firstAttackMemberTemp,
          secondAttackMemberTemp,
          privateSituationsItems,
          maxRegisterNumber,
          registeredBatterOrderTmp,
          gameTitleAndDate,
          inning
        );
      } else {
        // 裏の場合
        setBottomCase(
          firstAttackMemberTemp,
          secondAttackMemberTemp,
          privateSituationsItems,
          maxRegisterNumber,
          registeredBatterOrderTmp,
          gameTitleAndDate,
          inning
        );
      }
    }
  };

  const findSecondLargestIndex = (arr) => {
    // 一意のインデックスの配列を作成
    let uniqueIndexes = [...new Set(arr)];

    // 降順にソート
    uniqueIndexes.sort((a, b) => b - a);

    // 2番目の要素を返すか、存在しない場合はnullを返す
    return uniqueIndexes[1] !== undefined ? uniqueIndexes[1] : null;
  };

  const setTopCase = (
    firstAttackMemberTemp,
    secondAttackMemberTemp,
    privateSituationsItems,
    maxRegisterNumber,
    registeredBatterOrderTmp,
    gameTitleAndDate,
    inning
  ) => {
    const maxRegisterBatterNumber =
      firstAttackMemberTemp[`${registeredBatterOrderTmp}番打者`].length;
    setBatter(
      firstAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].name
    );
    setBat(
      firstAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].bat
    );
    setPosition(
      firstAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].position
    );

    const maxRegisterPitcherNumber = secondAttackMemberTemp[`投手`].length;
    setPitcher(
      secondAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].name
    );
    setPitThrow(
      secondAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].throw
    );

    setSituationTitle(
      `${gameTitleAndDate.date} ${gameTitleAndDate.title} ${inning}回表 ${!firstAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].name
        ? "NODATA"
        : firstAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
          maxRegisterBatterNumber - 1
        ].name
      }vs${!secondAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].name
        ? "NODATA"
        : secondAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].name
      }`
    );
    // }
  };

  const setBottomCase = (
    firstAttackMemberTemp,
    secondAttackMemberTemp,
    privateSituationsItems,
    maxRegisterNumber,
    registeredBatterOrderTmp,
    gameTitleAndDate,
    inning
  ) => {
    const maxRegisterBatterNumber =
      secondAttackMemberTemp[`${registeredBatterOrderTmp}番打者`].length;
    setBatter(
      secondAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].name
    );
    setBat(
      secondAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].bat
    );
    setPosition(
      secondAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].position
    );

    const maxRegisterPitcherNumber = firstAttackMemberTemp[`投手`].length;
    setPitcher(
      firstAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].name
    );
    setPitThrow(
      firstAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].throw
    );

    setSituationTitle(
      `${gameTitleAndDate.date} ${gameTitleAndDate.title} ${inning}回裏 ${!secondAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
        maxRegisterBatterNumber - 1
      ].name
        ? "NODATA"
        : secondAttackMemberTemp[`${registeredBatterOrderTmp}番打者`][
          maxRegisterBatterNumber - 1
        ].name
      }vs${!firstAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].name
        ? "NODATA"
        : firstAttackMemberTemp["投手"][maxRegisterPitcherNumber - 1].name
      }`
    );
  };

  const updateScores = (topOrBottom, detailsForNextBatterTmp) => {
    if (topOrBottom === "top") {
      setFirstattackScores(() =>
        detailsForNextBatterTmp.firstattackScores.map((score, i) =>
          i === inning - 1
            ? score + parseInt(detailsForNextBatterTmp.adjustScore)
            : score
        )
      );
      setSecondattackScores(detailsForNextBatterTmp.secondattackScores);
    } else {
      setFirstattackScores(detailsForNextBatterTmp.firstattackScores);
      setSecondattackScores(() =>
        detailsForNextBatterTmp.secondattackScores.map((score, i) =>
          i === inning - 1
            ? score + parseInt(detailsForNextBatterTmp.adjustScore)
            : score
        )
      );
    }
  };

  const fetchGame = async () => {
    let game;
    let gameTitleAndDate;
    if (disclosure === "private") {
      try {
        game = await API.graphql({
          query: GetPrivateGameAndSituationByOwnerForGameFormat,
          variables: { owner: owner, filter: { id: { eq: gameId } } },
        });
      } catch (error) {
        console.log(error);
        setIsError(true);
        return;
      }
      const firstAttackMemberTemp = JSON.parse(
        game.data.getPrivateGameByOwnerForGameFormat.items[0].firstAttackMember
      );
      const secondAttackMemberTemp = JSON.parse(
        game.data.getPrivateGameByOwnerForGameFormat.items[0].secondAttackMember
      );
      // register関数内で利用するため
      setFirstAttackMember(
        JSON.parse(
          game.data.getPrivateGameByOwnerForGameFormat.items[0]
            .firstAttackMember
        )
      );
      setSecondAttackMember(
        JSON.parse(
          game.data.getPrivateGameByOwnerForGameFormat.items[0]
            .secondAttackMember
        )
      );

      const firstAttackMembers = [];
      for (let i = 0; i < 9; i++) {
        firstAttackMembers.push({
          name: firstAttackMemberTemp[`${i + 1}番打者`][
            firstAttackMemberTemp[`${i + 1}番打者`].length - 1
          ].name
            ? firstAttackMemberTemp[`${i + 1}番打者`][
              firstAttackMemberTemp[`${i + 1}番打者`].length - 1
            ].name
            : "NODATA",
          position:
            firstAttackMemberTemp[`${i + 1}番打者`][
              firstAttackMemberTemp[`${i + 1}番打者`].length - 1
            ].position,
        });
      }
      setMemberBoardFirstAttackMember(firstAttackMembers);
      setMemberBoardFirstAttackMemberPitch(
        firstAttackMemberTemp["投手"][firstAttackMemberTemp["投手"].length - 1]
          .name
          ? firstAttackMemberTemp["投手"][
            firstAttackMemberTemp["投手"].length - 1
          ].name
          : "NODATA"
      );

      const secondAttackMembers = [];
      for (let i = 0; i < 9; i++) {
        secondAttackMembers.push({
          name: secondAttackMemberTemp[`${i + 1}番打者`][
            secondAttackMemberTemp[`${i + 1}番打者`].length - 1
          ].name
            ? secondAttackMemberTemp[`${i + 1}番打者`][
              secondAttackMemberTemp[`${i + 1}番打者`].length - 1
            ].name
            : "NODATA",
          position:
            secondAttackMemberTemp[`${i + 1}番打者`][
              secondAttackMemberTemp[`${i + 1}番打者`].length - 1
            ].position,
        });
      }
      setMemberBoardSecondAttackMember(secondAttackMembers);
      setMemberBoardSecondAttackMemberPitch(
        secondAttackMemberTemp["投手"][
          secondAttackMemberTemp["投手"].length - 1
        ].name
          ? secondAttackMemberTemp["投手"][
            secondAttackMemberTemp["投手"].length - 1
          ].name
          : "NODATA"
      );

      setGameTitle(
        game.data.getPrivateGameByOwnerForGameFormat.items[0].gameTitle
      );

      setFirstAttackTeam(
        game.data.getPrivateGameByOwnerForGameFormat.items[0].firstAttackTeam
      );
      setSecondAttackTeam(
        game.data.getPrivateGameByOwnerForGameFormat.items[0].secondAttackTeam
      );

      gameTitleAndDate = {
        date: game.data.getPrivateGameByOwnerForGameFormat.items[0].date,
        title: game.data.getPrivateGameByOwnerForGameFormat.items[0].gameTitle,
      };

      // 記録されていれば、試合の途中から記録を再開する
      // なにもまだ登録されていなかったら、1回のノーアウトとして記録し始める
      if (
        game.data.getPrivateGameByOwnerForGameFormat.items[0].privateSituations
          .items.length !== 0
      ) {
        // 登録されていた場合の途中からの入力用のコード
        // そもそもregisterFinishがtrueになっていなか確認する。なっていたら、登録画面は表示しない
        if (
          game.data.getPrivateGameByOwnerForGameFormat.items[0].registerFinish
        ) {
          //この試合は登録が完了しています
          navigate("/");
        }

        // 取得できたsituationの中で、最新の登録を確認する
        const privateSituationsItemsTmp =
          game.data.getPrivateGameByOwnerForGameFormat.items[0]
            .privateSituations.items;
        const privateSituationsItems = [...privateSituationsItemsTmp].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        const maxRegisterNumber = privateSituationsItems.length;

        setLatestSituadionId(privateSituationsItems[maxRegisterNumber - 1].id);

        if (
          privateSituationsItems[maxRegisterNumber - 1]
            .registerInningTopOrBottomFinishFlag
        ) {
          // また、registerInningTopOrBottomFinishFlagがtrueであれば、
          // そのtrueのさらにひとつ前のtrueの登録情報(前の回の攻撃)を確認し、そのtopOrBottomは変更せず、
          // そのinningをインクリメントする

          // 回が終了していることをしめすfinishフラグがいくつあるかカウントする
          let registerInningTopOrBottomFinishObj = { count: 0, indexes: [] };
          privateSituationsItems.map((item, index) => {
            if (item.registerInningTopOrBottomFinishFlag === true) {
              registerInningTopOrBottomFinishObj.count++;
              registerInningTopOrBottomFinishObj.indexes.push(index);
            }
          });
          // 回が終了していることをしめすfinishフラグのカウントが2以上であれば、2回より先にすすんでおり
          // 前のイニングを考慮できる。1以下の場合は、1回裏から開始と確定して打者の開始は1番からで確定なので別扱い
          if (registerInningTopOrBottomFinishObj.indexes.length > 1) {
            // 前の攻撃の情報のindex番号
            const preInningIndex = findSecondLargestIndex(
              registerInningTopOrBottomFinishObj.indexes
            );
            // 前の攻撃から次の回へ
            setInning(privateSituationsItems[preInningIndex].inning + 1);
            setTopOrBottom(privateSituationsItems[preInningIndex].topOrBottom);
            setOutCounts(0);
            setRunner(0);

            // 投球数の更新
            if (privateSituationsItems[maxRegisterNumber - 1].pitchInfo) {
              const latestPitchInfo = JSON.parse(
                privateSituationsItems[maxRegisterNumber - 1].pitchInfo
              );
              setPitchInfo(latestPitchInfo);

              if (
                privateSituationsItems[maxRegisterNumber - 1].topOrBottom ===
                "top"
              ) {
                const maxLength = latestPitchInfo.firstAttackPitchInfo.length;
                setPitchesNumber(
                  latestPitchInfo.firstAttackPitchInfo[maxLength - 1]
                    .pitchesNumber
                );
              } else {
                const maxLength = latestPitchInfo.secondAttackPitchInfo.length;
                setPitchesNumber(
                  latestPitchInfo.secondAttackPitchInfo[maxLength - 1]
                    .pitchesNumber
                );
              }
            } else {
              //投球数カウントリリース前の登録済みのデータは対象外
              setPitchInfo(null);
              setPitchesNumber(null);
            }

            // 前の攻撃から次の打者へインクリメントすべきか前の攻撃の打者のままか判定する
            if (
              privateSituationsItems[preInningIndex].registerBatterFinishFlag
            ) {
              let nextBatterOrder;
              if (
                privateSituationsItems[preInningIndex].registeredBatterOrder ===
                9
              ) {
                nextBatterOrder = 1;
              } else {
                nextBatterOrder =
                  parseInt(
                    privateSituationsItems[preInningIndex].registeredBatterOrder
                  ) + 1;
              }
              setRegisteredBatterOrder(nextBatterOrder);
              settingBatterPitcherAndTitleInPrivate(
                firstAttackMemberTemp,
                secondAttackMemberTemp,
                privateSituationsItems,
                maxRegisterNumber,
                nextBatterOrder,
                gameTitleAndDate,
                privateSituationsItems[preInningIndex].inning + 1
              );
            } else {
              setRegisteredBatterOrder(
                parseInt(
                  privateSituationsItems[preInningIndex].registeredBatterOrder
                )
              );
              settingBatterPitcherAndTitleInPrivate(
                firstAttackMemberTemp,
                secondAttackMemberTemp,
                privateSituationsItems,
                maxRegisterNumber,
                parseInt(
                  privateSituationsItems[preInningIndex].registeredBatterOrder
                ),
                gameTitleAndDate,
                privateSituationsItems[preInningIndex].inning + 1
              );
            }

            // 得点の更新
            let detailsForNextBatterTmp = JSON.parse(
              privateSituationsItems[maxRegisterNumber - 1].detailsForNextBatter
            );
            updateScores(topOrBottom, detailsForNextBatterTmp);
          } else {
            // この場合は、情報が1回の表しかないので、1回裏の情報に変更する
            setRegisteredBatterOrder(1);
            setInning(1);
            setTopOrBottom("bottom");

            setOutCounts(0);
            setRunner(0);

            settingBatterPitcherAndTitleInPrivate(
              firstAttackMemberTemp,
              secondAttackMemberTemp,
              privateSituationsItems,
              maxRegisterNumber,
              1,
              gameTitleAndDate,
              1
            );

            // 得点の更新
            let detailsForNextBatterTmp = JSON.parse(
              privateSituationsItems[maxRegisterNumber - 1].detailsForNextBatter
            );
            updateScores(topOrBottom, detailsForNextBatterTmp);

            // 投球数の更新
            if (privateSituationsItems[maxRegisterNumber - 1].pitchInfo) {
              setPitchInfo(
                JSON.parse(
                  privateSituationsItems[maxRegisterNumber - 1].pitchInfo
                )
              );
              setPitchesNumber(0);
            } else {
              //投球数カウントリリース前の登録済みのデータは対象外
              setPitchInfo(null);
              setPitchesNumber(null);
            }
          }
        } else {
          // 攻撃継続中
          let nextBatterOrder;
          if (
            privateSituationsItems[maxRegisterNumber - 1]
              .registeredBatterOrder === 9
          ) {
            nextBatterOrder = 1;
          } else {
            nextBatterOrder =
              parseInt(
                privateSituationsItems[maxRegisterNumber - 1]
                  .registeredBatterOrder
              ) + 1;
          }
          setRegisteredBatterOrder(nextBatterOrder);
          setInning(privateSituationsItems[maxRegisterNumber - 1].inning);
          setTopOrBottom(
            privateSituationsItems[maxRegisterNumber - 1].topOrBottom
          );

          settingBatterPitcherAndTitleInPrivate(
            firstAttackMemberTemp,
            secondAttackMemberTemp,
            privateSituationsItems,
            maxRegisterNumber,
            nextBatterOrder,
            gameTitleAndDate,
            privateSituationsItems[maxRegisterNumber - 1].inning
          );

          // 攻撃中なので次のバッター向けにアウトカウント、得点などの更新を行います
          let detailsForNextBatterTmp = JSON.parse(
            privateSituationsItems[maxRegisterNumber - 1].detailsForNextBatter
          );

          // アウトカウントの更新
          setOutCounts(detailsForNextBatterTmp.finalyOutCounts);

          // 得点の更新
          updateScores(topOrBottom, detailsForNextBatterTmp);

          // ランナーの更新
          updateRunner(
            privateSituationsItems[maxRegisterNumber - 1].runner,
            detailsForNextBatterTmp.preBatterResult
          );

          // 投球数の更新
          if (privateSituationsItems[maxRegisterNumber - 1].pitchInfo) {
            setPitchInfo(
              privateSituationsItems[maxRegisterNumber - 1].pitchInfo
            );

            const latestPitchInfo = JSON.parse(
              privateSituationsItems[maxRegisterNumber - 1].pitchInfo
            );
            if (
              privateSituationsItems[maxRegisterNumber - 1].topOrBottom ===
              "top"
            ) {
              const maxLength = latestPitchInfo.secondAttackPitchInfo.length;
              setPitchesNumber(
                latestPitchInfo.secondAttackPitchInfo[maxLength - 1]
                  .pitchesNumber
              );
            } else {
              const maxLength = latestPitchInfo.firstAttackPitchInfo.length;
              setPitchesNumber(
                latestPitchInfo.firstAttackPitchInfo[maxLength - 1]
                  .pitchesNumber
              );
            }
          } else {
            // 投球カウントリリース前の登録済みデータ対象外
            setPitchInfo(null);
            setPitchesNumber(null);
          }
        }
      } else {
        // なにもまだ登録されていないので、1回のノーアウトとして記録し始める
        // スターティングメンバーのみ登録して離脱した場合、あるいはスターティングメンバー登録してこのページきた場合
        setBatter(firstAttackMemberTemp["1番打者"][0].name);
        setBat(firstAttackMemberTemp["1番打者"][0].bat);
        setPosition(Number(firstAttackMemberTemp["1番打者"][0].position));

        setPitcher(secondAttackMemberTemp["投手"][0].name);
        setPitThrow(secondAttackMemberTemp["投手"][0].throw);

        setSituationTitle(
          `${gameTitleAndDate.date} ${gameTitleAndDate.title} ${inning}回${topOrBottom === "top" ? "表" : "裏"
          } ${!firstAttackMemberTemp["1番打者"][0].name
            ? "NODATA"
            : firstAttackMemberTemp["1番打者"][0].name
          }vs${!secondAttackMemberTemp["投手"][0].name
            ? "NODATA"
            : secondAttackMemberTemp["投手"][0].name
          }  `
        );
      }
    } else {
      // (限定)公開のほうのコード
    }
  };

  const nextBatter = async () => {
    // // アウトカウントの更新
    // setOutCounts(parseInt(outCounts) + parseInt(adjustOutCounts));
    // // 得点の更新
    // setFirstattackScores((prevScores) =>
    //   prevScores.map((score, i) =>
    //     i === inning - 1 ? score + parseInt(adjustScore) : score
    //   )
    // );

    // // ランナーの更新
    // updateRunner();

    // 再初期化
    setBalls(JSON.parse(JSON.stringify(defaultBalls)));
    setCount(1);
    setStrikeCount(0);
    setBallCount(0);
    setAdjustScore(0);
    setAdjustOutCounts(1);
    setHittingDirectionImage(sigCanvas.current.clear());
    setSituationMemo("");
    setSequenceMemo("");

    // 2順目の場合、オーダーが更新された場合を考慮してgameを再フェッチして対戦情報を更新する
    await fetchGame();
  };

  const ConvertExpression = (eng) => {
    let ja = "";
    switch (eng) {
      case "walk":
        ja = "四球";
        break;
      case "deadBall":
        ja = "死球";
        break;
      case "intentionalWalk":
        ja = "申告敬遠";
        break;
      case "infieldOut":
        ja = "ゴロアウト";
        break;
      case "flightOut":
        ja = "フライアウト";
        break;
      case "error":
        ja = "エラー";
        break;
      case "swingingStrikeOut":
        ja = "空振り三振";
        break;
      case "calledStrikeOut":
        ja = "見逃し三振";
        break;
      case "hit":
        ja = "ヒット";
        break;
      case "double":
        ja = "二塁打";
        break;
      case "triple":
        ja = "三塁打";
        break;
      case "homeRun":
        ja = "本塁打";
        break;
      case "fildersChoice":
        ja = "フィルダースチョイス";
        break;
      case "swingAway":
        ja = "振り逃げ";
        break;
      case "blowObstruction":
        ja = "打撃妨害";
        break;
      case "doublePlay":
        ja = "ダブルプレー";
        break;
      case "sacrificeBunt":
        ja = "犠打";
        break;
      case "sacrificeFly":
        ja = "犠牲フライ";
        break;
      default:
        ja = "No DATA";
        break;
    }
    return ja;
  };

  const cancelNextInning = () => {
    if (outCounts === 3) {
      setOutCounts(2);
    }
    handleCloseModal();
  };

  // 結果が入力された場合のデフォルトの増やすアウトカウントを指定します
  const setDefaultAdjustOutCount = () => {
    if (balls[count].result) {
      switch (balls[count].result) {
        case "walk":
          setAdjustOutCounts(0);
          break;
        case "deadBall":
          setAdjustOutCounts(0);
          break;
        case "intentionalWalk":
          setAdjustOutCounts(0);
          break;
        case "error":
          setAdjustOutCounts(0);
          break;
        case "infieldOut":
          setAdjustOutCounts(1);
          break;
        case "flightOut":
          setAdjustOutCounts(1);
          break;
        case "swingingStrikeOut":
          setAdjustOutCounts(1);
          break;
        case "calledStrikeOut":
          setAdjustOutCounts(1);
          break;
        case "hit":
          setAdjustOutCounts(0);
          break;
        case "double":
          setAdjustOutCounts(0);
          break;
        case "triple":
          setAdjustOutCounts(0);
          break;
        case "homeRun":
          setAdjustOutCounts(0);
          break;
        case "fildersChoice":
          setAdjustOutCounts(0);
          break;
        case "swingAway":
          setAdjustOutCounts(0);
          break;
        case "blowObstruction":
          setAdjustOutCounts(0);
          break;
        case "doublePlay":
          setAdjustOutCounts(2);
          break;
      }
    }
  };

  const setAdjustScoreRelatedRunnerAndResult = () => {
    if (balls[count].result) {
      switch (balls[count].result) {
        case "walk":
          if (runner === 7) {
            setAdjustScore(1);
          }
          break;
        case "deadBall":
          if (runner === 7) {
            setAdjustScore(1);
          }
          break;
        case "intentionalWalk":
          if (runner === 7) {
            setAdjustScore(1);
          }
          break;
        case "infieldOut":
          break;
        case "flightOut":
          break;
        case "swingingStrikeOut":
          break;
        case "calledStrikeOut":
          break;
        case "error":
          if (runner === 3 || runner === 5 || runner === 6 || runner === 7) {
            setAdjustScore(1);
          }
          break;
        case "hit":
          if (runner === 3 || runner === 5 || runner === 6 || runner === 7) {
            setAdjustScore(1);
          }
          break;

        case "double":
          if (runner === 2 || runner === 3 || runner === 4 || runner === 5) {
            setAdjustScore(1);
            break;
          }
          if (runner === 6 || runner === 7) {
            setAdjustScore(2);
          }
          break;
        case "triple":
          if (runner === 1 || runner === 2 || runner === 3) {
            setAdjustScore(1);
            break;
          }
          if (runner === 4 || runner === 5 || runner === 6) {
            setAdjustScore(2);
            break;
          }
          if (runner === 7) {
            setAdjustScore(3);
          }
          break;
        case "homeRun":
          if (runner === 0) {
            setAdjustScore(1);
            break;
          }
          if (runner === 1 || runner === 2 || runner === 3) {
            setAdjustScore(2);
            break;
          }
          if (runner === 4 || runner === 5 || runner === 6) {
            setAdjustScore(3);
            break;
          }
          if (runner === 7) {
            setAdjustScore(4);
          }
          break;
        case "fildersChoice":
          if (runner === 3 || runner === 5 || runner === 6 || runner === 7) {
            setAdjustScore(1);
          }
          break;
        case "swingAway":
          break;
        case "blowObstruction":
          if (runner === 7) {
            setAdjustScore(1);
          }
          break;
        case "doublePlay":
          if (outCounts === 0) {
            if (runner === 5) {
              setAdjustScore(1);
            }
          }
          break;
        case "sacrificeBunt":
          if (outCounts < 2) {
            if (runner === 7 || runner === 6 || runner === 5 || runner === 3) {
              setAdjustScore(1);
            }
          }
          break;
        case "sacrificeFly":
          if (outCounts < 2) {
            if (runner === 7 || runner === 6 || runner === 5 || runner === 3) {
              setAdjustScore(1);
            }
          }
          break;
        default:
          break;
      }
    }
  };

  const openModalAndSetEtc = () => {
    handleOpenModal();
    setDefaultAdjustOutCount();
    setAdjustScoreRelatedRunnerAndResult();
  };

  const [openModalFinishGame, setOpenModalFinishGame] = useState(false);
  const handleOpenModalFinishGame = () => setOpenModalFinishGame(true);
  const handleCloseModalFinishGame = () => setOpenModalFinishGame(false);

  const finishGameAndCloseModal = async () => {
    const inputUpdateGame = {
      id: gameId,
      registerFinish: true,
    };

    try {
      const updateGame = await API.graphql({
        query: mutations.updatePrivateGameForGameFormat,
        variables: { input: inputUpdateGame },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      // 集計データを作成し配置するS3バケットがわかるようにowner名のファイルをS3に配置する
      // 集計データを作成するLambdaがこのファイル名をさがして、バケットを特定する
      const blob = new Blob([""], { type: "text/plain" });
      await Storage.put(`${owner}.txt`, blob, {
        level: "private",
        contentType: "text/plain",
      });

      setIsRegisterError(false);
    } catch (error) {
      setIsRegisterError(true);
    }
    handleCloseModalFinishGame();
    navigate("/");
  };

  useEffect(() => {
    if (inning > 18) {
      handleOpenModalFinishGame();
    }
  }, [inning]);

  const convertPositionExpression = (number) => {
    let position;
    switch (number) {
      case 0:
        position = "-";
        break;
      case 1:
        position = "投";
        break;
      case 2:
        position = "捕";
        break;
      case 3:
        position = "一";
        break;
      case 4:
        position = "二";
        break;
      case 5:
        position = "三";
        break;
      case 6:
        position = "遊";
        break;
      case 7:
        position = "左";
        break;
      case 8:
        position = "中";
        break;
      case 9:
        position = "右";
        break;
      case 10:
        position = "DH";
        break;
      default:
        position = "-";
        break;
    }
    return position;
  };

  const [openModalChangeMember, setOpenModalChangeMember] = useState(false);
  const handleOpenModalChangeMember = () => setOpenModalChangeMember(true);
  const handleCloseModalChangeMember = () => setOpenModalChangeMember(false);

  return (
    <>
      <CustomPageLabel label="試合を記録する" />
      {isNotAuth && (
        <Alert
          severity="error"
          onClose={() => {
            setIsNotAuth(false);
          }}
        >
          ログインしてからアクセスしてください。
        </Alert>
      )}
      {isError && (
        <Alert
          severity="error"
          onClose={() => {
            setIsError(false);
          }}
        >
          データ取得できませんでした。ネットワーク接続を確認してください。
        </Alert>
      )}
      {isRegisterError && (
        <Alert
          severity="error"
          onClose={() => {
            setIsRegisterError(false);
          }}
        >
          登録に失敗しました。ネットワーク接続を確認をしてください。
        </Alert>
      )}
      {!isError && !firstAttackMember && (
        <>
          <CircularProgress />
        </>
      )}
      <Accordion
        expanded={expandedTitleMatch}
        onChange={handleChangeTitleMatch()}
      >
        <AccordionSummary sx={{ backgroundColor: "#AFEEEE" }}>
          <Typography>
            <span className="optional">任意</span> 配球タイトル・対戦情報
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <TextInput
                fullWidth={true}
                multiline={false}
                rows={1}
                type={"text"}
                value={situationTitle}
                onChange={inputSituationTitle}
                label="配球タイトル"
              />
            </Stack>
          </Box>
          <Box margin={1}></Box>
          {!isError &&
            memberBoardFirstAttackMember &&
            memberBoardSecondAttackMember && (
              <>
                <Divider>出場メンバー</Divider>
                <Container
                  sx={{ display: { xs: "block", sm: "none", md: "none" } }}
                >
                  <Grid container direction="row" justifyContent="space-around">
                    <Grid item xs={3} sm={6}>
                      {memberBoardFirstAttackMember.map((member, index) => (
                        <Box>
                          {index + 1 === registeredBatterOrder &&
                            member.name === batter ? (
                            // <Box sx={{ fontWeight: "bold", fontSize: 8, textAlign: "center" }}>
                            //   {index + 1} {member.name}
                            // </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                                {index + 1}
                              </Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ))}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ fontSize: 8 }}>投</Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: 8,
                          }}
                        >
                          {memberBoardFirstAttackMemberPitch}
                        </Box>
                        <Box
                          sx={{
                            textAlign: "right",
                            fontSize: 8,
                          }}
                        >
                          -
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3} sm={6}>
                      {memberBoardSecondAttackMember.map((member, index) => (
                        <Box>
                          {index + 1 === registeredBatterOrder &&
                            member.name === batter ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                                {index + 1}
                              </Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ))}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ fontSize: 8 }}>投</Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: 8,
                          }}
                        >
                          {memberBoardSecondAttackMemberPitch}
                        </Box>
                        <Box
                          sx={{
                            textAlign: "right",
                            fontSize: 8,
                          }}
                        >
                          -
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
                <Container
                  sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={15}
                  >
                    <Grid item sm={4} md={3}>
                      {memberBoardFirstAttackMember.map((member, index) => (
                        <Box>
                          {index + 1 === registeredBatterOrder &&
                            member.name === batter ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                                {index + 1}
                              </Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ))}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ fontSize: 8 }}>投</Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: 8,
                          }}
                        >
                          {memberBoardFirstAttackMemberPitch}
                        </Box>
                        <Box
                          sx={{
                            textAlign: "right",
                            fontSize: 8,
                          }}
                        >
                          -
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item sm={4} md={3}>
                      {memberBoardSecondAttackMember.map((member, index) => (
                        <Box>
                          {index + 1 === registeredBatterOrder &&
                            member.name === batter ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontWeight: "bold", fontSize: 8 }}>
                                {index + 1}
                              </Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ fontSize: 8 }}>{index + 1}</Box>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  fontSize: 8,
                                }}
                              >
                                {member.name}
                              </Box>
                              <Box
                                sx={{
                                  textAlign: "right",
                                  fontSize: 8,
                                }}
                              >
                                {convertPositionExpression(member.position)}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ))}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ fontSize: 8 }}>投</Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: 8,
                          }}
                        >
                          {memberBoardSecondAttackMemberPitch}
                        </Box>
                        <Box
                          sx={{
                            textAlign: "right",
                            fontSize: 8,
                          }}
                        >
                          -
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
                <Grid container justifyContent="center">
                  <Button
                    variant="outlined"
                    // startIcon={<ModeCommentIcon />}
                    onClick={handleOpenModalChangeMember}
                    sx={{ margin: 2 }}
                    size="small"
                    disabled={latestSituationId === 0}
                  >
                    {" "}
                    メンバー、守備変更
                  </Button>
                </Grid>
              </>
            )}
          <Divider>打者情報</Divider>
          <Box margin={1}></Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <InputLabel id="select-batter-label">打者名：　</InputLabel>
                <Box>{batter ? batter : "NODATA"}</Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <InputLabel id="select-bat-label">打席：　　</InputLabel>
                <Select
                  // id="select-bat"
                  value={bat}
                  onChange={changeBat}
                  size="small"
                >
                  <MenuItem value="right">右</MenuItem>
                  <MenuItem value="left">左</MenuItem>
                  {/* <MenuItem value="both">両打ち</MenuItem> */}
                </Select>
              </Stack>
            </Grid>
          </Grid>

          <Box margin={1}></Box>
          <Divider>投手情報</Divider>
          <Box margin={1}></Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <InputLabel id="select-pitcher-label">投手名：　</InputLabel>
                <Box>{pitcher ? pitcher : "NODATA"}</Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <InputLabel id="select-throw-label">投：　　　</InputLabel>

                <Select
                  labelId="select-throw-label"
                  id="select-throw"
                  value={pitThrow}
                  onChange={changeThrow}
                  size="small"
                >
                  <MenuItem value="right">右</MenuItem>
                  <MenuItem value="left">左</MenuItem>
                </Select>
              </Stack>
            </Grid>
            {pitchInfo ? (
              <Grid item xs={12} sm={4}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <InputLabel id="pitches-number-label">投球数：　</InputLabel>
                  <Box>{pitchesNumber ? pitchesNumber : 0}</Box>
                </Stack>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedSituations}
        onChange={handleChangeSituations()}
      >
        <AccordionSummary sx={{ backgroundColor: "#AFEEEE" }}>
          <Typography>
            <span className="optional">任意</span>{" "}
            得点・アウトカウント・ランナー
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          投球結果に応じて自動で表示されます。異なる場合は操作してください。
          <Box margin={1}></Box>
          <Divider>得点</Divider>
          <Box margin={1}></Box>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <div>
                  <table>
                    <thead>
                      <tr>
                        {/* <th>Team / Inning</th> */}
                        <th>-</th>
                        {firstattackScores.map((_, i) => {
                          if (inning < 10 && i < 9) {
                            return <th key={i}> {i + 1}</th>;
                          }

                          if (inning > 9 && i >= 9) {
                            return <th key={i}> {i + 1}</th>;
                          }
                        })}
                        <th>計</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{firstAttackTeam ? firstAttackTeam : "先攻"}</td>
                        {firstattackScores.map((score, i) => {
                          if (inning < 10 && i < 9) {
                            if (i < inning) {
                              return <td key={i}>{score}</td>;
                            }
                            // 計にscoreをあわせるために空白でうめる
                            if (inning < 10 && i < 9) {
                              return <td key={i}></td>;
                            }
                          }

                          if (inning > 9 && i >= 9) {
                            if (i < inning) {
                              return <td key={i}>{score}</td>;
                            }
                            if (inning < 19 && i < 18) {
                              return <td key={i}></td>;
                            }
                          }
                        })}
                        <td>{firstAttackTeamScore}</td>
                      </tr>
                      <tr>
                        <td>{secondAttackTeam ? secondAttackTeam : "後攻"}</td>
                        {secondattackScores.map((score, i) => {
                          if (inning < 10 && i < 9) {
                            if (topOrBottom === "top") {
                              if (i < inning - 1) {
                                return (
                                  <td key={i}>
                                    {score}
                                    {/* <button onClick={() => addScoreToSecondattack(i)}>+</button> */}
                                  </td>
                                );
                              }
                              // 計にscoreをあわせるために空白でうめる
                              if (inning < 10 && i < 9) {
                                return <td key={i}></td>;
                              }
                            }

                            if (topOrBottom === "bottom") {
                              if (i < inning) {
                                return (
                                  <td key={i}>
                                    {score}
                                    {/* <button onClick={() => addScoreToSecondattack(i)}>+</button> */}
                                  </td>
                                );
                              }
                              // 計にscoreをあわせるために空白でうめる
                              if (inning < 10 && i < 9) {
                                return <td key={i}></td>;
                              }
                            }

                            // if (inning > 9 && i >= 9) {
                            //   <td key={i}>
                            //     {score}
                            //     {/* <button onClick={() => addScoreToFirstattack(i)}>+</button> */}
                            //   </td>;
                            // }
                          }
                          if (inning > 9 && i >= 9) {
                            if (topOrBottom === "top") {
                              if (i < inning - 1) {
                                return (
                                  <td key={i}>
                                    {score}
                                    {/* <button onClick={() => addScoreToSecondattack(i)}>+</button> */}
                                  </td>
                                );
                              }
                              // 計にscoreをあわせるために空白でうめる
                              if (inning < 19 && i < 18) {
                                return <td key={i}></td>;
                              }
                            }

                            if (topOrBottom === "bottom") {
                              if (i < inning) {
                                return (
                                  <td key={i}>
                                    {score}
                                    {/* <button onClick={() => addScoreToSecondattack(i)}>+</button> */}
                                  </td>
                                );
                              }
                              // 計にscoreをあわせるために空白でうめる
                              if (inning < 19 && i < 18) {
                                return <td key={i}></td>;
                              }
                            }
                          }
                        })}
                        <td>{secondAttackTeamScore}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Stack>
            </Grid>
          </Grid>

          <Box margin={1}></Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {topOrBottom === "top" ? (
              <Button
                variant="outlined"
                size="small"
                onClick={() => addScoreToFirstattack(inning - 1)}
              >
                +
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => addScoreToSecondattack(inning - 1)}
              >
                +
              </Button>
            )}
            {topOrBottom === "top" ? (
              <Button
                variant="outlined"
                size="small"
                onClick={() => subtractScoreToFirstattack(inning - 1)}
              >
                -
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => subtractScoreToSecondattack(inning - 1)}
              >
                -
              </Button>
            )}
          </Stack>

          <Box margin={2}></Box>
          <Divider>アウトカウント</Divider>
          <Box margin={1}></Box>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Box>{displayBallsMark(ballCount)}</Box>
                  <Box>{displayStrikesMark(strikeCount)}</Box>
                  <Box>{displayOutCountMark(outCounts)}</Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Box margin={1}></Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={incrementOutCount}
              disabled={outCounts >= 3}
            >
              +
            </Button>
            <Button variant="outlined" size="small" onClick={decrementOutCount}>
              -
            </Button>
          </Stack>

          <Box margin={2}></Box>
          <Divider>ランナー</Divider>
          <Box margin={1}></Box>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Box>{displayRunnerPicture(runner)}</Box>
            <Box>
              <FormControl>
                <Select
                  labelId="select-runner-label"
                  value={runner}
                  onChange={selectRunner}
                >
                  <MenuItem value={0}>なし</MenuItem>
                  <MenuItem value={1}>一塁</MenuItem>
                  <MenuItem value={2}>二塁</MenuItem>
                  <MenuItem value={3}>三塁</MenuItem>
                  <MenuItem value={4}>一、二塁</MenuItem>
                  <MenuItem value={5}>一、三塁</MenuItem>
                  <MenuItem value={6}>二、三塁</MenuItem>
                  <MenuItem value={7}>満塁</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Box margin={2}></Box>
          <Divider>メモ</Divider>
          <Box margin={1}></Box>
          <Box>
            <TextInput
              fullWidth={true}
              multiline={true}
              rows={3}
              type={"text"}
              value={situationMemo}
              onChange={inputSituationMemo}
              placeholder={
                "ex.2球目にワイルドピッチで1点入る。3球目に盗塁でランナー2塁に。4球目に牽制死でアウトカウントが1つ増える。"
              }
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expandedPitch} onChange={handleChangePitch()}>
        <AccordionSummary sx={{ backgroundColor: "#AFEEEE" }}>
          <Typography>
            <span className="required">必須</span> コース・球種・投球結果{" "}
            <span className="optional">任意</span> 球速
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {/* 配球入力 */}
          <Typography>
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 16,
                fontWeight: "medium",
                textAlign: "left",
                margin: 2,
              }}
            >
              数字マークを動かし
            </InputLabel>
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 16,
                fontWeight: "medium",
                textAlign: "left",
                margin: 2,
              }}
            >
              コースを指定してください
            </InputLabel>
          </Typography>
          <DndProvider backend={IsMobileDevice() ? TouchBackend : HTML5Backend}>
            <GameSeq balls={balls} setBalls={setBalls} count={count} />
          </DndProvider>

          {/* 配球サマリーテーブル */}

          <Box sx={{ margin: 2 }}>
            <SequenceTable count={count} balls={balls}></SequenceTable>
          </Box>

          <Box sx={{ margin: 1 }}>
            <div>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                  <FormControl fullWidth required>
                    <InputLabel id="type-select-label">球種</InputLabel>
                    <Select
                      labelId="type-select-label"
                      id="type-select"
                      value={balls[count].type} // valueに指定されている値が表示されるようになる
                      label="Type"
                      onChange={selectType}
                    >
                      <MenuItem value="straight">ストレート(◯)</MenuItem>
                      <MenuItem value="twoseams">ツーシーム(◎)</MenuItem>
                      <MenuItem value="slider">スライダー(△)</MenuItem>
                      <MenuItem value="cutball">カットボール(△)</MenuItem>
                      <MenuItem value="curve">カーブ(◇)</MenuItem>
                      <MenuItem value="shoot">シュート(□)</MenuItem>
                      <MenuItem value="fork">フォーク(▽)</MenuItem>
                      <MenuItem value="sff">SFF(▽)</MenuItem>
                      <MenuItem value="verticalslider">
                        縦スライダー(▽)
                      </MenuItem>
                      <MenuItem value="changeup">チェンジアップ(☆)</MenuItem>
                      <MenuItem value="slowball">スローボール(☆)</MenuItem>
                      <MenuItem value="palm">パーム(☆)</MenuItem>
                      <MenuItem value="knuckle">ナックル(☆)</MenuItem>
                      <MenuItem value="sinker">シンカー(◇)</MenuItem>
                      <MenuItem value="screw">スクリュー(◇)</MenuItem>
                      <MenuItem value="other">その他(♡)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/*  カウントによって入力フォームを変える */}
              {(() => {
                if (strikeCount >= 2 && ballCount >= 3) {
                  return (
                    <Grid container direction="row" justifyContent="center">
                      <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                        <FormControl
                          fullWidth
                          required
                          error={!balls[count].result}
                        >
                          <InputLabel id="result-by-one-pitch" required="true">
                            投球結果
                          </InputLabel>
                          <Select
                            labelId="result-by-one-pitch-label"
                            id="result-by-one-pitch"
                            value={balls[count].result}
                            label="ResultByOnePitch"
                            onChange={selectResult}
                          >
                            <MenuItem value="foul">ファール</MenuItem>
                            <MenuItem value="walk">四球</MenuItem>
                            <MenuItem value="deadBall">死球</MenuItem>
                            <MenuItem value="intentionalWalk">申告敬遠</MenuItem>
                            <MenuItem value="error">エラー</MenuItem>
                            <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                            <MenuItem value="flightOut">フライアウト</MenuItem>
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="doublePlay">
                                ダブルプレー
                              </MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeBunt">犠打</MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeFly">
                                犠牲フライ
                              </MenuItem>
                            )}
                            <MenuItem value="swingingStrikeOut">
                              空振り三振
                            </MenuItem>
                            <MenuItem value="calledStrikeOut">
                              見逃し三振
                            </MenuItem>
                            <MenuItem value="hit">ヒット</MenuItem>
                            <MenuItem value="double">二塁打</MenuItem>
                            <MenuItem value="triple">三塁打</MenuItem>
                            <MenuItem value="homeRun">本塁打</MenuItem>
                            {runner !== 0 && (
                              <MenuItem value="fildersChoice">
                                フィルダースチョイス
                              </MenuItem>
                            )}
                            <MenuItem value="swingAway">振り逃げ</MenuItem>
                            <MenuItem value="blowObstruction">
                              打撃妨害
                            </MenuItem>
                          </Select>
                          <FormHelperText disabled={!balls[count].result}>
                            {count}球目の投球結果を入力してください
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  );
                } else if (strikeCount >= 2 && ballCount != 3) {
                  return (
                    <Grid container direction="row" justifyContent="center">
                      <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                        <FormControl
                          fullWidth
                          required
                          error={!balls[count].result}
                        >
                          <InputLabel id="result-by-one-pitch">
                            投球結果
                          </InputLabel>
                          <Select
                            labelId="result-by-one-pitch-label"
                            id="result-by-one-pitch"
                            value={balls[count].result}
                            label="ResultByOnePitch"
                            onChange={selectResult}
                          >
                            <MenuItem value="ball">ボール</MenuItem>
                            <MenuItem value="foul">ファール</MenuItem>
                            <MenuItem value="deadBall">死球</MenuItem>
                            <MenuItem value="intentionalWalk">申告敬遠</MenuItem>
                            <MenuItem value="error">エラー</MenuItem>
                            <MenuItem value="swingingStrikeOut">
                              空振り三振
                            </MenuItem>
                            <MenuItem value="calledStrikeOut">
                              見逃し三振
                            </MenuItem>
                            <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                            <MenuItem value="flightOut">フライアウト</MenuItem>
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="doublePlay">
                                ダブルプレー
                              </MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeBunt">犠打</MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeFly">
                                犠牲フライ
                              </MenuItem>
                            )}
                            <MenuItem value="hit">ヒット</MenuItem>
                            <MenuItem value="double">二塁打</MenuItem>
                            <MenuItem value="triple">三塁打</MenuItem>
                            <MenuItem value="homeRun">本塁打</MenuItem>
                            {runner !== 0 && (
                              <MenuItem value="fildersChoice">
                                フィルダースチョイス
                              </MenuItem>
                            )}
                            <MenuItem value="swingAway">振り逃げ</MenuItem>
                            <MenuItem value="blowObstruction">
                              打撃妨害
                            </MenuItem>
                          </Select>
                          <FormHelperText disabled={!balls[count].result}>
                            {count}球目の投球結果を入力してください
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  );
                } else if (strikeCount != 2 && ballCount == 3) {
                  return (
                    <Grid container direction="row" justifyContent="center">
                      <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                        <FormControl
                          fullWidth
                          required
                          error={!balls[count].result}
                        >
                          <InputLabel id="result-by-one-pitch">
                            投球結果
                          </InputLabel>
                          <Select
                            labelId="result-by-one-pitch-label"
                            id="result-by-one-pitch"
                            value={balls[count].result}
                            label="ResultByOnePitch"
                            onChange={selectResult}
                          >
                            <MenuItem value="strike">ストライク</MenuItem>
                            <MenuItem value="swing">空振り</MenuItem>
                            <MenuItem value="foul">ファール</MenuItem>
                            <MenuItem value="walk">四球</MenuItem>
                            <MenuItem value="deadBall">死球</MenuItem>
                            <MenuItem value="intentionalWalk">申告敬遠</MenuItem>
                            <MenuItem value="error">エラー</MenuItem>
                            <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                            <MenuItem value="flightOut">フライアウト</MenuItem>
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="doublePlay">
                                ダブルプレー
                              </MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeBunt">犠打</MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeFly">
                                犠牲フライ
                              </MenuItem>
                            )}
                            <MenuItem value="hit">ヒット</MenuItem>
                            <MenuItem value="double">二塁打</MenuItem>
                            <MenuItem value="triple">三塁打</MenuItem>
                            <MenuItem value="homeRun">本塁打</MenuItem>
                            {runner !== 0 && (
                              <MenuItem value="fildersChoice">
                                フィルダースチョイス
                              </MenuItem>
                            )}
                            <MenuItem value="blowObstruction">
                              打撃妨害
                            </MenuItem>
                          </Select>
                          <FormHelperText disabled={!balls[count].result}>
                            {count}球目の投球結果を入力してください
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid container direction="row" justifyContent="center">
                      <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                        <FormControl
                          fullWidth
                          required
                          error={!balls[count].result}
                        >
                          <InputLabel id="result-by-one-pitch">
                            投球結果
                          </InputLabel>
                          <Select
                            labelId="result-by-one-pitch-label"
                            id="result-by-one-pitch"
                            value={balls[count].result}
                            label="ResultByOnePitch"
                            onChange={selectResult}
                          >
                            <MenuItem value="strike">ストライク</MenuItem>
                            <MenuItem value="swing">空振り</MenuItem>
                            <MenuItem value="ball">ボール</MenuItem>
                            <MenuItem value="foul">ファール</MenuItem>
                            <MenuItem value="deadBall">死球</MenuItem>
                            <MenuItem value="intentionalWalk">申告敬遠</MenuItem>
                            <MenuItem value="error">エラー</MenuItem>
                            <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                            <MenuItem value="flightOut">フライアウト</MenuItem>
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="doublePlay">
                                ダブルプレー
                              </MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeBunt">犠打</MenuItem>
                            )}
                            {runner !== 0 && outCounts < 2 && (
                              <MenuItem value="sacrificeFly">
                                犠牲フライ
                              </MenuItem>
                            )}
                            <MenuItem value="hit">ヒット</MenuItem>
                            <MenuItem value="double">二塁打</MenuItem>
                            <MenuItem value="triple">三塁打</MenuItem>
                            <MenuItem value="homeRun">本塁打</MenuItem>
                            {runner !== 0 && (
                              <MenuItem value="fildersChoice">
                                フィルダースチョイス
                              </MenuItem>
                            )}
                            <MenuItem value="blowObstruction">
                              打撃妨害
                            </MenuItem>
                          </Select>
                          <FormHelperText disabled={!balls[count].result}>
                            {count}球目の投球結果を入力してください
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  );
                }
              })()}

              <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} sm={6} sx={{ marginRight: 2 }}>
                  <TextInput
                    fullWidth={true}
                    label={"球速"}
                    multiline={false}
                    rows={1}
                    type={"number"}
                    value={balls[count].speed}
                    onChange={inputSpeed}
                    margin={1}
                    error={balls[count].speed < 0}
                    helperText={
                      balls[count].speed < 0 ? "球速を0以上にしてください" : " "
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Grid container justifyContent="center">
        <Stack direction="row" spacing={2} sx={{ marginBottom: 6 }}>
          {count == 1 || pitchesNumber == 0 && (
            <Button disabled variant="contained" size="large">
              {" "}
              前の投球へ
            </Button>
          )}
          {count > 1 && pitchesNumber > 0 && (
            <Button variant="contained" onClick={preBall} size="large">
              {" "}
              前の投球へ
            </Button>
          )}
          {/* - その打席の結果が入力されている場合は「次の投球」ボタンは非活性化
            - また、投球結果が入力されてない場合も非活性化
         */}
          {count < 15 &&
            !atBatResults.includes(balls[count].result) &&
            pitchResults.includes(balls[count].result) ? (
            <Button variant="contained" onClick={nextBall} size="large">
              次の投球へ
            </Button>
          ) : (
            <Button disabled variant="contained" size="large">
              {" "}
              次の投球へ{" "}
            </Button>
          )}
        </Stack>
      </Grid>

      {/* メモ */}
      <Accordion expanded={expandedMemo} onChange={handleChangeMemo()}>
        <AccordionSummary sx={{ backgroundColor: "#AFEEEE" }}>
          <Typography>
            <span className="optional">任意</span> メモ
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box sx={{ margin: 2 }}>
            <TextInput
              fullWidth={true}
              multiline={true}
              rows={5}
              type={"text"}
              value={sequenceMemo}
              onChange={inputSequenceMemo}
              placeholder={
                "初球から直球を積極的に振ってくる可能性があるので、初球は外角にカーブ。2球目は目線を広げるために、内角高めにストレートでボール球。3球目は甘いところからシンカーを投げてスイングを誘発させて、内野ゴロを狙う"
              }
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* 打球方向 */}
      <Accordion
        expanded={expandedHittingDirection}
        onChange={handleChangeHittingDirection()}
      >
        <AccordionSummary sx={{ backgroundColor: "#AFEEEE" }}>
          <Typography>
            <span className="optional">任意</span> 打球方向
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 16,
                fontWeight: "medium",
                textAlign: "left",
                margin: 1,
              }}
            >
              フリーハンドで打球方向が描けます。
            </InputLabel>
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <div style={containerStyle}>
              <SignatureCanvas
                penColor="green"
                canvasProps={{
                  width: 350,
                  height: 350,
                  className: "sigCanvas",
                  background: "transparent",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                ref={(ref) => {
                  sigCanvas.current = ref;
                }}
                onEnd={() =>
                  setHittingDirectionImage(
                    sigCanvas.current.toDataURL("image/png")
                  )
                }
              />
            </div>
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* <Button onClick={saveSigCanvas}> 保存</Button> */}
            <Button variant="outlined" size="small" onClick={clearSigCanvas}>
              消去
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Divider></Divider>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {atBatResults.includes(balls[count].result) ? (
          <Button
            variant="outlined"
            // startIcon={<ModeCommentIcon />}
            onClick={openModalAndSetEtc}
            // sx={{ marginBottom: 2 }}
            disabled={!atBatResults.includes(balls[count].result)}
          >
            {" "}
            次の打者
          </Button>
        ) : (
          <Button
            variant="outlined"
            // startIcon={<ModeCommentIcon />}
            // sx={{ marginBottom: 2 }}
            disabled
          >
            {" "}
            次の打者
          </Button>
        )}

        <Button
          variant="outlined"
          // startIcon={<ModeCommentIcon />}
          onClick={handleOpenModalFinishGame}
        // sx={{ marginBottom: 2 }}
        // disabled={!gameTitle || !gameDate}
        >
          {" "}
          試合終了
        </Button>
      </Stack>

      {isRegisterError && (
        <Alert
          severity="error"
          onClose={() => {
            setIsRegisterError(false);
          }}
        >
          登録に失敗しました。ネットワーク接続を確認をしてください。
        </Alert>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: null,
        }}
      >
        <Box sx={styleModal}>
          {outCounts === 3 ? (
            // 打撃結果が入力されてない場合
            <Box>
              <Box sx={{ fontWeight: "bold" }}>3アウトで攻守交代しますか？</Box>

              <Box sx={{ mt: 1 }}>
                ※{inning + 1}回{topOrBottom === "top" ? "表" : "裏"}
                は今の打者から再開します
              </Box>
              <Box sx={{ mt: 1, mb: 2 }}>
                ※この回の得点は
                {topOrBottom === "top"
                  ? dispScoreFirstattack(inning - 1)
                  : dispScoreSecondattack(inning - 1)}
                点です。
              </Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  onClick={registerAndSameBatterInNextInning}
                >
                  はい
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={cancelNextInning}
                >
                  いいえ
                </Button>
              </Stack>
            </Box>
          ) : (
            // 打撃結果が入力されている場合
            <Box>
              <Typography id="modal-modal-title" variant="inherit">
                結果は
                <b>
                  {balls[count].result
                    ? ConvertExpression(balls[count].result)
                    : "なし"}
                </b>
                です。
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="inherit"
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                アウトカウントはいくつ増えますか
              </Typography>

              <Typography id="modal-modal-description">
                <TextInput
                  // label={"アウトカウント"}
                  multiline={false}
                  rows={1}
                  type={"number"}
                  value={adjustOutCounts}
                  onChange={inputAdjustOutCounts}
                  margin={1}
                  size="small"
                />
              </Typography>

              <Box>
                <Grid container>
                  <Grid item xs={12} sm={5}>
                    <Box>結果前：</Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box>　{displayOutCountMark(parseInt(outCounts))}</Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={5}>
                    <Box>結果後：</Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>

                      {displayOutCountMark(
                        parseInt(outCounts) + parseInt(adjustOutCounts)
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Typography
                id="modal-modal-title"
                variant="inherit"
                sx={{ mt: 4, fontWeight: "bold" }}
              >
                この回の得点は何点増えますか
              </Typography>

              {topOrBottom === "top" ? (
                <>
                  <TextInput
                    // label={"アウトカウント"}
                    multiline={false}
                    rows={1}
                    type={"number"}
                    value={adjustScore}
                    onChange={inputAdjustScore}
                    margin={1}
                    size="small"
                  />

                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={9}>
                        <Box>結果前の回の得点：</Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box>　{dispScoreFirstattack(inning - 1)}点</Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={9}>
                        <Box>結果後の回の得点：</Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box>

                          {dispScoreFirstattack(inning - 1) +
                            parseInt(adjustScore ? adjustScore : 0)}
                          点
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <TextInput
                    // label={"アウトカウント"}
                    multiline={false}
                    rows={1}
                    type={"number"}
                    value={adjustScore}
                    onChange={inputAdjustScore}
                    margin={1}
                    size="small"
                  />

                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={9}>
                        <Box>結果前の回の得点：</Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box>　{dispScoreSecondattack(inning - 1)}点</Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={9}>
                        <Box>結果後の回の得点：</Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box>

                          {dispScoreSecondattack(inning - 1) +
                            parseInt(adjustScore ? adjustScore : 0)}
                          点
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
              {isRegisterLoading && <CircularProgress />}

              {parseInt(outCounts) + parseInt(adjustOutCounts) >= 3 ? (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button
                    onClick={registerAndCloseModal}
                    variant="outlined"
                    size="small"
                  >
                    3アウトで攻守交代
                  </Button>{" "}
                  <Button
                    onClick={cancelNextInning}
                    variant="outlined"
                    size="small"
                  >
                    キャンセル
                  </Button>
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={registerAndCloseModal}
                  >
                    次の打者
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={cancelNextInning}
                  >
                    キャンセル
                  </Button>
                </Stack>
              )}
            </Box>
          )}
        </Box>
      </Modal>

      <Modal
        open={openModalFinishGame}
        onClose={handleCloseModalFinishGame}
        aria-labelledby="modal-modal-title-finish-game"
        aria-describedby="modal-modal-description-finish-game"
        BackdropProps={{
          onClick: null,
        }}
      >
        <Box sx={styleModal}>
          <Box>
            {inning > 18 ? (
              <Typography
                id="modal-modal-title"
                variant="inherit"
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                19回以上は登録できません
              </Typography>
            ) : (
              <Typography
                id="modal-modal-title"
                variant="inherit"
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                試合の記録を完了しますか？（最後の打者を記録する場合、先に次の打者ボタンを先にクリックしてください）。
              </Typography>
            )}

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Button
                onClick={finishGameAndCloseModal}
                variant="outlined"
                size="small"
              >
                はい
              </Button>{" "}
              <Button
                onClick={handleCloseModalFinishGame}
                variant="outlined"
                size="small"
              >
                いいえ
              </Button>{" "}
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalChangeMember}
        onClose={handleCloseModalChangeMember}
        aria-labelledby="modal-modal-title-change-member"
        aria-describedby="modal-modal-description-change-member"
      >
        <Box sx={styleModalMember}>
          <ChangeMember
            firstAttackMember={firstAttackMember}
            setFirstAttackMember={setFirstAttackMember}
            secondAttackMember={secondAttackMember}
            setSecondAttackMember={setSecondAttackMember}
            firstAttackTeam={firstAttackTeam}
            secondAttackTeam={secondAttackTeam}
            gameId={gameId}
            fetchGame={() => fetchGame()}
            handleCloseModalChangeMember={() => handleCloseModalChangeMember()}
            pitchInfo={pitchInfo}
            setPitchInfo={setPitchInfo}
            setPitchesNumber={setPitchesNumber}
            topOrBottom={topOrBottom}
            latestSituationId={latestSituationId}
            owner={owner}
          />
        </Box>
      </Modal>
    </>
  );
}
