import { Typography, Card, CardContent } from "@mui/material";

const MenuDescription = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="inherit" color="text.primary">
          <b>TOP</b>
          <br />
          ポータル画面です。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          <b>試合形式で記録する</b>
          <br />
          実際の試合に沿って、配球を記録します。スターティングメンバーを登録し、配球を登録します。詳細は下記の「試合形式で記録する、記録を見る」の動画を参照ください。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          <b>配球を見る</b>
          <br />
          「試合の記録を見る」「みんなの配球」「自分の配球」で構成されます。
          <br />
          「試合の記録を見る」は「試合形式で記録する」で登録した内容を見たり、各配球のメモを編集できます。また、自動集計した記録を球種やコースを円グラフやコース別分布で表示ができ、投手別に対左、右打者別に得意球やそのコースを把握したり、配球の型を検討できます。
          <br />
          「みんなの配球」は他のユーザーを含む公開設定になっている配球を一覧で見ることができます。
          <br />
          「自分の配球」は自分が登録した配球を一覧で見たり、登録された配球データのメモを変更できます。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          <b>配球を作ってみる</b>
          <br />
          配球を考えて登録する画面です。詳細な利用方法は下記の「考えた配球を登録する」の動画をご参照ください。
        </Typography>
        <br />
        {/* <Typography variant="inherit" color="text.primary">
          <b>集計データを見る</b>
          <br />
          配球登録時の選択項目の「グラフ集計」を対象とした配球を集計し、「右左打者別」、「投手別」、「投球結果別」の分類に応じて球種の割合が確認できる円グラフやコース分布を確認できます。
          <br />
          登録する配球が試合結果の記録の場合、「グラフ集計」を対象とし、集計データから得意球やまたコースの傾向を確認し、配球を考える参考にできます。
        </Typography>
        <br /> */}
        <Typography variant="inherit" color="text.primary">
          <b>勉強する</b>
          <br />
          配球についての考えをコラム的にまとめています。
        </Typography>
        <br />
        <Typography variant="inherit" color="text.primary">
          <b>操作方法など</b>
          <br />
          このページのことで、このアプリの使い方を説明しています。
        </Typography>
        <br />
      </CardContent>
    </Card>
  );
};

export default MenuDescription;
