import { Grid, Typography, Box, Paper } from "@mui/material";
import CustomPageLabel from "./CustomPageLabel";
import StrikeBoyImgPath from "../assets/img/strike_boy.png";

const PrivacyPolicy = () => {
  return (
    <>
      <CustomPageLabel label="プライバシーポリシー" />
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "white",
          color: "black",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: `url(${post.image})`,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,255,0.1)",
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 6, md: 6, sm: 6 },
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                gutterBottom
              >
                {/* {post.title} */}
                プライバシーポリシー
              </Typography>
              <Typography variant="body2" color="inherit" paragraph>
                {/* {post.description} */}
                <br />
                <br />
                <b>お客様から取得する情報</b>
                <br />
                <ul>
                  <li>メールアドレス</li>
                  <li>
                    当ウェブサイトの滞在時間、入力履歴、購買履歴等の当サイトの行動履歴
                  </li>
                  <li>端末の種類など</li>
                </ul>
                <b>お客様の情報を利用する目的</b>
                <br />
                <ul>
                  <li>当サービスに関する登録の受付、お客様の認証のため</li>
                  <li>当サービスの維持改善のため</li>
                  <li>当サービスのご案内のため</li>
                  <li>当サービスの法令、違反する行為に対応するため</li>
                  <li>当サービスの変更、提供中止、終了をご連絡するため</li>
                </ul>
                <b>第三者提供</b>
                <br />
                当サービスは、お客様から取得する情報のうち、個人情報（個人情報保護法第２条第６項）に該当する
                ものについては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者をふくみます。）に
                提供しません。
                <br />
                <br />
                <b>アクセス解析ツール</b>
                <br />
                当サービスは、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。
                Googleアナリティクスは、トラフィックデータの収集のために、Cookieを使用しています。
                トラフィックデータは匿名で収集されており、個人を特定するものではありません。
                なお、以下の設定より、情報の収集を拒否することができます。
                <br />
                <br />
                https://marketingplatform.google.com/about/analytics/terms/jp/
                <br />
                <br />
                <b>お問い合わせ</b>
                <br />
                機能改善要望、お客様の情報の開示、情報の訂正、利用停止、削除のご希望の場合は、以下のTwitterアカウントにご連絡ください。
                <br />
                https://twitter.com/PitStrategyNote
              </Typography>
              {/* <Link variant="subtitle1" href="#">
               {post.linkText} 
                LinkText
              </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="row" justifyContent="center">
        <Box sx={{ marginTop: 2 }}>
          <img
            src={StrikeBoyImgPath}
            alt="ストライク坊や"
            width={130}
            height={90}
          />
        </Box>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
