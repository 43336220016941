import { useEffect } from "react";
import { API } from "aws-amplify";
import { ViewSequencesSummaryPublicList, CustomPageLabel } from "./index";
import { useState } from "react";
import {
  CircularProgress,
  InputLabel,
  FormHelperText,
  // Pagination,
} from "@mui/material";
import { Pagination } from "@aws-amplify/ui-react";
import { useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Auth } from "aws-amplify";

export default function SearchPublic() {
  const [publicGames, setPublicGames] = useState();
  const [displayPublicGames, setDisplayPublicGames] = useState([]);
  const [pageTokens, setPageTokens] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const pageLength = 5;
  const viewNumberByPage = 5;
  const firstRenderingControlFlag = useRef(true);

  const [atBatResultFilter, setAtBatResultFilter] = useState("");
  const [filterGameIds, setFilterGameIds] = useState();

  const fetchGameIdsByAtBatResultFilter = async () => {
    let validUser;
    try {
      await Auth.currentAuthenticatedUser();
      validUser = true;
    } catch (error) {
      validUser = false;
    }

    const getPublicSequenceByAtBatResult = `
      query GetPublicSequenceByAtBatResult(
        $atBatResult: String!
        $sortDirection: ModelSortDirection
        $filter: ModelpublicSequenceFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getPublicSequenceByAtBatResult(
          atBatResult: $atBatResult
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            caseId
          }
          nextToken
        }
      }
    `;

    let resCaseIds;
    if (validUser) {
      resCaseIds = await API.graphql({
        query: getPublicSequenceByAtBatResult,
        variables: { atBatResult: atBatResultFilter },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } else {
      resCaseIds = await API.graphql({
        query: getPublicSequenceByAtBatResult,
        variables: { atBatResult: atBatResultFilter },
        authMode: "AWS_IAM",
      });
    }

    let tmpCaseIds = [];

    for (let item of resCaseIds.data.getPublicSequenceByAtBatResult.items) {
      tmpCaseIds.push(item.caseId);
    }

    let tmpCaseIds1 = [];
    for (let caseId of tmpCaseIds) {
      tmpCaseIds1.push({ id: { eq: caseId } });
    }

    let filterCaseIds;

    if (tmpCaseIds1.length == 0) {
      // もしcaseIdが0件だったら、表示結果を0件にするために存在しないIDをフィルタとして返却する
      return (filterCaseIds = {
        or: { id: { eq: "dammyId" } },
      });
    } else {
      filterCaseIds = {
        or: tmpCaseIds1,
      };
    }

    const listPublicCases = `
    query ListPublicCases(
      $id: ID
      $filter: ModelPublicCaseFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listPublicCases(
        id: $id
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          matchId
        }
        nextToken
      }
    }`;

    let resMatchIds;
    if (validUser) {
      resMatchIds = await API.graphql({
        query: listPublicCases,
        variables: { filter: filterCaseIds },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } else {
      resMatchIds = await API.graphql({
        query: listPublicCases,
        variables: { filter: filterCaseIds },
        authMode: "AWS_IAM",
      });
    }

    let tmpMatchIds = [];

    for (let item of resMatchIds.data.listPublicCases.items) {
      tmpMatchIds.push(item.matchId);
    }
    let tmpMatchIds1 = [];
    for (let matchId of tmpMatchIds) {
      tmpMatchIds1.push({ id: { eq: matchId } });
    }

    let filterMatchIds = {
      or: tmpMatchIds1,
    };

    const listPublicMatches = `
    query ListPublicMatches(
      $id: ID
      $gameId: ModelIDKeyConditionInput
      $filter: ModelPublicMatchFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listPublicMatches(
        id: $id
        gameId: $gameId
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          gameId
        }
        nextToken
      }
    }`;

    let resGameIds;
    if (validUser) {
      resGameIds = await API.graphql({
        query: listPublicMatches,
        variables: { filter: filterMatchIds },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } else {
      resGameIds = await API.graphql({
        query: listPublicMatches,
        variables: { filter: filterMatchIds },
        authMode: "AWS_IAM",
      });
    }

    let tmpGameIds = [];

    for (let item of resGameIds.data.listPublicMatches.items) {
      tmpGameIds.push(item.gameId);
    }

    let tmpGameIds1 = [];
    for (let gameId of tmpGameIds) {
      tmpGameIds1.push({ id: { eq: gameId } });
    }

    let filterGameIds = {
      or: tmpGameIds1,
    };

    return filterGameIds;
  };

  // フィルタが選択されたらクエリにつけるためのgameIdのフィルタをつくる
  useEffect(async () => {
    if (atBatResultFilter && atBatResultFilter !== "all") {
      const res = await fetchGameIdsByAtBatResultFilter();
      setFilterGameIds(res);
    }

    // すべてを選択した場合は、フィルタをからで初期化した上で、公開状態の配球を取得しにいく。
    if (atBatResultFilter === "all") {
      setFilterGameIds();
      fetchPublicSequenceList();
    }
  }, [atBatResultFilter]);

  const fetchPublicSequenceList = async () => {
    let validUser;
    try {
      await Auth.currentAuthenticatedUser();
      validUser = true;
    } catch (error) {
      validUser = false;
    }

    const customListPublicGames = `query PublicAllGameForUpdatedSort(
          $type: String!
          $updatedAt: ModelStringKeyConditionInput
          $sortDirection: ModelSortDirection
          $gameFilter: ModelpublicGameFilterInput
          $limit: Int
          $nextToken: String
          $matchFilter: ModelpublicMatchFilterInput
        ) { 
          publicAllGameForUpdatedSort(
            type: $type
            updatedAt: $updatedAt
            sortDirection: $sortDirection
            filter: $gameFilter
            limit: $limit
            nextToken: $nextToken
          ) {
              items {
                batterTeam
                createdAt
                date
                id
                owner
                pitcherTeam
                updatedAt
                publicMatches(
                  filter: $matchFilter
                ) {
                  items {
                    atBats
                    bat
                    batter
                    createdAt
                    gameId
                    id
                    owner
                    pitcher
                    throw
                    title
                    updatedAt
                    limitedDisclosure
                    cases {
                      items {
                        batterTeamScore
                        createdAt
                        id
                        inning
                        matchId
                        outCounts
                        owner
                        pitcherTeamScore
                        runner
                        topOrBottom
                        dataAggrFlag
                        updatedAt
                        sequences {
                          items {
                            atBatResult
                            balls
                            breakingBall
                            caseId
                            createdAt
                            id
                            memo
                            owner
                            pitchResult
                            pitchXPosition
                            pitchYPosition
                            sequence
                            speed
                            strikes
                            updatedAt
                          }
                        }
                      }
                    }
                  }
                }
              }
              nextToken
            }
          }`;
    // 公開情報については認証情報をcognitoからIAMに切り替えてpublicアクセスできるようにする
    let res;
    // if (hasMorePages && currentPageIndex === pageTokens.length){
    let tmpPageTokens;
    let tmpPublicGames;
    let tmpDisplayPublicGames;
    let tmpHasMorePages;
    tmpPageTokens = [""];
    tmpPublicGames = [];
    tmpDisplayPublicGames = [];
    tmpHasMorePages = true;

    // 公開設定されている配球情報を一通り取得する。URL限定は含まない。
    for (let i = 0; tmpHasMorePages; i++) {
      if (tmpHasMorePages) {
        if (validUser && i == 0) {
          res = await API.graphql({
            query: customListPublicGames,
            variables: {
              limit: viewNumberByPage,
              matchFilter: { limitedDisclosure: { eq: 0 } },
              type: "t",
              sortDirection: "DESC",
              gameFilter: filterGameIds,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        } else if (validUser && i > 0) {
          res = await API.graphql({
            query: customListPublicGames,
            variables: {
              limit: viewNumberByPage,
              nextToken: tmpPageTokens[i],
              matchFilter: { limitedDisclosure: { eq: 0 } },
              type: "t",
              sortDirection: "DESC",
              gameFilter: filterGameIds,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        } else if (!validUser && i == 0) {
          res = await API.graphql({
            query: customListPublicGames,
            variables: {
              limit: viewNumberByPage,
              // nextToken: tmpPageTokens[i],
              matchFilter: { limitedDisclosure: { eq: 0 } },
              type: "t",
              sortDirection: "DESC",
              gameFilter: filterGameIds,
            },
            authMode: "AWS_IAM",
          });
        } else {
          res = await API.graphql({
            query: customListPublicGames,
            variables: {
              limit: viewNumberByPage,
              nextToken: tmpPageTokens[i],
              matchFilter: { limitedDisclosure: { eq: 0 } },
              type: "t",
              sortDirection: "DESC",
              gameFilter: filterGameIds,
            },
            authMode: "AWS_IAM",
          });
        }

        // 終了に達した場合は、これ以上フェッチしない
        if (!res.data.publicAllGameForUpdatedSort.nextToken) {
          tmpHasMorePages = false;
        }

        let tmpToken;
        tmpToken = res.data.publicAllGameForUpdatedSort.nextToken;
        if (tmpToken) {
          tmpPageTokens.push(tmpToken);
        }

        // publicかどうかチェックして1個ずつpushする
        if (res) {
          for (let game of res.data.publicAllGameForUpdatedSort.items) {
            for (let match of game.publicMatches.items) {
              if (match.limitedDisclosure === 0) {
                tmpPublicGames.push(game);
              }
            }
          }
        }

        // 決めた取得件数数に達した場合はこれ以上フェッチしない
        if (tmpPublicGames.length >= pageLength * viewNumberByPage) {
          tmpHasMorePages = false;
          console.log("limit fetch size");
        }
      }
    }

    setPageTokens(tmpPageTokens);
    setPublicGames(tmpPublicGames);
    setHasMorePages(false);

    if (tmpPublicGames.length == 0) {
      setDisplayPublicGames(["notRegistered"]);
    } else {
      // 初回表示用の配球を抽出
      if (tmpPublicGames.length < viewNumberByPage) {
        for (let i = 0; i < tmpPublicGames.length; i++) {
          tmpDisplayPublicGames.push(tmpPublicGames[i]);
        }
      } else {
        for (let i = 0; i < viewNumberByPage; i++) {
          tmpDisplayPublicGames.push(tmpPublicGames[i]);
        }
      }
      setDisplayPublicGames(tmpDisplayPublicGames);
    }

    // 打席結果などを変更して再クエリした場合に、ページネーションのページを1に戻す。
    // 戻さないと存在しないページで表示される。
    setCurrentPageIndex(1);
  };

  useEffect(() => {
    fetchPublicSequenceList();
  }, [filterGameIds]);

  useEffect(() => {
    // 初回レンダリング時にfalseを入れてあげることによって、「currentPageIndex」が変更されるときだけ実行される
    if (firstRenderingControlFlag.current) {
      // 初回はなにもしない
    } else {
      let viewContents = [];
      for (
        let i = (currentPageIndex - 1) * viewNumberByPage;
        i < currentPageIndex * viewNumberByPage;
        i++
      ) {
        if (publicGames[i]) {
          viewContents.push(publicGames[i]);
        }
      }
      setDisplayPublicGames(viewContents);
    }
  }, [currentPageIndex]);

  useEffect(() => {
    firstRenderingControlFlag.current = false;
  }, []);

  const calcPageLength = () => {
    if (publicGames.length % viewNumberByPage == 0) {
      return Math.floor(publicGames.length / viewNumberByPage);
    } else {
      return Math.floor(publicGames.length / viewNumberByPage) + 1;
    }
  };

  const handleChangeAtBatFilter = (event) => {
    setAtBatResultFilter(event.target.value);
  };

  return (
    <>
      <div id="searchPublic">
        <CustomPageLabel label="みんなの配球" />
        {!displayPublicGames[0] ? (
          <CircularProgress />
        ) : (
          <>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="atbat-select">投球結果</InputLabel>
              <Select
                labelId="atbat-select"
                id="atbat-select"
                value={atBatResultFilter}
                label="投球結果"
                onChange={handleChangeAtBatFilter}
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="strikeOut">三振</MenuItem>
                <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                <MenuItem value="flightOut">フライアウト</MenuItem>
                <MenuItem value="hit">ヒット</MenuItem>
                <MenuItem value="double">二塁打</MenuItem>
                <MenuItem value="triple">三塁打</MenuItem>
                <MenuItem value="homeRun">本塁打</MenuItem>
                <MenuItem value="walk">四球</MenuItem>
              </Select>
              <FormHelperText>配球表示切り替え</FormHelperText>
            </FormControl>
            {displayPublicGames[0] === "notRegistered" ? (
              "登録がありません"
            ) : (
              <>
                <ViewSequencesSummaryPublicList
                  games={displayPublicGames}
                  // disclosure="public"
                />
                <Pagination
                  currentPage={currentPageIndex}
                  totalPages={
                    calcPageLength() < pageLength
                      ? calcPageLength()
                      : pageLength
                  }
                  hasMorePages={hasMorePages}
                  onNext={() => {
                    setCurrentPageIndex(currentPageIndex + 1);
                  }}
                  onPrevious={() => {
                    setCurrentPageIndex(currentPageIndex - 1);
                  }}
                  onChange={(pageIndex) => setCurrentPageIndex(pageIndex)}
                />
                {/* <Typography
                  variant="h7"
                  noWrap
                  component="div"
                  sx={{ margin: 3 }}
                >
                  最新25件までの表示ですm(_ _)m
                </Typography> */}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
