import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import StrikeBoyImgPath from "../../assets/img/strike_boy.png";

export function Article20230501() {
  return (
    <>
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "white",
          color: "black",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: `url(${post.image})`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,255,0.1)",
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 6, md: 6, sm: 6 },
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                gutterBottom
              >
                {/* {post.title} */}
                配球の基本①、初球の入り方（フルタの方程式 書評）
              </Typography>
              <Typography variant="body2" color="inherit" paragraph>
                {/* {post.description} */}
                <br />
                <br />
                配球は基本としてその考え方を抽出することは難しいことだと思っていたが、この本にはその配球の基本というものが抽出され表現されており、素晴らしい本だと思った。
                <br />
                <br />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <iframe
                    sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    width={120}
                    height={240}
                    marginwidth="0"
                    marginheight="0"
                    scrolling="no"
                    frameborder="0"
                    src="//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=everfree98704-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4022506318&linkId=6ef01836d68d69bef3cd54349219bd08"
                  ></iframe>
                </Stack>
                <br />
                <br />
                配球の基本は、どんな大打者や好打者であってもツーストライクまで追い込んだら、打率が低くなるということ、この事実から、配球はどうやって2つストライクを取りにいくかを考えることが重要であるとのこと。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>ツーストライクをとればどんな打者でも打率は低くなる</b>
                </Typography>
                ただし、好打者は追い込むまでのカウント、打者有利のカウント、いわゆるボール先行であり空振りをしてもまだ打者にとっては余裕があるようなカウントでは、極めて高い打率を残すので、そういったカウントになるべくしないようにすることを考えると、本質的には初球にストライクをとることが大事であるとこの本では説いている。
                <br />
                <br />
                文章にすると当たり前のことのように感じられるが、これは例えば、よく強打者や好打者に対しての攻め方については、初球は変化球のボール球から入るというのは十分に考えられるパターンであるが、強打者や好打者であればあるほど、初球にボールから入るという考え方は打者有利の悪循環に引き込まれる可能性が高くなることを言っている。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    ツーストライクまで有利にことをすすめるため、初球は全力でストライクをとりにく
                  </b>
                </Typography>
                <br />
                そのため、初球は決め球と同等なくらい、良い球を使い積極的にストライクを取りに行くということも引き出しとしてもっておきたい。
                <br />
                <br />
                また、仮に初球から積極的に行く打者だとしても、ストライクギリギリのコースを狙い積極的にストライクを取りに行く。とにかく有利なカウントでツーストライクにするために、初球は積極的にストライクを取りに行くために捕手と投手は全力で考え、投球することが必要だということをこの本から学んだ。
                <br />
                <br />
                本を読む前は、初球の位置づけは情報がなく、入るのが難しく好打者、強打者の場合は反応をみる様子見という考えが基本だと思っていたが意識が変わった。
                <br />
                <br />
                もちろん状況にはより、前の打席の傾向やその打者の得意球の情報などのはなしがあればまた考え方は変わると思うが、基本的な考え方としては、ツーストライク追い込んでからどんな打者でも打率が下がるというデータから、初球をストライクを全力でとりにく。この考え方が基本なんだと感じた。
                <br />
                <br />
                過去の野茂英雄投手のインタビューで、「初球は低めのストレートでストライクをとりにいく」ということを基本にしているというようなこと聞いたことがあったが、経験則からくる発言、データに基づくものかは不明だが、通ずるものを感じた。
                <br />
                <br />
                「フルタの方程式」は配球論の他、キャッチング、フレーミングや盗塁阻止、試合前の準備、投手へのタイプ別の声がけなど、実際の古田敦也さんの経験からくるアドバイスが簡潔にしるされた（字も大きく）読みやすい本だった。
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <iframe
                  sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                  width={120}
                  height={240}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=everfree98704-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4022506318&linkId=6ef01836d68d69bef3cd54349219bd08"
                ></iframe>
              </Stack>

              {/* <Link variant="subtitle1" href="#">
             {post.linkText} 
              LinkText
            </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="row" justifyContent="center">
        <Box sx={{ marginTop: 2 }}>
          <img
            src={StrikeBoyImgPath}
            alt="ストライク坊や"
            width={130}
            height={90}
          />
        </Box>
      </Grid>
    </>
  );
}
