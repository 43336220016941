import React from "react";
import { Box, InputLabel } from "@mui/material";
import { Auth, NoteBody } from "./index";
import { useAuthenticator } from "@aws-amplify/ui-react";

export default function Note() {
  // ログイン状態の取得
  const { route } = useAuthenticator((context) => [context.route]);

  return (
    <Box sx={{ width: "100%" }}>
      {route !== "authenticated" && (
        <div id="noteAuthDisplay">
          {" "}
          <InputLabel
            variant="standard"
            sx={{
              color: "text.primary",
              fontSize: 14,
              fontWeight: "medium",
              textAlign: "left",
              marginTop: 2,
            }}
          >
            「配球を考える」ご利用の場合
          </InputLabel>
          <div id="noteAuthDisplay">
            {" "}
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 14,
                fontWeight: "medium",
                textAlign: "left",
                // margin: 2,
              }}
            >
              新規登録頂き、ログイン願います。
            </InputLabel>
          </div>
          <Auth />
        </div>
      )}
      {route === "authenticated" && <NoteBody />}
    </Box>
  );
}
