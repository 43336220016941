import { Box, Button, Stack, Container, Grid } from "@mui/material";
import { Link as LinkRouter } from "react-router-dom";
import CustomPageLabel from "../CustomPageLabel";
import HomeBaseImgPath from "../../assets/img/homebase_boss.png";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useEffect } from "react";

export function GameNoteMenu() {
  const [searchOwnerButtonFlag, setSearchOwnerButtonFlag] = useState(true);
  const [owner, setOwner] = useState("");

  // ログインしてない場合は、自分の配球のボタンは非活性状態にする
  useEffect(
    () => [
      Auth.currentAuthenticatedUser()
        .then((user) => {
          setOwner(user.attributes.sub);
        })
        .catch((err) => {
          setSearchOwnerButtonFlag(false);
        }),
    ],
    []
  );
  return (
    <Box>
      <CustomPageLabel label="試合を記録する" />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Button
              size="large"
              variant="outlined"
              sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
              component={LinkRouter}
              to={`/game/note/starting`}
            >
              最初から入力する
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Button
              size="large"
              variant="outlined"
              disabled={!searchOwnerButtonFlag}
              sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
              component={LinkRouter}
              to={`/game/note/continue`}
            >
              続きから入力する
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="column" alignItems="center" justifyContent="center">
        <Box sx={{ marginTop: 5 }}>
          <img
            src={HomeBaseImgPath}
            alt="ホムべっちょ先輩"
            width={130}
            height={90}
          />
        </Box>
      </Stack>
    </Box>
  );
}
