import {
  Box,
  CircularProgress,
  Link,
  Alert,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import { API, Auth } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomPageLabel from "../CustomPageLabel";
import YanoSanImgPath from "../../assets/img/yano_san.png";
import GetTwoImgPath from "../../assets/img/get_two_kun.png";

export function GameViewMenu() {
  const [data, setData] = useState();
  const [isError, setIsError] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [owner, setOwner] = useState("");
  const navigate = useNavigate();

  const fetchPrivateGames = async (user) => {
    try {
      const game = await API.graphql({
        query: queries.getPrivateGameByOwnerForGameFormat,
        variables: { owner: user },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setOwner(user);

      const sortedData =
        game.data.getPrivateGameByOwnerForGameFormat.items.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        );
      setData(sortedData);

      setIsError(false);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };

  useEffect(async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      if (!user) {
        setIsAuthError(true);
        navigate("/");
        return;
      }
    } catch (error) {
      console.log(error);
      setIsAuthError(true);
      navigate("/");
      return;
    }
    setIsAuthError(false);
    fetchPrivateGames(user.attributes.sub);
  }, []);

  return (
    <Box>
      <CustomPageLabel label="試合の記録を見る" />
      {!data && !isError ? (
        <CircularProgress />
      ) : isError ? (
        <>
          <Grid container direction="row" justifyContent="center">
            <Alert
              severity="error"
              onClose={() => {
                setIsError(false);
              }}
            >
              データ取得できませんでした。ネットワーク接続を確認してください。
            </Alert>
            <Box sx={{ margin: 2 }}>
              <img
                src={YanoSanImgPath}
                alt="矢野さん"
                width={130}
                height={90}
              />
            </Box>
          </Grid>
        </>
      ) : (
        <>
          {data.length === 0 ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ marginTop: 2 }}>試合形式での記録がまだありません</Box>
              <Button
                size="large"
                variant="outlined"
                sx={{ fontWeight: "600", width: "300px", marginTop: 2 }}
                component={LinkRouter}
                to={`/game/note/starting`}
              >
                試合形式で記録する
              </Button>

              <Box sx={{ marginTop: 2 }}>
                <img
                  src={GetTwoImgPath}
                  alt="ゲッツーくん"
                  width={180}
                  height={120}
                />
              </Box>
            </Stack>
          ) : (
            <Box>
              日程：タイトル
              {data.map((item, index) => (
                <div key={index}>
                  <Link href={`/game/view/${owner}/${item.id}`}>
                    {item.date}：{item.gameTitle}
                  </Link>
                </div>
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
