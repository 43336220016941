/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($pk: String!) {
    getUser(pk: $pk) {
      pk
      userName
      owner
      email
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $pk: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        userName
        owner
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrivateGame = /* GraphQL */ `
  query GetPrivateGame($id: ID!) {
    getPrivateGame(id: $id) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      privateMatches {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const listPrivateGames = /* GraphQL */ `
  query ListPrivateGames(
    $id: ID
    $filter: ModelPrivateGameFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateGames(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        privateMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getPrivateMatch = /* GraphQL */ `
  query GetPrivateMatch($id: ID!, $gameId: ID!) {
    getPrivateMatch(id: $id, gameId: $gameId) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      cases {
        items {
          id
          owner
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPrivateMatches = /* GraphQL */ `
  query ListPrivateMatches(
    $id: ID
    $gameId: ModelIDKeyConditionInput
    $filter: ModelPrivateMatchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateMatches(
      id: $id
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        title
        cases {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrivateCase = /* GraphQL */ `
  query GetPrivateCase($id: ID!) {
    getPrivateCase(id: $id) {
      id
      owner
      matchId
      sequences {
        items {
          id
          owner
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      dataAggrFlag
    }
  }
`;
export const listPrivateCases = /* GraphQL */ `
  query ListPrivateCases(
    $id: ID
    $filter: ModelPrivateCaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateCases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        matchId
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        pitcherTeamScore
        batterTeamScore
        createdAt
        updatedAt
        dataAggrFlag
      }
      nextToken
    }
  }
`;
export const getPrivateSequence = /* GraphQL */ `
  query GetPrivateSequence($id: ID!, $sequence: Int!) {
    getPrivateSequence(id: $id, sequence: $sequence) {
      id
      owner
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
    }
  }
`;
export const listPrivateSequences = /* GraphQL */ `
  query ListPrivateSequences(
    $id: ID
    $sequence: ModelIntKeyConditionInput
    $filter: ModelPrivateSequenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateSequences(
      id: $id
      sequence: $sequence
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        caseId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getS3AggrDataPointer = /* GraphQL */ `
  query GetS3AggrDataPointer($owner: String!) {
    getS3AggrDataPointer(owner: $owner) {
      owner
      latestAggrDataFileName
      createdAt
      updatedAt
    }
  }
`;
export const listS3AggrDataPointers = /* GraphQL */ `
  query ListS3AggrDataPointers(
    $owner: String
    $filter: ModelS3AggrDataPointerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listS3AggrDataPointers(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        latestAggrDataFileName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getS3AggrDataPointerForGameFormat = /* GraphQL */ `
  query GetS3AggrDataPointerForGameFormat($gameId: ID!) {
    getS3AggrDataPointerForGameFormat(gameId: $gameId) {
      owner
      gameId
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const listS3AggrDataPointerForGameFormats = /* GraphQL */ `
  query ListS3AggrDataPointerForGameFormats(
    $gameId: ID
    $filter: ModelS3AggrDataPointerForGameFormatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listS3AggrDataPointerForGameFormats(
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        gameId
        fileName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrivateGameForGameFormat = /* GraphQL */ `
  query GetPrivateGameForGameFormat($id: ID!) {
    getPrivateGameForGameFormat(id: $id) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      privateSituations {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          pitchInfo
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const listPrivateGameForGameFormats = /* GraphQL */ `
  query ListPrivateGameForGameFormats(
    $id: ID
    $filter: ModelPrivateGameForGameFormatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateGameForGameFormats(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        firstAttackTeam
        secondAttackTeam
        date
        privateSituations {
          nextToken
        }
        createdAt
        updatedAt
        type
        firstAttackMember
        secondAttackMember
        gameTitle
        registerFinish
      }
      nextToken
    }
  }
`;
export const getPrivateSituationForGameFormat = /* GraphQL */ `
  query GetPrivateSituationForGameFormat($id: ID!, $gameId: ID!) {
    getPrivateSituationForGameFormat(id: $id, gameId: $gameId) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      pitchInfo
    }
  }
`;
export const listPrivateSituationForGameFormats = /* GraphQL */ `
  query ListPrivateSituationForGameFormats(
    $id: ID
    $gameId: ModelIDKeyConditionInput
    $filter: ModelPrivateSituationForGameFormatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateSituationForGameFormats(
      id: $id
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        firstAttackTeamScore
        secondAttackTeamScore
        createdAt
        updatedAt
        dataAggrFlag
        registeredBatterOrder
        registerInningTopOrBottomFinishFlag
        registerBatterFinishFlag
        title
        memo
        detailsForNextBatter
        firstAttackMember
        secondAttackMember
        pitchInfo
      }
      nextToken
    }
  }
`;
export const getPrivateSequenceForGameFormat = /* GraphQL */ `
  query GetPrivateSequenceForGameFormat($id: ID!, $sequence: Int!) {
    getPrivateSequenceForGameFormat(id: $id, sequence: $sequence) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
export const listPrivateSequenceForGameFormats = /* GraphQL */ `
  query ListPrivateSequenceForGameFormats(
    $id: ID
    $sequence: ModelIntKeyConditionInput
    $filter: ModelPrivateSequenceForGameFormatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateSequenceForGameFormats(
      id: $id
      sequence: $sequence
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        situationId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        hittingDirectionImage
      }
      nextToken
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        userName
        owner
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrivateGameByOwner = /* GraphQL */ `
  query GetPrivateGameByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelprivateGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateGameByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        privateMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getPrivateGameForCreatedSort = /* GraphQL */ `
  query GetPrivateGameForCreatedSort(
    $type: String!
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelprivateGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateGameForCreatedSort(
      type: $type
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        privateMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getPrivateAllGameForUpdatedSort = /* GraphQL */ `
  query GetPrivateAllGameForUpdatedSort(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelprivateGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateAllGameForUpdatedSort(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        privateMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getPrivateMatchByGame = /* GraphQL */ `
  query GetPrivateMatchByGame(
    $gameId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelprivateMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateMatchByGame(
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        title
        cases {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrivateCaseByMatch = /* GraphQL */ `
  query GetPrivateCaseByMatch(
    $matchId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelprivateCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateCaseByMatch(
      matchId: $matchId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        matchId
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        pitcherTeamScore
        batterTeamScore
        createdAt
        updatedAt
        dataAggrFlag
      }
      nextToken
    }
  }
`;
export const getPrivateSequenceByPrivateCase = /* GraphQL */ `
  query GetPrivateSequenceByPrivateCase(
    $caseId: ID!
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelprivateSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateSequenceByPrivateCase(
      caseId: $caseId
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        caseId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrivateGameByOwnerForGameFormat = /* GraphQL */ `
  query GetPrivateGameByOwnerForGameFormat(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelprivateGameForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateGameByOwnerForGameFormat(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstAttackTeam
        secondAttackTeam
        date
        privateSituations {
          nextToken
        }
        createdAt
        updatedAt
        type
        firstAttackMember
        secondAttackMember
        gameTitle
        registerFinish
      }
      nextToken
    }
  }
`;
export const getPrivateAllGameForUpdatedSortForGameFormat = /* GraphQL */ `
  query GetPrivateAllGameForUpdatedSortForGameFormat(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelprivateGameForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateAllGameForUpdatedSortForGameFormat(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstAttackTeam
        secondAttackTeam
        date
        privateSituations {
          nextToken
        }
        createdAt
        updatedAt
        type
        firstAttackMember
        secondAttackMember
        gameTitle
        registerFinish
      }
      nextToken
    }
  }
`;
export const getPrivateMatchByGameForGameFormat = /* GraphQL */ `
  query GetPrivateMatchByGameForGameFormat(
    $gameId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelprivateSituationForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateMatchByGameForGameFormat(
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        firstAttackTeamScore
        secondAttackTeamScore
        createdAt
        updatedAt
        dataAggrFlag
        registeredBatterOrder
        registerInningTopOrBottomFinishFlag
        registerBatterFinishFlag
        title
        memo
        detailsForNextBatter
        firstAttackMember
        secondAttackMember
        pitchInfo
      }
      nextToken
    }
  }
`;
export const getPrivateSequenceByOwnerForGameFormat = /* GraphQL */ `
  query GetPrivateSequenceByOwnerForGameFormat(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelprivateSequenceForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateSequenceByOwnerForGameFormat(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        situationId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        hittingDirectionImage
      }
      nextToken
    }
  }
`;
export const getPrivateSequenceBySituationForGameFormat = /* GraphQL */ `
  query GetPrivateSequenceBySituationForGameFormat(
    $situationId: ID!
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelprivateSequenceForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPrivateSequenceBySituationForGameFormat(
      situationId: $situationId
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        situationId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        hittingDirectionImage
      }
      nextToken
    }
  }
`;
export const getPublicGame = /* GraphQL */ `
  query GetPublicGame($id: ID!) {
    getPublicGame(id: $id) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      publicMatches {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          limitedDisclosure
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const listPublicGames = /* GraphQL */ `
  query ListPublicGames(
    $id: ID
    $filter: ModelPublicGameFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicGames(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        publicMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getPublicGameByOwner = /* GraphQL */ `
  query GetPublicGameByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicGameByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        publicMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getPublicGameForCreatedSort = /* GraphQL */ `
  query GetPublicGameForCreatedSort(
    $type: String!
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpublicGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicGameForCreatedSort(
      type: $type
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        publicMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const publicAllGameForUpdatedSort = /* GraphQL */ `
  query PublicAllGameForUpdatedSort(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpublicGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicAllGameForUpdatedSort(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        pitcherTeam
        batterTeam
        date
        publicMatches {
          nextToken
        }
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getPublicMatch = /* GraphQL */ `
  query GetPublicMatch($id: ID!, $gameId: ID!) {
    getPublicMatch(id: $id, gameId: $gameId) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      limitedDisclosure
      cases {
        items {
          id
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPublicMatches = /* GraphQL */ `
  query ListPublicMatches(
    $id: ID
    $gameId: ModelIDKeyConditionInput
    $filter: ModelPublicMatchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicMatches(
      id: $id
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        title
        limitedDisclosure
        cases {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPublicMatchByGame = /* GraphQL */ `
  query GetPublicMatchByGame(
    $gameId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicMatchByGame(
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        title
        limitedDisclosure
        cases {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPublicCase = /* GraphQL */ `
  query GetPublicCase($id: ID!) {
    getPublicCase(id: $id) {
      id
      matchId
      sequences {
        items {
          id
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      comments {
        items {
          id
          caseId
          name
          comment
          owner
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      dataAggrFlag
      owner
    }
  }
`;
export const listPublicCases = /* GraphQL */ `
  query ListPublicCases(
    $id: ID
    $filter: ModelPublicCaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicCases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        matchId
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        pitcherTeamScore
        batterTeamScore
        createdAt
        updatedAt
        comments {
          nextToken
        }
        dataAggrFlag
        owner
      }
      nextToken
    }
  }
`;
export const getPublicCaseByPublicMatch = /* GraphQL */ `
  query GetPublicCaseByPublicMatch(
    $matchId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicCaseByPublicMatch(
      matchId: $matchId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        matchId
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        pitcherTeamScore
        batterTeamScore
        createdAt
        updatedAt
        comments {
          nextToken
        }
        dataAggrFlag
        owner
      }
      nextToken
    }
  }
`;
export const getPublicComment = /* GraphQL */ `
  query GetPublicComment($id: ID!) {
    getPublicComment(id: $id) {
      id
      caseId
      name
      comment
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const listPublicComments = /* GraphQL */ `
  query ListPublicComments(
    $id: ID
    $filter: ModelPublicCommentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicComments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        caseId
        name
        comment
        owner
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPublicCommentByCase = /* GraphQL */ `
  query GetPublicCommentByCase(
    $caseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicCommentByCase(
      caseId: $caseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        name
        comment
        owner
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPublicCommentSortDirectionByUpdatedAt = /* GraphQL */ `
  query GetPublicCommentSortDirectionByUpdatedAt(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpublicCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicCommentSortDirectionByUpdatedAt(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        name
        comment
        owner
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPublicSequence = /* GraphQL */ `
  query GetPublicSequence($id: ID!, $sequence: Int!) {
    getPublicSequence(id: $id, sequence: $sequence) {
      id
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPublicSequences = /* GraphQL */ `
  query ListPublicSequences(
    $id: ID
    $sequence: ModelIntKeyConditionInput
    $filter: ModelPublicSequenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicSequences(
      id: $id
      sequence: $sequence
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        caseId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPublicSequenceByPublicCase = /* GraphQL */ `
  query GetPublicSequenceByPublicCase(
    $caseId: ID!
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpublicSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicSequenceByPublicCase(
      caseId: $caseId
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPublicSequenceByAtBatResult = /* GraphQL */ `
  query GetPublicSequenceByAtBatResult(
    $atBatResult: String!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicSequenceByAtBatResult(
      atBatResult: $atBatResult
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPublicGameForGameFormat = /* GraphQL */ `
  query GetPublicGameForGameFormat($id: ID!) {
    getPublicGameForGameFormat(id: $id) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      publicSituations {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const listPublicGameForGameFormats = /* GraphQL */ `
  query ListPublicGameForGameFormats(
    $id: ID
    $filter: ModelPublicGameForGameFormatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicGameForGameFormats(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        firstAttackTeam
        secondAttackTeam
        date
        publicSituations {
          nextToken
        }
        createdAt
        updatedAt
        type
        firstAttackMember
        secondAttackMember
        gameTitle
        registerFinish
      }
      nextToken
    }
  }
`;
export const getPublicGameForGameFormatByOwner = /* GraphQL */ `
  query GetPublicGameForGameFormatByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicGameForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicGameForGameFormatByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstAttackTeam
        secondAttackTeam
        date
        publicSituations {
          nextToken
        }
        createdAt
        updatedAt
        type
        firstAttackMember
        secondAttackMember
        gameTitle
        registerFinish
      }
      nextToken
    }
  }
`;
export const publicAllGameForUpdatedSortForGameFormat = /* GraphQL */ `
  query PublicAllGameForUpdatedSortForGameFormat(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpublicGameForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicAllGameForUpdatedSortForGameFormat(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstAttackTeam
        secondAttackTeam
        date
        publicSituations {
          nextToken
        }
        createdAt
        updatedAt
        type
        firstAttackMember
        secondAttackMember
        gameTitle
        registerFinish
      }
      nextToken
    }
  }
`;
export const getPublicSituationForGameFormat = /* GraphQL */ `
  query GetPublicSituationForGameFormat($id: ID!, $gameId: ID!) {
    getPublicSituationForGameFormat(id: $id, gameId: $gameId) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      owner
    }
  }
`;
export const listPublicSituationForGameFormats = /* GraphQL */ `
  query ListPublicSituationForGameFormats(
    $id: ID
    $gameId: ModelIDKeyConditionInput
    $filter: ModelPublicSituationForGameFormatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicSituationForGameFormats(
      id: $id
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        firstAttackTeamScore
        secondAttackTeamScore
        createdAt
        updatedAt
        dataAggrFlag
        registeredBatterOrder
        registerInningTopOrBottomFinishFlag
        registerBatterFinishFlag
        title
        memo
        detailsForNextBatter
        firstAttackMember
        secondAttackMember
        owner
      }
      nextToken
    }
  }
`;
export const getPublicMatchByGameForGameFormat = /* GraphQL */ `
  query GetPublicMatchByGameForGameFormat(
    $gameId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicSituationForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicMatchByGameForGameFormat(
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gameId
        batter
        bat
        atBats
        pitcher
        throw
        sequences {
          nextToken
        }
        inning
        topOrBottom
        outCounts
        runner
        firstAttackTeamScore
        secondAttackTeamScore
        createdAt
        updatedAt
        dataAggrFlag
        registeredBatterOrder
        registerInningTopOrBottomFinishFlag
        registerBatterFinishFlag
        title
        memo
        detailsForNextBatter
        firstAttackMember
        secondAttackMember
        owner
      }
      nextToken
    }
  }
`;
export const getPublicSequenceForGameFormat = /* GraphQL */ `
  query GetPublicSequenceForGameFormat($id: ID!, $sequence: Int!) {
    getPublicSequenceForGameFormat(id: $id, sequence: $sequence) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
export const listPublicSequenceForGameFormats = /* GraphQL */ `
  query ListPublicSequenceForGameFormats(
    $id: ID
    $sequence: ModelIntKeyConditionInput
    $filter: ModelPublicSequenceForGameFormatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublicSequenceForGameFormats(
      id: $id
      sequence: $sequence
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        situationId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        hittingDirectionImage
      }
      nextToken
    }
  }
`;
export const getPublicSequenceByOwnerForGameFormat = /* GraphQL */ `
  query GetPublicSequenceByOwnerForGameFormat(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicSequenceForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicSequenceByOwnerForGameFormat(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        situationId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        hittingDirectionImage
      }
      nextToken
    }
  }
`;
export const getPublicSequenceBySituationForGameFormat = /* GraphQL */ `
  query GetPublicSequenceBySituationForGameFormat(
    $situationId: ID!
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpublicSequenceForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicSequenceBySituationForGameFormat(
      situationId: $situationId
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        situationId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        hittingDirectionImage
      }
      nextToken
    }
  }
`;
export const getPublicSequenceByAtBatResultForGameFormat = /* GraphQL */ `
  query GetPublicSequenceByAtBatResultForGameFormat(
    $atBatResult: String!
    $sortDirection: ModelSortDirection
    $filter: ModelpublicSequenceForGameFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicSequenceByAtBatResultForGameFormat(
      atBatResult: $atBatResult
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        situationId
        sequence
        strikes
        balls
        speed
        pitchResult
        atBatResult
        pitchXPosition
        pitchYPosition
        breakingBall
        memo
        createdAt
        updatedAt
        hittingDirectionImage
      }
      nextToken
    }
  }
`;
