import TextInput from "./Forms/TextInput";
import { useCallback, useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
} from "@mui/material";
import { InputNoteContext } from "./NoteBody";
import { useContext } from "react";

const BatterInput = () => {
  const [bat, setBat] = useState("");
  const [atBats, setAtBats] = useState(null);

  // 入力データを保存するuseStateを取り出す
  const { inputs, setInputs } = useContext(InputNoteContext);

  const inputBatter = useCallback(
    (event) => {
      setInputs({ ...inputs, batter: event.target.value });
    },
    [inputs]
  );

  const inputBatterTeam = useCallback(
    (event) => {
      setInputs({ ...inputs, batterTeam: event.target.value });
    },
    [inputs]
  );

  const selectBat = useCallback(
    (event) => {
      setBat(event.target.value);

      // batを更新してすぐの状態を取得しなおして更新
      setBat((prevState) => {
        setInputs({ ...inputs, bat: prevState });
      });
    },
    [inputs, bat]
  );

  const selectAtBats = useCallback(
    (event) => {
      setAtBats(event.target.value);
      setAtBats((prevState) => {
        setInputs({ ...inputs, atBats: prevState });
      });
    },
    [inputs, atBats]
  );

  return (
    <div className="situationInputs">
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          <TextInput
            fullWidth={true}
            label={"打者チーム名"}
            multiline={false}
            rows={1}
            type={"text"}
            value={inputs.batterTeam}
            onChange={inputBatterTeam}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          <TextInput
            fullWidth={true}
            label={"打者名"}
            multiline={false}
            rows={1}
            type={"text"}
            value={inputs.batter}
            onChange={inputBatter}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          {/* <Box sx={{ minWidth: 120 }}> */}
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-bat-label">打ち方</InputLabel>
              <Select
                labelId="select-bat-label"
                // id="select-bat"
                value={inputs.bat}
                fullWidth
                label="打ち方"
                onChange={selectBat}
              >
                <MenuItem value="right">右打ち</MenuItem>
                <MenuItem value="left">左打ち</MenuItem>
                {/* <MenuItem value="both">両打ち</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="textInput">
          {/* <Box sx={{ minWidth: 120 }}> */}
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-atbats-label">何打席目</InputLabel>
              <Select
                labelId="select-atbats-label"
                // id="select-atbats"
                value={inputs.atBats}
                fullWidth
                label="何打席目"
                onChange={selectAtBats}
              >
                <MenuItem value={1}>1打席目</MenuItem>
                <MenuItem value={2}>2打席目</MenuItem>
                <MenuItem value={3}>3打席目</MenuItem>
                <MenuItem value={4}>4打席目</MenuItem>
                <MenuItem value={5}>5打席目</MenuItem>
                <MenuItem value={6}>6打席目</MenuItem>
                <MenuItem value={7}>7打席目</MenuItem>
                <MenuItem value={8}>8打席目</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Grid>
    </div>
  );
};

export default BatterInput;
