import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import StrikeBoyImgPath from "../../assets/img/strike_boy.png";

export function Article20230208() {
  return (
    <>
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "white",
          color: "black",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: `url(${post.image})`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,255,0.1)",
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 6, md: 6, sm: 6 },
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                gutterBottom
              >
                {/* {post.title} */}
                打者の裏をかく配球、リードとは
              </Typography>
              <Typography variant="body2" color="inherit" paragraph>
                {/* {post.description} */}
                <br />
                <br />
                配球の一番の醍醐味のひとつといえる裏をかく配球、リードを考えてみたい。
                <br />
                <br />
                これはそもそも、打者が配球について考えている打者かをまずは見極める必要がある。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    まずは配球のことを考えているタイプの打者であることが前提
                  </b>
                </Typography>
                <br />
                配球について考えておらず、来た球を単純に打ち返すだけの打者だったり、打席を通してある特定の球種に絞り込んでいる場合は、１球１球に想定がないため、裏をかくということそのものが存在しない。
                <br />
                <br />
                打者がどういうタイプなのかを見極める方法は、別で考えるとして、ある種のシーケンスを考える打者を相手に裏をかく配球、リードを考えてみる。
                <br />
                <br />
                裏をかく配球とは基本的に、打者が想定していなかった球種、コースの球を選びとることだと思う。
                <br />
                <br />
                打者がある種前提としているものから反する球を選ぶこととなる。具体的にそれはなにか。
                <br />
                <br />
                まず考えられるのは、通常よくあるセオリー的な配球とは逆をいくような配球だ。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>1. セオリーと逆の配球、リードをする</b>
                </Typography>
                <br />
                例えば、強打者で慎重な配球をするのであれば、様子見で打つ気をそらすような緩い変化球、ストライクからボールに外れるような変化球、あるいは外郭の際どいストレートから入ったりといったことが考えられるが、それとは逆に思い切って内角のストレートでストライクをとりにく。
                <br />
                <br />
                はたまた、それほどでもない打力の打者、下位の打者に対してはストレート主体で追い込んで、最後変化球を決め球とするのは、ベーシックな配球かと思うが、そのような打者に対して、ピンチの場面では変化球主体でせめて、最後に速球で仕留めるなど。
                <br />
                <br />
                さらには、なんらかの手段で２球で追い込んだ場合に、通常はストライクからボールに外れるような変化球や高めの釣り球のストレートなど、ボール球でじっくりせめていくパターンが多いと思うが、
                そこのところを逆に、思い切ってインコースにストレートを投げ込むといったようなセオリー的な配球と逆をいく配球、リードをする。
                <br />
                <br />
                場面や打者の考えを幻惑させるために、投手のコントロールや調子が良ければ、はさむことを考えて良いのではないか。
                <br />
                <br />
                次に裏をかくこととして考えられる２つ目は前の打席やこれまでの打者の配球と明らかに異なるパターンの配球、リードをすること。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    2.
                    前の打席やこれまでの打者の配球と異なるパターンの配球、リードをする
                  </b>
                </Typography>
                <br />
                ピンチの場面などは特にそのようなことを心がけて良いかと思うし、そうでなくても１順目、２順目、３順目と打者は球に慣れてくるので、その投手個々にあるそれまでせめていたセオリーから、回を追うごとには配球を変えることも裏をかくことになると思う。打者の能力が高ければなおさら、単調にならず回を追うごとに変化をつけたい。
                <br />
                <br />
                基本的に、
                <b>
                  配球は打者にどの球種がどのコースに来るのか、迷わせること、球種やコースを1種類以上意識させるようにすることが基本的な考え方かと思う
                </b>
                。
                <br />
                <br />
                球そのもので勝負できれば一番だが、そういった意味では、ピンチの場面など、前の打席やこれまでの打者の配球と明らかに異なるパターンの配球を心がけることは裏をかくというより基本的なセオリーなのかもしれない。
                <br />
                <br />
                裏をかくこととの3つとして、投手がその日にまだ投げてない球を投げるというのがあるかと思う。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>3. 投手がその日にまだ投げてない球を投げる</b>
                </Typography>
                <br />
                これはプロ野球でもよくみられる。これまた特にピンチの場面で相手が強打者で追い込んだあとに、その日にまだ投手が１球も投げていない想定できるわけがない球を決め球として使う。
                <br />
                <br />
                それまでストレート、スライダーを主体としていたが、チェンジアップといった球質も異なるような球が投げられるとよりその効果はさらに増すと考えられる。
                <br />
                <br />
                投げていないので想定もできないし、体感としても経験していないので打者としては打ちづらいはずだ。
                <br />
                <br />
                裏をかくこととの4つめとして、その打者が得意な球を逆に投げるというのもなくはないと思う。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>4. その打者が得意な球を逆に投げる</b>
                </Typography>
                <br />
                リスクの高い裏のかきかたであるため、使うときの条件はあると思う。
                <br />
                <br />
                例えば、ストレートに強い打者の３打席目とかで、前の２打席とも変化球主体でせめており、ストレートは見せ球、ボールになるような球で、それまでの打席でしっかり変化球主体の配球であるということを意識付けを行った上では、ひとつの選択肢となりえるのではないか。
                <br />
                <br />
                また、配球としても、変化球で追い込んだあとの３球勝負や変化球で追い込んでストレートで見せ球を投げたあとに内角にストレートなど、セオリー的なもの、その投手のセオリー、基本としている配球とは異なるような要素も合わせて混ぜ込んだ配球としたい。
                <br />
                <br />
                リスクが高いのであまりみられない裏のかきかたかもしれないが、このような打ち取り方はときおりみられる。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    5. その投手を象徴するような得意な球ではない球を逆に投げる
                  </b>
                </Typography>
                <br />
                ５つめに、投手にもし得意な球、象徴するような球、要はその球そのもので打者と勝負できるような球がある場合、配球と関係なく、その球が主体で勝負することとなると思う。
                <br />
                <br />
                そのような球があった際に、以下の条件のときは選択肢として考えられないか。
                <ul>
                  <li>回をまして打者が投手の得意な球になれつつある</li>
                  <li>投手が疲れてきてその得意な球も質が落ちてきた</li>
                  <li>好打者である</li>
                  <li>ピンチで打者の意表を突きたい</li>
                </ul>
                上記のような条件が重なった上で、さらにもう絶対にあの得意球をまた投げるであろうと、意識付けされた配球、場面で、その球と異なる球を投げるというのは選択肢としてとり得ると思う。
                <br />
                <br />
                最後６つ目に、超スローボールのようなボールそのもので打者の意表を付く球を投げる
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    6.
                    超スローボールのようなボールそのもので意表を突くような球を投げる。
                  </b>
                </Typography>
                これは使い方としては、「3.
                投手がその日にまだ投げてない球を投げる」と同等の狙いであり、その日に投げている球も少ないもので、想定しづらく、体感としても経験していないので打ちづらいはずだ。
              </Typography>
              {/* <Link variant="subtitle1" href="#">
             {post.linkText} 
              LinkText
            </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="row" justifyContent="center">
        <Box sx={{ marginTop: 2 }}>
          <img
            src={StrikeBoyImgPath}
            alt="ストライク坊や"
            width={130}
            height={90}
          />
        </Box>
      </Grid>
    </>
  );
}
