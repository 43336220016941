import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Stack,
  Button,
  Alert,
} from "@mui/material";
import TextInput from "../Forms/TextInput";
import { API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ChangeMember(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [firstMember, setFirstMember] = React.useState({
    "1番打者": { name: "", position: "", bat: "right" },
    "2番打者": { name: "", position: "", bat: "right" },
    "3番打者": { name: "", position: "", bat: "right" },
    "4番打者": { name: "", position: "", bat: "right" },
    "5番打者": { name: "", position: "", bat: "right" },
    "6番打者": { name: "", position: "", bat: "right" },
    "7番打者": { name: "", position: "", bat: "right" },
    "8番打者": { name: "", position: "", bat: "right" },
    "9番打者": { name: "", position: "", bat: "right" },
    投手: { name: "", position: 1, throw: "right" },
  });

  const [secondMember, setSecondMember] = React.useState({
    "1番打者": { name: "", position: "", bat: "right" },
    "2番打者": { name: "", position: "", bat: "right" },
    "3番打者": { name: "", position: "", bat: "right" },
    "4番打者": { name: "", position: "", bat: "right" },
    "5番打者": { name: "", position: "", bat: "right" },
    "6番打者": { name: "", position: "", bat: "right" },
    "7番打者": { name: "", position: "", bat: "right" },
    "8番打者": { name: "", position: "", bat: "right" },
    "9番打者": { name: "", position: "", bat: "right" },
    投手: { name: "", position: 1, throw: "right" },
  });

  // propsでもってきた各メンバーの最新の情報だけをとりだしてusestateの変数に1-9,投手の情報をコピーする
  // ここの情報を記入欄のvalueにいれるようにする
  React.useEffect(() => {
    let firstMemberTmp = JSON.parse(JSON.stringify(firstMember));
    for (let i = 0; i < 9; i++) {
      firstMemberTmp[`${i + 1}番打者`] =
        props.firstAttackMember[`${i + 1}番打者`][
          props.firstAttackMember[`${i + 1}番打者`].length - 1
        ];
    }
    firstMemberTmp["投手"] =
      props.firstAttackMember["投手"][
        props.firstAttackMember["投手"].length - 1
      ];
    setFirstMember(firstMemberTmp);

    let secondMemberTmp = JSON.parse(JSON.stringify(secondMember));
    for (let i = 0; i < 9; i++) {
      secondMemberTmp[`${i + 1}番打者`] =
        props.secondAttackMember[`${i + 1}番打者`][
          props.secondAttackMember[`${i + 1}番打者`].length - 1
        ];
    }
    secondMemberTmp["投手"] =
      props.secondAttackMember["投手"][
        props.secondAttackMember["投手"].length - 1
      ];
    setSecondMember(secondMemberTmp);
  }, []);

  const [DH, setDH] = React.useState(false);

  React.useEffect(() => {
    setDH(false);
    for (let i = 0; i < 9; i++) {
      if (firstMember[`${i + 1}番打者`].position === 10) {
        setDH(true);
        break;
      }
    }
    for (let i = 0; i < 9; i++) {
      if (secondMember[`${i + 1}番打者`].position === 10) {
        setDH(true);
        break;
      }
    }
  }, [firstMember, secondMember]);

  // onchangeでメンバー名が変更されたら、その都度、usestate内の各プロパティに格納して、setする
  const inputMember = (number, firstOrSecond, e) => {
    if (firstOrSecond == "first") {
      let firstMemberTmp = JSON.parse(JSON.stringify(firstMember));
      firstMemberTmp[`${number}番打者`].name = e.target.value;
      setFirstMember(firstMemberTmp);
    } else {
      let secondMemberTmp = JSON.parse(JSON.stringify(secondMember));
      secondMemberTmp[`${number}番打者`].name = e.target.value;
      setSecondMember(secondMemberTmp);
    }
  };

  const inputPitchMember = (firstOrSecond, e) => {
    if (firstOrSecond == "first") {
      let firstMemberTmp = JSON.parse(JSON.stringify(firstMember));
      firstMemberTmp["投手"].name = e.target.value;
      setFirstMember(firstMemberTmp);
    } else {
      let secondMemberTmp = JSON.parse(JSON.stringify(secondMember));
      secondMemberTmp["投手"].name = e.target.value;
      setSecondMember(secondMemberTmp);
    }
  };

  // selectも同様
  const inputMemberPosition = (number, firstOrSecond, e) => {
    if (firstOrSecond == "first") {
      let firstMemberTmp = JSON.parse(JSON.stringify(firstMember));
      firstMemberTmp[`${number}番打者`].position = e.target.value;
      setFirstMember(firstMemberTmp);
    } else {
      let secondMemberTmp = JSON.parse(JSON.stringify(secondMember));
      secondMemberTmp[`${number}番打者`].position = e.target.value;
      setSecondMember(secondMemberTmp);
    }
  };

  // 投手交代があったときにpitchInfoに投手情報を追加する
  const changePitchInfo = (pitchName) => {
    if (props.pitchInfo == null) {
      return;
    }

    let newPitchInfoObject;
    if (typeof props.pitchInfo === "string") {
      newPitchInfoObject = JSON.parse(
        JSON.stringify(JSON.parse(props.pitchInfo))
      );
    } else {
      newPitchInfoObject = JSON.parse(JSON.stringify(props.pitchInfo));
    }

    if (props.topOrBottom === "bottom") {
      newPitchInfoObject.firstAttackPitchInfo.push({
        name: pitchName,
        pitchesNumber: 0,
      });
    } else {
      newPitchInfoObject.secondAttackPitchInfo.push({
        name: pitchName,
        pitchesNumber: 0,
      });
    }

    return newPitchInfoObject;
  };

  // 登録ボタンを押すと、差異があるものだけ、propsでもってきた各メンバー情報にpushして、まるっとgameのテーブルに更新をかける
  const changeMembers = () => {
    setIsLoading(true);

    let firstAttackMember = JSON.parse(JSON.stringify(props.firstAttackMember));
    let secondAttackMember = JSON.parse(
      JSON.stringify(props.secondAttackMember)
    );

    let pitchInfo;
    if (typeof pitchInfo === "string") {
      pitchInfo = JSON.parse(JSON.stringify(JSON.parse(props.pitchInfo)));
    } else {
      pitchInfo = JSON.parse(JSON.stringify(props.pitchInfo));
    }

    for (let i = 1; i < 10; i++) {
      const firstMemInfo = JSON.stringify(
        firstAttackMember[`${i}番打者`][
          firstAttackMember[`${i}番打者`].length - 1
        ]
      );
      if (firstMemInfo !== JSON.stringify(firstMember[`${i}番打者`])) {
        firstAttackMember[`${i}番打者`].push(firstMember[`${i}番打者`]);
      }

      const secondMemInfo = JSON.stringify(
        secondAttackMember[`${i}番打者`][
          secondAttackMember[`${i}番打者`].length - 1
        ]
      );
      if (secondMemInfo !== JSON.stringify(secondMember[`${i}番打者`])) {
        secondAttackMember[`${i}番打者`].push(secondMember[`${i}番打者`]);
      }
    }

    const firstPitInfo = JSON.stringify(
      firstAttackMember[`投手`][firstAttackMember[`投手`].length - 1]
    );
    if (firstPitInfo !== JSON.stringify(firstMember[`投手`])) {
      firstAttackMember[`投手`].push(firstMember[`投手`]);

      pitchInfo = changePitchInfo(firstMember[`投手`].name);
    }

    const secondPitInfo = JSON.stringify(
      secondAttackMember[`投手`][secondAttackMember[`投手`].length - 1]
    );
    if (secondPitInfo !== JSON.stringify(secondMember[`投手`])) {
      secondAttackMember[`投手`].push(secondMember[`投手`]);

      pitchInfo = changePitchInfo(secondMember[`投手`].name);
    }

    // 他のポジションの人がピッチャーとなったときに、投手名をその人に更新する
    for (let i = 1; i < 10; i++) {
      if (
        firstMember[`${i}番打者`].position === 1 &&
        firstAttackMember[`投手`][firstAttackMember[`投手`].length - 1].name !==
          firstMember[`${i}番打者`].name
      ) {
        firstAttackMember[`投手`].push({
          throw: "",
          name: firstMember[`${i}番打者`].name,
          position: 1,
        });
        pitchInfo = changePitchInfo(firstMember[`${i}番打者`].name);
      }
    }

    for (let i = 1; i < 10; i++) {
      if (
        secondMember[`${i}番打者`].position === 1 &&
        secondAttackMember[`投手`][secondAttackMember[`投手`].length - 1]
          .name !== secondMember[`${i}番打者`].name
      ) {
        secondAttackMember[`投手`].push({
          throw: "",
          name: secondMember[`${i}番打者`].name,
          position: 1,
        });
        pitchInfo = changePitchInfo(secondMember[`${i}番打者`].name);
      }
    }

    // DHフラグがたってて投げの欄が入力されてなければ、投をさがして名前を入れておいてあげる
    if (!firstAttackMember["投手"][firstAttackMember["投手"].length - 1].name) {
      for (let i = 1; i < 9; i++) {
        if (
          firstAttackMember[`${i}番打者`][
            firstAttackMember[`${i}番打者`].length - 1
          ].position === 1
        ) {
          firstAttackMember["投手"][firstAttackMember["投手"].length - 1].name =
            firstAttackMember[`${i}番打者`][
              firstAttackMember[`${i}番打者`].length - 1
            ].name;
        }
      }
    }
    if (
      !secondAttackMember["投手"][secondAttackMember["投手"].length - 1].name
    ) {
      for (let i = 1; i < 9; i++) {
        if (
          secondAttackMember[`${i}番打者`][
            secondAttackMember[`${i}番打者`].length - 1
          ].position === 1
        ) {
          secondAttackMember["投手"][
            secondAttackMember["投手"].length - 1
          ].name =
            secondAttackMember[`${i}番打者`][
              secondAttackMember[`${i}番打者`].length - 1
            ].name;
        }
      }
    }

    return {
      firstAttackMember,
      secondAttackMember,
      pitchInfo,
    };
  };

  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // 登録してgameを再フェッチする
  const registerChangeMembers = async () => {
    const { firstAttackMember, secondAttackMember, pitchInfo } =
      changeMembers();

    let newPitchInfoObject;
    if (typeof pitchInfo === "string") {
      newPitchInfoObject = JSON.parse(JSON.stringify(JSON.parse(pitchInfo)));
    } else {
      newPitchInfoObject = JSON.parse(JSON.stringify(pitchInfo));
    }

    props.setFirstAttackMember(firstAttackMember);
    props.setSecondAttackMember(secondAttackMember);

    //DynamoDb更新
    const inputUpdateGame = {
      id: props.gameId,
      firstAttackMember: JSON.stringify(firstAttackMember),
      owner: props.owner,
      secondAttackMember: JSON.stringify(secondAttackMember),
    };
    const inputUpdateSituation = {
      id: props.latestSituationId,
      gameId: props.gameId,
      owner: props.owner,
      pitchInfo: JSON.stringify(newPitchInfoObject),
    };

    try {
      await API.graphql({
        query: mutations.updatePrivateGameForGameFormat,
        variables: { input: inputUpdateGame },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      await API.graphql({
        query: mutations.updatePrivateSituationForGameFormat,
        variables: { input: inputUpdateSituation },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      await props.fetchGame();
      setIsError(false);
      setIsLoading(false);
      props.handleCloseModalChangeMember();
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {isError && (
          <Alert
            severity="error"
            onClose={() => {
              setIsError(false);
            }}
          >
            変更に失敗しました。ネットワーク接続を確認してください。
          </Alert>
        )}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={props.firstAttackTeam ? props.firstAttackTeam : "先攻"}
              {...a11yProps(0)}
            />
            <Tab
              label={props.secondAttackTeam ? props.secondAttackTeam : "後攻"}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid container>
            {Array(9)
              .fill()
              .map((_, i) => (
                <>
                  <Grid item xs={1} sm={1}>
                    {i + 1}
                  </Grid>
                  <Grid item xs={7} sm={7}>
                    <Box>
                      <TextInput
                        fullWidth={true}
                        // label={props.label}
                        multiline={false}
                        rows={1}
                        type={"text"}
                        size="small"
                        value={firstMember[`${i + 1}番打者`].name}
                        onChange={inputMember.bind(null, i + 1, "first")}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <Box>
                      <FormControl fullWidth>
                        {/* <InputLabel id="select-defensive-position-label">
                        守備
                      </InputLabel> */}
                        <Select
                          labelId="select-defensive-position-label"
                          // id="select-bat"
                          fullWidth
                          size="small"
                          onChange={inputMemberPosition.bind(
                            null,
                            i + 1,
                            "first"
                          )}
                          value={firstMember[`${i + 1}番打者`].position}
                        >
                          <MenuItem value={0}>　</MenuItem>
                          <MenuItem value={1}>投</MenuItem>
                          <MenuItem value={2}>捕</MenuItem>
                          <MenuItem value={3}>一</MenuItem>
                          <MenuItem value={4}>二</MenuItem>
                          <MenuItem value={5}>三</MenuItem>
                          <MenuItem value={6}>遊</MenuItem>
                          <MenuItem value={7}>左</MenuItem>
                          <MenuItem value={8}>中</MenuItem>
                          <MenuItem value={9}>右</MenuItem>
                          <MenuItem value={10}>DH</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </>
              ))}
          </Grid>
          {DH && (
            <Grid container>
              <Grid item xs={1} sm={1}>
                投
              </Grid>
              <Grid item xs={7} sm={7}>
                <Box>
                  <TextInput
                    fullWidth={true}
                    // label={props.label}
                    multiline={false}
                    rows={1}
                    type={"text"}
                    size="small"
                    value={firstMember[`投手`].name}
                    onChange={inputPitchMember.bind(null, "first")}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid container>
            {Array(9)
              .fill()
              .map((_, i) => (
                <>
                  <Grid item xs={1} sm={1}>
                    {i + 1}
                  </Grid>
                  <Grid item xs={7} sm={7}>
                    <Box>
                      <TextInput
                        fullWidth={true}
                        // label={props.label}
                        multiline={false}
                        rows={1}
                        type={"text"}
                        size="small"
                        value={secondMember[`${i + 1}番打者`].name}
                        onChange={inputMember.bind(null, i + 1, "second")}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <Box>
                      <FormControl fullWidth>
                        {/* <InputLabel id="select-defensive-position-label">
                        守備
                      </InputLabel> */}
                        <Select
                          labelId="select-defensive-position-label"
                          // id="select-bat"
                          fullWidth
                          size="small"
                          onChange={inputMemberPosition.bind(
                            null,
                            i + 1,
                            "second"
                          )}
                          value={secondMember[`${i + 1}番打者`].position}
                        >
                          <MenuItem value={0}>　</MenuItem>
                          <MenuItem value={1}>投</MenuItem>
                          <MenuItem value={2}>捕</MenuItem>
                          <MenuItem value={3}>一</MenuItem>
                          <MenuItem value={4}>二</MenuItem>
                          <MenuItem value={5}>三</MenuItem>
                          <MenuItem value={6}>遊</MenuItem>
                          <MenuItem value={7}>左</MenuItem>
                          <MenuItem value={8}>中</MenuItem>
                          <MenuItem value={9}>右</MenuItem>
                          <MenuItem value={10}>DH</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </>
              ))}
          </Grid>
          {DH && (
            <Grid container>
              <Grid item xs={1} sm={1}>
                投
              </Grid>
              <Grid item xs={7} sm={7}>
                <Box>
                  <TextInput
                    fullWidth={true}
                    // label={props.label}
                    multiline={false}
                    rows={1}
                    type={"text"}
                    size="small"
                    value={secondMember[`投手`].name}
                    onChange={inputPitchMember.bind(null, "second")}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </CustomTabPanel>
        {isLoading && <CircularProgress />}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mb: 2 }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={registerChangeMembers}
          >
            変更
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={props.handleCloseModalChangeMember}
          >
            キャンセル
          </Button>
        </Stack>
      </Box>
    </>
  );
}
