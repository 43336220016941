import {
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { useState, useCallback, useContext } from "react";
import TextInput from "./Forms/TextInput";
import { InputNoteContext } from "./NoteBody";

const PitcherInput = () => {
  const [throwP, setThrowP] = useState("");

  const { inputs, setInputs } = useContext(InputNoteContext);

  const inputPitcherTeam = useCallback(
    (event) => {
      setInputs({ ...inputs, pitcherTeam: event.target.value });
    },
    [inputs]
  );

  const inputPitcher = useCallback(
    (event) => {
      setInputs({ ...inputs, pitcher: event.target.value });
    },
    [inputs]
  );

  const selectThrow = useCallback(
    (event) => {
      setThrowP(event.target.value);
      setThrowP((prevState) => {
        setInputs({ ...inputs, throw: prevState });
      });
    },
    [inputs, throwP]
  );

  return (
    <div className="situationInputs">
      <Grid item xs={12} sm={6}>
        <TextInput
          fullWidth={true}
          label={"チーム名"}
          multiline={false}
          rows={1}
          type={"text"}
          value={inputs.pitcherTeam}
          onChange={inputPitcherTeam}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          fullWidth={true}
          label={"投手名"}
          multiline={false}
          rows={1}
          type={"text"}
          value={inputs.pitcher}
          onChange={inputPitcher}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="select-throw-lable">何投げ</InputLabel>
            <Select
              labelId="select-throw-label"
              id="select-throw"
              value={inputs.throw}
              label="何投げ"
              onChange={selectThrow}
            >
              <MenuItem value="right">右投げ</MenuItem>
              <MenuItem value="left">左投げ</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </div>
  );
};
export default PitcherInput;
