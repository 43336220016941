import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Articles0107tanakaImgPath from "../../assets/img/articles/tanaka_0107.png";
import StrikeBoyImgPath from "../../assets/img/strike_boy.png";

export function Article20230107() {
  return (
    <>
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "white",
          color: "black",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: `url(${post.image})`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,255,0.1)",
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 6, md: 6, sm: 6 },
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                gutterBottom
              >
                {/* {post.title} */}
                配球に頼らなくて良いとき
              </Typography>
              <Typography variant="body2" color="inherit" paragraph>
                {/* {post.description} */}
                <br />
                <br />
                野球の醍醐味である配球はあらゆる要素を考慮し、正解があるとも限らない中で、なんとかひとつの選択をする難しい行為だと思う。
                <br />
                <br />
                非常に難しい行為だが、ただ、打者を抑えるということにフォーカスした場合、そもそも配球に頼りすぎる必要がないラッキーなケースもあるのではないかと考えたので、ここでつらつら書いてみる。
                <br />
                <br />
                先に結論を言ってしまうと、単純に
                <b>打者が打てない球を投げれる投手をリードする場合</b>
                だと思う。
                <br />
                当たり前のことだが、言い換えれば、俗にいう
                <b>わかっていても打てない球</b>
                を投げる投手をリードする局面だ。
                <br />
                もう少しそのような局面を具体的にそのシーンを考えてみる。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>シーン１：ナックルボーラー</b>
                </Typography>
                一番、極端な例は、ナックルを多投する行為がそれである。本人も捕手もどのように変化するか不明な球であり、その都度変化の仕方も変わるので、変化が不規則であればその球そのもので、勝負できることになる。
                <br />
                ただ、近年は、ナックルボーラーは少なく、ナックルを扱うことがいかに難しいかがよくわかる。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    シーン２：打者の手元で変化する小さく変化する球が投げれる
                  </b>
                </Typography>
                しかし、ナックルに限らず打ちにくい球はいくつもある。カットボールやツーシームなど、一般的に打者の手元で小さく変化する球はそうだし、
                <br />
                また、そのよう球でなくてもキレがよく急激に打者の近くで変化したり、伸びたりする球は打ちづらい。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    シーン３：他の投手が投げないような珍しい変化をする球が投げれる
                  </b>
                </Typography>
                さらに、変化球の種類としては、一般的でも、他の投手が投げないような変化の仕方をする球を投げれる投手は、その球だけで抑え込むことができる。
                <br />
                相対的に打者はなれていない球となるので打ちづらい。
                <br />
                <br />
                短いイニングであれば、それは特に生きる。ヤンキースのマリアーノ・リベラ投手のカットボールは手元で変化する球でただでさえ打ちづらい球である上に、さらに、他の投手が投げるようなカットボールとは異なる浮き上がりつつ食い込むようなボールだったため、その球をメインにしても、抑え込むことができたのではんだろうか。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    シーン４：ここぞという場面で1人の打者に得意な球を多投する
                  </b>
                </Typography>
                さらに同じ球を多投するという意味で、ここぞという場面で、1打者に対しても、調子のいい球、打ちづらい球を連続多投するというのは効果があるように思う。
                <br />
                <br />
                2013年の楽天日本一の胴上げの局面で田中投手の最後の矢野選手に対する対する配球について、例をあげると、最後の打者矢野選手に対する配球は
                <a href="https://www.pitching-strategy-note.com/search/public/dc4a65cf-6ce8-4b34-a850-58f1fe9fe019?gameId=338b58c2-bce5-4132-a134-fe6116b9f2cf">
                  全球SFFだった
                </a>
                。<br />
                <br />
                <br />
                <img
                  src={Articles0107tanakaImgPath}
                  id="tanaka_0107_image"
                  alt="田中投手日本シリーズ最後の打者への配球"
                />
                <br />
                <br />
                <br />
                2013年の田中投手といえば、24勝0敗。落差の大きいSFFに限らず、切れ味するどいスライダーや伸びのあるストレート、要所でギアを入れるピッチングで、先発としてとんでもない成績を残した。
                <br />
                <br />
                日本シリーズ最終戦での登板は抑えでの登板だったわけだが、前の打者である坂本選手やボーカー選手はSFFについては、全くついていけていなかった。（その日も）SFFはわかっていても打てない球だったんだと思う。
                <br />
                <br />
                ただし、ストレートについては、その抑えでの登板の際、村田選手、ボウカー選手にヒットを打たれており、３点差でランナー１，３塁の状況で本塁打で同点の場面で、チャンスに強く意外性のある矢野選手である。
                <br />
                <br />
                代打で結果を出すような選手は、一球で仕留めにかかるような集中力があるので安易にその日バットにあてられたストレートを投げるより、調子がよくわかっていても打てないようなSFFを多投するという選択肢は妥当な選択だ。
                <br />
                <br />
                さらに前の打者では多投するといった配球をしていない場合は、SFFを連続で投げれば投げるほど、、実際には他の球を投げていなくても、「次は他の球がくるのではないか？」と、ある意味緩急が仮想的にもついてくるので、ここぞというときの１打者に対する配球としては得意球を連続多投は有効なのではないだろうか。
              </Typography>
              {/* <Link variant="subtitle1" href="#">
             {post.linkText} 
              LinkText
            </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="row" justifyContent="center">
        <Box sx={{ marginTop: 2 }}>
          <img
            src={StrikeBoyImgPath}
            alt="ストライク坊や"
            width={130}
            height={90}
          />
        </Box>
      </Grid>
    </>
  );
}
