import { useCallback, useContext, useState, useEffect } from "react";
import { useDrop } from "react-dnd";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import update from "react-addons-update";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Divider,
  Tooltip,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Ball, SequenceTable } from "./index";
import pichsequenceImagePath from "../assets/img/pichsequence4_1.png";
import { IsMobileDevice } from "../App";
import { CustomDragLayer } from "./CustumDragLayer";
import TextInput from "./Forms/TextInput";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { InputNoteContext } from "./NoteBody";
import { Accordion, AccordionSummary, AccordionDetails } from "./Situation";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

export const atBatResults = [
  "infieldOut",
  "flightOut",
  "hit",
  "double",
  "triple",
  "homeRun",
  "walk",
  "strikeOut",
];

const Sequence = () => {
  const {
    inputs,
    setInputs,
    balls,
    setBalls,
    count,
    setCount,
    strikeCount,
    setStrikeCount,
    ballCount,
    setBallCount,
  } = useContext(InputNoteContext);

  const countUp = () => {
    setCount((prevState) => prevState + 1);
  };

  const countDown = () => {
    setCount((prevState) => prevState - 1);
  };

  const pitchResults = ["strike", "ball", "foul", "swing"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // $merge method usecase follow
  // const obj = {a: 5, b: 3};
  // const newObj = update(obj, {$merge: {b: 6, c: 7}}); // => {a: 5, b: 6, c: 7}
  const moveBall = useCallback(
    (id, left, top) => {
      setBalls(
        update(balls, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [balls, setBalls]
  );

  const [, drop] = useDrop(
    () => ({
      // https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer
      // useDragで定義したDragする要素をここで指定することで
      // 指定のDrop要素内でDrag操作できるようにする
      accept: "ball",
      // DragDrop情報をモニタリングし、都度その情報をDropに返す
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBall(item.id, left, top);
      },
      // moveBoxが更新されるたびにuseDropが発火
    }),
    [moveBall]
  );

  {
    /* // カウントによって入力フォームを変える */
  }

  const countStrikeBall = () => {
    setStrikeCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    setBallCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    for (let i = 1; i <= count; i++) {
      let { result } = balls[i];
      if (result == "strike") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "foul") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "swing") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "ball") {
        setBallCount((prevState) => {
          if (prevState < 3) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else {
      }
    }
  };

  const countBackStrikeBall = () => {
    setStrikeCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    setBallCount((prevState) => {
      prevState = 0;
      return prevState;
    });
    for (let i = 1; i < count - 1; i++) {
      let { result } = balls[i];
      if (result == "strike") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "foul") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "swing") {
        setStrikeCount((prevState) => {
          if (prevState < 2) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else if (result == "ball") {
        setBallCount((prevState) => {
          if (prevState < 3) {
            prevState = prevState + 1;
            return prevState;
          }
          return prevState;
        });
      } else {
      }
    }
  };

  // 結果は戻るタイミングで空にすることによって、あらためて選択させ、更新することによって、
  // カウントなどの整合性をとるようにする
  const clearSequence = () => {
    let tmpLeft;
    let tmpTop;
    let tmpType;
    let tmpSpeed;

    setBalls((prevState) => {
      tmpLeft = prevState[count].left;
      tmpTop = prevState[count].top;
      tmpType = prevState[count].type;
      tmpSpeed = prevState[count].speed;
    });

    setBalls(
      update(balls, {
        [count]: {
          $merge: {
            left: tmpLeft,
            top: tmpTop,
            type: tmpType,
            speed: tmpSpeed,
            balls: ballCount,
            strikes: strikeCount,
            result: "",
          },
        },
      })
    );
  };

  const nextBall = () => {
    countUp();
    countStrikeBall();
  };

  const preBall = () => {
    clearSequence();
    countDown();
    countBackStrikeBall();
  };

  const selectType = useCallback(
    (event) => {
      let tmpLeft;
      let tmpTop;

      // prevStateを用いて、今の位置を取得する
      setBalls((prevState) => {
        tmpLeft = prevState[count].left;
        tmpTop = prevState[count].top;
      });

      // 特定のカウントについてtypeだけ更新する
      setBalls(
        update(balls, {
          [count]: {
            $merge: { left: tmpLeft, top: tmpTop, type: event.target.value },
          },
        })
      );
    },
    [balls, setBalls, count]
  );

  const inputMemo = useCallback(
    (event) => {
      setInputs({ ...inputs, memo: event.target.value });
    },
    [inputs]
  );

  const selectResult = useCallback(
    (event) => {
      let tmpLeft;
      let tmpTop;
      let tmpType;
      let tmpSpeed;

      setBalls((prevState) => {
        tmpLeft = prevState[count].left;
        tmpTop = prevState[count].top;
        tmpType = prevState[count].type;
        tmpSpeed = prevState[count].speed;
      });

      setBalls(
        update(balls, {
          [count]: {
            $merge: {
              left: tmpLeft,
              top: tmpTop,
              type: tmpType,
              speed: tmpSpeed,
              balls: ballCount,
              strikes: strikeCount,
              result: event.target.value,
            },
          },
        })
      );
    },
    [balls, setBalls, count]
  );

  const inputSpeed = useCallback(
    (event) => {
      // 球速は1km以上
      if (event.target.value < 1) {
        event.target.value = 1;
      }
      setBalls(
        update(balls, {
          [count]: {
            $merge: {
              speed: event.target.value,
            },
          },
        })
      );
    },
    [balls, setBalls, count]
  );

  const checkDisclodure = useCallback((event) => {
    setInputs({ ...inputs, disclosure: Number(event.target.value) });
  });

  const checkDataAggrFlag = useCallback((event) => {
    setInputs({ ...inputs, dataAggrFlag: event.target.checked });
  });

  // パネル表示の開閉用
  const [expandedCource, setExpandedCource] = useState(true);
  const handleChangeCource = () => (event) => {
    setExpandedCource(expandedCource ? false : true);
  };
  const [expandedPitch, setExpandedPitch] = useState(true);
  const handleChangePitch = () => (event) => {
    setExpandedPitch(expandedPitch ? false : true);
  };
  const [expandedMemo, setExpandedMemo] = useState(true);
  const handleChangeMemo = () => (event) => {
    setExpandedMemo(expandedMemo ? false : true);
  };
  const [expandedDisclosure, setExpandedDisclodure] = useState(true);
  const handleDisclosure = () => (event) => {
    setExpandedDisclodure(expandedDisclosure ? false : true);
  };
  const [expandedDataAggr, setExpandedDataAggr] = useState(true);
  const handleDataAggr = () => (event) => {
    setExpandedDataAggr(expandedDataAggr ? false : true);
  };

  return (
    <>
      <div
        style={{ padding: 10, marginBottom: 10, border: 1, borderRadius: 5 }}
      >
        <Divider>STEP 2-1</Divider>
        <InputLabel
          variant="standard"
          sx={{
            color: "text.primary",
            fontSize: 16,
            fontWeight: "medium",
            textAlign: "left",
            margin: 2,
          }}
        >
          {count}球目の配球の入力です。
        </InputLabel>

        <Accordion expanded={expandedCource} onChange={handleChangeCource()}>
          <AccordionSummary
            aria-controls="panel-course-content"
            id="panel-course-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="required">必須</span> コース
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <InputLabel
                variant="standard"
                sx={{
                  color: "text.primary",
                  fontSize: 16,
                  fontWeight: "medium",
                  textAlign: "left",
                  margin: 2,
                }}
              >
                数字マークを動かしてコースを
              </InputLabel>
              <InputLabel
                variant="standard"
                sx={{
                  color: "text.primary",
                  fontSize: 16,
                  fontWeight: "medium",
                  textAlign: "left",
                  margin: 2,
                }}
              >
                指定してください
              </InputLabel>

              <Container sx={{ bgcolor: "Transparent" }}>
                {/* dropをrefに指定することでDrop要素を指定 */}
                <div id="pitchSequenceParentFrame">
                  <div ref={drop} id="pitchSequenceChildFrame">
                    <img
                      src={pichsequenceImagePath}
                      id="pitchSequenceImage"
                      alt="配球画面"
                    />
                    {/* モバイルのときはデフォルトではドラッグときの軌跡が描画されないので
                            描画されるようにカスタム */}
                    {IsMobileDevice() && <CustomDragLayer />}

                    {Object.keys(balls).map((key) => {
                      const { left, top, type } = balls[key];
                      // 球数に応じて表示する球を変える
                      if (count >= key) {
                        return (
                          <Ball
                            key={key}
                            id={key}
                            left={left}
                            top={top}
                            type={type}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              </Container>
            </Typography>

            {/* 配球サマリーテーブル */}
            <Box sx={{ margin: 2 }}>
              <SequenceTable count={count} balls={balls}></SequenceTable>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expandedPitch} onChange={handleChangePitch()}>
          <AccordionSummary
            aria-controls="panel-pitch-content"
            id="panel-pitch-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="required">必須</span> 球種、
              <span className="required">必須</span> 投球結果、
              <span className="optional">任意</span> 球速
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ margin: 1 }}>
              <div>
                <Grid container direction="row" justifyContent="center">
                  <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                    <FormControl fullWidth required>
                      <InputLabel id="type-select-label">球種</InputLabel>
                      <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={balls[count].type} // valueに指定されている値が表示されるようになる
                        label="Type"
                        onChange={selectType}
                      >
                        <MenuItem value="straight">ストレート(◯)</MenuItem>
                        <MenuItem value="twoseams">ツーシーム(◎)</MenuItem>
                        <MenuItem value="slider">スライダー(△)</MenuItem>
                        <MenuItem value="cutball">カットボール(△)</MenuItem>
                        <MenuItem value="curve">カーブ(◇)</MenuItem>
                        <MenuItem value="shoot">シュート(□)</MenuItem>
                        <MenuItem value="fork">フォーク(▽)</MenuItem>
                        <MenuItem value="sff">SFF(▽)</MenuItem>
                        <MenuItem value="verticalslider">
                          縦スライダー(▽)
                        </MenuItem>
                        <MenuItem value="changeup">チェンジアップ(☆)</MenuItem>
                        <MenuItem value="slowball">スローボール(☆)</MenuItem>
                        <MenuItem value="palm">パーム(☆)</MenuItem>
                        <MenuItem value="knuckle">ナックル(☆)</MenuItem>
                        <MenuItem value="sinker">シンカー(◇)</MenuItem>
                        <MenuItem value="screw">スクリュー(◇)</MenuItem>
                        <MenuItem value="other">その他(♡)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/*  カウントによって入力フォームを変える */}
                {(() => {
                  if (strikeCount >= 2 && ballCount >= 3) {
                    return (
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                          <FormControl
                            fullWidth
                            required
                            error={!balls[count].result}
                          >
                            <InputLabel
                              id="result-by-one-pitch"
                              required="true"
                            >
                              投球結果
                            </InputLabel>
                            <Select
                              labelId="result-by-one-pitch-label"
                              id="result-by-one-pitch"
                              value={balls[count].result}
                              label="ResultByOnePitch"
                              onChange={selectResult}
                            >
                              <MenuItem value="foul">ファール</MenuItem>
                              <MenuItem value="walk">四球</MenuItem>
                              <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                              <MenuItem value="flightOut">
                                フライアウト
                              </MenuItem>
                              <MenuItem value="strikeOut">三振</MenuItem>
                              <MenuItem value="hit">ヒット</MenuItem>
                              <MenuItem value="double">二塁打</MenuItem>
                              <MenuItem value="triple">三塁打</MenuItem>
                              <MenuItem value="homeRun">本塁打</MenuItem>
                            </Select>
                            <FormHelperText disabled={!balls[count].result}>
                              {count}球目の投球結果を入力してください
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  } else if (strikeCount >= 2 && ballCount != 3) {
                    return (
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                          <FormControl
                            fullWidth
                            required
                            error={!balls[count].result}
                          >
                            <InputLabel id="result-by-one-pitch">
                              投球結果
                            </InputLabel>
                            <Select
                              labelId="result-by-one-pitch-label"
                              id="result-by-one-pitch"
                              value={balls[count].result}
                              label="ResultByOnePitch"
                              onChange={selectResult}
                            >
                              <MenuItem value="ball">ボール</MenuItem>
                              <MenuItem value="foul">ファール</MenuItem>
                              <MenuItem value="strikeOut">三振</MenuItem>
                              <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                              <MenuItem value="flightOut">
                                フライアウト
                              </MenuItem>
                              <MenuItem value="hit">ヒット</MenuItem>
                              <MenuItem value="double">二塁打</MenuItem>
                              <MenuItem value="triple">三塁打</MenuItem>
                              <MenuItem value="homeRun">本塁打</MenuItem>
                            </Select>
                            <FormHelperText disabled={!balls[count].result}>
                              {count}球目の投球結果を入力してください
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  } else if (strikeCount != 2 && ballCount == 3) {
                    return (
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                          <FormControl
                            fullWidth
                            required
                            error={!balls[count].result}
                          >
                            <InputLabel id="result-by-one-pitch">
                              投球結果
                            </InputLabel>
                            <Select
                              labelId="result-by-one-pitch-label"
                              id="result-by-one-pitch"
                              value={balls[count].result}
                              label="ResultByOnePitch"
                              onChange={selectResult}
                            >
                              <MenuItem value="strike">ストライク</MenuItem>
                              <MenuItem value="swing">空振り</MenuItem>
                              <MenuItem value="foul">ファール</MenuItem>
                              <MenuItem value="walk">四球</MenuItem>
                              <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                              <MenuItem value="flightOut">
                                フライアウト
                              </MenuItem>
                              <MenuItem value="hit">ヒット</MenuItem>
                              <MenuItem value="double">二塁打</MenuItem>
                              <MenuItem value="triple">三塁打</MenuItem>
                              <MenuItem value="homeRun">本塁打</MenuItem>
                            </Select>
                            <FormHelperText disabled={!balls[count].result}>
                              {count}球目の投球結果を入力してください
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} sm={6} sx={{ margin: 1 }}>
                          <FormControl
                            fullWidth
                            required
                            error={!balls[count].result}
                          >
                            <InputLabel id="result-by-one-pitch">
                              投球結果
                            </InputLabel>
                            <Select
                              labelId="result-by-one-pitch-label"
                              id="result-by-one-pitch"
                              value={balls[count].result}
                              label="ResultByOnePitch"
                              onChange={selectResult}
                            >
                              <MenuItem value="strike">ストライク</MenuItem>
                              <MenuItem value="swing">空振り</MenuItem>
                              <MenuItem value="ball">ボール</MenuItem>
                              <MenuItem value="foul">ファール</MenuItem>
                              <MenuItem value="infieldOut">ゴロアウト</MenuItem>
                              <MenuItem value="flightOut">
                                フライアウト
                              </MenuItem>
                              <MenuItem value="hit">ヒット</MenuItem>
                              <MenuItem value="double">二塁打</MenuItem>
                              <MenuItem value="triple">三塁打</MenuItem>
                              <MenuItem value="homeRun">本塁打</MenuItem>
                            </Select>
                            <FormHelperText disabled={!balls[count].result}>
                              {count}球目の投球結果を入力してください
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  }
                })()}

                <Grid container direction="row" justifyContent="center">
                  <Grid item xs={12} sm={6} sx={{ marginRight: 2 }}>
                    <TextInput
                      fullWidth={true}
                      label={"球速"}
                      multiline={false}
                      rows={1}
                      type={"number"}
                      value={balls[count].speed}
                      onChange={inputSpeed}
                      margin={1}
                      // error={balls[count].speed<0}
                      // helperText={balls[count].speed<0 ? "球速を0以上にしてください": " "}
                    />
                  </Grid>
                </Grid>
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>

      <Grid container justifyContent="center">
        <Stack direction="row" spacing={2} sx={{ marginBottom: 6 }}>
          {count == 1 && (
            <Button disabled variant="contained" size="large">
              {" "}
              前の投球へ
            </Button>
          )}
          {count > 1 && (
            <Button variant="contained" onClick={preBall} size="large">
              {" "}
              前の投球へ
            </Button>
          )}
          {/* - その打席の結果が入力されている場合は「次の投球」ボタンは非活性化
            - また、投球結果が入力されてない場合も非活性化
         */}
          {count < 15 &&
          !atBatResults.includes(balls[count].result) &&
          pitchResults.includes(balls[count].result) ? (
            <Button variant="contained" onClick={nextBall} size="large">
              次の投球へ
            </Button>
          ) : (
            <Button disabled variant="contained" size="large">
              {" "}
              次の投球へ{" "}
            </Button>
          )}
        </Stack>
      </Grid>
      <Divider>STEP 2-2. メモ</Divider>
      {/* <InputLabel
        variant="standard"
        sx={{
          color: "text.primary",
          fontSize: 16,
          fontWeight: "medium",
          textAlign: "left",
          margin: 2,
        }}
      >  
        メモを入力します。
      </InputLabel> */}
      <div
        style={{ padding: 10, marginBottom: 10, border: 1, borderRadius: 5 }}
      >
        <Accordion expanded={expandedMemo} onChange={handleChangeMemo()}>
          <AccordionSummary
            aria-controls="panel-memo-content"
            id="panel-memo-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="optional">任意</span> メモ
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ margin: 2 }}>
              <TextInput
                fullWidth={true}
                multiline={true}
                rows={5}
                type={"text"}
                value={inputs.memo}
                onChange={inputMemo}
                placeholder={
                  "初球から直球を積極的に振ってくる可能性があるので、初球は外角にカーブ。2球目は目線を広げるために、内角高めにストレートでボール球。3球目は甘いところからシンカーを投げてスイングを誘発させて、内野ゴロを狙う"
                }
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>

      <Divider>STEP 2-3. 公開設定</Divider>

      <div style={{ padding: 10, marginBottom: 10, boder: 1, borderRadius: 5 }}>
        <Accordion expanded={expandedDisclosure} onChange={handleDisclosure()}>
          <AccordionSummary
            aria-controls="panel-disclosure-content"
            id="panel-disclosure-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="required">必須</span> 公開範囲
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              aria-labelledby="disclosure-setting-radio-buttons-group-label"
              name="disclosure-setting-radio-buttons-group"
              onChange={checkDisclodure}
              value={inputs.disclosure}
              sx={{ marginBottom: 2 }}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="全体公開"
              />
              <Box component="div" sx={{ display: "inline" }}>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="URLを知ってる人は閲覧可"
                />
              </Box>

              <FormControlLabel value={2} control={<Radio />} label="非公開" />
            </RadioGroup>
            <Stack
              direction="row"
              alignItems="flex-start
            justifyContent="
              flex-start
            >
              <HelpOutlineOutlinedIcon color="action" fontSize="small" />
              <Typography variant="subtitle2">
                「URLを知っている人は閲覧可」は「みんなの配球」の一覧に表示されません。
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </div>

      {/* <Divider>STEP 2-4. グラフ集計</Divider>

      <div style={{ padding: 10, marginBottom: 10, boder: 1, borderRadius: 5 }}>
        <Accordion expanded={expandedDataAggr} onChange={handleDataAggr()}>
          <AccordionSummary
            aria-controls="panel-disclosure-content"
            id="panel-disclosure-header"
            sx={{ backgroundColor: "#AFEEEE" }}
          >
            <Typography>
              <span className="required">必須</span> グラフ集計
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={{ marginBottom: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputs.dataAggrFlag}
                    onChange={checkDataAggrFlag}
                  />
                }
                label="対象"
              />
            </FormGroup>
            <Stack
              direction="row"
              alignItems="flex-start
            justifyContent="
              flex-start
            >
              <HelpOutlineOutlinedIcon color="action" fontSize="small" />
              <Typography variant="subtitle2">
                個人で閲覧する「集計データを見る」のグラフに反映されます。試合の記録向け。あとから変更可。
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </div> */}
    </>
  );
};

export default Sequence;
