import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const typeConvertJapanese = (type) => {
  switch (type) {
    case "straight":
      return "ストレート";
    case "twoseams":
      return "ツーシーム";
    case "slider":
      return "スライダー";
    case "cutball":
      return "カットボール";
    case "curve":
      return "カーブ";
    case "shoot":
      return "シュート";
    case "fork":
      return "フォーク";
    case "sff":
      return "SFF";
    case "verticalslider":
      return "縦スライダー";
    case "changeup":
      return "チェンジアップ";
    case "slowball":
      return "スローボール";
    case "palm":
      return "パーム";
    case "knuckle":
      return "ナックル";
    case "sinker":
      return "シンカー";
    case "screw":
      return "スクリュー";
    default:
      return "その他";
  }
};

const resultConvertJapanese = (result) => {
  switch (result) {
    case "foul":
      return "ファール";
    case "walk":
      return <b>四球</b>;
    case "deadBall":
      return <b>死球</b>;
    case "intentionalWalk":
      return <b>申告敬遠</b>;
    case "infieldOut":
      return <b>ゴロアウト</b>;
    case "flightOut":
      return <b>フライアウト</b>;
    case "strikeOut":
      return <b>三振</b>;
    case "hit":
      return <b>ヒット</b>;
    case "double":
      return <b>二塁打</b>;
    case "triple":
      return <b>三塁打</b>;
    case "homeRun":
      return <b>本塁打</b>;
    case "strike":
      return "ストライク";
    case "ball":
      return "ボール";
    case "swing":
      return "空振り";
    case "sacrificeFly":
      return <b>犠牲フライ</b>;
    case "fildersChoice":
      return <b>フィルダースチョイス</b>;
    case "swingAway":
      return <b>振り逃げ</b>;
    case "blowObstruction":
      return <b>打撃妨害</b>;
    case "doublePlay":
      return <b>ダブルプレー</b>;
    case "sacrificeBunt":
      return <b>犠打</b>;
    case "swingingStrikeOut":
      return <b>空振り三振</b>;
    case "calledStrikeOut":
      return <b>見逃し三振</b>;
    case "error":
      return <b>エラー</b>;
    default:
      return "";
  }
};

function createData(number, type, result, speed) {
  type = typeConvertJapanese(type);
  result = resultConvertJapanese(result);
  return { number, type, result, speed };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function SequenceTable({ count, balls }) {
  let rows = [];
  Object.keys(balls).map((key) => {
    // DBから値を引っ張ってくるときは球種はbreakingBallで、UIから値を入れるときはtypeなので
    // こういう処理をする
    let { type, breakingBall } = balls[key];
    if (breakingBall) {
      type = breakingBall;
    }

    // DBから値を引っ張ってくるときは結果はpitchResult, atBatResultで、
    // UIから値を入れるときはresultであるためこのような処理をする

    let { result, pitchResult, atBatResult } = balls[key];
    if (atBatResult) {
      result = atBatResult;
    } else {
      if (pitchResult) {
        result = pitchResult;
      }
    }
    let { speed } = balls[key];

    // DBから値を引っ張ってくるときはballsのkeyは0からで
    // UIから値を入れるときはballsのkeyは1からなので、breakingBallの有無で
    // どちらのなのかを判定し、以下のような処理をして、投球数を1球目からの表示にする。
    if (breakingBall) {
      rows.push(createData(Number(key) + 1, type, result, speed));
    } else {
      if (count >= key) {
        rows.push(createData(key, type, result, speed));
      }
    }
  });
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#F3FFD8" }}>
          <TableRow>
            <TableCell size="small" sx={{ fontSize: 11 }}>
              投球
            </TableCell>
            <TableCell size="small" align="right" sx={{ fontSize: 11 }}>
              球種
            </TableCell>
            <TableCell size="small" align="right" sx={{ fontSize: 11 }}>
              結果
            </TableCell>
            <TableCell size="small" align="right" sx={{ fontSize: 11 }}>
              球速(km)
            </TableCell>
            {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell> */}
            {/* <TableCell align="right">Protein&nbsp;(g)</TableCell>  */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.number}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                fontSize: 11,
              }}
            >
              <TableCell component="th" scope="row" size="small">
                {row.number}
              </TableCell>
              <TableCell
                align="right"
                size="small"
                sx={{ padding: 0, fontSize: 11 }}
              >
                {row.type}
              </TableCell>
              <TableCell
                align="right"
                size="small"
                sx={{ padding: 0, fontSize: 11 }}
              >
                {row.result}
              </TableCell>
              <TableCell
                align="right"
                size="small"
                sx={{ padding: 0, fontSize: 11 }}
              >
                {row.speed}
              </TableCell>
              {/* <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
