import {
  Box,
  MenuItem,
  Typography,
  FormControl,
  Select,
  InputLabel,
  Tabs,
  Tab,
  Grid,
  Stack,
} from "@mui/material";
import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomPageLabel from "./CustomPageLabel";
import { useMatch, useNavigate } from "react-router-dom";
import CourseData from "./CourseData";
import { Amplify, Storage } from "aws-amplify";
import awsconfig from "../aws-exports";
import YanoSanImgPath from "../assets/img/yano_san.png";
Amplify.configure(awsconfig);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DashBoard = (props) => {
  const [owner, setOwner] = useState();
  const [response, setResponse] = useState();

  const [pieViewAllData, setPieViewAllData] = useState();
  const [linksViewAllData, setLinksViewAllData] = useState();
  const [courseViewAllData, setCourseViewAllData] = useState();
  const [allCourseNum, setAllCourseNum] = useState("all");

  const [pieViewOutData, setPieViewOutData] = useState();
  const [linksViewOutData, setLinksViewOutData] = useState();
  const [courseViewOutData, setCourseViewOutData] = useState();
  const [outCourseNum, setOutCourseNum] = useState("all");

  const [pieViewSwingData, setPieViewSwingData] = useState();
  const [linksViewSwingData, setLinksViewSwingData] = useState();
  const [courseViewSwingData, setCourseViewSwingData] = useState();
  const [swingCourseNum, setSwingCourseNum] = useState("all");

  const [pieViewInfieldOutData, setPieViewInfieldOutData] = useState();
  const [linksViewInfieldOutData, setLinksViewInfieldOutData] = useState();
  const [courseViewInfieldOutData, setCourseViewInfieldOutData] = useState();
  const [infieldOutCourseNum, setInfieldOutCourseNum] = useState("all");

  const [pieViewFlightOutData, setPieViewFlightOutData] = useState();
  const [linksViewFlightOutData, setLinksViewFlightOutData] = useState();
  const [courseViewFlightOutData, setCourseViewFlightOutData] = useState();
  const [flightOutCourseNum, setFlightOutCourseNum] = useState("all");

  const [batterSelect, setBatterSelect] = useState("all");
  const [pitcherSelect, setPitcherSelect] = useState("all");
  const [selectValue, setSelectValue] = useState("all");

  const [fetchError, setFetchError] = useState(false);
  const [readFileError, setReadFileError] = useState(false);
  const [notFoundAggrDataError, setNotFoundAggrDataError] = useState(false);

  const handleChangeBatterSelect = (event) => {
    setBatterSelect(event.target.value);
  };

  const handleChangePitcherSelect = (event) => {
    setPitcherSelect(event.target.value);
  };

  const [pitcherSelectList, setPitcherSelectList] = useState(["all"]);

  const fetchData = async () => {
    const getS3AggrDataPointerFunc = `
    query GetS3AggrDataPointer($owner: String!) {
      getS3AggrDataPointer(owner: $owner) {
        owner
        latestAggrDataFileName
      }
    }
  `;

    if (!owner) {
      return;
    }
    let s3DataAggrPointer;

    try {
      s3DataAggrPointer = await API.graphql({
        query: getS3AggrDataPointerFunc,
        variables: { owner: owner },
      });
    } catch {
      console.error("Failed to fetch data.");
      setFetchError(true);
      return;
    }

    if (!s3DataAggrPointer.data.getS3AggrDataPointer) {
      console.error("Not found aggr data.");
      setNotFoundAggrDataError(true);
      return;
    }
    let result;
    try {
      result = await Storage.get(
        s3DataAggrPointer.data.getS3AggrDataPointer.latestAggrDataFileName,
        {
          level: "private",
          download: true,
          contentType: "application/json", // set return content type, eg "text/html"
        }
      );
    } catch (error) {
      console.error(error);
      setNotFoundAggrDataError(true);
      return;
    }

    const fr = new FileReader();

    // ファイルの読み込みが完了
    fr.onload = (event) => {
      setResponse(JSON.parse(event.target.result));
      setNotFoundAggrDataError(false);
      setFetchError(false);
      setReadFileError(false);
    };

    // ファイル読み込みのエラーの場合
    fr.onerror = (event) => {
      console.error("Failed to read file error.");
      setReadFileError(true);
    };

    // ファイルの読み込みを開始
    if (result) {
      fr.readAsText(result.Body);
    } else {
      console.error("not yet create aggr data.");
      setNotFoundAggrDataError(true);
    }
  };

  // 投手名の選択肢を作成
  useEffect(() => {
    if (response) {
      const pitcherSelectList = ["all"];
      Object.keys(response.all).forEach((property) => {
        //正規表現で先頭がleftのpropertyを取得
        if (property.match(/^left/)) {
          if (property !== "left") {
            //先頭のleftを削除
            pitcherSelectList.push(property.replace(/^left/, ""));
          }
        }
      });
      setPitcherSelectList(pitcherSelectList);
    }
  }, [response]);

  const transformData = (
    courseNum,
    ownerData,
    countBreakingBalls,
    links,
    selectValue
  ) => {
    let linksInternal = [];
    if (courseNum === "all") {
      for (let breakingBall in ownerData[selectValue].all.breakingBall) {
        if (ownerData[selectValue].all.breakingBall[breakingBall] !== 0) {
          countBreakingBalls.push({
            x: breakingBall,
            y: ownerData[selectValue].all.breakingBall[breakingBall],
          });
        }
      }
      for (let link in ownerData[selectValue].all.links) {
        links.push(ownerData[selectValue].all.links[link]);
      }
    } else {
      for (let breakingBall in ownerData[selectValue].courses[courseNum]
        .breakingBall) {
        if (
          ownerData[selectValue].courses[courseNum].breakingBall[
            breakingBall
          ] !== 0
        ) {
          countBreakingBalls.push({
            x: breakingBall,
            y: ownerData[selectValue].courses[courseNum].breakingBall[
              breakingBall
            ],
          });
        }
      }
      for (let link in ownerData[selectValue].courses[courseNum].links) {
        links.push(ownerData[selectValue].courses[courseNum].links[link]);
      }
    }
  };

  // データ種別の選択
  useEffect(() => {
    if (batterSelect === "all" && pitcherSelect === "all") {
      setSelectValue("all");
    } else if (batterSelect === "all" && pitcherSelect !== "all") {
      setSelectValue(pitcherSelect);
    } else if (batterSelect !== "all" && pitcherSelect === "all") {
      setSelectValue(batterSelect);
    } else {
      setSelectValue(batterSelect + pitcherSelect);
    }
    // コースを初期化する
    setAllCourseNum("all");
    setOutCourseNum("all");
    setSwingCourseNum("all");
    setInfieldOutCourseNum("all");
    setFlightOutCourseNum("all");
  }, [batterSelect, pitcherSelect]);

  useEffect(() => {
    if (response) {
      let ownerAllData = response.all;
      let ownerOutData = response.out;
      let ownerSwingData = response.swing;
      let ownerInfieldOutData = response.infield;
      let ownerFlightOutData = response.flight;

      // 取得したdataを打者別、投手名で切り替える
      const switchDataByBatterAndPitcher = (selectValue) => {
        setCourseViewAllData(ownerAllData[selectValue].courses);
        setCourseViewOutData(ownerOutData[selectValue].courses);
        setCourseViewSwingData(ownerSwingData[selectValue].courses);
        setCourseViewInfieldOutData(ownerInfieldOutData[selectValue].courses);
        setCourseViewFlightOutData(ownerFlightOutData[selectValue].courses);
      };

      switchDataByBatterAndPitcher(selectValue);

      let countBreakingBallsAll = [];
      let linksAll = [];
      let countBreakingBallsOut = [];
      let linksOut = [];
      let countBreakingBallsSwing = [];
      let linksSwing = [];
      let countBreakingBallsInfieldOut = [];
      let linksInfieldOut = [];
      let countBreakingBallsFlightOut = [];
      let linksFlightOut = [];

      // 全投球
      transformData(
        allCourseNum,
        ownerAllData,
        countBreakingBallsAll,
        linksAll,
        selectValue
      );

      // アウト
      transformData(
        outCourseNum,
        ownerOutData,
        countBreakingBallsOut,
        linksOut,
        selectValue
      );

      // 空振り
      transformData(
        swingCourseNum,
        ownerSwingData,
        countBreakingBallsSwing,
        linksSwing,
        selectValue
      );

      // インフィールド
      transformData(
        infieldOutCourseNum,
        ownerInfieldOutData,
        countBreakingBallsInfieldOut,
        linksInfieldOut,
        selectValue
      );

      // フライトアウト
      transformData(
        flightOutCourseNum,
        ownerFlightOutData,
        countBreakingBallsFlightOut,
        linksFlightOut,
        selectValue
      );
      setPieViewAllData(countBreakingBallsAll);
      setLinksViewAllData(linksAll);

      setPieViewOutData(countBreakingBallsOut);
      setLinksViewOutData(linksOut);

      setPieViewSwingData(countBreakingBallsSwing);
      setLinksViewSwingData(linksSwing);

      setPieViewInfieldOutData(countBreakingBallsInfieldOut);
      setLinksViewInfieldOutData(linksInfieldOut);

      setPieViewFlightOutData(countBreakingBallsFlightOut);
      setLinksViewFlightOutData(linksFlightOut);
    } else {
      setPieViewAllData(null);
      setLinksViewAllData(null);
      setPieViewOutData(null);
      setLinksViewOutData(null);
      setPieViewSwingData(null);
      setLinksViewSwingData(null);
      setPieViewInfieldOutData(null);
      setLinksViewInfieldOutData(null);
      setPieViewFlightOutData(null);
      setLinksViewFlightOutData(null);
      return;
    }
  }, [
    response,
    allCourseNum,
    outCourseNum,
    swingCourseNum,
    infieldOutCourseNum,
    flightOutCourseNum,
    selectValue,
  ]);

  const navigate = useNavigate();
  useEffect(async () => {
    let user;
    const fn = async () => {
      try {
        user = await Auth.currentAuthenticatedUser();
        if (user) {
          setOwner(user.attributes.sub);
        }
      } catch {
        setOwner(null);
      }
    };
    fn();
  }, []);

  const match = useMatch("/data/:owner");
  useEffect(() => {
    try {
      fetchData("all");
      if (match && owner) {
        if (match.pathname !== `/data/${owner}`) {
          navigate("/");
        }
      }
    } catch {
      setFetchError(true);
    }
  }, [owner]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, index) => {
    setTabIndex(index);
  };

  return (
    <>
      <CustomPageLabel label="集計データを見る" />
      {owner ? (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="batter-select-label">右、左打者</InputLabel>
              <Select
                labelId="batter-select-label"
                id="batter-select-label"
                value={batterSelect}
                onChange={handleChangeBatterSelect}
                label="右、左打者"
                size="small"
              >
                <MenuItem value="right">右打者</MenuItem>
                <MenuItem value="left">左打者</MenuItem>
                {/* <MenuItem value="both">両打ち</MenuItem> */}
                <MenuItem value="all">すべて</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="pitcher-select-label">投手名</InputLabel>
              <Select
                labelId="pitcher-select-label"
                id="pitcher-select-label"
                value={pitcherSelect}
                onChange={handleChangePitcherSelect}
                label="投手名"
                size="small"
              >
                {pitcherSelectList.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item === "all" ? "すべて" : item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                scrollButtons
                allowScrollButtonsMobile
                variant="scrollable"
              >
                <Tab label="全投球" {...a11yProps(0)} />
                <Tab label="全アウト決め球" {...a11yProps(1)} />
                <Tab label="三振、空振り取った球" {...a11yProps(2)} />
                <Tab label="ゴロアウト決め球" {...a11yProps(3)} />
                <Tab label="フライアウト決め球" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
              <CourseData
                pieViewData={pieViewAllData}
                courses={courseViewAllData}
                setCourseNum={setAllCourseNum}
                courseNum={allCourseNum}
                links={linksViewAllData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <CourseData
                pieViewData={pieViewOutData}
                courses={courseViewOutData}
                setCourseNum={setOutCourseNum}
                courseNum={outCourseNum}
                links={linksViewOutData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <CourseData
                pieViewData={pieViewSwingData}
                courses={courseViewSwingData}
                setCourseNum={setSwingCourseNum}
                courseNum={swingCourseNum}
                links={linksViewSwingData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <CourseData
                pieViewData={pieViewInfieldOutData}
                courses={courseViewInfieldOutData}
                setCourseNum={setInfieldOutCourseNum}
                courseNum={infieldOutCourseNum}
                links={linksViewInfieldOutData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <CourseData
                pieViewData={pieViewFlightOutData}
                courses={courseViewFlightOutData}
                setCourseNum={setFlightOutCourseNum}
                courseNum={flightOutCourseNum}
                links={linksViewFlightOutData}
                owner={owner}
                fetchError={fetchError}
                notFoundAggrDataError={notFoundAggrDataError}
                readFileError={readFileError}
              />
            </TabPanel>
          </Box>
        </>
      ) : (
        <>
          <div id="noteAuthDisplay">
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 14,
                fontWeight: "medium",
                textAlign: "left",
                marginTop: 2,
              }}
            >
              「集計データを見る」をご利用の場合
            </InputLabel>{" "}
            <InputLabel
              variant="standard"
              sx={{
                color: "text.primary",
                fontSize: 14,
                fontWeight: "medium",
                textAlign: "left",
                margin: 2,
              }}
            >
              新規登録いただき、ログインをお願いします。
            </InputLabel>
          </div>
          <Grid container direction="row" justifyContent="center">
            <Box sx={{ marginTop: 2 }}>
              <img
                src={YanoSanImgPath}
                alt="矢野さん"
                width={100}
                height={70}
              />
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default DashBoard;
