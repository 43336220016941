import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import StrikeBoyImgPath from "../../assets/img/strike_boy.png";

export function Article20230509() {
  return (
    <>
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "white",
          color: "black",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: `url(${post.image})`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,255,0.1)",
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 6, md: 6, sm: 6 },
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                gutterBottom
              >
                {/* {post.title} */}
                配球の基本②（谷繁ノート 書評）
              </Typography>
              <Typography variant="body2" color="inherit" paragraph>
                {/* {post.description} */}
                <br />
                <br />
                NPBの通算出場記録を持つ谷繁元信さんが書いた谷繁ノートを読み、そこから配球の基本をここに書いてみたい。
                <br />
                <br />
                谷繁ノートを読んでみると何度も出てくるのは「内角高めと外角低めはどんな打者も苦手である」ということ。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>内角高めと外角低めはどんな打者も苦手である</b>
                </Typography>
                これはバットを純粋にふったときに、他のコースとくらべて、腕をたたんだり、身体を動かす量が多くなるため、バットの芯にアジャストさせることが難しいからだと思う。
                <br />
                <br />
                よく言われる甘い球、真中付近は素直にバットを出せば腕がのびた状態でバットの芯にアジャストする箇所だ。
                <br />
                <br />
                ただ、どの打者も苦手な内角高め、外角低めだとしても、同じコースに投げ続けると痛打される可能性は高くなるので、一般的に難しいとされるこのコースがいきるように他の箇所にも放るという配球が基本になりそうだ。
                <br />
                <br />
                ただ、極端なケースだが、落合博満さんは時代も時代のため、頭部へのデッドボールを回避することを常に頭においてそれを軸に打撃をしていたといい、このようになんらかの理由で、意識するポイントがずれている場合は、その限りではないだろうが、初見で情報がない打者と対戦する場合など、配球の基本として考えると有益だと思った。
                <br />
                <br />
                なお、谷繁ノートは、谷繁さんが現役時代に対戦した各強打者の特徴が解説されおり、それに基づいてどのような配球を心がけたかが書かれている。
                <br />
                <br />
                総じて、各強打者の弱点や傾向を把握し、その弱点となるコースや球がより活きるような配球を心がけたという印象を受けた。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    苦手なコースを把握し、そのコースがより活きるような球を使う
                  </b>
                </Typography>
                例えば、内角に沈み込む変化球が弱点で初球を見逃す傾向のある打者だとわかっていれば、まずは外角ストレートでストライクをとりにいき、2球目に外角のストライクからボールになる変化球で様子見し、十分に外の球を見せてから一転して内角がより内角と感じられるような配球を行った上で、内角に沈み込む変化球を投げるというようなパターンなど。
                <br />
                <br />
                なお、谷繁ノートは、配球論もそうだが、特に現役時代のエピソードトークが多くおもしろかった。
                <br />
                <br />
                松井秀喜選手が当時出ていた焼き肉のCMを引き合いにだして、「今日は黒だれですか？赤だれですか？」と意識をそらすために、打席に入るときに松井選手に声かけをしたが、逆に打たれてしまっただとか。
                <br />
                <br />
                多く盗塁を許してしまったときの観客のリアクションに谷繁さんがどうおもっただとか、大矢監督から受けたアドバイスなど。
                <br />
                <br />
                配球の基本や打者タイプ別の配球といった配球の勉強だけでなく、当時の対戦の生なやり取りが知れる読み物としても魅力的な本でした。
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <iframe
                  sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                  width={120}
                  height={240}
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  frameborder="0"
                  src="//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=everfree98704-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=433495359X&linkId=a06e0bcb0554b2ae14db977fe6da1c8d"
                ></iframe>
              </Stack>

              {/* <Link variant="subtitle1" href="#">
             {post.linkText} 
              LinkText
            </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="row" justifyContent="center">
        <Box sx={{ marginTop: 2 }}>
          <img
            src={StrikeBoyImgPath}
            alt="ストライク坊や"
            width={130}
            height={90}
          />
        </Box>
      </Grid>
    </>
  );
}
