import { SequenceDetail, CustomPageLabel } from "./index";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import {
  useLocation,
  useSearchParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { Auth } from "aws-amplify";

export default function SearchPrivateByCaseAndOwner() {
  const [queryParams] = useSearchParams();
  const gameId = queryParams.get("gameId");
  const [user, setUser] = useState();
  let redirectURL;
  if (user) {
    redirectURL = `/search/${user.attributes.sub}`;
  }

  const [redirect, setRedirect] = useState(false);

  const [responsePrivate, setResponsePrivate] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 315,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const fetchPrivateSequenceByCaseId = async (owner) => {
    const filter = {
      id: {
        eq: gameId,
      },
    };
    const customListPrivateGames = `query ListPrivateGames(
          $filter: ModelPrivateGameFilterInput
        ) { 
          listPrivateGames(
            filter: $filter
          ) {
              items {
                batterTeam
                createdAt
                date
                id
                owner
                pitcherTeam
                updatedAt
                privateMatches {
                  items {
                    atBats
                    bat
                    batter
                    createdAt
                    gameId
                    id
                    owner
                    pitcher
                    throw
                    title
                    updatedAt
                    cases {
                      items {
                        batterTeamScore
                        createdAt
                        id
                        inning
                        matchId
                        outCounts
                        owner
                        pitcherTeamScore
                        runner
                        topOrBottom
                        dataAggrFlag
                        updatedAt
                        sequences {
                          items {
                            atBatResult
                            balls
                            breakingBall
                            caseId
                            createdAt
                            id
                            memo
                            owner
                            pitchResult
                            pitchXPosition
                            pitchYPosition
                            sequence
                            speed
                            strikes
                            updatedAt
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }`;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        API.graphql({
          query: customListPrivateGames,
          variables: { filter: filter },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        }).then((response) => {
          setResponsePrivate(response);
        });
      })
      .catch((err) => {
        navigate("/");
      });
  };

  const deletePrivateSequences = async (game) => {
    const inputsGame = {
      id: game.id,
    };
    const deleteGame = await API.graphql({
      query: mutations.deletePrivateGame,
      variables: { input: inputsGame },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    // 今の実装は、gameに対して、match,caseは1対1なのでitems[0]を指定
    const inputsMatch = {
      gameId: game.id,
      id: game.privateMatches.items[0].id,
    };
    const deleteMatch = await API.graphql({
      query: mutations.deletePrivateMatch,
      variables: { input: inputsMatch },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const inputsCase = {
      id: game.privateMatches.items[0].cases.items[0].id,
    };
    const deleteCase = await API.graphql({
      query: mutations.deletePrivateCase,
      variables: { input: inputsCase },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    for (
      let i = 0;
      i < game.privateMatches.items[0].cases.items[0].sequences.items.length;
      i++
    ) {
      const inputsSeqence = {
        id: game.privateMatches.items[0].cases.items[0].sequences.items[i].id,
        sequence: i + 1,
      };

      const deleteSequence = await API.graphql({
        query: mutations.deletePrivateSequence,
        variables: { input: inputsSeqence },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    }
    handleClose();

    setRedirect(true);
  };

  useEffect(() => {
    fetchPrivateSequenceByCaseId();
  }, []);

  return (
    <div className="viewSequenceDetail">
      {/* 読込中 */}
      {!responsePrivate && <CircularProgress />}

      {/* 削除したあとに再アクセスした場合にリダイレクトされる */}
      {redirect && (
        <Navigate
          to={`${redirectURL}`}
          state={{ from: useLocation() }}
          replace
        />
      )}
      {responsePrivate &&
        responsePrivate.data.listPrivateGames.items.length == 0 && (
          <Navigate
            to={`${redirectURL}`}
            state={{ from: useLocation() }}
            replace
          />
        )}

      {responsePrivate &&
        responsePrivate.data.listPrivateGames.items.map((game) =>
          game.privateMatches.items.map((match) =>
            match.cases.items.map((c) => {
              return (
                <>
                  <CustomPageLabel label="配球の詳細を見る" />
                  <div className="viewSequenceDetail">
                    <Card
                      variant="outlined"
                      sx={{
                        width: "315px",
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <CardContent>
                        <SequenceDetail
                          game={game}
                          match={match}
                          case={c}
                          title={match.title}
                          disclosure="private"
                        ></SequenceDetail>
                      </CardContent>
                    </Card>
                  </div>

                  {/* ログインユーザーがこの配球の登録ユーザーであれば削除ボタンを表示する */}
                  {user && game.owner == user.attributes.sub ? (
                    <div>
                      <Button
                        onClick={handleOpen}
                        size="large"
                        variant="outlined"
                        sx={{ fontWeight: "600" }}
                      >
                        削除
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            本当に削除していいですか？
                          </Typography>
                          <Button onClick={() => deletePrivateSequences(game)}>
                            はい
                          </Button>
                          <Button onClick={handleClose}>いいえ</Button>
                        </Box>
                      </Modal>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })
          )
        )}
    </div>
  );
}
