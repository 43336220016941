import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import TextInput from "./Forms/TextInput";
import pichsequenceImagePath from "../assets/img/pichsequence4_1.png";
import { SequenceTable } from "./index";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";

export default function SequenceDetail(props) {
  const [balls, setBalls] = useState([]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [editMemoFlag, setEditMemoFlag] = useState(false);
  const [memo, setMemo] = useState();
  const [aggrFlag, setAggrFlag] = useState(props.case.dataAggrFlag);

  let newBalls = [];
  let count = 0;

  const style = {
    position: "absolute",
    width: 25,
    height: 25,
  };
  const ballStyleChange = [
    "changeup",
    "slowball",
    "palm",
    "knuckle",
    "curve",
    "sinker",
    "screw",
    "slider",
    "cutball",
    "twoseams",
    "fork",
    "verticalslider",
    "sff",
  ];

  const styleChange = {
    position: "absolute",
    width: 31,
    height: 31,
  };

  const changeEditMemoFlag = () => {
    if (!editMemoFlag) {
      setEditMemoFlag(true);
      if (
        props.case.sequences.items[props.case.sequences.items.length - 1].memo
      ) {
        setMemo(
          props.case.sequences.items[props.case.sequences.items.length - 1].memo
        );
      }
    } else {
      setEditMemoFlag(false);
    }
  };

  const CreateImagePath = (type, count) => {
    if (ballStyleChange.includes(type)) {
      return (
        <img
          src={require(`../assets/img/balls/${type}_${count}.png`)}
          width={styleChange.width}
          height={styleChange.height}
          alt={type}
        />
      );
    }
    return (
      <img
        src={require(`../assets/img/balls/${type}_${count}.png`)}
        width={style.width}
        height={style.height}
        alt={type}
      />
    );
  };

  const SelectBallImage = (type, count) => CreateImagePath(type, count);

  const displayBat = (bat) => {
    switch (bat) {
      case "right":
        return "右打";
      case "left":
        return "左打";
      case "both":
        return "両打";
    }
  };

  const displayOutCount = (outcount) => {
    switch (outcount) {
      case 0:
        return "ノーアウト";
      case 1:
        return "ワンアウト";
      case 2:
        return "ツーアウト";
    }
  };

  const displayOutCountMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>O </span>
            <font color="red">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>O </span>
            <font color="red">●●</font>
          </>
        );
      default:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };

  const displayBallsMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>B </span>
            <font color="green">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>B </span>
            <font color="green">●●</font>
          </>
        );
      case 3:
        return (
          <>
            <span>B </span>
            <font color="green">●●●</font>
          </>
        );
      default:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
    }
  };

  const displayStrikesMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>S </span>
            <font color="yellow">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>S </span>
            <font color="yellow">●●</font>
          </>
        );
      default:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };

  const displayRunner = (runner) => {
    switch (runner) {
      case 0:
        return;
      case 1:
        return "一塁";
      case 2:
        return "二塁";
      case 3:
        return "三塁";
      case 4:
        return "一、二塁";
      case 5:
        return "一、三塁";
      case 6:
        return "二、三類";
      case 7:
        return "満塁";
    }
  };

  const styleRunner = {
    position: "absolute",
    width: 50,
    height: 50,
  };

  const displayRunnerPicture = (runner) => {
    switch (runner) {
      case 0:
        return (
          <img
            src={require(`../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 1:
        return (
          <img
            src={require(`../assets/img/runner/first.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 2:
        return (
          <img
            src={require(`../assets/img/runner/second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 3:
        return (
          <img
            src={require(`../assets/img/runner/third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 4:
        return (
          <img
            src={require(`../assets/img/runner/first_second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 5:
        return (
          <img
            src={require(`../assets/img/runner/first_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 6:
        return (
          <img
            src={require(`../assets/img/runner/second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 7:
        return (
          <img
            src={require(`../assets/img/runner/first_second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      default:
        return (
          <img
            src={require(`../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
    }
  };

  const disclosure = { public: 0, limitedDisclosure: 1, private: 2 };

  const changeAggrFlag = async (aggrFlag) => {
    if (props.disclosure === "public") {
      const inputUpdateAggrFlag = {
        id: props.case.id,
        dataAggrFlag: aggrFlag ? false : true,
      };

      const updateAggrFlag = await API.graphql({
        query: mutations.updatePublicCase,
        variables: { input: inputUpdateAggrFlag },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setAggrFlag(updateAggrFlag.data.updatePublicCase.dataAggrFlag);
    } else {
      const inputUpdateAggrFlag = {
        id: props.case.id,
        dataAggrFlag: aggrFlag ? false : true,
      };

      const updateAggrFlag = await API.graphql({
        query: mutations.updatePrivateCase,
        variables: { input: inputUpdateAggrFlag },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setAggrFlag(updateAggrFlag.data.updatePrivateCase.dataAggrFlag);
    }
  };

  useEffect(() => {
    props.case.sequences.items.map((sequence) => {
      balls.push(sequence);
    });
    // 配列は単純に中身をpushしただけでは再レンダリングしてくれない。なので、別の要素を渡してあげるためのもの
    newBalls = balls.slice(0, balls.length);
    setBalls(newBalls);
  }, []);

  const inputMemo = (event) => {
    setMemo(event.target.value);
  };

  const registerMemo = async () => {
    if (!memo) {
      // do nothing
    } else {
      if (props.disclosure === "public") {
        const inputUpdateMemo = {
          id: props.case.sequences.items[props.case.sequences.items.length - 1]
            .id,
          sequence: props.case.sequences.items.length,
          memo: memo,
        };

        const updateMemo = await API.graphql({
          query: mutations.updatePublicSequence,
          variables: { input: inputUpdateMemo },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        props.case.sequences.items[props.case.sequences.items.length - 1].memo =
          updateMemo.data.updatePublicSequence.memo;
      }

      if (props.disclosure === "private") {
        const inputUpdateMemo = {
          id: props.case.sequences.items[props.case.sequences.items.length - 1]
            .id,
          sequence: props.case.sequences.items.length,
          memo: memo,
        };

        const updateMemo = await API.graphql({
          query: mutations.updatePrivateSequence,
          variables: { input: inputUpdateMemo },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        props.case.sequences.items[props.case.sequences.items.length - 1].memo =
          updateMemo.data.updatePrivateSequence.memo;
      }
    }
    changeEditMemoFlag();
  };

  return (
    <>
      <div>
        {props.match.limitedDisclosure === disclosure.public && (
          <span className="disclosure">公開設定</span>
        )}
        {props.match.limitedDisclosure === disclosure.limitedDisclosure && (
          <span className="disclosure">URL知っている人閲覧可</span>
        )}
        {!props.match.limitedDisclosure &&
          props.match.limitedDisclosure !== 0 && (
            <span className="disclosure">非公開</span>
          )}
        {user && props.game.owner == user.attributes.sub && (
          <>
            <span> </span>
            <span className="disclosure">じぶん配球</span>
          </>
        )}
      </div>

      <Box sx={{ marginTop: 1 }}>
        <Container
          sx={{
            backgroundColor: "#222222",
            color: "white",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12}>
              {props.game.date == "2199-12-31" ? (
                <Box component="span" sx={{ fontSize: 12 }}>
                  試合日情報なし
                </Box>
              ) : (
                props.game.date
              )}
            </Grid>
          </Grid>
        </Container>

        <Container
          sx={{
            paddingTop: 1,
            backgroundColor: "#222222",
            color: "white",
          }}
        >
          <Grid container>
            <Grid item xs={6} sm={6}>
              <div>
                {!props.game.batterTeam ? (
                  // ? "batterTeam No DATA"
                  <Box component="span" sx={{ fontSize: 12 }}>
                    打者チーム情報なし
                  </Box>
                ) : (
                  props.game.batterTeam
                )}
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div>
                {!props.case.batterTeamScore &&
                props.case.batterTeamScore !== 0 ? (
                  // ? "batterTeamScore No DATA"
                  <Box component="span" sx={{ fontSize: 12 }}>
                    得点情報なし
                  </Box>
                ) : (
                  props.case.batterTeamScore
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <div>
                {!props.game.pitcherTeam ? (
                  // ? "pitcherTeam No DATA"
                  <Box component="span" sx={{ fontSize: 12 }}>
                    投手チーム情報なし
                  </Box>
                ) : (
                  props.game.pitcherTeam
                )}
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div>
                {!props.case.pitcherTeamScore &&
                props.case.pitcherTeamScore !== 0 ? (
                  // ? "pitcherTeamScore No DATA"
                  <Box component="span" sx={{ fontSize: 12 }}>
                    得点情報なし
                  </Box>
                ) : (
                  props.case.pitcherTeamScore
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container
          sx={{
            paddingTop: 1,
            backgroundColor: "#222222",
            color: "white",
          }}
        >
          <Grid container>
            <Grid item xs={6} sm={6}>
              <span>
                {props.match.batter == "NODATA" ? (
                  // ? "batter No DATA"
                  ""
                ) : (
                  <>
                    <span className="batter">打</span>
                    <> </>
                    {props.match.batter}
                  </>
                )}
              </span>
            </Grid>
            <Grid item xs={6} sm={6}>
              <span>
                {!props.match.bat
                  ? // ? "bat NoDATA"
                    ""
                  : ` ${displayBat(props.match.bat)}`}
              </span>
            </Grid>
            <Grid item xs={6} sm={6}>
              <span>
                {props.match.pitcher == "NODATA" ? (
                  // ? "pitcher No DATA"
                  ""
                ) : (
                  <>
                    <span className="pitcher">投</span>
                    <> </> {props.match.pitcher}
                  </>
                )}
              </span>
            </Grid>
            <Grid item xs={6} sm={6}>
              <span>
                {props.match.throw == "right" && " 右投"}
                {props.match.throw == "left" && " 左投"}
                {!props.match.throw && ""}
              </span>
            </Grid>
          </Grid>
        </Container>
        <Container
          sx={{
            paddingTop: 1,
            backgroundColor: "#222222",
            color: "white",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            marginBottom: 3,
          }}
        >
          <Grid container>
            <Grid item xs={4} sm={4}>
              <div>
                {!props.case.sequences.items[
                  props.case.sequences.items.length - 1
                ].balls
                  ? // ? "balls no data"
                    "B"
                  : displayBallsMark(
                      props.case.sequences.items[
                        props.case.sequences.items.length - 1
                      ].balls
                    )}
              </div>
              <div>
                {!props.case.sequences.items[
                  props.case.sequences.items.length - 1
                ].strikes
                  ? // ? "strikes no data"
                    "S"
                  : displayStrikesMark(
                      props.case.sequences.items[
                        props.case.sequences.items.length - 1
                      ].strikes
                    )}
              </div>
              <div>
                {!props.case.outCounts
                  ? // ? "outCounts no data"
                    "O"
                  : displayOutCountMark(props.case.outCounts)}
              </div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <span>
                {!props.case.inning ? (
                  // ? "inning no data"
                  <Box component="span" sx={{ fontSize: 12 }}>
                    イニング情報なし
                  </Box>
                ) : (
                  `${props.case.inning}回`
                )}
              </span>
              <span>
                {props.case.topOrBottom == "top" && "表"}
                {props.case.topOrBottom == "bottom" && "裏"}
                {!props.case.topOrBottom && ""}
              </span>

              <div>
                {!props.case.runner
                  ? // ? "runner no data"
                    displayRunnerPicture(0)
                  : displayRunnerPicture(props.case.runner)}
              </div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <div>
                {!props.match.atBats ? (
                  // ? "atBats no data"
                  <Box component="span" sx={{ fontSize: 12 }}>
                    打席情報なし
                  </Box>
                ) : (
                  `第${props.match.atBats}打席`
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <span className="title">タイトル</span>
      <Box sx={{ margin: 1 }}>{props.title}</Box>
      <Container sx={{ bgcolor: "Transparent" }}>
        <div id="pitchSequenceParentFrame">
          <div id="pitchSequenceChildFrame">
            <img
              src={pichsequenceImagePath}
              id="pitchSequenceImage"
              alt="配球画面"
            />

            {Object.keys(balls).map((key) => {
              let left = 0;
              let top = 0;

              left = balls[key].pitchXPosition;
              top = balls[key].pitchYPosition;
              count = Number(key) + 1;
              return (
                <div id={count} style={{ ...style, left, top }}>
                  {SelectBallImage(balls[key].breakingBall, count)}
                </div>
              );
            })}
          </div>
        </div>
      </Container>

      {/* 球種と結果一覧 */}
      <Box sx={{ margin: 2 }}>
        <SequenceTable count={count} balls={balls}></SequenceTable>
      </Box>

      <div>
        {!props.case.sequences.items[props.case.sequences.items.length - 1]
          .memo &&
          !editMemoFlag && (
            <>
              <span className="title">メモ</span>
              <Box sx={{ margin: 1 }}>メモの内容がありません</Box>
            </>
          )}
        {props.case.sequences.items[props.case.sequences.items.length - 1]
          .memo &&
          !editMemoFlag && (
            <>
              <span className="title">メモ</span>
              <Box sx={{ margin: 1 }} style={{ whiteSpace: "pre-wrap" }}>
                {
                  props.case.sequences.items[
                    props.case.sequences.items.length - 1
                  ].memo
                }
              </Box>
            </>
          )}
        {editMemoFlag && (
          <>
            <span className="title">メモ</span>
            <Box sx={{ margin: 1 }}>
              <TextInput
                fullWidth={true}
                multiline={true}
                rows={8}
                type={"text"}
                value={memo}
                onChange={inputMemo}
                error={!memo}
                helperText={!memo && "なにも入力されていません"}
              ></TextInput>
            </Box>
          </>
        )}

        {user && props.game.owner == user.attributes.sub && !editMemoFlag && (
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Button variant="outlined" onClick={changeEditMemoFlag}>
              メモの編集
            </Button>
          </Stack>
        )}
        {user && props.game.owner == user.attributes.sub && editMemoFlag && (
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Button variant="outlined" onClick={registerMemo}>
              メモの編集完了
            </Button>
          </Stack>
        )}
      </div>
      {/* データ集計の表示と編集 */}
      {/* {user && props.game.owner == user.attributes.sub && (
        <Box sx={{ marginTop: 1 }}>
          <span className="title">データ集計</span>
          <Stack direction="row" alignItems="center">
            <Switch
              checked={aggrFlag}
              onChange={() => changeAggrFlag(aggrFlag)}
            />
            <Typography>対象</Typography>
          </Stack>
        </Box>
      )} */}
    </>
  );
}
