import * as React from "react";
import { useState, useEffect } from "react";
import { Link as LinkRouter } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Container,
  Link,
  Stack,
  Card,
  Grid,
} from "@mui/material";
// import introductionSubtitleImgPath2 from "../assets/img/introduction/basic_intro_title.png";
import introductionThanks from "../assets/img/introduction/Thanks10000_2.png";
import introductionGameImgPath from "../assets/img/introduction/introductionGameImage.png";
import introductionNewRegisterButtonImgPath from "../assets/img/introduction/introductionNewRegisterButton1.png";
import introductionDataAggrImgPath from "../assets/img/introduction/introductionDataAggr.png";
import GetTwoImgPath from "../assets/img/get_two_kun.png";
import YanoSanImgPath from "../assets/img/yano_san.png";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Description from "./Description";
import { DashBoard, UpdateInformation } from ".";
import { TwitterIcon } from "react-share";
import FiberNewIcon from "@mui/icons-material/FiberNew";

const Introduction = () => {
  const [owner, setOwner] = useState("");
  // const [publicRegisterGameNumber, setPublicRegisterGameNumber] = useState(0);
  // const [
  //   privateRegisterGameNumberByOwner,
  //   setPrivateRegisterGameNumberByOwner,
  // ] = useState(0);
  // const [publicRegisterGameNumberByOwner, setPublicRegisterGameNumberByOwner] =
  //   useState(0);
  // const [registerGameNumberByOwner, setRegisterGameNumberByOwner] = useState(0);

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  // useEffect(async () => {
  //   let user = await Auth.currentAuthenticatedUser();

  //   setOwner(user.attributes.sub);

  // let fetchGames;
  // if (user.attributes.sub) {
  //   fetchGames = await API.graphql({
  //     query: queries.getPrivateGameByOwner,
  //     variables: { owner: user.attributes.sub },
  //     authMode: "AMAZON_COGNITO_USER_POOLS",
  //   });
  //   setPrivateRegisterGameNumberByOwner(
  //     fetchGames.data.getPrivateGameByOwner.items.length
  //   );
  // } else {
  //   fetchGames = await API.graphql({
  //     query: queries.getPrivateGameByOwner,
  //     variables: { owner: user.attributes.sub },
  //     authMode: "AWS_IAM",
  //   });
  //   setPrivateRegisterGameNumberByOwner(
  //     fetchGames.data.getPrivateGameByOwner.items.length
  //   );
  // }
  // }, []);

  useEffect(async () => {
    let user = await Auth.currentAuthenticatedUser();
    setOwner(user.attributes.sub);

    // let fetchGames;
    // if (user.attributes.sub) {
    //   fetchGames = await API.graphql({
    //     query: queries.getPublicGameByOwner,
    //     variables: { owner: user.attributes.sub },
    //     authMode: "AMAZON_COGNITO_USER_POOLS",
    //   });
    // } else {
    //   fetchGames = await API.graphql({
    //     query: queries.getPublicGameByOwner,
    //     variables: { owner: user.attributes.sub },
    //     authMode: "AWS_IAM",
    //   });
    // }
    // setPublicRegisterGameNumberByOwner(
    //   fetchGames.data.getPublicGameByOwner.items.length
    // );
  }, []);

  // useEffect(() => {
  //   setRegisterGameNumberByOwner(
  //     privateRegisterGameNumberByOwner + publicRegisterGameNumberByOwner
  //   );
  // }, [privateRegisterGameNumberByOwner, publicRegisterGameNumberByOwner]);

  // useEffect(async () => {
  //   let user = await Auth.currentAuthenticatedUser();
  //   setOwner(user.attributes.sub);

  //   const customListPublicGames = `query ListPublicGames(
  //       $filter: ModelPublicGameFilterInput
  //       $limit: Int
  //       $nextToken: String
  //       $matchFilter: ModelpublicMatchFilterInput
  //       ) {
  //         listPublicGames(
  //           filter: $filter
  //           limit: $limit
  //           nextToken: $nextToken
  //         ) {
  //             items {
  //               publicMatches(
  //                 filter: $matchFilter
  //               ) {
  //                 items {
  //                   limitedDisclosure
  //                 }
  //                 }
  //              }
  //           }
  //         }`;
  //   let resPublic;
  //   if (user.attributes.sub) {
  //     resPublic = await API.graphql({
  //       query: customListPublicGames,
  //       variables: {
  //         matchFilter: { limitedDisclosure: { eq: 0 } },
  //       },
  //       authMode: "AMAZON_COGNITO_USER_POOLS",
  //     });
  //   } else {
  //     resPublic = await API.graphql({
  //       query: customListPublicGames,
  //       variables: {
  //         matchFilter: { limitedDisclosure: { eq: 0 } },
  //       },
  //       authMode: "AWS_IAM",
  //     });
  //   }
  //   let publicCount = 0;
  //   for (let i = 0; i < resPublic.data.listPublicGames.items.length; i++) {
  //     if (
  //       resPublic.data.listPublicGames.items[i].publicMatches.items.length == 1
  //     ) {
  //       publicCount++;
  //     }
  //   }
  //   setPublicRegisterGameNumber(publicCount);
  // }, []);

  return (
    <>
      {!user && (
        <div id="introduction">
          {/* <img
            src={introductionThanks}
            id="introductionSubtitleImg"
            alt="サブタイトル"
          /> */}
          <Typography
            variant="h7"
            noWrap
            component="div"
            sx={{ textDecoration: "underline", marginTop: 0 }}
          >
            配球ノートとは
          </Typography>
          <Container sx={{ maxWidth: 500, marginBottom: 2 }}>
            <Description />
          </Container>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            {/* <Grid item xs={12} sm={12} md={6}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h7"
                  noWrap
                  component="div"
                  sx={{ textDecoration: "underline", marginTop: 2 }}
                >
                  詳細画面イメージ
                </Typography>
                <img
                  src={introductionSequenceImgPath}
                  id="pitchSequenceImg"
                  alt="配球画面"
                />
              </Stack>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h7"
                  noWrap
                  component="div"
                  sx={{ textDecoration: "underline", marginTop: 2 }}
                >
                  試合記録閲覧イメージ
                </Typography>
                <img
                  src={introductionGameImgPath}
                  id="pitchSequenceImg"
                  alt="配球画面"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h7"
                  noWrap
                  component="div"
                  sx={{ textDecoration: "underline", marginTop: 2 }}
                >
                  データ集計画面イメージ
                </Typography>

                <Card sx={{ margin: 1, maxWidth: 350 }}>
                  <img
                    src={introductionDataAggrImgPath}
                    id="pitchSequenceImg"
                    alt="データ集計画面"
                  />
                </Card>
              </Stack>
            </Grid>
          </Grid>

          <Link href="/auth" sx={{ margin: 2 }}>
            <img
              src={introductionNewRegisterButtonImgPath}
              id="introductionButtonImg"
              alt="新規登録"
            />
          </Link>


          <Typography
            variant="h7"
            noWrap
            component="div"
            sx={{ textDecoration: "underline", marginTop: 2 }}
          >
            操作方法
          </Typography>
          <div class="youtube">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/9kKOBnwgie4?si=bjve52LQTQysKVM7"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <Box sx={{ marginBottom: 4 }}></Box>

          <UpdateInformation />

          <Box sx={{ marginBottom: 2 }}></Box>

          {/* <Link href="/search/public" sx={{ margin: 3 }}>
            <img
              src={introductionEveryonesNoteButtomImgPath}
              id="introductionEveryonesNoteButtom"
              alt="みんなの配球を見る"
            />
          </Link> */}

          <Link href="/auth" sx={{ margin: 2 }}>
            <img
              src={introductionNewRegisterButtonImgPath}
              id="introductionButtonImg"
              alt="新規登録"
            />
          </Link>

          <Box sx={{ marginTop: 2 }}>
            <img src={YanoSanImgPath} alt="矢野さん" width={130} height={90} />
          </Box>
        </div>
      )}
      {user && (
        <>
          <Stack direction="column" alignItems="center" justifyContent="center">
            {/* {!registerGameNumberByOwner && registerGameNumberByOwner !== 0 ? (
              <Box component="div">
                <CircularProgress />
              </Box>
            ) : (
              <Box component="div" sx={{ color: "red" }}>
                自分の配球登録数{" "}
                <Box component="span" sx={{ fontSize: 22 }}>
                  {registerGameNumberByOwner}
                </Box>
              </Box>
            )} */}
            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{ textDecoration: "underline" }}
            >
              記録
            </Typography>

            <Box component="div">
              <Button
                size="large"
                variant="outlined"
                sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
                component={LinkRouter}
                // to={`/game/note/starting`}
                to={`/game/note/menu`}
              // startIcon={<FiberNewIcon color="error" />}
              >
                試合形式で記録する
              </Button>
            </Box>

            <Box component="div">
              <Button
                size="large"
                variant="outlined"
                sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
                component={LinkRouter}
                to={`/game/view/menu`}
              // startIcon={<FiberNewIcon color="error" />}
              >
                試合の記録を見る
              </Button>
            </Box>

            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{ textDecoration: "underline", marginTop: "2px" }}
            >
              考える
            </Typography>
            <Box>
              <Button
                size="large"
                variant="outlined"
                sx={{
                  fontWeight: "600",
                  width: "300px",
                  marginTop: "10px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                component={LinkRouter}
                to={`/learn`}
              >
                配球を勉強する
              </Button>
            </Box>
            <Box component="div">
              <Button
                size="large"
                variant="outlined"
                sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
                component={LinkRouter}
                to={`/note`}
              >
                配球を作ってみる
              </Button>
            </Box>

            <Box component="div">
              <Button
                size="large"
                variant="outlined"
                sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
                component={LinkRouter}
                to={`/search/${owner}`}
              >
                自分で作った配球を見る
              </Button>
            </Box>

            <Box component="div">
              <Button
                size="large"
                variant="outlined"
                sx={{
                  fontWeight: "600",
                  width: "300px",
                  margin: "10px",
                }}
                component={LinkRouter}
                to={`/search/public`}
              >
                みんなの配球を見る
              </Button>
            </Box>


            <UpdateInformation />
          </Stack>

          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{ textDecoration: "underline" }}
            >
              このアプリについて
            </Typography>
            <Button
              size="large"
              variant="outlined"
              sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
              component={LinkRouter}
              to={`/how`}
            >
              操作方法などを確認する
            </Button>

            <Button
              size="large"
              variant="outlined"
              sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
            >
              <Link href="https://forms.gle/kBGBubDP1xothrbY9" underline="none">
                アンケートに回答する
              </Link>
            </Button>
            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{ textDecoration: "underline", margin: "10px" }}
            >
              基本的な操作方法
            </Typography>
            <div class="youtube">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/9kKOBnwgie4?si=bjve52LQTQysKVM7"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{ textDecoration: "underline", marginTop: 3 }}
            >
              お問い合わせ
            </Typography>

            <Link href="https://twitter.com/PitStrategyNote">
              <Box component="div" sx={{ marginTop: 1 }}>
                <TwitterIcon size={32} round={true} />
              </Box>
            </Link>

            <Box sx={{ marginTop: 2 }}>
              <img
                src={GetTwoImgPath}
                alt="ゲッツーくん"
                width={180}
                height={120}
              />
            </Box>

            {/* <Box component="div">
            <Button
              size="large"
              variant="outlined"
              sx={{ fontWeight: "600", width: "300px", margin: "10px" }}
              component={LinkRouter}
              to={`/search/${owner}`}
            >
              じぶんの配球を見る
            </Button>
          </Box> */}
          </Stack>
        </>
      )}
    </>
  );
};

export default Introduction;
