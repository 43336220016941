import { useContext, useEffect } from "react";
import { InputNoteContext } from "./NoteBody";
import { SequenceTable } from "./index";
import { Box } from "@mui/system";
import pichsequenceImagePath from "../assets/img/pichsequence4_1.png";
import { Container, Grid, Stack, Card } from "@mui/material";

const TestContext = () => {
  const { inputs, balls, count } = useContext(InputNoteContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const style = {
    position: "absolute",
    width: 25,
    height: 25,
  };
  const ballStyleChange = [
    "changeup",
    "slowball",
    "palm",
    "knuckle",
    "curve",
    "sinker",
    "screw",
    "slider",
    "cutball",
    "twoseams",
    "fork",
    "verticalslider",
    "sff",
  ];

  const styleChange = {
    position: "absolute",
    width: 31,
    height: 31,
  };

  const styleRunner = {
    position: "absolute",
    width: 50,
    height: 50,
  };

  const displayOutCountMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>O </span>
            <font color="red">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>O </span>
            <font color="red">●●</font>
          </>
        );
      default:
        return (
          <>
            <span>O </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };

  const displayBallsMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>B </span>
            <font color="green">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>B </span>
            <font color="green">●●</font>
          </>
        );
      case 3:
        return (
          <>
            <span>B </span>
            <font color="green">●●●</font>
          </>
        );
      default:
        return (
          <>
            <span>B </span>
            <font color="grey">○○○</font>
          </>
        );
    }
  };

  const displayStrikesMark = (outcount) => {
    switch (outcount) {
      case 0:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
      case 1:
        return (
          <>
            <span>S </span>
            <font color="yellow">●</font>
          </>
        );
      case 2:
        return (
          <>
            <span>S </span>
            <font color="yellow">●●</font>
          </>
        );
      default:
        return (
          <>
            <span>S </span>
            <font color="grey">○○</font>
          </>
        );
    }
  };

  const CreateImagePath = (type, count) => {
    if (ballStyleChange.includes(type)) {
      return (
        <img
          src={require(`../assets/img/balls/${type}_${count}.png`)}
          width={styleChange.width}
          height={styleChange.height}
          alt={type}
        />
      );
    }
    return (
      <img
        src={require(`../assets/img/balls/${type}_${count}.png`)}
        width={style.width}
        height={style.height}
        alt={type}
      />
    );
  };

  // const { user, signOut } = useAuthenticator((context) => [context.user]);

  const SelectBallImage = (type, count) => CreateImagePath(type, count);

  const displayBat = (bat) => {
    switch (bat) {
      case "right":
        return "右打";
      case "left":
        return "左打";
      case "both":
        return "両打";
    }
  };

  const displayOutCount = (outcount) => {
    switch (outcount) {
      case 0:
        return "ノーアウト";
      case 1:
        return "ワンアウト";
      case 2:
        return "ツーアウト";
    }
  };

  const displayRunner = (runner) => {
    switch (runner) {
      case 0:
        return "なし";
      case 1:
        return "一塁";
      case 2:
        return "二塁";
      case 3:
        return "三塁";
      case 4:
        return "一、二塁";
      case 5:
        return "一、三塁";
      case 6:
        return "二、三類";
      case 7:
        return "満塁";
    }
  };

  const displayRunnerPicture = (runner) => {
    switch (runner) {
      case 0:
        return (
          <img
            src={require(`../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 1:
        return (
          <img
            src={require(`../assets/img/runner/first.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 2:
        return (
          <img
            src={require(`../assets/img/runner/second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 3:
        return (
          <img
            src={require(`../assets/img/runner/third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 4:
        return (
          <img
            src={require(`../assets/img/runner/first_second.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 5:
        return (
          <img
            src={require(`../assets/img/runner/first_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 6:
        return (
          <img
            src={require(`../assets/img/runner/second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      case 7:
        return (
          <img
            src={require(`../assets/img/runner/first_second_third.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
      default:
        return (
          <img
            src={require(`../assets/img/runner/nothing.png`)}
            width={styleRunner.width}
            height={styleRunner.height}
          />
        );
    }
  };

  const disclosure = { public: 0, limitedDisclosure: 1, private: 2 };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ margin: 1 }}
    >
      <Card>
        <Box sx={{ maxWidth: 700 }}>
          <Box sx={{ margin: 1 }}>
            <div>
              {inputs.disclosure === disclosure.public && (
                <span className="disclosure">公開設定</span>
              )}
              {inputs.disclosure === disclosure.limitedDisclosure && (
                <span className="disclosure">URLを知っている人は閲覧可能</span>
              )}
              {inputs.disclosure === disclosure.private && (
                <span className="disclosure">非公開</span>
              )}
              {/* {!inputs.disclosure && inputs.disclosure !== 0 && (
                <span className="disclosure">非公開</span>
              )} */}
            </div>

            <Box sx={{ marginTop: 1 }}>
              <Container
                sx={{
                  backgroundColor: "#222222",
                  color: "white",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    {inputs.matchDate == "2199-12-31" ? (
                      <Box component="span" sx={{ fontSize: 12 }}>
                        試合日情報なし
                      </Box>
                    ) : (
                      inputs.matchDate
                    )}
                  </Grid>
                </Grid>
              </Container>

              <Container
                sx={{
                  paddingTop: 1,
                  backgroundColor: "#222222",
                  color: "white",
                }}
              >
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!inputs.batterTeam ? (
                        // ? "batterTeam No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          打者チーム情報なし
                        </Box>
                      ) : (
                        inputs.batterTeam
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!inputs.batterTeamScore &&
                      inputs.batterTeamScore !== 0 ? (
                        // ? "batterTeamScore No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          得点情報なし
                        </Box>
                      ) : (
                        inputs.batterTeamScore
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!inputs.pitcherTeam ? (
                        // ? "pitcherTeam No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          投手チーム情報なし
                        </Box>
                      ) : (
                        inputs.pitcherTeam
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div>
                      {!inputs.pitcherTeamScore &&
                      inputs.pitcherTeamScore !== 0 ? (
                        // ? "pitcherTeamScore No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          得点情報なし
                        </Box>
                      ) : (
                        inputs.pitcherTeamScore
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box>
              <Container
                sx={{
                  paddingTop: 1,
                  backgroundColor: "#222222",
                  color: "white",
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <span>
                      {inputs.batter == "" ? (
                        // ? "batter No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          打者名なし
                        </Box>
                      ) : (
                        <>
                          <span className="batter">打</span>
                          <> </>
                          {inputs.batter}
                        </>
                      )}
                    </span>
                    <span>
                      {!inputs.bat ? (
                        // ? "bat NoDATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          {" "}
                          打ち方情報なし
                        </Box>
                      ) : (
                        ` ${displayBat(inputs.bat)}`
                      )}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span>
                      {inputs.pitcher == "" ? (
                        // ? "pitcher No DATA"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          投手名なし
                        </Box>
                      ) : (
                        <>
                          <span className="pitcher">投</span>
                          <> </> {inputs.pitcher}
                        </>
                      )}
                    </span>
                    <span>
                      {inputs.throw == "right" && " 右投げ"}
                      {inputs.throw == "left" && " 左投げ"}
                    </span>
                    <Box component="span" sx={{ fontSize: 12 }}>
                      {!inputs.throw && " 投げ方情報なし"}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
              <Container
                sx={{
                  paddingTop: 1,
                  backgroundColor: "#222222",
                  color: "white",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  marginBottom: 3,
                }}
              >
                <Grid container>
                  <Grid item xs={4} sm={4}>
                    <div>
                      {!balls[count].balls
                        ? // ? "balls no data"
                          "B"
                        : displayBallsMark(balls[count].balls)}
                    </div>
                    <div>
                      {!balls[count].strikes
                        ? // ? "strikes no data"
                          "S"
                        : displayStrikesMark(balls[count].strikes)}
                    </div>
                    <div>
                      {!inputs.outCounts
                        ? // ? "outCounts no data"
                          "O"
                        : displayOutCountMark(inputs.outCounts)}
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <span>
                      {!inputs.inning ? (
                        // ? "inning no data"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          イニング情報なし
                        </Box>
                      ) : (
                        `${inputs.inning}回`
                      )}
                    </span>
                    <span>
                      {inputs.topOrBottom == "top" && "表"}
                      {inputs.topOrBottom == "bottom" && "裏"}
                      {!inputs.topOrBottom && ""}
                    </span>

                    <div>
                      {!inputs.runner
                        ? // ? "runner no data"
                          displayRunnerPicture(0)
                        : displayRunnerPicture(inputs.runner)}
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div>
                      {!inputs.atBats ? (
                        // ? "atBats no data"
                        <Box component="span" sx={{ fontSize: 12 }}>
                          打席情報なし
                        </Box>
                      ) : (
                        `第${inputs.atBats}打席`
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>

          <Box sx={{ margin: 1 }}>
            <span className="title">タイトル</span>
            <Box sx={{ margin: 1 }}>{inputs.title}</Box>
            <Container sx={{ bgcolor: "Transparent" }}>
              <div id="pitchSequenceParentFrame">
                <div id="pitchSequenceChildFrame">
                  <img
                    src={pichsequenceImagePath}
                    id="pitchSequenceImage"
                    alt="配球画面"
                  />

                  {Object.keys(balls).map((key) => {
                    if (key <= count) {
                      let left = 0;
                      let top = 0;
                      let localCount = 0;

                      left = balls[key].left;
                      top = balls[key].top;
                      // localCount = Number(key) + 1;
                      return (
                        <div id={key} style={{ ...style, left, top }}>
                          {SelectBallImage(balls[key].type, key)}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </Container>
          </Box>

          {/* 球種と結果一覧 */}
          <Box sx={{ margin: 1, marginTop: 2 }}>
            <SequenceTable count={count} balls={balls}></SequenceTable>
          </Box>

          {/* メモの確認欄 */}
          <Box sx={{ margin: 1, marginTop: 2 }}>
            <span className="title">メモ</span>
            {!inputs.memo ? (
              <Box sx={{ margin: 1 }}>メモなし</Box>
            ) : (
              <Box sx={{ margin: 1 }} style={{ whiteSpace: "pre-wrap" }}>
                {inputs.memo}
              </Box>
            )}
          </Box>

          {/* データ集計の確認欄 */}
          {/* <Box sx={{ margin: 1, marginTop: 2 }}>
            <span className="title">グラフ集計</span>
            <Box sx={{ margin: 1 }}>
              {inputs.dataAggrFlag ? "対象" : "対象外"}
            </Box>
          </Box> */}
        </Box>
      </Card>
    </Stack>
  );
};

export default TestContext;
