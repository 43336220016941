import { Grid, InputLabel } from "@mui/material";

export default function CustomPageLabel(props) {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ backgroundColor: "#009999", border: "1px", marginBottom: "2px" }}
      >
        <InputLabel
          variant="standard"
          sx={{
            color: "white",
            fontSize: 16,
            fontWeight: "medium",
            textAlign: "left",
            margin: 2,
          }}
        >
          | {props.label}
        </InputLabel>
      </Grid>
    </Grid>
  );
}
