import "./App.css";
import "./assets/styles/styles.css";
import { Routes, Route } from "react-router-dom";
import {
  Header,
  Introduction,
  Footer,
  Auth,
  Note,
  SearchTop,
  UserInfo,
  SearchByOwner,
  SearchPublicByCase,
  SearchPublic,
  SearchPrivateByCaseAndOwner,
  NotFound,
  How,
  PrivacyPolicy,
  Learn,
  ArticleList,
  DashBoard,
} from "./components/index";

import ScrollTop from "./ScrollTop";
import { GameNote } from "./components/Game/GameNote";
import { GameStartingMember } from "./components/Game/GameStartingMember";
import { GameNoteMenu } from "./components/Game/GameRegisterMenu";
import { GameViewMenu } from "./components/Game/GameViewMenu";
import GameViewPrivate from "./components/Game/GameViewPrivate";
import { GameNoteContinue } from "./components/Game/GameNoteContinue";
import GamePitchData from "./components/Game/GamePitchData";
import { HelmetProvider } from "react-helmet-async";

export function IsMobileDevice() {
  return !!window.navigator.userAgent.match(/iPhone|iPad|iPod|Android/);
}

function App() {
  return (
    <HelmetProvider>
      <div id="header">
        <Header />
      </div>
      <div id="content">
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Introduction />} />
          <Route path="/learn" element={<Learn />} />
          <Route path="/learn/:articleId" element={<ArticleList />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/note" element={<Note />} />
          {/* <Route path="/game" element={<GameNote />} /> */}
          <Route path="/game/note/menu" element={<GameNoteMenu />} />
          <Route path="/game/note/starting" element={<GameStartingMember />} />
          <Route path="/game/note/:owner/:gameId" element={<GameNote />} />
          <Route path="/game/note/continue" element={<GameNoteContinue />} />
          <Route path="/game/view/menu" element={<GameViewMenu />} />
          <Route
            path="game/view/:owner/:gameId"
            element={<GameViewPrivate />}
          />
          <Route
            path="game/view/data/:paramOwner/:gameId"
            element={<GamePitchData />}
          />
          <Route path="/user" element={<UserInfo />} />
          <Route path="/search" element={<SearchTop />} />
          <Route path="/search/public" element={<SearchPublic />} />
          <Route path="/search/:owner" element={<SearchByOwner />} />
          <Route
            path="/search/public/:caseId"
            element={<SearchPublicByCase />}
          />
          <Route
            path="/search/:owner/:caseId"
            element={<SearchPrivateByCaseAndOwner />}
          />
          {/* <Route path="/data/:owner" element={<DashBoard />} /> */}
          <Route path="/how" element={<How />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
