import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Articles0129bantImgPath from "../../assets/img/articles/bant_0129.png";
import YanoSanImgPath from "../../assets/img/yano_san.png";

export function Article20230129() {
  return (
    <>
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "white",
          color: "black",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: `url(${post.image})`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,255,0.1)",
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 6, md: 6, sm: 6 },
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                gutterBottom
              >
                {/* {post.title} */}
                バントや進塁打を打たせたくないときの配球
              </Typography>
              <Typography variant="body2" color="inherit" paragraph>
                {/* {post.description} */}
                <br />
                <br />
                バントや進塁打を打たせたくないときの配球を考えてみたい。
                <br />
                <br />
                そもそもバントや進塁打のケースというのは、基本的に、1塁にランナーがいるか2塁にランナーがいるケースである。
                <br />
                <br />
                <b>
                  進塁打であれば、打者としては2塁や3塁に遠い右方向に打球を飛ばすのがセオリーである
                </b>
                ため、それがしずらい配球を心がけることが基本だと思う。
                <br />
                <br />
                <b>
                  右打者であれば、右に流せないようにインコース中心になるし、左打者であれば、引っ張れないように外角中心にせめることとなる。
                </b>
                <br />
                <br />
                ただし、そもそもその打者が進塁打を狙うような打者なのかは、打順や前の打席でタイミングあっているのか、１球投げてみての打ち方、またサインをみる振る舞いなどから見極めが必要だ。
                <br />
                <br />
                なお、バントの構えをしていた場合、バントじゃない可能性も頭にいれておいたほうが良い。（右打者の場合）進塁打狙いを警戒する配球でも同様と考えるが、初球はバントが一般的にしずらいボール球のインコースを攻めて、安易に進塁を成功させず、また様子見をするのが良いと考える。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>「初球はボール球で様子見」</b>
                </Typography>
                初球にバントの構えからバントを一旦引いた場合は、単純にバントではなく、さらにチャンス拡大を狙ったバスターやエンドランを狙ってくる可能性がある。
                <br />
                <br />
                バントシフトなどの守備体系を打者側が確認するために、打者側もバントの構えで様子を伺うこともあるだろう。
                もしも1球目ストライクなのに、バントを構えていた状態から、バントを引いた場合は、その可能性はより高くなると考えられる。
                <br />
                <br />
                さらにこのような状況下では、ランナーのリードの様子はキャッチャーとしてはよくみておくと良いと思う。
                <br />
                <br />
                あるサインが途中ででたときに、リードの構えの姿勢が低くなるなど、リードする距離がかわるなどするときは、なにかあるときかもしれない。
                <br />
                <br />
                このように可能性は多岐にわたるが、基本的にランナーを進塁させたくないときの配球の
                <a href="https://www.pitching-strategy-note.com/search/public/69ef392a-60e2-4770-99d8-ac967a927a67?gameId=8dc5305f-7f64-4b91-88e5-be88b9aea0bb">
                  一例
                </a>
                を考えてみた。バントをしてくるという前提にたってインコース攻めするものだ。
                <br />
                <br />
                <Typography variant="subtitle1" color="inherit" paragraph>
                  <b>
                    「２，３球目で外も意識させつつカウントを整え、バントや右に流しづらいインコースをせめる」
                  </b>
                </Typography>
                <img
                  src={Articles0129bantImgPath}
                  id="bant_0129_image"
                  alt="バント阻止"
                />
                <br />
                <br />
                前述の通り、1球目は一般的にバントしずらい内角の厳しいコースに投げる。様子見も加味する。
                <br />
                <br />
                2球目は、1球目の球道インコースのボール球からストライクをとるようなスライダーやカーブのような球を投げて、見逃しのストライクを狙う。
                <br />
                <br />
                3球目は１，２球目で存分にインコースの意識付けができているので、外角のスライダーで左右の幅を広げ、真ん中付近から曲がるから外のボール球で見逃しかファールを誘いたい。
                <br />
                <br />
                このように外と内を両方つかった状態で、あとは、バントしずらい内角にストレートを投げ込み続ける。
              </Typography>
              {/* <Link variant="subtitle1" href="#">
             {post.linkText} 
              LinkText
            </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="row" justifyContent="center">
        <Box sx={{ margin: 2 }}>
          <img src={YanoSanImgPath} alt="矢野さん" width={130} height={90} />
        </Box>
      </Grid>
    </>
  );
}
