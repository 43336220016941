/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      pk
      userName
      owner
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      pk
      userName
      owner
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      pk
      userName
      owner
      email
      createdAt
      updatedAt
    }
  }
`;
export const createPublicGame = /* GraphQL */ `
  mutation CreatePublicGame(
    $input: CreatePublicGameInput!
    $condition: ModelPublicGameConditionInput
  ) {
    createPublicGame(input: $input, condition: $condition) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      publicMatches {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          limitedDisclosure
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const updatePublicGame = /* GraphQL */ `
  mutation UpdatePublicGame(
    $input: UpdatePublicGameInput!
    $condition: ModelPublicGameConditionInput
  ) {
    updatePublicGame(input: $input, condition: $condition) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      publicMatches {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          limitedDisclosure
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const deletePublicGame = /* GraphQL */ `
  mutation DeletePublicGame(
    $input: DeletePublicGameInput!
    $condition: ModelPublicGameConditionInput
  ) {
    deletePublicGame(input: $input, condition: $condition) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      publicMatches {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          limitedDisclosure
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const createPublicMatch = /* GraphQL */ `
  mutation CreatePublicMatch(
    $input: CreatePublicMatchInput!
    $condition: ModelPublicMatchConditionInput
  ) {
    createPublicMatch(input: $input, condition: $condition) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      limitedDisclosure
      cases {
        items {
          id
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePublicMatch = /* GraphQL */ `
  mutation UpdatePublicMatch(
    $input: UpdatePublicMatchInput!
    $condition: ModelPublicMatchConditionInput
  ) {
    updatePublicMatch(input: $input, condition: $condition) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      limitedDisclosure
      cases {
        items {
          id
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePublicMatch = /* GraphQL */ `
  mutation DeletePublicMatch(
    $input: DeletePublicMatchInput!
    $condition: ModelPublicMatchConditionInput
  ) {
    deletePublicMatch(input: $input, condition: $condition) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      limitedDisclosure
      cases {
        items {
          id
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPublicCase = /* GraphQL */ `
  mutation CreatePublicCase(
    $input: CreatePublicCaseInput!
    $condition: ModelPublicCaseConditionInput
  ) {
    createPublicCase(input: $input, condition: $condition) {
      id
      matchId
      sequences {
        items {
          id
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      comments {
        items {
          id
          caseId
          name
          comment
          owner
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      dataAggrFlag
      owner
    }
  }
`;
export const updatePublicCase = /* GraphQL */ `
  mutation UpdatePublicCase(
    $input: UpdatePublicCaseInput!
    $condition: ModelPublicCaseConditionInput
  ) {
    updatePublicCase(input: $input, condition: $condition) {
      id
      matchId
      sequences {
        items {
          id
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      comments {
        items {
          id
          caseId
          name
          comment
          owner
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      dataAggrFlag
      owner
    }
  }
`;
export const deletePublicCase = /* GraphQL */ `
  mutation DeletePublicCase(
    $input: DeletePublicCaseInput!
    $condition: ModelPublicCaseConditionInput
  ) {
    deletePublicCase(input: $input, condition: $condition) {
      id
      matchId
      sequences {
        items {
          id
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      comments {
        items {
          id
          caseId
          name
          comment
          owner
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      dataAggrFlag
      owner
    }
  }
`;
export const createPublicComment = /* GraphQL */ `
  mutation CreatePublicComment(
    $input: CreatePublicCommentInput!
    $condition: ModelPublicCommentConditionInput
  ) {
    createPublicComment(input: $input, condition: $condition) {
      id
      caseId
      name
      comment
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const updatePublicComment = /* GraphQL */ `
  mutation UpdatePublicComment(
    $input: UpdatePublicCommentInput!
    $condition: ModelPublicCommentConditionInput
  ) {
    updatePublicComment(input: $input, condition: $condition) {
      id
      caseId
      name
      comment
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const deletePublicComment = /* GraphQL */ `
  mutation DeletePublicComment(
    $input: DeletePublicCommentInput!
    $condition: ModelPublicCommentConditionInput
  ) {
    deletePublicComment(input: $input, condition: $condition) {
      id
      caseId
      name
      comment
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const createPublicSequence = /* GraphQL */ `
  mutation CreatePublicSequence(
    $input: CreatePublicSequenceInput!
    $condition: ModelPublicSequenceConditionInput
  ) {
    createPublicSequence(input: $input, condition: $condition) {
      id
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePublicSequence = /* GraphQL */ `
  mutation UpdatePublicSequence(
    $input: UpdatePublicSequenceInput!
    $condition: ModelPublicSequenceConditionInput
  ) {
    updatePublicSequence(input: $input, condition: $condition) {
      id
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePublicSequence = /* GraphQL */ `
  mutation DeletePublicSequence(
    $input: DeletePublicSequenceInput!
    $condition: ModelPublicSequenceConditionInput
  ) {
    deletePublicSequence(input: $input, condition: $condition) {
      id
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPrivateGame = /* GraphQL */ `
  mutation CreatePrivateGame(
    $input: CreatePrivateGameInput!
    $condition: ModelPrivateGameConditionInput
  ) {
    createPrivateGame(input: $input, condition: $condition) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      privateMatches {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const updatePrivateGame = /* GraphQL */ `
  mutation UpdatePrivateGame(
    $input: UpdatePrivateGameInput!
    $condition: ModelPrivateGameConditionInput
  ) {
    updatePrivateGame(input: $input, condition: $condition) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      privateMatches {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const deletePrivateGame = /* GraphQL */ `
  mutation DeletePrivateGame(
    $input: DeletePrivateGameInput!
    $condition: ModelPrivateGameConditionInput
  ) {
    deletePrivateGame(input: $input, condition: $condition) {
      id
      owner
      pitcherTeam
      batterTeam
      date
      privateMatches {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      type
    }
  }
`;
export const createPrivateMatch = /* GraphQL */ `
  mutation CreatePrivateMatch(
    $input: CreatePrivateMatchInput!
    $condition: ModelPrivateMatchConditionInput
  ) {
    createPrivateMatch(input: $input, condition: $condition) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      cases {
        items {
          id
          owner
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePrivateMatch = /* GraphQL */ `
  mutation UpdatePrivateMatch(
    $input: UpdatePrivateMatchInput!
    $condition: ModelPrivateMatchConditionInput
  ) {
    updatePrivateMatch(input: $input, condition: $condition) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      cases {
        items {
          id
          owner
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePrivateMatch = /* GraphQL */ `
  mutation DeletePrivateMatch(
    $input: DeletePrivateMatchInput!
    $condition: ModelPrivateMatchConditionInput
  ) {
    deletePrivateMatch(input: $input, condition: $condition) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      title
      cases {
        items {
          id
          owner
          matchId
          inning
          topOrBottom
          outCounts
          runner
          pitcherTeamScore
          batterTeamScore
          createdAt
          updatedAt
          dataAggrFlag
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPrivateCase = /* GraphQL */ `
  mutation CreatePrivateCase(
    $input: CreatePrivateCaseInput!
    $condition: ModelPrivateCaseConditionInput
  ) {
    createPrivateCase(input: $input, condition: $condition) {
      id
      owner
      matchId
      sequences {
        items {
          id
          owner
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      dataAggrFlag
    }
  }
`;
export const updatePrivateCase = /* GraphQL */ `
  mutation UpdatePrivateCase(
    $input: UpdatePrivateCaseInput!
    $condition: ModelPrivateCaseConditionInput
  ) {
    updatePrivateCase(input: $input, condition: $condition) {
      id
      owner
      matchId
      sequences {
        items {
          id
          owner
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      dataAggrFlag
    }
  }
`;
export const deletePrivateCase = /* GraphQL */ `
  mutation DeletePrivateCase(
    $input: DeletePrivateCaseInput!
    $condition: ModelPrivateCaseConditionInput
  ) {
    deletePrivateCase(input: $input, condition: $condition) {
      id
      owner
      matchId
      sequences {
        items {
          id
          owner
          caseId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      pitcherTeamScore
      batterTeamScore
      createdAt
      updatedAt
      dataAggrFlag
    }
  }
`;
export const createPrivateSequence = /* GraphQL */ `
  mutation CreatePrivateSequence(
    $input: CreatePrivateSequenceInput!
    $condition: ModelPrivateSequenceConditionInput
  ) {
    createPrivateSequence(input: $input, condition: $condition) {
      id
      owner
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
    }
  }
`;
export const updatePrivateSequence = /* GraphQL */ `
  mutation UpdatePrivateSequence(
    $input: UpdatePrivateSequenceInput!
    $condition: ModelPrivateSequenceConditionInput
  ) {
    updatePrivateSequence(input: $input, condition: $condition) {
      id
      owner
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
    }
  }
`;
export const deletePrivateSequence = /* GraphQL */ `
  mutation DeletePrivateSequence(
    $input: DeletePrivateSequenceInput!
    $condition: ModelPrivateSequenceConditionInput
  ) {
    deletePrivateSequence(input: $input, condition: $condition) {
      id
      owner
      caseId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
    }
  }
`;
export const createS3AggrDataPointer = /* GraphQL */ `
  mutation CreateS3AggrDataPointer(
    $input: CreateS3AggrDataPointerInput!
    $condition: ModelS3AggrDataPointerConditionInput
  ) {
    createS3AggrDataPointer(input: $input, condition: $condition) {
      owner
      latestAggrDataFileName
      createdAt
      updatedAt
    }
  }
`;
export const updateS3AggrDataPointer = /* GraphQL */ `
  mutation UpdateS3AggrDataPointer(
    $input: UpdateS3AggrDataPointerInput!
    $condition: ModelS3AggrDataPointerConditionInput
  ) {
    updateS3AggrDataPointer(input: $input, condition: $condition) {
      owner
      latestAggrDataFileName
      createdAt
      updatedAt
    }
  }
`;
export const deleteS3AggrDataPointer = /* GraphQL */ `
  mutation DeleteS3AggrDataPointer(
    $input: DeleteS3AggrDataPointerInput!
    $condition: ModelS3AggrDataPointerConditionInput
  ) {
    deleteS3AggrDataPointer(input: $input, condition: $condition) {
      owner
      latestAggrDataFileName
      createdAt
      updatedAt
    }
  }
`;
export const createS3AggrDataPointerForGameFormat = /* GraphQL */ `
  mutation CreateS3AggrDataPointerForGameFormat(
    $input: CreateS3AggrDataPointerForGameFormatInput!
    $condition: ModelS3AggrDataPointerForGameFormatConditionInput
  ) {
    createS3AggrDataPointerForGameFormat(input: $input, condition: $condition) {
      owner
      gameId
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const updateS3AggrDataPointerForGameFormat = /* GraphQL */ `
  mutation UpdateS3AggrDataPointerForGameFormat(
    $input: UpdateS3AggrDataPointerForGameFormatInput!
    $condition: ModelS3AggrDataPointerForGameFormatConditionInput
  ) {
    updateS3AggrDataPointerForGameFormat(input: $input, condition: $condition) {
      owner
      gameId
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const deleteS3AggrDataPointerForGameFormat = /* GraphQL */ `
  mutation DeleteS3AggrDataPointerForGameFormat(
    $input: DeleteS3AggrDataPointerForGameFormatInput!
    $condition: ModelS3AggrDataPointerForGameFormatConditionInput
  ) {
    deleteS3AggrDataPointerForGameFormat(input: $input, condition: $condition) {
      owner
      gameId
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const createPublicGameForGameFormat = /* GraphQL */ `
  mutation CreatePublicGameForGameFormat(
    $input: CreatePublicGameForGameFormatInput!
    $condition: ModelPublicGameForGameFormatConditionInput
  ) {
    createPublicGameForGameFormat(input: $input, condition: $condition) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      publicSituations {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const updatePublicGameForGameFormat = /* GraphQL */ `
  mutation UpdatePublicGameForGameFormat(
    $input: UpdatePublicGameForGameFormatInput!
    $condition: ModelPublicGameForGameFormatConditionInput
  ) {
    updatePublicGameForGameFormat(input: $input, condition: $condition) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      publicSituations {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const deletePublicGameForGameFormat = /* GraphQL */ `
  mutation DeletePublicGameForGameFormat(
    $input: DeletePublicGameForGameFormatInput!
    $condition: ModelPublicGameForGameFormatConditionInput
  ) {
    deletePublicGameForGameFormat(input: $input, condition: $condition) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      publicSituations {
        items {
          id
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const createPublicSituationForGameFormat = /* GraphQL */ `
  mutation CreatePublicSituationForGameFormat(
    $input: CreatePublicSituationForGameFormatInput!
    $condition: ModelPublicSituationForGameFormatConditionInput
  ) {
    createPublicSituationForGameFormat(input: $input, condition: $condition) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      owner
    }
  }
`;
export const updatePublicSituationForGameFormat = /* GraphQL */ `
  mutation UpdatePublicSituationForGameFormat(
    $input: UpdatePublicSituationForGameFormatInput!
    $condition: ModelPublicSituationForGameFormatConditionInput
  ) {
    updatePublicSituationForGameFormat(input: $input, condition: $condition) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      owner
    }
  }
`;
export const deletePublicSituationForGameFormat = /* GraphQL */ `
  mutation DeletePublicSituationForGameFormat(
    $input: DeletePublicSituationForGameFormatInput!
    $condition: ModelPublicSituationForGameFormatConditionInput
  ) {
    deletePublicSituationForGameFormat(input: $input, condition: $condition) {
      id
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      owner
    }
  }
`;
export const createPublicSequenceForGameFormat = /* GraphQL */ `
  mutation CreatePublicSequenceForGameFormat(
    $input: CreatePublicSequenceForGameFormatInput!
    $condition: ModelPublicSequenceForGameFormatConditionInput
  ) {
    createPublicSequenceForGameFormat(input: $input, condition: $condition) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
export const updatePublicSequenceForGameFormat = /* GraphQL */ `
  mutation UpdatePublicSequenceForGameFormat(
    $input: UpdatePublicSequenceForGameFormatInput!
    $condition: ModelPublicSequenceForGameFormatConditionInput
  ) {
    updatePublicSequenceForGameFormat(input: $input, condition: $condition) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
export const deletePublicSequenceForGameFormat = /* GraphQL */ `
  mutation DeletePublicSequenceForGameFormat(
    $input: DeletePublicSequenceForGameFormatInput!
    $condition: ModelPublicSequenceForGameFormatConditionInput
  ) {
    deletePublicSequenceForGameFormat(input: $input, condition: $condition) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
export const createPrivateGameForGameFormat = /* GraphQL */ `
  mutation CreatePrivateGameForGameFormat(
    $input: CreatePrivateGameForGameFormatInput!
    $condition: ModelPrivateGameForGameFormatConditionInput
  ) {
    createPrivateGameForGameFormat(input: $input, condition: $condition) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      privateSituations {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          pitchInfo
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const updatePrivateGameForGameFormat = /* GraphQL */ `
  mutation UpdatePrivateGameForGameFormat(
    $input: UpdatePrivateGameForGameFormatInput!
    $condition: ModelPrivateGameForGameFormatConditionInput
  ) {
    updatePrivateGameForGameFormat(input: $input, condition: $condition) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      privateSituations {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          pitchInfo
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const deletePrivateGameForGameFormat = /* GraphQL */ `
  mutation DeletePrivateGameForGameFormat(
    $input: DeletePrivateGameForGameFormatInput!
    $condition: ModelPrivateGameForGameFormatConditionInput
  ) {
    deletePrivateGameForGameFormat(input: $input, condition: $condition) {
      id
      owner
      firstAttackTeam
      secondAttackTeam
      date
      privateSituations {
        items {
          id
          owner
          gameId
          batter
          bat
          atBats
          pitcher
          throw
          inning
          topOrBottom
          outCounts
          runner
          firstAttackTeamScore
          secondAttackTeamScore
          createdAt
          updatedAt
          dataAggrFlag
          registeredBatterOrder
          registerInningTopOrBottomFinishFlag
          registerBatterFinishFlag
          title
          memo
          detailsForNextBatter
          firstAttackMember
          secondAttackMember
          pitchInfo
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      firstAttackMember
      secondAttackMember
      gameTitle
      registerFinish
    }
  }
`;
export const createPrivateSituationForGameFormat = /* GraphQL */ `
  mutation CreatePrivateSituationForGameFormat(
    $input: CreatePrivateSituationForGameFormatInput!
    $condition: ModelPrivateSituationForGameFormatConditionInput
  ) {
    createPrivateSituationForGameFormat(input: $input, condition: $condition) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      pitchInfo
    }
  }
`;
export const updatePrivateSituationForGameFormat = /* GraphQL */ `
  mutation UpdatePrivateSituationForGameFormat(
    $input: UpdatePrivateSituationForGameFormatInput!
    $condition: ModelPrivateSituationForGameFormatConditionInput
  ) {
    updatePrivateSituationForGameFormat(input: $input, condition: $condition) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      pitchInfo
    }
  }
`;
export const deletePrivateSituationForGameFormat = /* GraphQL */ `
  mutation DeletePrivateSituationForGameFormat(
    $input: DeletePrivateSituationForGameFormatInput!
    $condition: ModelPrivateSituationForGameFormatConditionInput
  ) {
    deletePrivateSituationForGameFormat(input: $input, condition: $condition) {
      id
      owner
      gameId
      batter
      bat
      atBats
      pitcher
      throw
      sequences {
        items {
          id
          owner
          situationId
          sequence
          strikes
          balls
          speed
          pitchResult
          atBatResult
          pitchXPosition
          pitchYPosition
          breakingBall
          memo
          createdAt
          updatedAt
          hittingDirectionImage
        }
        nextToken
      }
      inning
      topOrBottom
      outCounts
      runner
      firstAttackTeamScore
      secondAttackTeamScore
      createdAt
      updatedAt
      dataAggrFlag
      registeredBatterOrder
      registerInningTopOrBottomFinishFlag
      registerBatterFinishFlag
      title
      memo
      detailsForNextBatter
      firstAttackMember
      secondAttackMember
      pitchInfo
    }
  }
`;
export const createPrivateSequenceForGameFormat = /* GraphQL */ `
  mutation CreatePrivateSequenceForGameFormat(
    $input: CreatePrivateSequenceForGameFormatInput!
    $condition: ModelPrivateSequenceForGameFormatConditionInput
  ) {
    createPrivateSequenceForGameFormat(input: $input, condition: $condition) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
export const updatePrivateSequenceForGameFormat = /* GraphQL */ `
  mutation UpdatePrivateSequenceForGameFormat(
    $input: UpdatePrivateSequenceForGameFormatInput!
    $condition: ModelPrivateSequenceForGameFormatConditionInput
  ) {
    updatePrivateSequenceForGameFormat(input: $input, condition: $condition) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
export const deletePrivateSequenceForGameFormat = /* GraphQL */ `
  mutation DeletePrivateSequenceForGameFormat(
    $input: DeletePrivateSequenceForGameFormatInput!
    $condition: ModelPrivateSequenceForGameFormatConditionInput
  ) {
    deletePrivateSequenceForGameFormat(input: $input, condition: $condition) {
      id
      owner
      situationId
      sequence
      strikes
      balls
      speed
      pitchResult
      atBatResult
      pitchXPosition
      pitchYPosition
      breakingBall
      memo
      createdAt
      updatedAt
      hittingDirectionImage
    }
  }
`;
